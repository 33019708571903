import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import { Card, Grid, Container, Typography, Divider, Box } from '@mui/material';
import { GetVendorJobsByid, DeleteVendorJob } from '../../actions/VendorJobsAction';
import CommonDeleteBox from '../../components/Component/ModelBox/CommonDeleteBox';
import PagesTopBar from '../../components/Component/PagesTopBar';
import ShowMessage from '../Modal/ShowMessage';

const VendorJobDetails = () => {
  const navigate = useNavigate();
  const formik = useFormik({});

  const dispatch = useDispatch();
  const vendorJobinfo = useLocation();
  const vendorJobData = vendorJobinfo?.state?.jobData;

  const [candidateId, setCandidateId] = useState();
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [error, setError] = useState(false);

  const { vendorJobs } = useSelector((state) => ({
    vendorJobs: state?.vendorJobs?.vendorJobs.data,
  }));

  console.log('vendorJobs', vendorJobs);

  useEffect(() => {
    dispatch(GetVendorJobsByid(vendorJobData?.uuid));
  }, []);

  // console.log('SINGLE CANDIDATE DATA', vendorJobDetails);
  const columns = [
    { field: 'company_name', headerName: 'Company Name' },
    { field: 'no_of_openings', headerName: 'No of Openings' },
    { field: 'job_min_experience', headerName: 'Minimum Experience' },
  ];
  const rows = [
    {
      id: 1,
      company_name: vendorJobs?.company_name,
      no_of_openings: vendorJobs.no_of_openings,
      job_min_experience: vendorJobs.job_min_experience,
    },
  ];
  const columns1 = [
    { field: 'job_max_experience', headerName: 'Maximum Experience' },
    { field: 'job_min_salary', headerName: 'Minimum Salary' },
    { field: 'job_max_salary', headerName: 'Maximum Salary' },
  ];
  const rows1 = [
    {
      id: 1,
      job_max_experience: vendorJobs?.job_max_experience,
      job_min_salary: vendorJobs?.job_min_salary,
      job_max_salary: vendorJobs?.job_max_salary,
    },
  ];
  const columns2 = [
    { field: 'education', headerName: 'Education' },
    { field: 'city', headerName: 'City' },
    { field: 'State', headerName: 'State' },
    // { field: 'Country', headerName: 'Country' },
    // { field: 'Created_On', headerName: 'Created On' },
  ];
  const rows2 = [
    {
      id: 1,
      city: vendorJobs?.city,
      education: vendorJobs?.recruitmentEducation?.name,
      State: vendorJobs?.state,
      Country: 'India',
      // Created_On: vendorJobDetails?.createdAt ? fDateTime(vendorJobDetails?.data?.createdAt) : 'NA',
    },
  ];

  const handleSubmit = () => {
    dispatch(DeleteVendorJob(vendorJobData?.uuid))
      .then((response) => {
        console.log('response', response);
        if (response.status === 200) {
          setIsShow(true);
          setErrorMessage('Vendor Job deleted successfully');
          setOpen(!open);
        }
        setTimeout(() => {
          setIsShow(false);
          navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };
  const handleDeleteCancle = () => {
    // setCandidateId(e.uuid);
    setOpen(!open);
  };
  const handleDelete = (e) => {
    console.log('Em', e.uuid);
    setCandidateId(e.uuid);
    setOpen(!open);
  };
  return (
    <div>
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Vendor Job"
          description="Are you sure you want to delete Job ?"
        />
      ) : null}
      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />

      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <PagesTopBar
          title=" Vendor Job Details"
          subTitle="Showing details of Vendor Job"
          btnText="Delete Vendor job"
          Delete
          handleDelete={handleDelete}
          hideSearch
        />

        <Divider sx={{ mt: '10px' }} />
        <Card sx={{ padding: '20px' }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 700,
            }}
          >
            Basic Details
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            It is showing vendor job details
          </Typography>
          <Divider sx={{ mt: '10px' }} />
          <Typography
            sx={{
              fontSize: '30px',
              fontWeight: 700,
            }}
          >
            {vendorJobs?.job_title}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              marginTop: '20px',
              fontWeight: 700,
            }}
          >
            Job Description
          </Typography>
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: 500,
            }}
          >
            {vendorJobs?.job_description}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              marginTop: '20px',
            }}
          >
            Job Category
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 700,
              textAlign: 'center',
              backgroundColor: '#d1e7d9',
              color: '#035421',
              borderRadius: '6px',
              border: '1.5px solid #035421',
              width: 'max-Content',
              paddingX: '20px',
            }}
          >
            {vendorJobs?.recruitmentJobCategoryModel?.name}
          </Typography>
          <Divider sx={{ mt: '10px' }} />

          {rows.map((row) => (
            <Grid item key={row.id} xs={12}>
              <Grid container spacing={2}>
                {columns.map((column) => (
                  <Grid item xs key={column.field}>
                    <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                      {column.headerName}:
                    </Typography>
                    <Typography variant="body1" component="div">
                      {row[column.field]}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}

          <Grid container spacing={2} style={{ marginTop: '20PX' }}>
            {rows1.map((row) => (
              <Grid item key={row.id} xs={12}>
                <Grid container spacing={2}>
                  {columns1.map((column) => (
                    <Grid item xs key={column.field}>
                      <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                        {column.headerName}:
                      </Typography>
                      <Typography variant="body1" component="div">
                        {row[column.field]}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '20PX' }}>
            {rows2.map((row) => (
              <Grid item key={row.id} xs={12}>
                <Grid container spacing={2}>
                  {columns2.map((column) => (
                    <Grid item xs key={column.field}>
                      <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                        {column.headerName}:
                      </Typography>
                      <Typography variant="body1" component="div">
                        {row[column.field]}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </div>
  );
};

export default VendorJobDetails;
