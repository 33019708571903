import Server from '../api';
import axiosInstance from '../api/withJWTServer';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import { LOGIN, RESET_STATE, VENDOR_DETAIL, PROFILE_PIC, PROFILE_DATA, SUBSRIPTION_PLANS } from './Types';

const LoginUser = (params) => async (dispatch) => {
  if (params.slug === 0) {
    axiosInstance
      .post('vendor/register', params)
      .then((response) => {
        dispatch({
          type: LOGIN,
          payload: response?.data?.data[0],
        });
        dispatch(HandleExceptionWithSecureCatch(response));
      })
      .catch((err) => {
        dispatch(
          HandleExceptionWithSecureCatch({
            data: { err },
          })
        );
      });
  }
};

const Logout = (params) => async (dispatch) => {
  const response = await axiosInstance.get('vendor/logout', params);
  await localStorage.clear();
  // localStorage.clear();
  dispatch({
    type: RESET_STATE,
    payload: null,
  });
  // dispatch({
  //   type: PROFILE_DATA,
  //   payload: null,
  // });
  // dispatch({
  //   type: RESET_STATE,
  //   payload: null,
  // });
  // dispatch({
  //   type: PROFILE_PIC,
  //   payload: '',
  // });
  // dispatch({
  //   type: VENDOR_DETAIL,
  //   payload: 1,
  // });
  // dispatch({
  //   type: SUBSRIPTION_PLANS,
  //   payload: null,
  // });
};

export { LoginUser, Logout };
