import React, { useState, useEffect, useRef } from 'react';

import { Button, Typography, Stack, Box, Divider, Grid, TextField, Autocomplete, IconButton } from '@mui/material';

import { Form, Formik, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../../api/withJWTServer';

import { VENDOR_DETAIL } from '../../actions/Types';
import { fetchProfile } from '../../api/CommanApicall';

// import axiosInstance from '../../api/withJWTServer';

const ShopAddressDetails = (props) => {
  const GOOGLE_GEOCODING_API_KEY = 'AIzaSyBk1nf5tRWZ1h6ccQGqstoAh-Di6VH3iXU';
  const dispatch = useDispatch();
  const [cities, setAllCities] = useState([]);
  const [selecteCity, setSelectedCity] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [states, setState] = useState([]);
  const [selectedState, setSelectedState] = useState('');

  const vendorUUID = useRef('');

  const [submitLoader, setSubmitLoader] = useState(false);

  const navigate = useNavigate();

  console.log('Propsss', props);

  const countries = [
    {
      id: '1',
      value: 'India',
      label: 'India',
    },
  ];

  const getVendorProfile = async () => {
    try {
      const response = await fetchProfile();
      if (response.data?.data) {
        // setProfileData(response.data.data);
        const profileInfo = response.data.data[0];

        if (profileInfo) {
          vendorUUID.current = profileInfo.uuid ?? '';
        }
      }
    } catch (error) {
      // Handle error if fetchProfile() fails
      console.error('Error fetching vendor profile:', error);
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`vendor/get-districts?key=${selectedState}`)
      .then((res) => {
        setAllCities(res?.data?.data);
      })
      .catch((err) => {});
  }, [selectedState]);

  useEffect(() => {
    getVendorProfile();
  }, []);

  // const categoryId = useRef('');
  useEffect(() => {
    axiosInstance
      .get('vendor/get-states')
      .then((res) => {
        setState(res.data.data);
      })
      .catch((err) => {});
  }, [selectedCountry]);

  const submitData = async () => {
    setSubmitLoader(true);
    const params = {
      selected_plan_uuid: props.selectedPlanUUID,
      selected_plan_name: props.selectedPlanName,
    };

    await axiosInstance
      .patch('vendor/update-business-details', params)
      .then((res) => {
        setSubmitLoader(false);
        setTimeout(() => {
          dispatch({
            type: VENDOR_DETAIL,
            payload: 0,
          });
          navigate('/dashboard/app', { replace: true });
        }, [1500]);
      })
      .catch((error) => {
        console.log('Error', error);
      });
  };

  const validationShop = Yup.object().shape({
    address: Yup.string().required('Shope Address is required').nullable(),
    // landmark: Yup.string().required('Landmark is required'),
    country: Yup.string().required('Country is required').nullable(),
    state: Yup.string().required('State is required').nullable(),
    city: Yup.string().required('City is required').nullable(),
    pin: Yup.string()
      .required('Postal Code is required')
      .matches(/^\d{6}$/, 'Postal code must be 6 digits')
      .nullable(),
  });
  const formInitialValues = {
    address: '',
    landmark: '',
    country: '',
    state: '',
    city: '',
    pin: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: validationShop,
    onSubmit: (values, actions) => {
      console.log('values in address', values);
      setSubmitLoader(true);
      const params = [
        {
          vendor_uuid: vendorUUID.current,
          plan_id: props.selectedPlanUUID,
          address_line_1: values.address,
          address_line_2: values.landmark,
          country: values.country,
          city: values.city,
          state: values.state,
          pin: values.pin,
          isUpgrade: false,
        },
      ];
      console.log('values in address param', params);
      axiosInstance
        .post('vendor/outlet-address', params)
        .then((res) => {
          setSubmitLoader(false);
          if (res.data.status === 200) {
            submitData();
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
        });
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setFieldError } = formik;
  const handleCountryChange = (e, newValue) => {
    // formik.handleChange(e);
    // setSelectedCountry(newValue?.value);
    // setSelectedState('');
    formik.setFieldValue('country', newValue?.value);
    formik.setFieldValue('state', '');
    console.log('Selected country:', newValue?.value);
  };
  const handleStateChange = (e, newValue) => {
    // formik.handleChange(e);
    // setSelectedState(newValue?.value);
    // setSelectedCity('');
    formik.setFieldValue('state', newValue?.value);
    formik.setFieldValue('city', '');
    console.log('Selected state:', newValue?.value);

    axiosInstance
      .get(`vendor/get-districts?key=${newValue?.value}`)
      .then((res) => {
        setAllCities(res?.data?.data);
      })
      .catch((err) => {});
  };

  const handleCityChange = (e, newValue) => {
    // formik.handleChange(e);
    // setSelectedCity(newValue?.value);
    formik.setFieldValue('city', newValue?.value);
    console.log('Selected city:', newValue?.value);
  };
  const handlePostalCode = async (postalCode) => {
    console.log('postal code', postalCode);
    if (/^\d{6}$/.test(postalCode)) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${GOOGLE_GEOCODING_API_KEY}`
        );
        if (response.data.results.length > 0) {
          const { address_components: AddressComponent } = response.data.results[0];

          let city = '';
          let state = '';

          AddressComponent.forEach((component) => {
            if (component.types.includes('locality')) {
              city = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
            }
          });
          setSelectedState(state);
          formik.setFieldValue('state', state);

          axiosInstance
            .get(`vendor/get-districts?key=${state}`)
            .then((res) => {
              setAllCities(res?.data?.data);
              if (res?.data?.data.some((item) => item.value === city)) {
                setSelectedCity(city);
                formik.setFieldValue('city', city);
              } else {
                setSelectedCity(null);
              }
            })
            .catch((err) => {});
          // setWrongPostalCode(false);
        } else {
          // setWrongPostalCode(true);
          // setOpenPostalModel(true);
          formik.setFieldValue('state', '');
          formik.setFieldValue('city', '');
          formik.setFieldValue('pin', '');
          // throw new Error('No results found for the given postal code.');
        }
      } catch (error) {
        // setWrongPostalCode(true);
        // setOpenPostalModel(true)
        // throw new Error('Error fetching location information. Please try again.');
      }
    }
  };
  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid lg={6} item>
              <TextField
                fullWidth
                size="medium"
                type="text"
                variant="outlined"
                name="address"
                label="Shop Address*"
                disabled={formik.values.vendor_status === 'Active'}
                inputProps={{
                  style: {
                    fontSize: '16px ',
                  },
                }}
                error={Boolean(formik.touched.address && formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                {...getFieldProps('address')}
              />
            </Grid>

            <Grid lg={6} item>
              <TextField
                fullWidth
                size="medium"
                name="landmark"
                variant="outlined"
                label="Landmark"
                inputProps={{
                  style: {
                    fontSize: '16px ',
                  },
                }}
                {...getFieldProps('landmark')}
              />
            </Grid>

            <Grid lg={6} item>
              <Autocomplete
                disablePortal
                fullWidth
                isOptionEqualToValue={(option, value) => option === value}
                name="country*"
                options={countries}
                value={formik.values.country || null}
                onChange={(event, newValue) => handleCountryChange(event, newValue)}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="country"
                    label="Country"
                    size="medium"
                    variant="outlined"
                    {...getFieldProps('country')}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                )}
              />
            </Grid>
            <Grid lg={6} item>
              <Autocomplete
                disablePortal
                fullWidt
                options={states}
                value={formik.values.state || null}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event, newValue) => handleStateChange(event, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="state"
                    label="State*"
                    size="medium"
                    variant="outlined"
                    {...getFieldProps('state')}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                )}
              />
            </Grid>
            <Grid lg={6} item>
              <Autocomplete
                disablePortal
                fullWidth
                isOptionEqualToValue={(option, value) => option === value}
                options={cities}
                value={formik.values.city || null}
                onChange={(event, newValue) => handleCityChange(event, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="city"
                    label="City*"
                    size="medium"
                    variant="outlined"
                    {...getFieldProps('city')}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                )}
              />
            </Grid>

            <Grid lg={6} item>
              <TextField
                fullWidth
                size="medium"
                variant="outlined"
                name="pin"
                label="Postal Code*"
                inputProps={{
                  style: {
                    fontSize: '16px ',
                  },
                }}
                value={formik.values.pin} // Add value prop to bind the input to Formik's value
                onChange={(e) => {
                  handlePostalCode(e.target.value); // Call your custom handler
                  formik.handleChange(e); // Call Formik's handleChange to update Formik's state
                }}
                onBlur={formik.handleBlur} // Ensure Formik handles blur events for validation
                error={Boolean(formik.touched.pin && formik.errors.pin)} // Use Formik's touched and errors to handle errors
                helperText={formik.touched.pin && formik.errors.pin}
              />
            </Grid>

            <Grid item lg={12}>
              <Divider sx={{ my: '10px' }} />
            </Grid>

            <Grid lg={12} item mt={'5px'}>
              <Stack direction={'row'} justifyContent={'flex-end'} flex={1}>
                {props.isStepper ? (
                  <LoadingButton
                    variant="outlined"
                    size="medium"
                    color="error"
                    disableElevation
                    onClick={props.onBackPress}
                    sx={{
                      alignSelf: 'flex-end',
                      padding: '8px 40px',
                      marginRight: '10px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Back
                  </LoadingButton>
                ) : null}

                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={submitLoader}
                  disableElevation
                  sx={{
                    alignSelf: 'flex-end',
                    padding: '8px 40px',
                    textTransform: 'uppercase',
                    '&:hover': {
                      bgcolor: 'green',
                    },
                  }}
                >
                  {props.isStepper ? 'Submit For Approval ' : 'Save'}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default ShopAddressDetails;
