import axiosInstance from '../api/withJWTServer';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import { GET_CANDIDATES, GET_CANDIDATES_BY_ID, GET_CITY, GET_EDUCATION, GET_JOB_CATEGORY, GET_STATE } from './Types';

const GetCandidates = (params) => async (dispatch) => {
  let url = `/vendor/get-recruitment-candidates?page=${params.page}&limit=${params.limit}`;

  if (params.jobCategoryId) {
    url = `${url}&job_category=${params.jobCategoryId}`;
  }
  if (params.educationId) {
    url = `${url}&education=${params.educationId}`;
  }

  if (params.stateValue) {
    url = `${url}&state=${params.stateValue}`;
  }

  if (params.cityValue) {
    url = `${url}&city=${params.cityValue}`;
  }

  axiosInstance
    .get(url)
    .then((response) => {
      dispatch({
        type: GET_CANDIDATES,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        HandleExceptionWithSecureCatch({
          data: { err },
        })
      );
    });
};

const GetCandidateById = (params) => async (dispatch) => {
  const url = `/vendor/get-recruitment-candidate-by-id?uuid=${params}`;

  axiosInstance
    .get(url)
    .then((response) => {
      dispatch({
        type: GET_CANDIDATES_BY_ID,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        HandleExceptionWithSecureCatch({
          data: { err },
        })
      );
      dispatch({
        type: GET_CANDIDATES_BY_ID,
        payload: {},
      });
    });
};

const GetJobCategory = (params) => async (dispatch) => {
  axiosInstance
    .get(`/vendor/get-recruitment-job-categories`)
    .then((response) => {
      dispatch({
        type: GET_JOB_CATEGORY,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        HandleExceptionWithSecureCatch({
          data: { err },
        })
      );
    });
};

const GetEducation = (params) => async (dispatch) => {
  axiosInstance
    .get(`/vendor/get-recruitment-educations`)
    .then((response) => {
      dispatch({
        type: GET_EDUCATION,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        HandleExceptionWithSecureCatch({
          data: { err },
        })
      );
    });
};

const GetState = (params) => async (dispatch) => {
  axiosInstance
    .get(`/vendor/get-states`)
    .then((response) => {
      dispatch({
        type: GET_STATE,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        HandleExceptionWithSecureCatch({
          data: { err },
        })
      );
    });
};

const GetCity = (params) => async (dispatch) => {
  axiosInstance
    .get(`/vendor/get-districts?key=${params}`)
    .then((response) => {
      dispatch({
        type: GET_CITY,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        HandleExceptionWithSecureCatch({
          data: { err },
        })
      );
    });
};

export { GetCandidates, GetCandidateById, GetJobCategory, GetEducation, GetState, GetCity };
