import React from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Box,
  // Button
} from '@mui/material';

const JobCategoryButton = (props) => {
  return (
    <div>
      <Button
        variant="contained"
        size="small"
        disableElevation
        sx={{
          paddingY: '5px',
          borderRadius: '5px',
          bgcolor: '#0B8238',
          fontSize: '10px',
          fontWeight: '400',
          '&:hover': {
            background: '#0B8238',
          },
        }}
        // disableElevation
        // startIcon={<Add />}
      >
        {props.data}
      </Button>
    </div>
  );
};

export default JobCategoryButton;
