import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import { green } from '@mui/material/colors';
// components
import Page from '../components/Page';
import noConnection from '../Assets/no_connection.png';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  backgroundColor: green[800],
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <Page title="404 Page Not Found" style={{ backgroundColor: green[800] }}>
      <Container style={{ backgroundColor: green[800] }}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" color={'white'} paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: 'white' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
          </Typography>

          <Box component="img" src={noConnection} sx={{ height: 150, mx: 'auto', my: { xs: 5, sm: 10 } }} />

          <Button
            to="/"
            size="large"
            disableElevation
            sx={{
              bgcolor: 'white',
              color: 'green',
              borderRadius: '6px',
              padding: '10px 40px',
              '&:hover': {
                bgcolor: 'white',
                opacity: '0.9',
              },
            }}
            component={RouterLink}
          >
            Go to Home Page
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
