import axios from 'axios';

// const isStage = window.location.hostname.includes('stage');

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_VENDOR,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token_vendor');
  config.headers.token = token;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      setTimeout(() => {
        localStorage.clear();
      }, 1000);
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000);
    }
  }
);

export default axiosInstance;
