import React from 'react';
import { makeStyles } from '@material-ui/styles';

const StatusBox = (props) => {
    
    const useStyles = makeStyles((theme) => ({
        withHold: {
            border: "2px solid #d0250e",
            borderRadius: "10px",
            padding: `${props.slug === "mob"? "5px" : "5px"}`,
            color: "#d0250e",
            backgroundColor: "#f6d3cf",
            fontSize: "15px",
            fontWeight: 600
          },
          renewal: {
            border: "2px solid #B507D1",
            borderRadius: "10px",
            padding: "10px",
            color: "#B507D1",
            backgroundColor: "#f4daf8",
            fontSize: "15px",
            fontWeight: 600
          },
          approve: {
            border: "2px solid #035421",
            borderRadius: "10px",
            padding: "10px",
            color: "#035421",
            backgroundColor: "#d1e7d9",
            fontSize: "15px",
            fontWeight: 600
          },
          underProcess: {
            border: "2px solid #E17A15",
            borderRadius: "10px",
            padding: "10px",
            color: "#E17A15",
            backgroundColor: "#f4ddc6",
            fontSize: "15px",
            fontWeight: 600
          },
          initialted:{
            border: "2px solid #0B1782",
            borderRadius: "10px",
            padding: "10px",
            color: "#0B1782",
            backgroundColor: "#dbdaec",
            fontSize: "15px",
            fontWeight: 600
          },
        color: {
          color: '#07703C',
        },
      }));
      const classes = useStyles();

    let alertComponent;

    if (props.profileDetail === 'Active') {
      alertComponent = <span className={classes.approve}>Approved</span>;
    } else if (props.profileDetail === 'Under Process') {
      alertComponent = <span className={classes.underProcess}>{props?.profileDetail?.toUpperCase()}</span>;
    } else if (props.profileDetail === 'Renewal') {
      alertComponent = <span className={classes.renewal}>{props?.profileDetail?.toUpperCase()}</span>;
    } else if (props.profileDetail === 'Submitted for Renewal') {
      alertComponent = <span>{props?.profileDetail?.toUpperCase()}</span>;
    } else if (props.profileDetail === 'With-Hold') {
      alertComponent = <span className={classes.withHold}>With-Hold</span>;
    } else {
      alertComponent = <span className={classes.initialted}>{props?.profileDetail?.toUpperCase()}</span>;
    }

  return (
    <>{alertComponent}</>
  )
}

export default StatusBox