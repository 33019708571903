
import React from 'react'

const ButtonCard = (props) => (
    <span style={{
        padding: '7px 15px',
        cursor: 'pointer',
        color: "#fff",
        borderRadius: '20px',
        border: '1.5px solid #0B8238',
        background: '#0B8238',}}>{props?.text}</span>
  )

export default ButtonCard