import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Card,
  Button,
  Typography,
  Stack,
  Box,
  Divider,
  Grid,
  TextField,
  Autocomplete,
  IconButton,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import ShowMessage from './Modal/ShowMessage';
import BusinessDetails from './TabComp/BusinessDetails';
import BusinessAddress from './TabComp/BusinessAddress';
import BusinessDocument from './TabComp/BusinessDocument';
import PersonalDetails from './TabComp/PersonalDetails';

const BusinessProfile = () => {
  const [value, setValue] = React.useState('1');
  const [open, setOpen] = React.useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const showToastMessage = (message) => {
    setIsShow(true);
    setError(false);
    setErrorMessage(message);
    setTimeout(() => {
      setIsShow(false);
    }, 1500);
  };

  const showErrorToastMessage = (message) => {
    setIsShow(true);
    setError(true);
    setErrorMessage(message);
    setTimeout(() => {
      setIsShow(false);
    }, 1500);
  };

  return (
    <>
      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      <Box px={'10px'}>
        <Stack>
          <Typography fontWeight={'600'} fontSize={'18px'}>
            Business Profile
          </Typography>
          <Typography fontSize={'14px'}>Update your business details </Typography>
        </Stack>

        <Card sx={{ mt: '10px', padding: '10px' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="profile-tabs">
                <Tab label="Business Details " value="1" />
                <Tab label="Address Details " value="2" />
                <Tab label="Document Details" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <BusinessDetails
                showToastMessage={(message) => {
                  showToastMessage(message);
                }}
                showErrorToastMessage={(message) => {
                  showErrorToastMessage(message);
                }}
              />
            </TabPanel>
            <TabPanel value="2">
              <BusinessAddress
                showToastMessage={(message) => {
                  showToastMessage(message);
                }}
              />
            </TabPanel>
            <TabPanel value="3">
              <BusinessDocument
                showToastMessage={(message) => {
                  showToastMessage(message);
                }}
                showErrorToastMessage={(message) => {
                  showErrorToastMessage(message);
                }}
              />
            </TabPanel>
            <TabPanel value="4">
              <PersonalDetails
                showToastMessage={(message) => {
                  showToastMessage(message);
                }}
                showErrorToastMessage={(message) => {
                  showErrorToastMessage(message);
                }}
              />
            </TabPanel>
          </TabContext>
        </Card>
      </Box>
    </>
  );
};

export default BusinessProfile;
