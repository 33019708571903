import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik'; // Import Field from Formik
import { FormHelperText, InputLabel, FormControl, MenuItem, Select } from '@mui/material';

function SelectCity(props) {
  const { label, data, onValueChangeCity, ...rest } = useMemo(() => props, [props]);
  const [field, meta, helpers] = useField(props); // Use Formik's useField hook

  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }
  useEffect(() => {
    if (props.initialCity) {
      helpers.setValue(props.initialCity);
    }
  }, [props.initialCity]);
  const handleValueChange = (event) => {
    const newValue = event.target.value;
    helpers.setValue(newValue); // Use Formik's setValue to update the form field value
    if (newValue) {
      onValueChangeCity(newValue);
    }
  };
  useEffect(() => {
    if (props.currentCity) {
      helpers.setValue(props.currentCity); // Use Formik's setValue to set the initial value
    }
  }, [props.currentCity]);

  return (
    <FormControl {...rest} error={isError} variant="standard" sx={{ mt: '-4px!important' }}>
      <InputLabel>{label}</InputLabel>
      <Select {...field} value={selectedValue} label={label} onChange={handleValueChange}>
        {data?.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'flex-start',
              padding: '5px',
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

SelectCity.defaultProps = {
  data: [],
};

SelectCity.propTypes = {
  data: PropTypes.array,
};

export default SelectCity;
