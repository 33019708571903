import React, { useState } from 'react';

import { Button, Typography, Stack, Box, CircularProgress } from '@mui/material';

import FilePresentIcon from '@mui/icons-material/FilePresent';

import pdfImg from '../../Assets/pdf.png';
import TabBusinesImages from './Model/TabBusinessImage';

const DocUploadComponent = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  console.log('openDialog', openDialog);

  const handleViewdoc = (e) => {
    console.log('hfhkfchuid', e);
    setOpenDialog(true);
  };

  console.log('props in doc.', props);
  return (
    <>
      <Stack>
        <Typography fontWeight={'500'}> {props.title} </Typography>
        <Typography fontSize={'12px'}> {props.subTitle}</Typography>

        {(() => {
          if (props.selectedFile) {
            if (props.selectedFile.type === 'image/gif') {
              // Handle GIF file type here, you might want to display a placeholder or show an error message
              return (
                <Typography variant="body1" color="error">
                  GIF files are not allowed.
                </Typography>
              );
            }

            if (props.selectedFile.name.includes('.pdf')) {
              return (
                <img
                  src={pdfImg}
                  width={'120px'}
                  height={'120px'}
                  alt={'gst'}
                  style={{ borderRadius: '10px', margin: '10px' }}
                />
              );
            }

            return (
              <img
                src={URL.createObjectURL(props.selectedFile)}
                width={'130px'}
                height={'130px'}
                alt={'gst'}
                style={{ borderRadius: '10px', margin: '10px' }}
              />
            );
          }

          if (props.path.includes('.pdf')) {
            return (
              <img
                src={pdfImg}
                width={'130px'}
                height={'130px'}
                alt={'gst'}
                style={{ borderRadius: '10px', margin: '10px' }}
              />
            );
          }

          if (props.path.includes('https:')) {
            return (
              <img
                src={props.path}
                width={'130px'}
                height={'130px'}
                alt={'gst'}
                style={{ borderRadius: '10px', margin: '10px' }}
              />
            );
          }

          if (props.selectedFile) {
            return (
              <img
                src={URL.createObjectURL(props.selectedFile)}
                width={'120px'}
                height={'120px'}
                alt={'gst'}
                style={{ borderRadius: '10px', margin: '10px' }}
              />
            );
          }

          return (
            <FilePresentIcon
              sx={{
                fontSize: '130px',
                color: 'black',
                opacity: '0.2',
                margin: '10px',
              }}
            />
          );
        })(1)}

        {props.status === 'Active' ? (
          <Stack direction={'row'} spacing={1} ml={'10px'}>
            {props.path.includes('https') && (
              <Box>
                <Button
                  variant="outlined"
                  disableElevation
                  // onClick={(e) => {}}
                  sx={{
                    mt: '10px',
                    mr: '10px',
                    padding: '5px 15px',
                    justifySelf: 'flex-start',
                    alignSelf: 'flex-start',
                  }}
                  size="small"
                  onClick={handleViewdoc}
                >
                  View Documentsss
                </Button>
              </Box>
            )}
          </Stack>
        ) : (
          <Stack direction={'row'} spacing={1} ml={'10px'}>
            {!props.path.includes('https:') ? (
              <Box>
                <Button
                  variant="outlined"
                  sx={{ mt: '10px', padding: '5px 15px', justifySelf: 'flex-start', alignSelf: 'flex-start' }}
                  size="small"
                  onClick={() => props.openFileDialog()}
                >
                  Select Document
                </Button>

                {props.selectedFile && (
                  <Button
                    variant="contained"
                    disableElevation
                    color="error"
                    onClick={(e) => {
                      props.deleteLocalFile();
                    }}
                    sx={{
                      mt: '10px',
                      ml: '10px',
                      padding: '5px 15px',
                      justifySelf: 'flex-start',
                      alignSelf: 'flex-start',
                    }}
                    size="small"
                  >
                    Remove
                  </Button>
                )}
              </Box>
            ) : (
              <Box>
                <Button
                  variant="outlined"
                  disableElevation
                  onClick={handleViewdoc}
                  sx={{
                    mt: '10px',
                    mr: '10px',
                    padding: '5px 15px',
                    justifySelf: 'flex-start',
                    alignSelf: 'flex-start',
                  }}
                  size="small"
                >
                  View Document
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  color="error"
                  onClick={(e) => {
                    props.deleteFile();
                  }}
                  sx={{ mt: '10px', padding: '5px 15px', justifySelf: 'flex-start', alignSelf: 'flex-start' }}
                  size="small"
                >
                  Remove
                </Button>
              </Box>
            )}
          </Stack>
        )}
      </Stack>
      <TabBusinesImages docs={props} isOpen={openDialog} handleClose={() => setOpenDialog(false)} />
    </>
  );
};

export default DocUploadComponent;
