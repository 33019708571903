// @mui
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { styled, Container, Grid, Stack, Typography, Button, CircularProgress } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import axiosInstance from '../api/withJWTServer';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  background: '#0b8238',
  height: '100%',
  alignItems: 'center',
  alignContent: 'center',
  alignSelf: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
}));

// ----------------------------------------------------------------------

export default function ValidatingMatrimonyPayment() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  console.log('queryParameters in ValidatingPayment object', queryParameters);
  console.log('queryParameters in ValidatingPayment', queryParameters.get('razorpay_payment_id'));

  // razorpay_payment_id=pay_NoHTcJejvfmgDi&razorpay_payment_link_id=plink_NoHSsA9zXCYv0v&razorpay_payment_link_reference_id=&razorpay_payment_link_status=paid&razorpay_signature=bb41559e457d96eeeb2d86aa9433f8194600c81a28e37b8c4e1c3d3665e91ee0

  const [somethingWrong, setSomethingWrong] = useState(false);

  const verifyPayment = async () => {
    setSomethingWrong(false);
    const paramsData = {
      razorpay_payment_id: queryParameters.get('razorpay_payment_id'),
      razorpay_payment_link_id: queryParameters.get('razorpay_payment_link_id'),
      razorpay_payment_link_reference_id: queryParameters.get('razorpay_payment_link_reference_id'),
      razorpay_payment_link_status: queryParameters.get('razorpay_payment_link_status'),
      razorpay_signature: queryParameters.get('razorpay_signature'),
    };

    if (paramsData.razorpay_payment_id && paramsData.razorpay_payment_link_status) {
      const respo = await axiosInstance
        .get('/admin/verify-payment', {
          params: paramsData,
        })
        .then((res) => {
          console.log('res of verify payment', res);
          if (res.status === 200) {
            setTimeout(() => {
              if (res.data.success) {
                navigate('/sucessPayment');
              } else {
                navigate('/failPayment');
              }
            }, 2000);
          } else {
            setSomethingWrong(true);
          }
        })
        .catch((error) => {
          console.log('error of verify payment', error);
          setSomethingWrong(true);
        });
    } else {
      setSomethingWrong(true);
    }
  };

  useEffect(() => {
    verifyPayment();
  }, []);

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="ValidatingMatrimonyPayment" style={{ height: '100%' }}>
      <RootStyle>
        <Stack sx={{ alignItems: 'center' }}>
          <CircularProgress sx={{ color: 'white' }} />

          <Typography mt={'10px'} fontWeight={'600'} color={'white'}>
            {somethingWrong
              ? 'Something went wrong .'
              : 'Please wait we are validating your payment. Please do not refresh the page or go back.'}
          </Typography>
        </Stack>
      </RootStyle>
    </Page>
  );
}
