import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import AlertReducer from './AlertReducer';
import ProfilePicReducer from './ProfileReducer';
import VendorDocsReducer from './VendorDocsReducer';
import ProfileDataReducer from './ProfileDataReducer';
import SubscriptionDataReducer from './SubsriptionReducer';
import SelectedPlansReducer from './SelectedPlansReducer';
import upgradeplanReducer from './UpgradeplanReducer';
import CandidateReducer from './CandidateReducer';
import VendorJobsReducer from './VendorJobsReducer';

export default combineReducers({
  auth: AuthReducer,
  alerts: AlertReducer,
  profilePic: ProfilePicReducer,
  vendorDetailDocs: VendorDocsReducer,
  profileData: ProfileDataReducer,
  subscriptionData: SubscriptionDataReducer,
  selectedPlans: SelectedPlansReducer,
  upgradePlan: upgradeplanReducer,
  candidates: CandidateReducer,
  vendorJobs: VendorJobsReducer,
});
