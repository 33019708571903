import axiosInstance from '../api/withJWTServer';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import {
  GET_VENDOR_JOBS,
  UPDATE_VENDOR_JOB,
  DELETE_VENDOR_JOB,
  CREATE_VENDOR_JOB,
  GET_CANDIDATES_BY_ID,
} from './Types';

const GetVendorJobs = (params, profileData) => async (dispatch) => {
  const url = params.filterData
    ? `/vendor/get-vendor-jobs?uuid=${profileData}&page=${params.page + 1}&limit=${params.rowsPerPage}&key=${
        params.filterData
      }`
    : `/vendor/get-vendor-jobs?uuid=${profileData}&page=${params.page + 1}&limit=${params.rowsPerPage}`;
  axiosInstance
    .get(url)
    .then((response) => {
      console.log('response', response);
      dispatch({
        type: GET_VENDOR_JOBS,
        payload: response?.data,
      });
    })
    .catch((err) => {
      console.log('error', err);
    });
};

const GetVendorJobsByid = (params) => async (dispatch) => {
  const url = `/vendor/get-vendor-jobs-by-id?uuid=${params}`;
  axiosInstance
    .get(url)
    .then((response) => {
      dispatch({
        type: GET_VENDOR_JOBS,
        payload: response.data,
      });
    })
    .catch((err) => {
      // dispatch(
      //   HandleExceptionWithSecureCatch({
      //     data: { err },
      //   })
      // );
      console.log('err', err);
    });
};

const AddVendorJob = (params) => async (dispatch) => {
  try {
    console.log('paramsin add job', params);
    const url = `/vendor/create-vendor-jobs`;
    const response = await axiosInstance.post(url, params);
    dispatch({
      type: CREATE_VENDOR_JOB,
      payload: response?.data,
    });
    return response;
  } catch (err) {
    console.error('Error:', err);
    throw err;
  }
};

const UpdateVendorJob = (params, uuid) => async (dispatch) => {
  try {
    const url = `/vendor/update-vendor-jobs?uuid=${uuid}`;
    const response = await axiosInstance.patch(url, params);
    console.log('res9uo9', response);
    dispatch({
      type: UPDATE_VENDOR_JOB,
      payload: response?.data,
    });
    return response;
  } catch (err) {
    console.error('Error:', err);
    throw err;
  }
};

const DeleteVendorJob = (params) => async (dispatch) => {
  try {
    const url = `/vendor/delete-vendor-jobs?uuid=${params}`;
    const response = await axiosInstance.delete(url); // Make the API call using async/await
    console.log('deletevendoraction', response);
    dispatch({
      type: DELETE_VENDOR_JOB,
      payload: response.data, // Assuming you want to dispatch the response data
    });
    return response; // Return the response if needed
  } catch (err) {
    console.error('Error:', err);
    throw err; // Rethrow the error to be caught by the caller
  }
};

export { UpdateVendorJob, GetVendorJobs, DeleteVendorJob, AddVendorJob, GetVendorJobsByid };
