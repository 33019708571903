import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  stepper: {
    backgroundColor: 'transparent',
    '& .MuiStepIcon-active': { color: '#EB4F0E' },
    '& .MuiStepIcon-completed': { color: '#0B8238' },
    '& .Mui-disabled .MuiStepIcon-root': { color: 'grey' },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  stepText: {
    color: 'black',
    fontWeight: 500,
    fontSize: '16px',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    top: '50%',
    left: '50%',
  },
  hide: {
    display: ' none !important',
  },
}));
