import { Container, Card } from '@mui/material';
import React from 'react';

const PrivacyPolicy = () => (
    <>
      <Card>
        <Container>
          <br />
          <h2> Privacy Policy for Dial 4 Vyapar</h2>
          <br />
          Effective date: 25 July 2023
          <br />
          <br />
          This page informs you of our policies regarding the collection, use, and disclosure of personal data when you
          use our Service and the choices you have associated with that data. We use your data to provide and improve
          the Service. By using the Service, you agree to the collection and use of information in accordance with this
          policy.
          <h3>Information Collection and Use</h3>
          We collect several different types of information for various purposes to provide and improve our Service to
          you. Types of Data Collected Personal Data While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally
          identifiable information may include, but is not limited to: Email address First name and last name Phone
          number Cookies and Usage Data Usage Data We may also collect information about how the Service is accessed and
          used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address
          (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date
          of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
          <h3>Tracking & Cookies Data</h3>
          We use cookies and similar tracking technologies to track the activity on our Service and hold certain
          information. Cookies are files with a small amount of data which may include an anonymous unique identifier.
          Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are
          beacons, tags, and scripts to collect and track information and to improve and analyze our Service. You can
          instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not
          accept cookies, you may not be able to use some portions of our Service. Examples of Cookies we use: Session
          Cookies: We use Session Cookies to operate our Service. Preference Cookies: We use Preference Cookies to
          remember your preferences and various settings. Security Cookies: We use Security Cookies for security
          purposes. Use of Data: We uses the collected data for various purposes: To provide and maintain the Service To
          notify you about changes to our Service To allow you to participate in interactive features of our Service
          when you choose to do so To provide customer care and support To provide analysis or valuable information so
          that we can improve the Service To monitor the usage of the Service To detect, prevent and address technical
          issues Transfer of Data Your information, including Personal Data, may be transferred to — and maintained on —
          computers located outside of your state, province, country or other governmental jurisdiction where the data
          protection laws may differ than those from your jurisdiction. If you are located outside India and choose to
          provide information to us, please note that we transfer the data, including Personal Data, to India and
          process it there. Your consent to this Privacy Policy followed by your submission of such information
          represents your agreement to that transfer. Dial4Vyapar will take all steps reasonably necessary to ensure
          that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal
          Data will take place to an organization or a country unless there are adequate controls in place including the
          security of your data and other personal information.
          <h3>Disclosure of Data</h3>
          Legal Requirements Dial4Vyapar may disclose your Personal Data in the good faith belief that such action is
          necessary to: To comply with a legal obligation To protect and defend the rights or property of Dial4Vyapar To
          prevent or investigate possible wrongdoing in connection with the Service To protect the personal safety of
          users of the Service or the public To protect against legal liability
          <h3>Security of Data</h3>
          The security of your data is important to us but remember that no method of transmission over the Internet or
          method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect
          your Personal Data, we cannot guarantee its absolute security.
          <h3>Service Providers</h3>
          We may employ third-party companies and individuals to facilitate our Service ("Service Providers"), to
          provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our
          Service is used. These third parties have access to your Personal Data only to perform these tasks on our
          behalf and are obligated not to disclose or use it for any other purpose.
          <h3>Links to Other Sites</h3>
          Our Service may contain links to other sites that are not operated by us. If you click on a third-party link,
          you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every
          site you visit. We have no control over and assume no responsibility for the content, privacy policies or
          practices of any third-party sites or services.
          <h3>Children's Privacy</h3>
          Our Service does not address anyone under the age of 12 ("Children"). We do not knowingly collect personally
          identifiable information from anyone under the age of 12. If you are a parent or guardian and you are aware
          that your Children has provided us with Personal Data, please contact us. If we become aware that we have
          collected Personal Data from children without verification of parental consent, we take steps to remove that
          information from our servers.
          <h3>Changes to This Privacy Policy</h3>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
          Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to
          the change becoming effective and update the "effective date" at the top of this Privacy Policy. You are
          advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
          effective when they are posted on this page.
          <h3>Right to Deletion(or to be forgotten)</h3>
          You may have the right to request that we delete any of your personal information or personal data that we
          collected from you and retained, subject to certain exceptions.
          <h3>Exercising Your Rights</h3>
          To exercise the rights described above, please submit a verifiable request to the Data Protection Officer(as
          set forth in the Contact Information section below).Only you, or someone legally authorized to act on your
          behalf, may make a verifiable request related to your personal information or personal data. California
          residents may only make a verifiable request for access or data portability twice within a 12-month
          period.Verifiable Request must: Provide sufficient information that allows us to reasonably verify you are the
          person about whom we collected personal information or an authorized representative, which may include:
          Verifying your Site user login and password; Verifying certain information that we have collected; or Such
          other methods that we deem necessary to reasonably verify your identity. Describe your request with sufficient
          detail that allows us to properly understand, evaluate, and respond to it. We cannot respond to your request
          or provide you with personal information if we cannot verify your identity or authority to make the request
          and confirm the personal information relates to you. Making a verifiable request does not require you to
          create an account with us. We will only use personal information provided in a verifiable request to verify
          the requestor’s identity or authority to make the request
          <h3>Contact Information</h3>
          Except as otherwise set forth above, to make any request permitted by this policy ,ask questions, or comment
          about this policy, contact us by <a href="mailto: support@dial4vyapar.com">support@dial4vyapar.com</a> Users
          located in GDPR Locations may contact their local supervisory authority. if you feel you have not received an
          adequate or timely response from us, or would like to file a complaint.
          <br /> <br /> <br />
        </Container>
      </Card>
    </>
  );

export default PrivacyPolicy;
