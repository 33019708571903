import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Box, Stack, AppBar, Toolbar, IconButton, Chip, Button } from '@mui/material';
import StatusBox from '../../components/comman/StatusBox';
import CardRapped from '../../components/comman/CardRapped';
import { fetchProfile } from '../../api/CommanApicall';
// components
import Iconify from '../../components/Iconify';
import AccountPopover from './AccountPopover';
import { PROFILE_DATA, RESET_STATE } from '../../actions/Types';
import NotificationsPopover from './NotificationsPopover';
import LogoutModelBox from '../../components/Component/ModelBox/LogoutModelBox';
import { Logout } from '../../actions/AuthActions';
import '../../comman.css';
import AccountDeleteBox from '../../pages/Modal/AccountDeleteBox';

import axiosInstance from '../../api/withJWTServer';

import { HandleExceptionWithSecureCatch } from '../../actions/CombineCatch';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  background: '#fff',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
}));
const RootStyleBar = styled(AppBar)(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  background: '#fff',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const [profileDetail, setProfileDetail] = useState('');
  const [remarkReason, setRemarkReason] = useState('');
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [show, setShow] = useState(false);
  const [openLogoutModel, setOpenLogoutModel] = useState(false);

  const [openDeleteModel, setDeleteModel] = useState(false);

  const navigate = useNavigate();
  console.log('isMobile', isMobile);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { isLogged, profileData1 } = useSelector((state) => ({
    isLogged: state.auth.loggedUser.token,
    // profilePic: state.profilePic,
    profileData1: state?.profileData1?.vendorBusinessDocs?.vendor_profile?.doc_path,
  }));

  const { vendorDetail, profileData } = useSelector((state) => ({
    vendorDetail: state.vendorDetailDocs,
    profileData: state?.profileData,
  }));

  useEffect(() => {
    async function profile() {
      try {
        const profileDetail = await fetchProfile();
        dispatch({
          type: PROFILE_DATA,
          payload: profileDetail?.data?.data[0] === undefined ? null : profileDetail?.data?.data[0],
        });
        setProfileDetail(profileDetail?.data?.data[0].status);
        setRemarkReason(profileDetail?.data?.data[0].remarks);
      } catch (error) {
        console.error(error);
      }
    }
    profile();
  }, []);

  const cropName = (name) => {
    const all = name;
    const words = all?.split(' ');

    const truncatedText = words?.slice(0, 1).join(' ');

    // Add ellipsis if there are more than three words
    const displayText = words?.length > 2 ? `${truncatedText} ..` : truncatedText;
    return displayText;
  };
  const logoutFromApp = async () => {
    await localStorage.clear();
    setOpenLogoutModel(false);
    dispatch(Logout(isLogged));
    navigate('/login', { replace: true });
  };

  const handleDeleteSubmit = () => {
    const params = {
      action: 'delete',
    };
    axiosInstance
      .patch('vendor/account', params)
      .then((res) => {
        localStorage.clear();
        dispatch({
          type: RESET_STATE,
          payload: null,
        });
      })
      .catch((err) => {
        dispatch(HandleExceptionWithSecureCatch({}));
      });
  };

  return (
    <>
      {openLogoutModel && (
        <LogoutModelBox
          isOpen={openLogoutModel}
          handleLogoutCancel={() => {
            setOpenLogoutModel(false);
          }}
          handleLogout={() => {
            logoutFromApp();
          }}
        />
      )}

      {openDeleteModel && (
        <AccountDeleteBox
          openDialog={openDeleteModel}
          handleClose={() => {
            setDeleteModel(false);
          }}
          handleDeleteYes={() => {
            handleDeleteSubmit();
          }}
        />
      )}

      {vendorDetail === 1 ? (
        <RootStyle>
          <ToolbarStyle>
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>
            <CardRapped text={'VENDOR'} slug="" />
            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <span style={{ display: isMobile ? 'none' : '' }}>
                <StatusBox profileDetail={profileDetail} />
              </span>

              <AccountPopover
                handleLogoutFromApp={() => {
                  setOpenLogoutModel(true);
                }}
                handleDeleteAccount={() => {
                  setDeleteModel(true);
                }}
              />
            </Stack>
          </ToolbarStyle>
        </RootStyle>
      ) : (
        <RootStyleBar>
          <ToolbarStyle>
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>
            <span style={{ marginRight: '10px', color: '#000', cursor: 'pointer' }}>
              Welcome
              <br />
              <Tooltip title={profileData?.firstname}>
                <h3>{cropName(profileData?.firstname)}</h3>
              </Tooltip>
            </span>
            <span style={{ marginTop: '20px', display: isMobile ? 'none' : '' }}>
              <CardRapped text={'VENDOR'} slug="" />
            </span>
            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" alignItems="center" justifyContent="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <span style={{ display: isMobile ? 'none' : '' }}>
                  <StatusBox profileDetail={profileDetail} style={{ marginBottom: '10px' }} />
                </span>

                {profileDetail === 'With-Hold' && (
                  <p style={{ color: '#d0250e', marginTop: '8px', fontSize: '14px' }}>Reason: {remarkReason}</p>
                )}
                <span
                  style={{
                    display: isMobile ? '' : 'none',
                    marginTop: '30%',
                    // position: 'fixed',
                    // top: '80px',
                    // width: '100%',
                    // right: '-155px',
                    // marginRight: '-70px',
                  }}
                >
                  <StatusBox profileDetail={profileDetail} slug={'mob'} />
                  <br />

                  <div style={{ mariginTop: '50px' }}>
                    {profileDetail === 'With-Hold' && (
                      <span style={{ color: '#d0250e', marginTop: '10px' }}>
                        Reason:
                        <Button
                          variant="outlined"
                          color="error"
                          style={{ padding: '2px', fontSize: '8px', marginLeft: '5px' }}
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          {show ? 'Click to Close' : 'Click to View'}
                        </Button>
                        {/* <button onClick={()=>{setShow(!show)}}>Click to View</button> */}
                      </span>
                    )}
                    <br />
                    {show ? (
                      <div
                        style={{
                          // border: "2px solid #d0250e",
                          borderRadius: '10px',
                          padding: '5px',
                          color: '#d0250e',
                          backgroundColor: '#f6d3cf',
                          wordWrap: 'break-word', // Add this property
                          width: '40%', // Set the width to 20%
                          boxSizing: 'border-box',
                          fontSize: '15px',
                          fontWeight: 600,
                        }}
                      >
                        {remarkReason}
                      </div>
                    ) : null}
                  </div>
                </span>
              </Box>
              <NotificationsPopover />
              <AccountPopover
                handleLogoutFromApp={() => {
                  setOpenLogoutModel(true);
                }}
                handleDeleteAccount={() => {
                  setDeleteModel(true);
                }}
              />
            </Stack>
          </ToolbarStyle>
        </RootStyleBar>
      )}
    </>
  );
}
