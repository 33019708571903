import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Box,
  Stack,
  IconButton,
  // Button
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';

import { Link as ButtonLink } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
// import DateRangePickerMui from '../components/DateRangePickerMui';
// import Iconify from '../components/Iconify';

const PagesTopBar = (props) => {
  const [searchValue, setSearchValue] = useState('');

  let timer = null;
  const handleSearchValueChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      props.handleSearch(value.trim());
    }, 1000);
    // setSearchValue(e.target.value);
    // console.log(e);
    // props.handleSearch(e.target.value);
  };

  const clearInput = () => {
    // Wait for X ms and then process the request
    props.clearInput();
    props.handleSearch('');
    setSearchValue('');
  };

  return (
    <Grid container sx={{ marginBottom: '15px', display: 'flex', alignItem: 'center' }}>
      <Grid md={3} xs={12} sm={12} item gap={2}>
        <Typography fontSize={'20px'} fontWeight={'600'} color={'#010203'}>
          {props.title}
        </Typography>
        <Typography fontSize={'14px'} fontWeight={'400'}>
          {props.subTitle}
        </Typography>
      </Grid>

      <Grid flex={1} item justifyContent={'flex-end'}>
        <Stack direction={'row'} justifyContent={'flex-end'}>
          {!props.hideSearch && (
            <TextField
              // autoComplete="off"
              id="searchField"
              name="searchField"
              size="small"
              placeholder={props.vendor ? 'Search By Business' : 'Search Here'}
              // variant="standard"
              value={searchValue}
              autoComplete="search"
              // label="Search"
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px !important',
                  background: 'white',
                  width: '100%',
                  fontSize: '14px',
                },
              }}
              onChange={handleSearchValueChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={clearInput} edge="end">
                      <CancelIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {props.Add && (
            <ButtonLink to={props.link}>
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: 0,
                  p: 0,
                  ml: '12px',
                  minWidth: 0,
                  height: '40px',
                  bgcolor: '#0B8238',
                  fontSize: '12px',
                  padding: '0px 15px !important',
                  '&:hover': {
                    background: '#0B8238',
                    opacity: 0.9,
                  },
                }}
                // disableElevation
                startIcon={<Add />}
              >
                {props.btnText}
              </Button>
            </ButtonLink>
          )}

          {props.Delete && (
            <Button
              variant="contained"
              size="small"
              sx={{
                m: 0,
                p: 0,
                ml: '12px',
                minWidth: 0,
                height: '40px',
                bgcolor: '#D0250E',
                fontSize: '12px',
                padding: '0px 15px !important',
                '&:hover': {
                  background: '#D0250E',
                  opacity: 0.9,
                },
              }}
              onClick={props.handleDelete}
              // disableElevation
            >
              {props.btnText}
            </Button>
          )}

          {/* {props.excel && (
            <>
              {props.vendor ? (
                <DateRangePickerMui />
              ) : (
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    // marginTop: '5px',
                    float: 'right',
                    borderRadius: '8PX',
                    height: '40px',
                    backgroundColor: '#E2EDE7',
                    marginLeft: '12PX',
                    boxShadow: 'none',
                    border: '1px solid green',
                  }}
                  onClick={props.downloadXlsx}
                >
                  <Typography style={{ color: 'green', fontSize: '14px' }}>Export</Typography>
                  <Iconify
                    icon="ic:baseline-cloud-download"
                    sx={{ color: 'green', ml: '5px', height: '20px', width: '20px' }}
                  />
                </Button>
              )}
            </>
          )} */}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PagesTopBar;
