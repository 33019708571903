import React, { useState } from 'react';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { useDispatch } from 'react-redux';
import { Link, Stack, TextField, Typography, Card, Button, Grid, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Support from '../../../pages/Support';
import Iconify from '../../../components/Iconify';
// component
import axiosInstance from '../../../api/withJWTServer';

import useStyles from '../../../pages/WizardComponent/Step/styles';
import useResponsive from '../../../hooks/useResponsive';
import ShowMessage from '../../../pages/Modal/ShowMessage';

// ----------------------------------------------------------------------
const SectionStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '100vh',
  },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(0, 0, 0, 0),
  borderRadius: 33,
  position: 'relative',
  alignItems: 'center',
}));

const SubSection = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    marginLeft: '200px',
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};
const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
}));

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const mdUp = useResponsive('up', 'md');

  const [submitLoader, setSubmitLoader] = useState(false);
  const LoginSchema = Yup.object().shape({
    phonenumber: Yup.string()
      .required('Mobile Number is required')
      .test('no-leading-zero', 'Phone number must not start with zero and should be 10 digits', (value) =>
        /^[1-9][0-9]{9}$/.test(value)
      ),
  });

  const formik = useFormik({
    initialValues: {
      phonenumber: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      setSubmitLoader(true);
      const params = {
        mobile_number: value.phonenumber.toString(),
      };
      try {
        axiosInstance
          .post('vendor/verify-mobile', params)
          .then((res) => {
            if (res?.data?.status === 200 && res?.data?.success === true) {
              setIsShow(true);
              setError(false);
              // setOpen(!open);
              console.log('response', res);
              setErrorMessage(res.data.message);
              setTimeout(() => {
                setIsShow(false);
                setSubmitLoader(false);
                navigate('/otp', {
                  state: {
                    phone: value.phonenumber.toString(),
                  },
                });
              }, 1500);
            } else {
              setIsShow(true);
              setError(true);
              // setOpen(!open);
              console.log('response', res);
              setErrorMessage(res.data.message);
              setTimeout(() => {
                setIsShow(false);
                setSubmitLoader(false);
              }, 1500);
            }
          })
          .catch((err) => {
            setIsShow(true);
            setError(true);
            setErrorMessage(err.message);
            setTimeout(() => {
              setIsShow(false);
              setSubmitLoader(false);
            }, 1500);
          });
      } catch (e) {
        // console.log('Something went wrong');
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <SectionStyle>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Iconify
              icon="eva:close-outline"
              sx={{
                float: 'right',
                height: '30px',
                width: '30px',
                color: '#000',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
            <Support />
          </Box>
        </Modal>

        <SubSection>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ fontSize: 20, marginTop: 1 }}>
                LOGIN
              </Typography>
              <Typography style={{ fontSize: 15, marginBottom: 15 }}>Please provide your mobile number</Typography>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                    <TextField
                      label="Mobile number"
                      fullWidth
                      type={'text'}
                      InputProps={{
                        inputProps: {
                          maxLength: 10,
                        },
                      }}
                      // inputProps={{ maxLength: 10 }}
                      {...getFieldProps('phonenumber')}
                      error={Boolean(touched.phonenumber && errors.phonenumber)}
                      helperText={touched.phonenumber && errors.phonenumber}
                    />
                  </Stack>

                  <Stack sx={{ mt: 2 }}>
                    <Link to="/dashboard/app" className="btn btn-primary">
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={submitLoader}
                        sx={{
                          '&:hover': {
                            bgcolor: 'green',
                          },
                        }}
                        style={{ borderRadius: 29.5, height: 46 }}
                      >
                        Login with OTP
                      </LoadingButton>
                    </Link>
                  </Stack>
                  <Stack sx={{ mt: 2 }}>
                    <h5 style={{ color: 'grey', textAlign: 'center', marginBottom: '1px', fontWeight: '600' }}>
                      Need help?
                      <Button style={{ marginLeft: '1px' }} onClick={handleOpen}>
                        Contact Support
                      </Button>
                    </h5>
                  </Stack>
                </Form>
              </FormikProvider>
            </Grid>
          </Grid>
        </SubSection>

        <ShowMessage
          onClose={() => setIsShow(false)}
          title={errorMessage}
          type={error ? 'error' : 'success'}
          open={isShow}
        />
      </SectionStyle>
    </>
  );
}
