import React, { useEffect, useMemo, useRef } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';
import IncorrectPostalCodeModel from '../../../Modal/IncorrectPostalCodeModel';

export default function InputPostalcode(props) {
  const { errorText, postalReset, onChangePostal, slugError, ...rest } = useMemo(() => props, [props]);
  const [field, meta, helpers] = useField(props);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    props.onChangePostal(null);
  };

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  useEffect(() => {
    if (props.wrongPostalCode) {
      helpers.setValue('');
      handleOpen();
    }
  }, [props.wrongPostalCode]);

  useEffect(() => {
    if (props.currentPostalCode) {
      helpers.setValue(props.currentPostalCode);
    } else {
      helpers.setValue('');
    }
  }, [props.currentPostalCode]);

  // Handle the change event
  const handleChange = (e) => {
    const target = e.target.value;
    helpers.setValue(e.target.value);
    if (props.planSlug === 0) {
      onChangePostal(target);
    }
    onChangePostal(target);
  };

  const isFirstRun = useRef(true);

  const isFirstRunPostal = useRef(true);
  useEffect(() => {
    if (isFirstRunPostal.current) {
      isFirstRunPostal.current = true;
      return;
    }
    helpers.setValue(props.initialPostalCode);
  }, []);

  useEffect(() => {
    if (props.initialPostalCode) {
      helpers.setValue(props.initialPostalCode);
    }
  }, [props.initialPostalCode]);

  return (
    <>
      <IncorrectPostalCodeModel open={open} handleClose={handleClose} />
      <TextField
        type="text"
        error={meta.touched && meta.error && true}
        helperText={_renderHelperText()}
        {...field}
        {...rest}
        onChange={handleChange}
      />
    </>
  );
}
