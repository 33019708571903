import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { at } from 'lodash';
import { useField } from 'formik';
import { FormHelperText, InputLabel, FormControl, MenuItem, Select } from '@mui/material';

function SelectField(props) {
  const { profileData } = useSelector((state) => ({
    profileData: state?.profileData,
  }));
  const { label, data, onValueChange, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (props.changedValue !== undefined && selectedValue !== props.changedValue) {
        const newValue = props.changedValue; // Adjust this based on how the new value should be set
        field.onChange(newValue);
        onValueChange(newValue);
      }
    } else {
      isInitialRender.current = false;
    }
  }, [props.changedValue]);
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }
  const handleValueChange = (event) => {
    props.selectedCategory(event.target.value);
    if (event.target.value === 0) {
      props.otherCategoryField(true);
    } else {
      props.otherCategoryField(false);
    }
    field.onChange(event);
  };

  useEffect(() => {
    if (profileData?.vendorBusinessDetailsModels?.[0]?.category_id) {
      helpers.setValue(profileData?.vendorBusinessDetailsModels?.[0]?.category_id);
    }
  }, []);

  return (
    <>
      <FormControl {...rest} error={isError} variant="standard" sx={{ mt: '-4px!important' }}>
        <InputLabel>{label}</InputLabel>
        <Select {...field} value={selectedValue} label={label} onChange={handleValueChange}>
          {data?.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {_renderHelperText()}
      </FormControl>
    </>
  );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array,
};

export default SelectField;
