import React from 'react';
import Modal from '@mui/material/Modal';
import {Box, Button, Typography } from '@mui/material';
import logo from '../../Assets/cancel.png';
// import Modal from '@mui/material/Modal';

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    border: 'none',
    boxShadow: 24,
    p: 4,
  };
const IncorrectPostalCodeModel = (props) => (
    <>
      <Modal
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ float: 'center' }}>
            <img src={logo} alt="login" style={{ height: '20px', width: '20px', marginBottom: '-25px' }} />
            <span style={{ marginLeft: '30px' }}>Postal Code is invalid</span>
          </Typography>
          <br />
          <Button
            onClick={() => {
              props?.handleClose();
            }}
            style={{ float: 'right', background: 'green' }}
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Modal>
    </>
  )

export default IncorrectPostalCodeModel