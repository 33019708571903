import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Typography, MenuItem, IconButton, Avatar, Button } from '@mui/material';
import defaultAvatar from '../../Assets/profile.png';

// components

import axiosInstance from '../../api/withJWTServer';
import MenuPopover from '../../components/MenuPopover';
import account from '../../_mock/account';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

export default function AccountPopover(props) {
  const { profiledata } = useSelector((state) => ({
    profiledata: state,
  }));
  const [profileDetail, setProfileDetail] = useState({});
  const [deleteModule, setDeleteModule] = useState(false);
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const fetchProfile = () => {
    axiosInstance.get('vendor/profile').then((res) => {
      setProfileDetail(res?.data?.data[0]);
    });
  };
  useEffect(() => {
    fetchProfile();
  }, []);
  const { isLogged, profileData } = useSelector((state) => ({
    isLogged: state.auth.loggedUser.token,
    // profilePic: state.profilePic,
    profileData: state?.profileData?.vendorBusinessDocs?.vendor_profile?.doc_path,
  }));

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOut = () => {
    // dispatch(Logout(isLogged));
    props.handleLogoutFromApp();
    // navigate('/login', { replace: true });
    setOpen(null);
  };
  const deleteProfile = () => {
    props.handleDeleteAccount();
    // setDeleteModule(true);
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
            },
          }),
        }}
      >
        {profileData ? (
          <img
            src={`${profileData}`}
            alt="pic"
            height="50px"
            width="50px"
            style={{ borderRadius: '100%', objectFit: 'cover' }}
          />
        ) : (
          <img alt="avatar" src={defaultAvatar} style={{ height: '40px', width: '40px', borderRadius: '100px' }} />
        )}
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {profiledata?.profileData?.firstname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profiledata?.profileData?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Link to={'profile'} style={{ textDecoration: 'none', color: '#000' }}>
          <MenuItem sx={{ m: 1 }} onClick={handleClose}>
            Account Setting
          </MenuItem>
        </Link>
        <Divider />
        <Link
          to={{
            pathname: 'business-profile', // Add your desired state here
          }}
          style={{ textDecoration: 'none', color: '#000' }}
        >
          <MenuItem sx={{ m: 1 }} onClick={handleClose}>
            Business Profile
          </MenuItem>
        </Link>

        <Divider />
        <MenuItem sx={{ m: 1 }} onClick={deleteProfile}>
          Delete Profile
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleLogOut} sx={{ m: 1, color: 'red', fontWeight: '600' }}>
          <Typography fontWeight={'600'} fontSize={'14px'}>
            Logout
          </Typography>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
