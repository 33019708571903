// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={18} height={18} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('ic:baseline-dashboard'),
    type: 'item',
  },
  {
    title: 'Business Images',
    path: '/dashboard/upload-images',
    icon: getIcon('ic:baseline-perm-media'),
    type: 'item',
  },
  {
    title: 'Plans & Payment',
    path: '/dashboard/payment/0',
    icon: getIcon('ic:baseline-payment'),
    type: 'item',
  },
  {
    title: 'Jobs',
    path: '/dashboard/jobs',
    icon: getIcon('mdi:briefcase-variant'),
    type: 'item',
  },
  {
    title: 'Search Candidates',
    path: '/dashboard/candidates',
    icon: getIcon('mdi:account-search'),
    type: 'item',
  },
  {
    title: 'Primary Links',
    path: '/dashboard/payment/0',
    icon: getIcon('ic:baseline-payment'),
    type: 'divider',
  },

  {
    title: 'Privacy Policy',
    path: 'https://sunn-grahak.com/privacy-policy.html',
    icon: getIcon('ic:outline-security'),
    slug: 1,
    type: 'item',
  },
  {
    title: 'vendor agreement',
    path: 'https://sunn-grahak.com/vendor_agreement.html',
    icon: getIcon('ic:outline-document-scanner'),
    slug: 1,
    type: 'item',
  },
  {
    title: 'Support',
    path: '/dashboard/payment/0',
    icon: getIcon('ic:baseline-payment'),
    type: 'divider',
  },
  {
    title: 'Contact Support',
    path: '/dashboard/support',
    icon: getIcon('ic:outline-call'),
    type: 'item',
  },
  {
    title: 'FAQ ',
    path: 'https://sunn-grahak.com/faq.html',
    icon: getIcon('ic:outline-mark-unread-chat-alt'),
    slug: 1,
    type: 'item',
  },
];

export default navConfig;
