import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteAlert } from '../../actions/AlertActions';
import './alert.css';

export default function Alert() {
  const dispatch = useDispatch();
  const { alerts } = useSelector((state) => ({
    alerts: state.alerts,
  }));
  const handleClick = () => {
    dispatch(DeleteAlert(alert));
  };

  if (!alerts || alerts.length <= 0) {
    return null;
  }
  return (
    <div className="alertpage">
      {alerts.map((alert) => (
        <div
          key={alert.id}
          className={
            alert?.data?.alertType !== 'danger-stay'
              ? `alertpage-alert-${alert?.data?.alertType}`
              : 'alertpage-alertnone'
          }
        >
          <div
            className={`alertpage-alert-alertspan-span-danger`}
            style={{ position: 'absolute', top: '5px', right: '10px', cursor: "pointer" }}
            aria-hidden
            onClick={handleClick}
          />
          <div className={`alertpage-alert-alertspan-${alert?.data?.alertType}`} />
          <span className="alertpage-alert-msg">{alert?.data?.msg? alert?.data?.msg: "Something went wrong try after some time."}</span>
          
          {/* <Iconify
            icon="eva:close-circle-fill"
            sx={{
              float: 'right',
              marginTop: '-30px',
              marginRight: '-55px',
              height: '30px',
              width: '30px',
              color: 'red',
            }}
            onClick={handleClick}
          /> */}
        </div>
      ))}
    </div>
  );
}
