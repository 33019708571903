import {  PROFILE_DATA } from "../actions/Types";

const INIT_STATE = {};

export default function ProfileDataReducer(state = INIT_STATE, {type, payload} = {}) {
   switch(type) {
        case PROFILE_DATA:
            return payload;

        default: 
            return state;
    }
}

