import {  PROFILE_PIC } from "../actions/Types";

const INIT_STATE = '';

export default function ProfilePicReducer(state = INIT_STATE, {type, payload} = {}) {
    switch(type) {
        case PROFILE_PIC:
            return payload;

        default: 
            return state;
    }
}

