import {
  GET_CANDIDATES,
  GET_CANDIDATES_BY_ID,
  GET_CITY,
  GET_EDUCATION,
  GET_JOB_CATEGORY,
  GET_STATE,
  RESET_STATE,
} from '../actions/Types';

const INIT_STATE = {
  candidates: { data: [] },
  jobCategory: {},
  education: {},
  state: {},
  city: {},
  candidateDetails: {},
};

export default function CandidateReducer(state = INIT_STATE, { type, payload } = {}) {
  switch (type) {
    case GET_CANDIDATES:
      return {
        ...state,
        candidates: payload,
      };
    case GET_CANDIDATES_BY_ID:
      return {
        ...state,
        candidateDetails: payload,
      };
    case GET_JOB_CATEGORY:
      return {
        ...state,
        jobCategory: payload,
      };
    case GET_EDUCATION:
      return {
        ...state,
        education: payload,
      };
    case GET_STATE:
      return {
        ...state,
        state: payload,
      };
    case GET_CITY:
      return {
        ...state,
        city: payload,
      };

    case RESET_STATE:
      return { ...INIT_STATE };
    default:
      return state;
  }
}
