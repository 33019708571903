import * as Yup from 'yup';
import { useState, useRef, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { useDispatch, useSelector } from 'react-redux';
import { Link, Stack, TextField, Container, Typography, Card, Grid, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

import axiosInstance from '../../../api/withJWTServer';
import { LOGIN } from '../../../actions/Types';

// component

import useResponsive from '../../../hooks/useResponsive';

import Footericon from '../../../Assets/bottomImg.png';

import backgroundimage from '../../../Assets/bg.png';
import loginTop from '../../../Assets/logintop.png';
import loginMiddle from '../../../Assets/loginMidle.png';
import darkLogo from '../../../Assets/imgResLogo.png';

import ShowMessage from '../../../pages/Modal/ShowMessage';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
const SectionStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  backgroundImage: `url(${backgroundimage})`,
  height: '100vh',
  backgroundSize: '90%',
  backgroundRepeat: 'no-repeat',
}));

const ResponsiveSectionStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '5px',
  flexDirection: 'column',
  justifyContent: 'start',
  height: 'auto',
  width: 'fit-content',
  backgroundSize: '90%',
  backgroundRepeat: 'no-repeat',
}));

const SectionStyle2 = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '100vh',
  },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(0, 0, 0, 0),
  borderRadius: 33,
  position: 'relative',
  alignItems: 'center',
}));

const SubSection2 = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    marginLeft: '200px',
  },
}));

export default function Register() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [submitLoader, setSubmitLoader] = useState(false);

  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const { isLogged } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
  }));

  const LoginSchema = Yup.object().shape({
    fname: Yup.string()
      .required('Full Name is required')
      .matches(/^[^\d]*$/, 'Full Name should not contain numbers'),
    email: Yup.string()
      // .required('Email is required')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address'),
  });

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    navigate('/dashboard/app', { replace: true });
  }, [isLogged]);

  const formik = useFormik({
    initialValues: {
      fname: '',
      email: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      setSubmitLoader(true);
      const params = {
        firstname: value.fname,
        email: value.email,
        slug: 0,
      };

      axiosInstance
        .post('vendor/register', params)
        .then((response) => {
          setIsShow(true);
          setError(false);

          console.log('response', response);
          setErrorMessage(response.data.message);

          setTimeout(() => {
            setSubmitLoader(false);
            dispatch({
              type: LOGIN,
              payload: response?.data?.data[0],
            });
          }, [1500]);
        })
        .catch((err) => {
          setIsShow(true);
          setError(true);
          setErrorMessage(err.message);
          setSubmitLoader(false);
        });
    },
  });

  function renderRegistrationForm() {
    return (
      <Grid item md={4} xs={12}>
        <SectionStyle2>
          <SubSection2>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: 20, marginTop: 20 }}>
                  Vendor Registration
                </Typography>

                <Typography style={{ fontSize: 15, marginBottom: 15 }}>Enter your details below.</Typography>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                      <TextField
                        fullWidth
                        label="Full Name"
                        type={'text'}
                        {...getFieldProps('fname')}
                        error={Boolean(touched.fname && errors.fname)}
                        helperText={touched.fname && errors.fname}
                      />

                      <TextField
                        fullWidth
                        label="Email"
                        autoComplete="current-password"
                        type={'email'}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>

                    <Stack sx={{ mt: 2, mb: 5 }}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={submitLoader}
                        sx={{
                          '&:hover': {
                            bgcolor: 'green',
                          },
                        }}
                        style={{ borderRadius: 29.5, height: 46 }}
                      >
                        Sign Up
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Grid>
            </Grid>

            <ShowMessage
              onClose={() => setIsShow(false)}
              title={errorMessage}
              type={error ? 'error' : 'success'}
              open={isShow}
            />
          </SubSection2>
        </SectionStyle2>
      </Grid>
    );
  }

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return mdUp ? (
    <>
      <Grid container>
        <Grid item md={6}>
          {mdUp && (
            <SectionStyle>
              <Box
                sx={{
                  height: '100vh',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: '30px',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  flexDirection: 'column',
                }}
              >
                <img src={`${loginTop}`} alt="login" width={'130px'} />

                <Box>
                  <Typography sx={{ fontSize: '22px', fontWeight: '400', color: '#FFFFFF' }}> Welcome Back,</Typography>
                  <img src={`${loginMiddle}`} alt="login" height="140px" style={{ marginLeft: '-10px' }} />
                  <Typography sx={{ fontSize: '18px', fontWeight: '200', color: '#FFFFFF' }}>
                    Your Business Ultimate Growth Partner
                  </Typography>
                </Box>

                <span>
                  <img src={Footericon} alt="logo" style={{ width: '60px', height: '60px' }} />
                  <Box height={'5px'} />
                  <p style={{ fontSize: '14px', fontWeight: '300', color: 'rgba(255,255,255,0.8)' }}>
                    In association with
                  </p>
                  <b style={{ fontSize: '18px', fontWeight: '600', color: '#FFFFFF' }}>
                    International Sindhi Samaj Sangathan
                  </b>
                  <Box height={'10px'} />
                </span>
              </Box>
            </SectionStyle>
          )}
        </Grid>
        {renderRegistrationForm()}
      </Grid>
    </>
  ) : (
    <Container>
      <Grid item md={12}>
        <ResponsiveSectionStyle>
          <Box
            sx={{
              height: 'auto',
              width: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
              marginRight: '30px',
              paddingTop: '20px',
              flexDirection: 'column',
            }}
          >
            <img src={`${darkLogo}`} alt="login" width={'130px'} />
          </Box>
        </ResponsiveSectionStyle>
      </Grid>
      <Grid item md={12}>
        {renderRegistrationForm()}
      </Grid>
    </Container>
  );
}
