import {  SUBSRIPTION_PLANS } from "../actions/Types";

const INIT_STATE = null;

export default function SubscriptionDataReducer(state = INIT_STATE, {type, payload} = {}) {
   const pay= !payload? INIT_STATE: payload;
   switch(type) {
        case SUBSRIPTION_PLANS:
            return pay;

        default: 
            return state;
    }
}

