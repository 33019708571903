import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Card, Stack, Grid, TextField, Typography, MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import Iconify from '../../components/Iconify';
import { PROFILE_DATA } from '../../actions/Types';
import { HandleExceptionWithSecureCatch } from '../../actions/CombineCatch';
import axiosInstance from '../../api/withJWTServer';
import IncorrectPostalCodeModel from './IncorrectPostalCodeModel';
import closeIcon from '../../Assets/cancel.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4,
  border: '1px solid red',
};

const EditAddressModel = (props) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    props.handleCloseOutlet(false);
  };
  const [cities, setAllCities] = useState([]);
  const [currentCity, setCurrentCity] = useState(props.outletAddressDetail.city);
  const [currentState, setCurrentState] = useState(null);
  const [currentPostalCode, setCurrentPostaleCode] = useState(props.outletAddressDetail.pin);
  const [wrongPostalCode, setWrongPostalCode] = useState(false);
  const [states, setState] = useState([]);
  const [openPostalModel, setOpenPostalModel] = useState(false);
  const GOOGLE_GEOCODING_API_KEY = 'AIzaSyBk1nf5tRWZ1h6ccQGqstoAh-Di6VH3iXU';
  // useEffect(()=>{
  //   if(wrongPostalCode){
  //     formik.setFieldValue('state', '');
  //     formik.setFieldValue('city', '');
  //   }
  // },[wrongPostalCode])
  useEffect(() => {
    if (props.outletAddressDetail.state) {
      axiosInstance
        .get(`vendor/get-districts?key=${props?.outletAddressDetail?.state}`)
        .then((res) => {
          setAllCities(res?.data?.data);
        })
        .catch((err) => {});
    }
  }, [props.outletAddressDetail.state]);

  useEffect(() => {
    axiosInstance
      .get('vendor/get-states')
      .then((res) => {
        setState(res.data.data);
      })
      .catch((err) => {});
  }, []);
  const LoginSchema = Yup.object().shape({
    address: Yup.string().required(`Address is required`),
    country: Yup.string().required(`Country is required`),
    state: Yup.string().required(`State is required`),
    city: Yup.string().required(`City is required`),
    pin: Yup.number()
      .required(`Postal code is required`)
      .typeError('Postal code must be a number')
      .test('is-postal', 'Postal code should be 6-digit', (value) => {
        if (value) {
          const pinRegex = /^[0-9]{6}$/;
          return pinRegex.test(value.toString());
        }
        return false; // Return false if the field is empty
      }),
    // type: Yup.string().required('Plan Type  is required'),
    // locationtype: Yup.string().required('No. of Location is required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: props.outletAddressDetail.address_line_1 || '',
      landmark: props.outletAddressDetail.address_line_2 || '',
      country: props.outletAddressDetail.country || '',
      state: props.outletAddressDetail.state || '',
      city: props.outletAddressDetail.city || '',
      pin: props.outletAddressDetail.pin || '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (value, { resetForm }) => {
      //   setfullLoader(true);
      //   const id = editSubscriptionData.uuid;
      console.log('value', value);
      const params = {
        id: props.outletAddressDetail.id,
        address_line_1: value.address,
        address_line_2: value.landmark,
        city: value.city,
        state: value.state,
        country: value.country,
        pin: value.pin,
      };
      axiosInstance
        .patch('vendor/outlet-address', params)
        .then((res) => {
          dispatch(HandleExceptionWithSecureCatch(res));
          axiosInstance.get('vendor/profile').then((res) => {
            dispatch({
              type: PROFILE_DATA,
              payload: res?.data?.data[0] === undefined ? null : res?.data?.data[0],
            });
          });
          handleClose();
          //   onUpdate();
          //   onUpdateSubscription();
          //   setfullLoader(false);
          // resetForm();
        })
        .catch((err) => {
          dispatch(HandleExceptionWithSecureCatch(err));
          handleClose();
          //   setfullLoader(false);
        });

      //   if (!buttonClicked) {
      //     setButtonClicked(true);
      //   }
    },
  });
  const { errors, touched, getFieldProps } = formik;
  // const planType = [
  //   // { id: 1, name: 'Monthly' },
  //   // { id: 1, name: 'Quarterly' },
  //   // { id: 1, name: 'Half Yearly' },
  //   { id: 1, name: 'Yearly' },
  // ];
  // const noOfLocation = [
  //   { id: '1', location: '1', label: '1' },
  //   { id: '2', location: '2', label: '2' },
  //   // { id: '3', location: '3', label: '3' },
  // ];
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: 1,
      },
    },
    textarea: {
      resize: 'both',
    },
  }));
  const countries = [
    {
      id: '1',
      value: 'India',
      label: 'India',
    },
  ];

  useEffect(() => {
    axiosInstance
      .get(`vendor/get-districts?key=${props.outletAddressDetail.state}`)
      .then((res) => {
        setAllCities(res?.data?.data);
      })
      .catch((err) => {});
  }, []);

  const handleSelectedValueChange = (value) => {
    formik.setFieldValue('city', '');
    formik.setFieldValue('pin', '');
    
    axiosInstance
      .get(`vendor/get-districts?key=${value}`)
      .then((res) => {
        setAllCities(res?.data?.data);
      })
      .catch((err) => {});
  };
  const handleState = (e) => {
    setCurrentState(e.target.value);
  };

  const classes = useStyles();
  const handlePostalCode = async (postalCode) => {
    if (/^\d{6}$/.test(postalCode)) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${GOOGLE_GEOCODING_API_KEY}`
        );
        if (response.data.results.length > 0) {
          const { address_components: AddressComponent } = response.data.results[0];

          let city = '';
          let state = '';

          AddressComponent.forEach((component) => {
            if (component.types.includes('locality')) {
              city = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
            }
          });
          setCurrentState(state);
          formik.setFieldValue('state', state);

          axiosInstance
            .get(`vendor/get-districts?key=${state}`)
            .then((res) => {
              setAllCities(res?.data?.data);
              if (res?.data?.data.some((item) => item.value === city)) {
                setCurrentCity(city);
                formik.setFieldValue('city', city);
              } else {
                setCurrentCity(null);
              }
            })
            .catch((err) => {});
          setWrongPostalCode(false);
        } else {
          setWrongPostalCode(true);
          setOpenPostalModel(true);
          formik.setFieldValue('state', '');
          formik.setFieldValue('city', '');
          formik.setFieldValue('pin', '');
          // throw new Error('No results found for the given postal code.');
        }
      } catch (error) {
        setWrongPostalCode(true);
        // setOpenPostalModel(true)
        // throw new Error('Error fetching location information. Please try again.');
      }
    }
  };

  const handleClosePostalCode = () => {
    setOpenPostalModel(false);
    formik.setFieldValue('pin', '');
    formik.setFieldValue('state', '');
    formik.setFieldValue('city', '');
  };
  const { onChange, value, ...pinFieldProps } = getFieldProps('pin');
  return (
    <div>
      <IncorrectPostalCodeModel open={openPostalModel} handleClose={handleClosePostalCode} />
      <Modal
        open={props.openOutlet}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Outlet Address {props.outletId}
          </Typography>
          <Iconify
            icon="eva:close-outline"
            sx={{
              float: 'right',
              marginTop: '-30px',
              height: '30px',
              width: '30px',
              color: '#000',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          />
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Stack spacing={3} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="address"
                      label="Address"
                      {...getFieldProps('address')}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="landmark"
                      label="Landmark"
                      {...getFieldProps('landmark')}
                      inputProps={{ className: classes.textarea }}
                      multiline
                      rows={1}
                      error={Boolean(touched.landmark && errors.landmark)}
                      helperText={touched.landmark && errors.landmark}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      id="country"
                      name="country"
                      label="Country"
                      // defaultValue=""
                      // initialValues=''
                      {...getFieldProps('country')}
                      style={{ marginTop: '7px' }}
                      // value={selectedPlanType}
                      onChange={(e) => {
                        //   handlePlanType(e);
                        formik.handleChange(e);
                      }}
                      error={formik.touched.country && Boolean(formik.errors.country)}
                      helperText={formik.touched.country && formik.errors.country}
                    >
                      <MenuItem disabled initialValues value="">
                        <em>Country</em>
                      </MenuItem>
                      {countries?.map((option) => (
                        <MenuItem key={option.id} value={option.value} initialValues="">
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                    <TextField
                      select
                      fullWidth
                      id="state"
                      name="state"
                      label="State"
                      {...getFieldProps('state')}
                      style={{ marginTop: '7px' }}
                      onChange={(e) => {
                        handleState(e);
                        handleSelectedValueChange(e.target.value);
                        formik.handleChange(e);
                      }}
                      // value={wrongPostalCode ? '' : formik.values.state}
                      error={formik.touched.state && Boolean(formik.errors.state)}
                      helperText={formik.touched.state && formik.errors.state}
                    >
                      <MenuItem disabled initialValues value="">
                        <em>State</em>
                      </MenuItem>
                      {states?.map((option, index) => (
                        <MenuItem key={index} value={option.value} initialValues="">
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                    <TextField
                      select
                      fullWidth
                      id="city"
                      name="city"
                      label="City"
                      {...getFieldProps('city')}
                      style={{ marginTop: '7px' }}
                      // value={noOfTotalLocation}
                      onChange={(e) => {
                        //   handleNoOfLocation(e);
                        formik.handleChange(e);
                      }}
                      // value={wrongPostalCode ? '' : formik.values.city}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    >
                      <MenuItem disabled initialValues value="">
                        <em>City</em>
                      </MenuItem>
                      {cities?.map((option, index) => (
                        <MenuItem key={index} value={option.value} initialValues="">
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="pin"
                      label="Postal Code"
                      {...getFieldProps('pin')}
                      onChange={(e) => {
                        handlePostalCode(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={formik.values.pin}
                      error={Boolean(touched.pin && errors.pin)}
                      helperText={touched.pin && errors.pin}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{ float: 'right' }}
                      // disabled={buttonClicked}
                    >
                      Update
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={5} />
                </Grid>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
};

export default EditAddressModel;
