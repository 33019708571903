import * as Yup from 'yup';
import stepperFormModel from './stepperFormModel';

const {
  formField: {
    bussinessName,
    // businessDoc,
    // gumastaLicenceNo,
    mobileNo,
    googlereviewlink,
    bussinessExpertise,
    address1,
    businessCategory,
    city,
    state,
    zipcode,
    country,
    address,
    Landmark,
    alertnateNo,
    // uploadGstFile,
    // uploadGumastaFile,
    // uploadAadharCard,
    // uploadAadharCardback,
    // uploadPanCard,
    checkboxList,
    // firstLocAddress,
    // firstLocLandmark,
    // firstLocCountry,
    // firstLocState,
    // firstLocCity,
    // firstLocZipcode,
    // // otherCategory
    // plansBoxList,
    // secondLocAddress,
    // secondLocLandmark,
    // secondLocCountry,
    // secondLocState,
    // secondLocCity,
    // secondLocZipcode,
    // bussinessDescription
  },
} = stepperFormModel;

export default [
  Yup.object().shape({
    [bussinessName.name]: Yup.string()
      .max(100, 'Business name should not exceed 100 characters')
      .test('word-count-and-no-only-numbers', 'Business name can be alphabet & No.', (value) => {
        if (value) {
          const words = value.trim().split(/\s+/);

          // Check word count
          if (words.length > 10) {
            return false; // Fail validation if more than 10 words
          }

          // Check if the value is not just a number
          const containsOnlyNumbers = /^\d+$/.test(value.trim());
          if (containsOnlyNumbers) {
            return false; // Fail validation if contains only numbers
          }

          return true; // Passes all validations
        }

        return true; // Return true for empty values, assuming they are not required
      })
      .required(`${bussinessName.requiredErrorMsg}`),
    [bussinessExpertise.name]: Yup.string().matches(
      /^(?:\d{1,2}(?:\.\d{1,2})?)?$/,
      'Input should be numeric with maximum two digits'
    ),
    [address1.name]: Yup.string(),
    [businessCategory.name]: Yup.string().required(`${businessCategory.requiredErrorMsg}`),
    [checkboxList.name]: Yup.array().required(`CheckBox is required`).min(1),
    [googlereviewlink.name]: Yup.string().matches(
      '^(https:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'Enter valid website url'
    ),
  }),
  Yup.object().shape({
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [state.name]: Yup.string().required(`${state.requiredErrorMsg}`),
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [Landmark.name]: Yup.string(),
    [mobileNo.name]: Yup.number()
      .typeError('Mobile number must be a number')
      .test(
        'is-mobile',
        'Mobile number should be a 10-digit mobile number and should not start with leading zero',
        (value) => {
          if (value !== undefined && value !== null) {
            const stringValue = value.toString();

            // Check for 10 digits and ensure it doesn't start with a leading zero
            return /^\d{10}$/.test(stringValue) && stringValue[0] !== '0';
          }
          return false; // Return false if the field is empty
        }
      )
      .required('Mobile number is required'),
    [alertnateNo.name]: Yup.number()
      .typeError('Mobile number must be a number')
      .test('is-mobile', 'Alternate number should be a 10-digit mobile number', (value) => {
        if (value) {
          const mobileRegex = /^[0-9]{10}$/;
          return mobileRegex.test(value.toString());
        }
        return true; // If the field is empty, consider it valid or you can use `.required()` if it should not be empty
      }),
    [zipcode.name]: Yup.number()
      .required(`${zipcode.requiredErrorMsg}`)
      .typeError('Postal code must be a number')
      .test('is-postal', 'Postal code should be 6-digit', (value) => {
        if (value) {
          const pinRegex = /^[0-9]{6}$/;
          return pinRegex.test(value.toString());
        }
        return false; // Return false if the field is empty
      }),
    [country.name]: Yup.string().required(`${country.requiredErrorMsg}`),
  }),
  Yup.object().shape({}),
];
