import {
  GET_VENDOR_JOBS,
  GET_VENDOR_JOBS_BY_ID,
  CREATE_VENDOR_JOB,
  UPDATE_VENDOR_JOB,
  DELETE_VENDOR_JOB,
} from '../actions/Types';

const INIT_STATE = {
  vendorJobs: null,
  isLoading: true,
};

export default function VendorJobsReducer(state = INIT_STATE, { type, payload } = {}) {
  switch (type) {
    case GET_VENDOR_JOBS:
      return {
        ...state,
        vendorJobs: payload,
        isLoading: false,
      };

    case GET_VENDOR_JOBS_BY_ID:
      return {
        ...state,
        selectedVendorJob: payload,
        isLoading: false,
      };

    case CREATE_VENDOR_JOB:
      return {
        ...state,
        vendorJobs: payload,
        isLoading: false,
      };

    case UPDATE_VENDOR_JOB:
      return {
        ...state,
        vendorJobs: {
          ...state.vendorJobs,
          vendorJobs: payload,
          isLoading: false,
        },
      };
    case DELETE_VENDOR_JOB:
      return {
        ...state,
        vendorJobs: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
