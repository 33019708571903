export const LOGIN = 'login';
export const LOG_OUT = 'logout';
export const RESET_STATE = 'reset-state';
export const PROFILE_PIC = 'profile-pic';
export const VENDOR_DETAIL = 'vendor-detail';
export const PROFILE_DATA = 'profile-data';
export const SUBSRIPTION_PLANS = 'subscription-plans';
export const SELECTED_PLANS = 'selected-plans';
export const UPGRADE_PLANS = 'upgrade-plans';

export const SET_ALERT = 'set-new-alert';
export const REMOVE_ALERT = 'remove-alert';
export const REMOVE_SPECIFIC_ALERT = 'remove-specific-alert';

// candidates Types
export const GET_CANDIDATES = 'get-recruimemt-candidates';
export const GET_CANDIDATES_BY_ID = 'get-recruimemt-candidates-by-id';
export const GET_JOB_CATEGORY = 'get-job-category';
export const GET_EDUCATION = 'get-education';
export const GET_STATE = 'get-state';
export const GET_CITY = 'get-city';

// vendor jobs
export const GET_VENDOR_JOBS = 'get-vendor-jobs';
export const GET_VENDOR_JOBS_BY_ID = 'get-vendor-jobs-by-id';
export const CREATE_VENDOR_JOB = 'create-vendor-job';
export const DELETE_VENDOR_JOB = 'delete-vendor-job';
export const UPDATE_VENDOR_JOB = 'update-vendor-job';
