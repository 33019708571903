export default {
  formId: 'stepperForm',
  formField: {
    bussinessName: {
      name: 'bussinessName',
      label: 'Business name*',
      requiredErrorMsg: 'Business name is required',
    },
    otherCategory: {
      name: 'otherCategory',
      label: 'Other Category Name',
      requiredErrorMsg: 'Other Category is required & category should not be repeated*',
    },
    bussinessExpertise: {
      name: 'bussinessExpertise',
      label: 'Business experience(years)',
      requiredErrorMsg: 'Business experience is required',
    },
    tag: {
      name: 'tag',
      label: 'Tag',
    },
    googlereviewlink: {
      name: 'googlereviewlink',
      label: 'Google Review Link',
    },
    address1: {
      name: 'address1',
      label: 'Address Line 1*',
      requiredErrorMsg: 'Address Line 1 is required',
    },
    address2: {
      name: 'address2',
      label: 'Address Line 2',
    },
    businessCategory: {
      name: 'businessCategory',
      label: 'Business Category*',
      requiredErrorMsg: 'Business Category is required',
    },
    city: {
      name: 'city',
      label: 'City*',
      requiredErrorMsg: 'City is required',
    },
    state: {
      name: 'state',
      label: 'State*',
      requiredErrorMsg: 'State is required',
    },
    zipcode: {
      name: 'zipcode',
      label: 'Postal Code*',
      requiredErrorMsg: 'Postal Code is required',
    },
    country: {
      name: 'country',
      label: 'Country*',
      requiredErrorMsg: 'Country is required',
    },
    address: {
      name: 'address',
      label: 'Address*',
      requiredErrorMsg: 'Address is required',
    },
    Landmark: {
      name: 'Landmark',
      label: 'Landmark',
    },
    mobileNo: {
      name: 'mobileNo',
      label: 'Mobile No*',
      requiredErrorMsg: 'Mobile No. is required',
    },
    alertnateNo: {
      name: 'alertnateNo',
      label: 'Alternate No',
    },
    businessDoc: {
      name: 'businessDoc',
      label: 'Business Document*',
    },
    gumastaLicenceNo: {
      name: 'gumastaLicenceNo',
      label: 'Gumasta Licence No.*',
    },
    uploadGumastaLicence: {
      name: 'uploadGumastaLicence',
      label: 'Upload Gumasta Licence*',
    },
    businessLogo: {
      name: 'businessLogo',
      label: 'Business Logo',
    },
    businessFromTime: {
      name: 'businessFromTime',
      label: 'From Time',
    },
    businessToTime: {
      name: 'businessToTime',
      label: 'Business To Time',
    },
    checkboxList: {
      name: 'checkboxList',
      label: 'Checkbox List',
    },
    checkboxListAll: {
      name: 'checkboxListAll',
      label: 'Checkbox List All',
    },
    uploadBusinessDoc: {
      name: 'uploadBusinessDoc',
      label: 'BusinessDocs File',
    },
    uploadGstFile: {
      name: 'uploadGstFile',
      label: 'Gst File',
      requiredErrorMsg: 'Gst docs is required',
    },
    uploadGumastaFile: {
      name: 'uploadGumastaFile',
      label: 'Gumasta File',
      requiredErrorMsg: 'Gumasta docs is required',
    },
    uploadAadharCard: {
      name: 'uploadAadharCard',
      label: 'Aadharcard File',
    },
    uploadAadharCardback: {
      name: 'uploadAadharCardback',
      label: 'Aadharcard File',
    },
    uploadPanCard: {
      name: 'uploadPanCard',
      label: 'PAN Card File',
      requiredErrorMsg: 'PAN card is required',
    },
    plansBoxList: {
      name: 'plansBoxList',
      label: 'Plans List',
      requiredErrorMsg: 'Plans List is required',
    },
    firstLocAddress: {
      name: 'firstLocAddress',
      label: 'Address',
      requiredErrorMsg: 'Address is required',
    },
    firstLocLandmark: {
      name: 'firstLocLandmark',
      label: 'Landmark',
      requiredErrorMsg: 'Landmark is required',
    },
    firstLocCountry: {
      name: 'firstLocCountry',
      label: 'Country',
      requiredErrorMsg: 'Country is required',
    },
    firstLocState: {
      name: 'firstLocState',
      label: 'State',
      requiredErrorMsg: 'State is required',
    },
    firstLocCity: {
      name: 'firstLocCity',
      label: 'City',
      requiredErrorMsg: 'City is required',
    },
    firstLocZipcode: {
      name: 'firstLocZipcode',
      label: 'Postal Code',
      requiredErrorMsg: 'Zipcode is required',
    },
    secondLocAddress: {
      name: 'secondLocAddress',
      label: 'Address',
      requiredErrorMsg: 'Address is required',
    },
    secondLocLandmark: {
      name: 'secondLocLandmark',
      label: 'Landmark',
      requiredErrorMsg: 'Landmark is required',
    },
    secondLocCountry: {
      name: 'secondLocCountry',
      label: 'Country',
      requiredErrorMsg: 'Country is required',
    },
    secondLocState: {
      name: 'secondLocState',
      label: 'State',
      requiredErrorMsg: 'State is required',
    },
    secondLocCity: {
      name: 'secondLocCity',
      label: 'City',
      requiredErrorMsg: 'City is required',
    },
    secondLocZipcode: {
      name: 'secondLocZipcode',
      label: 'Postal Code',
      requiredErrorMsg: 'Zipcode is required',
    },
    bussinessDescription: {
      name: 'bussinessDescription',
      label: 'Business Description',
    },
  },
};
