import React from 'react';
import { Container, Card, Button, Typography, Stack, Box, Divider } from '@mui/material';
import Iconify from '../components/Iconify';

const Support = () => (
    <>
      <Container>
        <br />
        <Typography variant="h5">Contact Us</Typography>
        <Typography fontWeight={'400'} fontSize={'16px'}>
          We are always eager to hear from you. If you have any queries and suggestions feel free to reach out to us
          via.
        </Typography>
        <br />
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Iconify icon="ic:twotone-email" width={'22px'} height={'22px'} color={'green'} />

          <Typography fontWeight={'500'} color={'green'}>
            info@sunngrahak.com
          </Typography>
          <Button
            variant="outlined"
            href="mailto:info@sunngrahak.com"
            sx={{
              color: 'green',
              '&:hover': {
                bgcolor: 'green',
                color: 'white',
              },
            }}
          >
            Send
          </Button>
        </Stack>
        <Box height={'15px'} />

        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Iconify icon="ic:twotone-headphones" width={'22px'} height={'22px'} color={'green'} />

          <Typography fontWeight={'500'} color={'green'}>
            +91 7583903335
          </Typography>
          <Button
            variant="outlined"
            href="tel:+7583903335"
            sx={{
              color: 'green',
              '&:hover': {
                bgcolor: 'green',
                color: 'white',
              },
            }}
          >
            Call
          </Button>
        </Stack>

        <br />
      </Container>
    </>
  );

export default Support;
