import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Stack,
  Grid,
  TextField,
  Container,
  Typography,
  Divider,
  Chip,
  IconButton,
  Autocomplete,
  Box,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../../api/withJWTServer';
import { AddVendorJob, GetVendorJobsByid, UpdateVendorJob } from '../../actions/VendorJobsAction';
import ShowMessage from '../Modal/ShowMessage';

const CreateVendorJob = (props) => {
  // ============ Const decleration ============
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jobFormData = useLocation();
  const jobData = jobFormData?.state?.jobData;

  const { vendorJobs, profileData } = useSelector((state) => ({
    vendorJobs: state?.vendorJobs?.vendorJobs,
    profileData: state.profileData,
  }));

  // ============ UseState ============
  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [states, setState] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [cities, setAllCities] = useState([]);
  const [selecteCity, setSelectedCity] = useState('');
  const [allTags, setAlltags] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [educationQualification, setEducationQualification] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);

  // ============ UseEffect ============
  // get vendor job by id
  useEffect(() => {
    if (jobData?.uuid) {
      dispatch(GetVendorJobsByid(jobData?.uuid));
    }
  }, [jobData?.uuid]);

  //  job category,job type,eduction
  useEffect(() => {
    axiosInstance
      .get('vendor/get-recruitment-job-categories')
      .then((res) => {
        const data = res?.data?.data;
        if (data) {
          const categories = data.map((info) => ({
            label: info.name,
            value: info.uuid,
          }));
          setCategoryList(categories);
        }
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });

    axiosInstance
      .get('vendor/get-recruitment-masters?master_type=JOB_TYPES')
      .then((res) => {
        const data = res?.data?.data;
        if (data) {
          const jobs = data.map((info) => ({
            label: info?.name,
          }));
          setJobTypes(jobs);
        }
      })
      .catch((error) => {
        console.error('Error fetching job type', error);
      });

    axiosInstance
      .get('vendor/get-recruitment-educations')
      .then((res) => {
        const data = res?.data?.data;
        if (data) {
          const educationQualification = data.map((info) => ({
            label: info.name,
            value: info.uuid,
          }));
          setEducationQualification(educationQualification);
        }
      })
      .catch((error) => {
        console.error('Error fetching educationQualification', error);
      });
  }, []);
  // get state
  useEffect(() => {
    axiosInstance
      .get('vendor/get-states')
      .then((res) => {
        setState(res.data.data);
      })
      .catch((err) => {});
  }, []);

  // get all tags
  useEffect(() => {
    if (vendorJobs?.data?.role_keys !== '') {
      const tags = vendorJobs?.data?.role_keys?.split('###') ?? [];
      setAlltags(tags);
    }
  }, [vendorJobs]);

  const handleStateChange = (e, newValue) => {
    // formik.handleChange(e);
    // setSelectedState(newValue?.value);
    // setSelectedCity('');
    formik.setFieldValue('state', newValue?.value);
    formik.setFieldValue('city', '');
    axiosInstance
      .get(`vendor/get-districts?key=${newValue?.value}`)
      .then((res) => {
        setAllCities(res?.data?.data);
      })
      .catch((err) => {});
  };
  const handleCityChange = (e, newValue) => {
    // formik.handleChange(e);
    // setSelectedCity(newValue?.value);
    formik.setFieldValue('city', newValue?.value);
    console.log('Selected city:', newValue?.value);
  };
  const handleCategoryChange = (e, newValue) => {
    formik.setFieldValue('category', newValue?.label);
    formik.setFieldValue('category_uuid', newValue?.value);
  };
  const handleEducationChange = (e, newValue) => {
    formik.setFieldValue('education_qualification', newValue?.label);
    formik.setFieldValue('education_qualification_uuid', newValue?.value);
  };
  const handleJobTypeChange = (e, newValue) => {
    console.log('jobTypes9999', newValue);
    formik.setFieldValue('job_type', newValue?.label);
  };
  const showErrorToastMessage = (message) => {
    setIsShow(true);
    setError(true);
    setErrorMessage(message);
    setTimeout(() => {
      setIsShow(false);
    }, 1500);
  };
  const addJobKeys = () => {
    if (allTags.length >= 10) {
      showErrorToastMessage('You can add maximum 10 job key points.');
    } else {
      const tag = formik.values.job_key;
      // console.log('tag0-909', tag);
      if (tag.length > 300) {
        showErrorToastMessage('Job keys cannot exceed 300 characters.');
      } else if (!tag) {
        formik.setFieldError('job_key', 'Please enter the job key text.');
      } else if (allTags.includes(tag)) {
        showErrorToastMessage('Job key already added in the list.');
      } else {
        // formik.values.business_tags.push(tag);
        formik.setFieldValue('job_key', '');
        setAlltags([...allTags, tag.trim().toString()]);
      }
    }
  };
  const removeTags = (tag) => {
    const newArray = allTags?.filter((item) => item !== tag);
    setAlltags(newArray);
    console.log('tags', tag);
  };
  // ============== Validation schema ==============
  const LoginSchema = Yup.object().shape({
    job_Title: Yup.string()
      .required('Job title is required')
      .matches(/^[^\d]+$/, 'Job title cannot contain numbers')
      .min(3, 'Job title must be at least 3 characters')
      .max(40, 'Job title cannot exceed 40 characters')
      .nullable(),
    minimum_Experience: Yup.number()
      .typeError('Minimum experience must be a number')
      .required('Minimum experience is required')
      .nullable(),
    maximum_Experience: Yup.number()
      .typeError('Maximum experience must be a number')
      .required('Maximum experience is required')
      .moreThan(Yup.ref('minimum_Experience'), 'Maximum experience must be greater than minimum experience')
      .nullable(),
    job_description: Yup.string().required('Job Description is required'),
    category: Yup.string()
      .required('Job category is required')
      .nullable()
      .test('is-valid-city', 'Please select a valid job category', (value) => {
        return !value || categoryList.some((obj) => obj.label === value);
      }),
    education_qualification: Yup.string()
      .required('Education qualification is required')
      .nullable()
      .test('is-valid-city', 'Please select a valid education qualification', (value) => {
        return !value || educationQualification.some((obj) => obj.label === value);
      }),
    job_type: Yup.string()
      .required('Job type is required')
      .nullable()
      .test('is-valid-city', 'Please select a valid job type', (value) => {
        return !value || jobTypes.some((obj) => obj.label === value);
      }),
    No_of_Positions: Yup.number()
      .typeError('Number of openings must be a number')
      .required('Number of openings is required')
      .min(1, 'Number of openings must be more than 0')
      .nullable(),
    state: Yup.string()
      .required('State is required')
      .nullable()
      .test('is-valid-city', 'Please select a valid state', (value) => {
        return !value || states.some((obj) => obj.value === value);
      }),
    city: Yup.string()
      .required('City is required')
      .nullable()
      .test('is-valid-city', 'Please select a valid city', (value) => {
        return !value || cities.some((obj) => obj.value === value);
      }),
    min_salary: Yup.number()
      .required('Minimum salary is required')
      .typeError('Minimum salary must be a number')
      .min(0, 'Minimum salary must be greater than or equal to 0')
      .test('maxDigits', 'Number of digits cannot exceed 12', (value) => value && value.toString().length <= 12)
      .nullable(),
    max_salary: Yup.number()
      .required('Maximum salary is required')
      .typeError('Maximum salary must be a number')
      .min(Yup.ref('min_salary'), 'Maximum salary must be greater than or equal to Minimum Salary')
      .test('maxDigits', 'Number of digits cannot exceed 12', (value) => value && value.toString().length <= 12)
      .nullable(),
    job_key: Yup.string().max(300, 'Job keys cannot exceed 300 characters'),
  });

  // ============== Formik ==============
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      job_Title: vendorJobs?.data.job_title ? vendorJobs?.data.job_title : '',
      minimum_Experience: vendorJobs?.data.job_min_experience ? vendorJobs?.data.job_min_experience : '',
      maximum_Experience: vendorJobs?.data.job_max_experience ? vendorJobs?.data.job_max_experience : '',
      job_description: vendorJobs?.data.job_description ? vendorJobs?.data.job_description : '',
      job_type: vendorJobs?.data.job_type ? vendorJobs?.data.job_type : null,
      category: vendorJobs?.data.recruitmentJobCategoryModel
        ? vendorJobs?.data.recruitmentJobCategoryModel?.name
        : null,
      education_qualification: vendorJobs ? vendorJobs?.data?.recruitmentEducation?.name : null,
      No_of_Positions: vendorJobs?.data.no_of_openings ? vendorJobs?.data.no_of_openings : '',
      min_salary: vendorJobs?.data.job_min_salary ? vendorJobs?.data.job_min_salary : '',
      max_salary: vendorJobs?.data.job_max_salary ? vendorJobs?.data.job_max_salary : '',
      state: vendorJobs?.data.state ? vendorJobs?.data.state : '',
      city: vendorJobs?.data.city ? vendorJobs?.data.city : '',
      job_key: jobData?.job_keys ? jobData?.job_keys : '',
      category_uuid: vendorJobs?.data.recruitment_job_category_uuid
        ? vendorJobs?.data.recruitment_job_category_uuid
        : null,
      education_qualification_uuid: vendorJobs?.data.recruitment_education_uuid
        ? vendorJobs?.data.recruitment_education_uuid
        : null,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      const reqBody = {
        recruitment_job_category_uuid: values?.category_uuid,
        recruitment_education_uuid: values?.education_qualification_uuid,
        job_title: values?.job_Title,
        job_description: values?.job_description,
        job_type: values?.job_type,
        company_name: profileData.firstname ? profileData?.firstname : '',
        job_min_experience: values?.minimum_Experience,
        job_max_experience: values?.maximum_Experience,
        no_of_openings: values?.No_of_Positions,
        state: values?.state,
        city: values?.city,
        job_min_salary: values.min_salary,
        job_max_salary: values.max_salary,
        role_keys: allTags.join('.###'),
      };

      if (vendorJobs?.data.uuid) {
        dispatch(UpdateVendorJob(reqBody, vendorJobs?.data.uuid))
          .then((response) => {
            setSubmitLoader(false);
            if (response.status === 200) {
              setIsShow(true);
              setError(false);
              // setOpen(!open);
              setErrorMessage(response.data.message);
              setTimeout(() => {
                setIsShow(false);
                setSubmitLoader(false);
                navigate(-1);
              }, 1500);
            } else if (response?.status === 400) {
              console.log('error', error.response.status, error.response.data.error);
              setIsShow(true);
              setError(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShow(false);
              }, 3000);
            } else {
              setIsShow(false);
            }
          })
          .catch((error) => {
            console.log('error', error);
            if (error) {
              setIsShow(true);
              setError(true);
              setErrorMessage('Something went wrong');
              setTimeout(() => {
                setIsShow(false);
              }, 3000);
            } else {
              setIsShow(false);
            }
          });
      } else {
        dispatch(AddVendorJob(reqBody))
          .then((res) => {
            if (res?.data?.status === 200 && res?.data?.success === true) {
              setIsShow(true);
              setError(false);
              // setOpen(!open);
              setErrorMessage(res.data.message);
              setTimeout(() => {
                setIsShow(false);
                setSubmitLoader(false);
                navigate(-1);
              }, 1500);
            } else {
              setIsShow(true);
              setError(true);
              // setOpen(!open);
              setErrorMessage(res.data.message);
              setTimeout(() => {
                setIsShow(false);
                setSubmitLoader(false);
              }, 1500);
            }
          })
          .catch((err) => {
            setIsShow(true);
            setError(true);
            setErrorMessage(err.message);
            setTimeout(() => {
              setIsShow(false);
              setSubmitLoader(false);
            }, 1500);
          });
      }
    },
  });
  const { errors, touched, getFieldProps } = formik;

  useEffect(() => {
    if (formik.values.state) {
      axiosInstance
        .get(`vendor/get-districts?key=${formik.values.state}`)
        .then((res) => {
          setAllCities(res?.data?.data);
        })
        .catch((err) => {});
    }
  }, [formik.values.state]);

  return (
    <div>
      {/* ------Toast message----- */}
      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      {/* -------Container-------------- */}
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <Typography variant="h6" fontWeight="600">
          {jobData ? 'Update Vendor Job' : 'Add Jobs'}
        </Typography>
        <Typography fontSize={14}>Add your job and hire employees</Typography>

        <Divider sx={{ mt: '10px' }} />
        <Card sx={{ padding: '20px', paddingRight: '80px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Stack spacing={3} sx={{ mt: 3 }}>
                <Grid container spacing={3}>
                  {/* job category */}
                  <Grid item xs={12} sm={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      fullWidt
                      // options={categoryList}
                      options={categoryList.slice().sort((a, b) => a.label.localeCompare(b.label))}
                      // getOptionLabel={(option) => option?.label}
                      value={formik.values.category || ''}
                      onChange={(event, newValue) => handleCategoryChange(event, newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="category"
                          label="Job Category*"
                          type="text"
                          size="medium"
                          variant="outlined"
                          {...getFieldProps('category')}
                          error={formik.touched.category && Boolean(formik.errors.category)}
                          helperText={formik.touched.category && formik.errors.category}
                        />
                      )}
                    />
                  </Grid>
                  {/* job title */}
                  <Grid item xs={12} sm={6} lg={6}>
                    <TextField
                      fullWidth
                      label="Job Title*"
                      name="job_Title"
                      {...getFieldProps('job_Title')}
                      error={Boolean(touched.job_Title && errors.job_Title)}
                      helperText={touched.job_Title && errors.job_Title}
                    />
                  </Grid>
                  {/* descriptionm */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Job Description*"
                      name="job_description"
                      {...getFieldProps('job_description')}
                      error={Boolean(touched.job_description && errors.job_description)}
                      helperText={touched.job_description && errors.job_description}
                    />
                  </Grid>
                  {/* Minimum Experience */}
                  <Grid item xs={12} sm={6} lg={6}>
                    <TextField
                      fullWidth
                      label="Minimum Experience*"
                      name="minimum_Experience"
                      {...getFieldProps('minimum_Experience')}
                      error={Boolean(touched.minimum_Experience && errors.minimum_Experience)}
                      helperText={touched.minimum_Experience && errors.minimum_Experience}
                    />
                  </Grid>
                  {/* Maximum Experience */}
                  <Grid item xs={12} sm={6} lg={6}>
                    <TextField
                      fullWidth
                      label="Maximum Experience*"
                      name="maximum_Experience"
                      {...getFieldProps('maximum_Experience')}
                      error={Boolean(touched.maximum_Experience && errors.maximum_Experience)}
                      helperText={touched.maximum_Experience && errors.maximum_Experience}
                    />
                  </Grid>
                  {/* Job Type */}
                  <Grid item xs={12} sm={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      disableFreeSolo
                      fullWidth
                      options={jobTypes}
                      // getOptionLabel={(option) => option?.label}
                      value={formik.values.job_type || ''}
                      onChange={(event, newValue) => handleJobTypeChange(event, newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="job_type"
                          label="Job Type*"
                          type="text"
                          size="medium"
                          variant="outlined"
                          {...getFieldProps('job_type')}
                          error={formik.touched.job_type && Boolean(formik.errors.job_type)}
                          helperText={formik.touched.job_type && formik.errors.job_type}
                        />
                      )}
                    />
                  </Grid>
                  {/* Education Qualification */}
                  <Grid item xs={12} sm={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      fullWidt
                      options={educationQualification}
                      // getOptionLabel={(option) => option?.label}
                      value={formik.values.education_qualification || ''}
                      // disabled={formik.values.vendor_status === 'Active'}
                      onChange={(event, newValue) => handleEducationChange(event, newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="education_qualification"
                          label="Education Qualification*"
                          type="text"
                          size="medium"
                          variant="outlined"
                          {...getFieldProps('education_qualification')}
                          error={
                            formik.touched.education_qualification && Boolean(formik.errors.education_qualification)
                          }
                          helperText={formik.touched.education_qualification && formik.errors.education_qualification}
                        />
                      )}
                    />
                  </Grid>
                  {/* No.of Positions */}
                  <Grid item xs={12} sm={6} lg={6}>
                    <TextField
                      label="No of Opening*"
                      fullWidth
                      name="No_of_Positions"
                      {...getFieldProps('No_of_Positions')}
                      error={Boolean(touched.No_of_Positions && errors.No_of_Positions)}
                      helperText={touched.No_of_Positions && errors.No_of_Positions}
                    />
                  </Grid>
                  {/* State */}
                  <Grid xs={12} sm={6} lg={6} item>
                    <Autocomplete
                      disablePortal
                      fullWidt
                      options={states}
                      value={formik.values.state || ''}
                      disabled={formik.values.vendor_status === 'Active'}
                      onChange={(event, newValue) => handleStateChange(event, newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="state"
                          label="State*"
                          type="text"
                          size="medium"
                          variant="outlined"
                          {...getFieldProps('state')}
                          error={formik.touched.state && Boolean(formik.errors.state)}
                          helperText={formik.touched.state && formik.errors.state}
                        />
                      )}
                    />
                  </Grid>

                  {/* Cities */}
                  <Grid xs={12} sm={6} item>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={cities}
                      // disabled={formik.values.vendor_status === 'Active'}
                      value={formik.values.city || ''}
                      onChange={(event, newValue) => handleCityChange(event, newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="city"
                          label="City*"
                          type="text"
                          size="medium"
                          variant="outlined"
                          {...getFieldProps('city')}
                          error={formik.touched.city && Boolean(formik.errors.city)}
                          helperText={formik.touched.city && formik.errors.city}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item />
                  {/* Minimun Salary */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="min_salary"
                      label="Minimum Salary*"
                      fullWidth
                      {...getFieldProps('min_salary')}
                      error={Boolean(touched.min_salary && errors.min_salary)}
                      helperText={touched.min_salary && errors.min_salary}
                    />
                  </Grid>
                  {/* Maximun Salary */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="max_salary"
                      label="Maximum Salary*"
                      fullWidth
                      {...getFieldProps('max_salary')}
                      error={Boolean(touched.max_salary && errors.max_salary)}
                      helperText={touched.max_salary && errors.max_salary}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <p>
                      Salary always measure in months <b>Ex:20k-30k/Months</b>
                    </p>
                  </Grid>
                  {/* Job Keys */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size="medium"
                      variant="outlined"
                      label="Job key"
                      placeholder="Add Job Key point and press + button to add into the list"
                      name="job_key"
                      error={Boolean(touched.job_key && errors.job_key)}
                      helperText={touched.job_key && errors.job_key}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              addJobKeys();
                            }}
                          >
                            <AddCircleIcon sx={{ color: 'green' }} />
                          </IconButton>
                        ),
                        style: {
                          fontSize: '16px ',
                        },
                      }}
                      {...getFieldProps('job_key')}
                    />

                    <Stack
                      direction="column"
                      spacing={1}
                      mt={'15px'}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                        gap: '10px',
                        paddingRight: '20px',
                        paddingLeft: '10px',
                      }}
                    >
                      {allTags.map((item) => (
                        <Stack direction="row" spacing={2}>
                          <FiberManualRecordRoundedIcon sx={{ marginRight: '8px', fontSize: '10px' }} />{' '}
                          <Typography sx={{ flex: 1 }}>{item}</Typography>
                          <DoDisturbOnIcon
                            color="error"
                            onClick={() => removeTags(item)}
                            sx={{
                              '& .MuiChip-deleteIcon': {
                                color: 'red',
                              },
                            }}
                          />
                        </Stack>
                      ))}
                    </Stack>
                  </Grid>
                  {/* Loading Button */}
                  <Grid item xs={12}>
                    <LoadingButton
                      loading={submitLoader}
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{
                        float: 'right',
                        '&:hover': {
                          background: '#0B8238',
                          opacity: 0.9,
                        },
                      }}
                    >
                      {jobData ? 'Update' : 'Post Job'}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </div>
  );
};

export default CreateVendorJob;
