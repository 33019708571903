// @mui
import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Box, Card } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import useRazorpay from 'react-razorpay';
import { useSelector, useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';

import { LoadingButton } from '@mui/lab';

import moment from 'moment';
import { fetchProfile } from '../api/CommanApicall';
import { PROFILE_DATA } from '../actions/Types';
import axiosInstance from '../api/withJWTServer';
// components
import Page from '../components/Page';
import { HandleExceptionWithSecureCatch } from '../actions/CombineCatch';
import logo from '../Assets/logintop.png';
import PlanAddressPaymentModel from './Modal/PlanAddressPaymentModel';
import tick from '../Assets/tick.png';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
const MainBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  mt: 5,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  maxWidth: 1000,
  height: '95vh',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

const Payment = () => {
  const { profileData, selectedPlans, subscriptionData, upgradePlan } = useSelector((state) => ({
    profileData: state?.profileData,
    selectedPlans: state?.selectedPlans,
    subscriptionData: state?.subscriptionData,
    upgradePlan: state?.upgradePlan,
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Razorpay] = useRazorpay();
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [changePlan, setChangePlan] = useState(false);
  const handleOpen = (e, plan) => {
    setOpen(true);
    setNoOfLocation(e);
    setSelectedPlan(plan);
  };
  const [plans, setPlans] = useState([]);
  const [checked, setChecked] = useState(false);
  const [noOfLocation, setNoOfLocation] = useState(null);
  const { plan } = useParams();
  const [planSelected, setPlanSelected] = useState(
    selectedPlans.length > 0
      ? selectedPlans?.[selectedPlans.length - 1].subscriptionPlansModels?.[0]?.uuid
      : profileData?.vendorBusinessDetailsModels?.[0]?.selected_plan_uuid
  );

  const [planType, setPlanType] = useState({
    amount: '',
    currency: '',
    plan_uuid: '',
    plan_type: '',
  });
  const [profileDetail, setProfileDetail] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };

  const handleChange = (event, selectedAddressDetail) => {
    setChecked(event);
    if (event) {
      handleSubmit(selectedAddressDetail);
    }
  };

  const handleTerms = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setChecked(false);
  };

  useEffect(() => {
    axiosInstance.get('vendor/subscription-plans-info').then((res) => {
      setPlans(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    async function profile() {
      try {
        const profileDetail = await fetchProfile();
        dispatch({
          type: PROFILE_DATA,
          payload: profileDetail?.data?.data[0] === undefined ? null : profileDetail?.data?.data[0],
        });
        setProfileDetail(profileDetail?.data?.data[0].status);
      } catch (error) {
        console.error(error);
      }
    }
    profile();
  }, []);

  const handleSubmit = (selectedAddressDetail) => {
    setOpen(false);
    setOpenBackdrop(true);
    const param = {
      total_amount: planType.amount,
      currency: planType.currency,
    };

    axiosInstance
      .post('vendor/order-id', param)
      .then((response) => {
        const params = {
          selected_plan_uuid: selectedPlan?.uuid,
          selected_plan_name: selectedPlan?.plan_title,
        };
        axiosInstance.patch('vendor/update-business-details', params).then((res) => {
          setOpenBackdrop(false);
          const options = {
            key: response?.data?.data?.razorpay_key,
            amount: `${planType.amount}`,
            currency: 'INR',
            partial_payment: 0,
            name: 'Sunn-Grahak',
            description: 'Sunn-Grahak Transaction',
            image: `${logo}`,
            order_id: response?.data?.data?.id,
            handler: (response) => {
              const startDate = moment().format('YYYY-MM-DD');

              function getYearEndDate(startDate) {
                const startDateMoment = moment(startDate, 'YYYY-MM-DD');
                const yearEndDate = startDateMoment.clone().add(1, 'year').subtract(1, 'day');
                return yearEndDate.format('YYYY-MM-DD');
              }

              const yearEndDate = getYearEndDate(startDate);
              axiosInstance
                .post('vendor/update-payment-status', {
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_signature: response.razorpay_signature,
                  plan_uuid: planType.plan_uuid,
                  start_date: startDate,
                  end_date: yearEndDate,
                })
                .then((response) => {
                  if (response) {
                    axiosInstance
                      .post('vendor/outlet-address', selectedAddressDetail)
                      .then((res) => {
                        axiosInstance.get('vendor/profile').then((res) => {
                          dispatch({
                            type: PROFILE_DATA,
                            payload: res?.data?.data[0] === undefined ? null : res?.data?.data[0],
                          });
                        });
                      })
                      .catch((err) => {
                        // handleChange(false);
                      });
                  }

                  dispatch(HandleExceptionWithSecureCatch(response));
                  navigate('/dashboard/app');
                  setChecked(false);
                })
                .catch((err) => {
                  dispatch(HandleExceptionWithSecureCatch(err));
                  setChecked(false);
                });
            },
            prefill: {
              name: profileData.firstname,
              email: profileData.email,
              contact: profileData.mobile_number,
            },
            notes: {
              address: 'Razorpay Corporate Office',
            },
            theme: {
              color: '#3399cc',
            },
          };

          const rzp1 = new Razorpay(options);

          rzp1.on('payment.failed', (response) => {
            axiosInstance
              .post('vendor/update-payment-status', {
                razorpay_order_id: response.error.metadata.order_id,
                razorpay_payment_id: response.error.metadata.payment_id,
              })
              .then((response) => {
                setChecked(false);
              })
              .catch((err) => {
                setChecked(false);
              });
          });

          rzp1.open();
        });
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setChecked(false);
      });
  };

  const handlePlanType = (e) => {
    setPlanType(e);
  };
  const vendorAddressDetail = (e) => {
    handleChange(true, e);
  };
  return (
    <RootStyle>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{}}>
          <Typography id="modal-modal-description" sx={{ mt: 2, height: 'max-content' }}>
            <PlanAddressPaymentModel
              vendorAddressDetail={vendorAddressDetail}
              noOfLocation={noOfLocation}
              selectedPlan={selectedPlan}
              planType={plan}
              onClose={handleClose}
            />
          </Typography>
        </Box>
      </Modal>
      <Page title="Dashboard" sx={{ width: '100%' }}>
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: -3 }}>
            Plans & Payment 1998
          </Typography>
          <Grid container spacing={3} sx={{ my: 3 }}>
            {!changePlan ? (
              <>
                {' '}
                {plan === '1' ? (
                  <>
                    {upgradePlan
                      ?.filter((item) => item.subscription_type === 'vendor_recruitment_subscription')
                      .map((val, index) => (
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                          <Card
                            sx={{
                              width: '100%',
                              borderRadius: '26px !important',
                              border: '2px solid #035421',
                              background: `${planSelected === val?.uuid ? '#e9f4ed !important' : '#F9F9F9 !important'}`,
                              p: 3,
                            }}
                          >
                            {planSelected === val?.uuid && (
                              <>
                                <span
                                  style={{
                                    position: 'absolute',
                                    left: '0',
                                    top: '0',
                                    padding: '5px 10px',
                                    backgroundColor: '#02742d',
                                    color: '#fff',
                                    marginTop: '5px',
                                    marginLeft: '5px',
                                    zIndex: '999',
                                    borderRadius: '10px',
                                  }}
                                >
                                  SELECTED
                                </span>
                              </>
                            )}
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                borderBottom: '1px solid #e8e4e4',
                                marginTop: `${planSelected === val?.uuid ? '10px' : '0px'}`,
                              }}
                            >
                              <MainBox>
                                <Typography variant="h3">
                                  <b>{val.plan_title}</b>
                                </Typography>
                              </MainBox>
                              <h5>
                                All the basics for businesses that are just getting started with per business per
                                location
                              </h5>
                              <MainBox style={{ textDecoration: 'line-through' }}>
                                <Typography variant="h5" sx={{ mr: 1 }}>
                                  ₹
                                </Typography>
                                <Typography
                                  variant="h5"
                                  style={{ marginRight: '5px' }}
                                >{`${val?.plan_price} `}</Typography>
                                <Typography variant="h5"> {`  /${val.plan_type}`}</Typography>
                              </MainBox>
                              <MainBox style={{ color: '#02742d' }}>
                                <Typography variant="h4" sx={{ mr: 1, mb: 1 }}>
                                  Need to pay - ₹ {val?.upgradeAmount}
                                </Typography>
                              </MainBox>
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                // borderBottom: '1px solid #e8e4e4',
                                marginTop: `${planSelected === val?.uuid ? '10px' : '0px'}`,
                              }}
                            >
                              <MainBox>
                                <Typography variant="h5" sx={{ mr: 1, mb: 1 }}>
                                  {val.no_of_locations === '1' ? '(SINGLE LOCATION)' : '(DOUBLE LOCATION)'}
                                </Typography>
                              </MainBox>
                            </Grid>

                            <Box sx={{ ml: 2, mt: 2 }}>
                              {/* <ul> */}
                              <h4 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                                <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} />{' '}
                                Prioritizing user's needs and exceptional service free of charge
                              </h4>
                              <br />
                              <h4 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                                <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} />{' '}
                                No hidden fees or subsriptions are required to access our comprehensive vendor database
                              </h4>
                              <br />
                              <h4 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                                <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} />{' '}
                                Users can explore and connect with vendors without an obligations
                              </h4>
                              <br />
                              <h4 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                                <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} />{' '}
                                Trade disputes will not be a part of this Application
                              </h4>
                              <span style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                <LoadingButton
                                  onClick={() => {
                                    handleTerms();
                                    setPlanSelected(val?.uuid);
                                    handlePlanType({
                                      amount: `${val?.upgradeAmount}`,
                                      currency: `INR`,
                                      plan_uuid: `${val?.uuid}`,
                                      plan_type: `${val?.plan_type}`,
                                    });
                                    if (profileDetail === 'Active' || profileDetail === 'Renewal') {
                                      handleOpen('1', val);
                                    }
                                  }}
                                  size="large"
                                  type="submit"
                                  variant="contained"
                                  sx={{ mt: 2 }}
                                  disabled={
                                    (
                                      profileDetail === 'Renewal'
                                        ? 0
                                        : profileDetail !== 'Active' || (plan === '0' && selectedPlans?.length > 0)
                                    )
                                      ? 1
                                      : 0
                                  }
                                >
                                  Pay Now
                                </LoadingButton>
                              </span>
                            </Box>
                          </Card>
                        </Grid>
                      ))}
                  </>
                ) : (
                  <>
                    <>
                      {plans
                        ?.filter((item) => item.subscription_type === 'vendor_recruitment_subscription')
                        .map((val, index) => (
                          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <Box>
                              {planSelected === val?.uuid && (
                                <>
                                  <Box
                                    sx={{
                                      padding: '5px 20px',
                                      backgroundColor: '#02742d',
                                      color: '#fff',
                                      position: 'absolute',
                                      zIndex: 999,
                                      borderTopLeftRadius: '15px',
                                      borderTopRightRadius: '15px',
                                      borderBottomRightRadius: '15px',
                                      textAlign: 'center',
                                      marginLeft: '0px',
                                      marginTop: '-10px',
                                      fontWeight: '600',
                                    }}
                                  >
                                    SELECTED
                                  </Box>
                                </>
                              )}
                              <Card
                                sx={{
                                  p: 3,
                                  mt: '0px',
                                  borderRadius: '26px',
                                  border: '2px solid #035421',
                                  background: `${
                                    planSelected === val?.uuid ? '#e9f4ed !important' : '#F9F9F9 !important'
                                  }`,
                                }}
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  style={{
                                    borderBottom: '1px solid #e8e4e4',
                                    marginTop: `${planSelected === val?.uuid ? '10px' : '0px'}`,
                                  }}
                                >
                                  <MainBox>
                                    <Typography fontWeight={'700'} fontSize={'26px'}>
                                      {val.plan_title}
                                    </Typography>
                                  </MainBox>

                                  <p style={{ textAlign: 'center' }}>
                                    All the basics for businesses that are just getting started with per business per
                                    location.
                                  </p>

                                  <MainBox>
                                    <Typography variant="h5" sx={{ mr: 1, color: '#0B8238' }}>
                                      ₹
                                    </Typography>
                                    <Typography
                                      variant="h2"
                                      style={{ marginRight: '5px', color: '#0B8238' }}
                                    >{`${val?.plan_price} /`}</Typography>
                                    <Typography variant="h5" style={{ color: '#0B8238' }}>
                                      {' '}
                                      {` ${val?.plan_type === 'Yearly' ? 'Yr' : null}`}
                                    </Typography>
                                  </MainBox>
                                </Grid>
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  style={{
                                    // borderBottom: '1px solid #e8e4e4',
                                    marginTop: `${planSelected === val?.uuid ? '10px' : '10px'}`,
                                  }}
                                >
                                  <MainBox>
                                    <b> {val.no_of_locations === '1' ? '(SINGLE LOCATION)' : '(DOUBLE LOCATION)'}</b>
                                  </MainBox>
                                </Grid>

                                <Box sx={{ ml: 2 }}>
                                  <Typography
                                    style={{
                                      fontWeight: '300',
                                      fontSize: '15px',
                                      display: 'flex',
                                      marginTop: '5px',
                                      marginRight: '5px',
                                    }}
                                  >
                                    <img
                                      src={tick}
                                      alt="plan"
                                      height="20px"
                                      width="20px"
                                      style={{ marginRight: '5px', marginTop: '2px' }}
                                    />
                                    Prioritizing user's needs and exceptional service free of charge
                                  </Typography>

                                  <Typography
                                    style={{
                                      fontWeight: '300',
                                      fontSize: '15px',
                                      display: 'flex',
                                      marginTop: '5px',
                                      marginRight: '5px',
                                    }}
                                  >
                                    <img
                                      src={tick}
                                      alt="plan"
                                      height="20px"
                                      width="20px"
                                      style={{ marginRight: '5px', marginTop: '2px' }}
                                    />
                                    No hidden fees or subsriptions are required to access our comprehensive vendor
                                    database
                                  </Typography>

                                  <Typography
                                    style={{
                                      fontWeight: '300',
                                      fontSize: '15px',
                                      display: 'flex',
                                      marginTop: '5px',
                                      marginRight: '5px',
                                    }}
                                  >
                                    <img
                                      src={tick}
                                      alt="plan"
                                      height="20px"
                                      width="20px"
                                      style={{ marginRight: '5px', marginTop: '2px' }}
                                    />
                                    Users can explore and connect with vendors without an obligations
                                  </Typography>

                                  <Typography
                                    style={{
                                      fontWeight: '300',
                                      fontSize: '15px',
                                      display: 'flex',
                                      marginTop: '5px',
                                      marginRight: '5px',
                                    }}
                                  >
                                    <img
                                      src={tick}
                                      alt="plan"
                                      height="20px"
                                      width="20px"
                                      style={{ marginRight: '5px', marginTop: '2px' }}
                                    />
                                    Trade disputes will not be a part of this Application
                                  </Typography>
                                  <span style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                    <LoadingButton
                                      onClick={() => {
                                        handleTerms();
                                        setPlanSelected(val?.uuid);
                                        handlePlanType({
                                          amount: `${val?.plan_price}`,
                                          currency: `INR`,
                                          plan_uuid: `${val?.uuid}`,
                                          plan_type: `${val?.plan_type}`,
                                        });
                                        if (profileDetail === 'Active' || profileDetail === 'Renewal') {
                                          handleOpen(val.no_of_locations, val);
                                        }
                                      }}
                                      size="large"
                                      type="submit"
                                      fullWidth
                                      variant="contained"
                                      sx={{ mt: 2 }}
                                      disabled={
                                        (
                                          profileDetail === 'Renewal'
                                            ? 0
                                            : profileDetail !== 'Active' || (plan === '0' && selectedPlans?.length > 0)
                                        )
                                          ? 1
                                          : 0
                                      }
                                    >
                                      Pay Now
                                    </LoadingButton>
                                  </span>
                                </Box>
                              </Card>
                            </Box>
                          </Grid>
                        ))}
                    </>
                  </>
                )}
              </>
            ) : (
              <>
                {plans?.map((val, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Card
                      sx={{
                        p: 3,
                        borderRadius: '26px',
                        border: '2px solid #035421',
                        background: `${
                          profileData?.vendorBusinessDetailsModels?.[0]?.selected_plan_uuid === val.uuid
                            ? '#02742d !important'
                            : '#F9F9F9 !important'
                        }`,
                      }}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{ borderBottom: '1px solid #e8e4e4' }}
                      >
                        <MainBox>
                          <Typography variant="h4" style={{ fontWeight: 900 }}>
                            {val.plan_title}
                          </Typography>
                        </MainBox>
                        <p style={{ textAlign: 'center' }}>
                          we have a All the basics for businesses that are just getting started with per business per
                          location.
                        </p>

                        <MainBox>
                          <Typography variant="h5" sx={{ mr: 1, color: '#0B8238' }}>
                            ₹
                          </Typography>
                          <Typography
                            variant="h2"
                            style={{ marginRight: '5px', color: '#0B8238' }}
                          >{`${val?.plan_price} /`}</Typography>
                          <Typography variant="h5" style={{ color: '#0B8238' }}>
                            {' '}
                            {` ${val?.plan_type === 'Yearly' ? 'Yr' : null}`}
                          </Typography>
                        </MainBox>
                      </Grid>
                      <br />

                      <Box sx={{ ml: 2 }}>
                        <h4 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                          <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} />{' '}
                          Prioritizing user's needs and exceptional service free of charge
                        </h4>
                        <br />
                        <h4 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                          <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} /> No
                          hidden fees or subsriptions are required to access our comprehensive vendor database
                        </h4>
                        <br />
                        <h4 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                          <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} /> Users
                          can explore and connect with vendors without an obligations
                        </h4>
                        <span style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                          <LoadingButton
                            onClick={() => {
                              handleTerms();
                              handlePlanType({
                                amount: `${val?.plan_price}`,
                                currency: `INR`,
                                plan_uuid: `${val?.uuid}`,
                                plan_type: `${val?.plan_type}`,
                              });
                              if (profileDetail === 'Active' || profileDetail === 'Renewal') {
                                handleOpen(val.no_of_locations, val);
                              }
                            }}
                            size="large"
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={
                              (
                                profileDetail === 'Renewal'
                                  ? 0
                                  : profileDetail !== 'Active' || (plan === '0' && selectedPlans?.length > 0)
                              )
                                ? 1
                                : 0
                            }
                          >
                            Pay Now
                          </LoadingButton>
                        </span>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Container>
      </Page>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </RootStyle>
  );
};

export default Payment;
