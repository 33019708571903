import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormHelperText,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';

function SelectState(props) {
  const { label, data,required, onValueChange, ...rest } = useMemo(() => props, [props]);
  const [field, meta, helpers] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  const isInitialRender = useRef(true);
useEffect(() => {
  if(props.currentState){
    helpers.setValue(props.currentState);
  }
  else{
    helpers.setValue('');
  }
},[props.currentState])

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  useEffect(()=>{
    if(props.initalState){
      helpers.setValue(props.initalState); 
      onValueChange(props.initalState);
    }
  },[props.initalState])
  const handleValueChange = (event) => {
    const newValue = event.target.value;
    if(newValue){
      helpers.setValue(newValue); // Use Formik's setValue to update the form field value
    
    }
    if(newValue){
      onValueChange(newValue);
    }
  };

  return (
    <FormControl {...rest} error={isError} variant="standard" sx={{ mt: "-4px !important" }}>
    <InputLabel>{label}{props.planSlug === 1? "*":""}</InputLabel>
    <Select {...field} value={selectedValue}  label={label} onChange={handleValueChange}>
      {data?.map((item, index) => (
        <MenuItem key={index} value={item.value} style={{display:"flex",flexDirection:"column",justifyContent:"start",alignItems:"flex-start",padding:"5px"}}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
    {_renderHelperText()}
  </FormControl>
  );
}

SelectState.defaultProps = {
  data: [],
};

SelectState.propTypes = {
  data: PropTypes.array,
};

export default SelectState;
