import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Typography,
  Card,
  Chip,
  Box,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { requestForToken } from '../../../firebase';
import { fetchProfile } from '../../../api/CommanApicall';
import { LoginUser } from '../../../actions/AuthActions';
// component
import Page from '../../../components/Page';
import useResponsive from '../../../hooks/useResponsive';
import Iconify from '../../../components/Iconify';
import Server from '../../../api';
import { HandleExceptionWithSecureCatch } from '../../../actions/CombineCatch';
import { LOGIN, VENDOR_DETAIL, PROFILE_DATA } from '../../../actions/Types';
import axiosInstance from '../../../api/withJWTServer';
import Footericon from '../../../Assets/bottomImg.png';
import backgroundimage from '../../../Assets/bg.png';
import loginTop from '../../../Assets/loginLogoNew.png';
import loginMiddle from '../../../Assets/loginMidle.png';
import darkLogo from '../../../Assets/imgResLogo.png';

import ShowMessage from '../../../pages/Modal/ShowMessage';

// ----------------------------------------------------------------------
const SectionStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  backgroundImage: `url(${backgroundimage})`,
  height: '100vh',
  backgroundSize: '90%',
  backgroundRepeat: 'no-repeat',
}));

const ResponsiveSectionStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '5px',
  flexDirection: 'column',
  justifyContent: 'start',
  height: 'auto',
  width: 'fit-content',
  backgroundSize: '90%',
  backgroundRepeat: 'no-repeat',
}));

const SectionStyle2 = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '100vh',
  },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(0, 0, 0, 0),
  borderRadius: 33,
  position: 'relative',
  alignItems: 'center',
}));
const SubSection2 = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    marginLeft: '200px',
  },
}));

export default function Otp() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [showOtp, setShowOtp] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [counter, setCounter] = useState(30);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const { isLogged } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
  }));

  useEffect(() => {
    // Function to decrease the counter by 1 every second
    const countdown = () => {
      if (counter > 0) {
        setCounter(counter - 1);
      }
    };

    const intervalId = setInterval(countdown, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [counter]);

  const LoginSchema = Yup.object().shape({
    otp: Yup.string()
      .matches(/^\d{4}$/, 'OTP must be a 4-digit number')
      .required('OTP is required'),
  });

  async function profile() {
    try {
      const profileDetail = await fetchProfile();
      dispatch({
        type: PROFILE_DATA,
        payload: profileDetail?.data?.data[0] === undefined ? null : profileDetail?.data?.data[0],
      });
    } catch (error) {
      console.error(error);
    }
  }
  const formik = useFormik({
    initialValues: {
      otp: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (value, { resetForm }) => {
      setSubmitLoader(true);
      const params = {
        mobile_number: state.phone,
        otp: value.otp.toString(),
      };
      axiosInstance
        .post('vendor/verify-otp', params)
        .then((res) => {
          localStorage.setItem('token_vendor', res?.data?.data[0].token);
          setTimeout(() => {
            requestForToken();
          }, 1000);

          setIsShow(true);
          setError(false);

          console.log('response', res);
          setErrorMessage(res.data.message);

          resetForm();

          if (res.data.status === 404) {
            console.log('ujcgudhci');
            setIsShow(true);
            setError(true);
            setErrorMessage(res.data.message);
          }

          setTimeout(() => {
            setSubmitLoader(false);
            setIsShow(false);
            if (res.status === 200 && res?.data?.data[0].vendor_details === 'Available') {
              dispatch(
                LoginUser({
                  firstname: res?.data?.data[0].firstname,
                  lastname: res?.data?.data[0].lastname,
                  email: res?.data?.data[0].email,
                  setupTour: res?.data?.data[0].setup_tour_required,
                  slug: 1,
                })
              );
              const para = {
                mobile_number: res?.data?.data[0].mobile_number,
                otp: value.otp.toString(),
                token: res?.data?.data[0].token,
                uuid: res?.data?.data[0].uuid,
              };
              dispatch({
                type: LOGIN,
                payload: para,
              });

              dispatch({
                type: VENDOR_DETAIL,
                payload: res?.data?.data[0].setup_tour_required,
              });

              profile();
              navigate('/dashboard/app', { replace: true });
            } else {
              dispatch({
                type: VENDOR_DETAIL,
                payload: 1,
              });
              navigate('/register', {
                state: {
                  uuid: res?.data?.data[0].uuid,
                  token: res?.data?.data[0].token,
                },
              });
            }
          }, [2000]);
        })
        .catch((error) => {
          setSubmitLoader(false);
          setIsShow(true);
          setError(true);
          setErrorMessage('Invalid OTP');
          console.log('Error:', error);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowOtp = () => {
    setShowOtp((show) => !show);
  };

  useEffect(() => {
    const isAuth = JSON.parse(localStorage.getItem('dial4vyapar_vendor_state'));
    if (!isAuth?.auth?.isLogged || isAuth === null) {
      // navigate('/dashboard');
    } else {
      navigate('/dashboard/app');
    }

    setPhoneNumber(state.phone);
  }, []);

  const handleSetOtp = () => {
    const params = {
      mobile_number: phoneNumber.toString(),
    };
    try {
      axiosInstance
        .post('vendor/resend-otp', params)
        .then((res) => {
          setSubmitLoader(false);
          dispatch(HandleExceptionWithSecureCatch(res));
          // if (res?.data?.status === 200) {
          //   navigate('/otp', {
          //     state: {
          //       phone: value.phonenumber.toString(),
          //     },
          //   });
          // }
        })
        .catch((err) => {
          setSubmitLoader(false);
          dispatch(HandleExceptionWithSecureCatch(err));
          // if (!navigator.onLine) {
          //   dispatch(HandleExceptionWithSecureCatch({}));
          // }
        });
    } catch (e) {
      if (e) {
        // setSubmitLoader(false);
      }
    }
  };

  function renderOtpSection() {
    return (
      <Grid item md={4} xs={12}>
        <SectionStyle2>
          <SubSection2>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ fontSize: 20, marginTop: 20 }}>
                  OTP Verification
                </Typography>
                <Typography gutterBottom style={{ fontSize: 15, marginBottom: 15 }}>
                  Please enter the OTP sent to <span style={{ fontWeight: '600' }}>+91 {phoneNumber}</span>
                </Typography>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={1}>
                      <TextField
                        fullWidth
                        label="OTP"
                        autoComplete="current-password"
                        placeholder="OTP"
                        type={showOtp ? 'text' : 'password'}
                        {...getFieldProps('otp')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowOtp} edge="end">
                                <Iconify icon={showOtp ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.otp && errors.otp)}
                        helperText={touched.otp && errors.otp}
                      />
                    </Stack>

                    <Stack sx={{ mt: 2, mb: 2 }}>
                      <Link to="/dashboard/app" className="btn btn-primary">
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={submitLoader && true}
                          // disabled={counter === 60}
                          sx={{
                            '&:hover': {
                              bgcolor: 'green',
                            },
                          }}
                          style={{ borderRadius: '100px' }}
                        >
                          Verify
                        </LoadingButton>
                      </Link>
                    </Stack>
                    {/* <h5 style={{color: "grey", textAlign: "center"}}>Didn't Receive OTP? Retry in 20Sec </h5> */}

                    {counter === 0 ? (
                      <>
                        {/* <h5 style={{color: "grey", textAlign: "center"}}>Didn't Receive OTP? Retry </h5><br/> */}
                        <LoadingButton
                          fullWidth
                          size="large"
                          // type="submit"
                          style={{ border: '1px solid green', borderRadius: '100px' }}
                          onClick={() => {
                            handleSetOtp();
                            setCounter(30);
                          }}
                          // variant="contained"
                          // loading={submitLoader && true}
                        >
                          Resend OTP
                        </LoadingButton>
                      </>
                    ) : (
                      <h5 style={{ color: 'grey', textAlign: 'center', marginTop: '1px', fontWeight: '600' }}>
                        Didn't Receive OTP ? Retry in 00: {counter}sec{' '}
                      </h5>
                    )}
                  </Form>
                </FormikProvider>
              </Grid>
            </Grid>
          </SubSection2>

          <ShowMessage
            onClose={() => setIsShow(false)}
            title={errorMessage}
            type={error ? 'error' : 'success'}
            open={isShow}
          />
        </SectionStyle2>
      </Grid>
    );
  }

  return mdUp ? (
    <>
      <Grid container>
        <Grid item md={6}>
          {mdUp && (
            <SectionStyle>
              <Box
                sx={{
                  height: '100vh',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: '30px',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  flexDirection: 'column',
                }}
              >
                <img src={`${loginTop}`} alt="login" width={'130px'} />

                <Box>
                  <Typography sx={{ fontSize: '22px', fontWeight: '400', color: '#FFFFFF' }}> Welcome Back,</Typography>
                  <img src={`${loginMiddle}`} alt="login" height="140px" style={{ marginLeft: '-10px' }} />
                  <Typography sx={{ fontSize: '18px', fontWeight: '200', color: '#FFFFFF' }}>
                    Your Business Ultimate Growth Partner
                  </Typography>
                </Box>

                <span>
                  <img src={Footericon} alt="logo" style={{ width: '60px', height: '60px' }} />
                  <Box height={'5px'} />
                  <p style={{ fontSize: '14px', fontWeight: '300', color: 'rgba(255,255,255,0.8)' }}>
                    In association with
                  </p>
                  <b style={{ fontSize: '18px', fontWeight: '600', color: '#FFFFFF' }}>
                    International Sindhi Samaj Sangathan
                  </b>
                  <Box height={'10px'} />
                </span>
              </Box>
            </SectionStyle>
          )}
        </Grid>

        {renderOtpSection()}
      </Grid>
    </>
  ) : (
    <Container>
      <Grid item md={12}>
        <ResponsiveSectionStyle>
          <Box
            sx={{
              height: 'auto',
              width: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
              marginRight: '30px',
              paddingTop: '20px',
              paddingBottom: '20px',
              flexDirection: 'column',
            }}
          >
            <img src={`${darkLogo}`} alt="login" width={'130px'} />
          </Box>
        </ResponsiveSectionStyle>
      </Grid>
      <Grid item md={12}>
        {renderOtpSection()}
      </Grid>
    </Container>
  );
}
