import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import noPdf from '../../../Assets/noPdf.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Input = styled('input')({
  display: 'none',
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TabBusinesImages(props) {
  console.log('docs', props.docs);

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={props.isOpen}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.docs.title}
        </BootstrapDialogTitle>

        <DialogContent dividers>
          {/\.pdf$/i.test(props.docs?.path) ? (
            <Grid container spacing={12}>
              <Grid item xs={12} style={{ width: '1000px', height: '600px' }}>
                <div style={{ marginTop: '30px', textAlign: 'center' }}>
                  <img src={noPdf} alt="Selected" style={{ height: '300px', aligned: 'center', margin: '0 auto' }} />
                  <div
                    style={{
                      background: 'green',
                      color: 'white',
                      width: '350px',
                      fontSize: '16px',
                      borderRadius: '10px',
                      padding: '10px',
                      textAlign: 'center',
                      bottom: '0px',
                      marginTop: '20px',
                      margin: '20px auto',
                    }}
                  >
                    <a href={`${props.docs?.path}`} rel="noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                      Download Document
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={12}>
              <Grid item xs={12} style={{ width: '1000px', height: '600px' }}>
                <div>
                  <img
                    src={props?.docs?.path}
                    alt="im"
                    style={{ width: '600px', height: '400', borderRadius: '8px' }}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
