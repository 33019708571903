import React from 'react';
import { Typography, Link, Card } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Container, Grid } from '@mui/material';

function FormSuccess() {
  return (
    <>
      <Container maxWidth="lg">
        <Grid xs={12}>
          <Card
            sx={{
              width: '100%',
              height: '100vh',
              padding: '20px',
              // borderRadius: '26px !important',
              // border: '2px solid #035421',

              // background: `${planSelected === val?.uuid ? '#e9f4ed !important' : '#F9F9F9 !important'}`,
              p: 3,
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ padding: '10px' }}>
              Your setup tour submitted successfully.
            </Typography>
          </Card>
        </Grid>
      </Container>
    </>
  );
}

export default FormSuccess;
