import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Button,
  Typography,
  Stack,
  Box,
  Divider,
  Grid,
  TextField,
  Autocomplete,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { Form, Formik, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axiosInstance from '../../api/withJWTServer';
import { PROFILE_DATA } from '../../actions/Types';
import { fetchProfile } from '../../api/CommanApicall';

// import axiosInstance from '../../api/withJWTServer';

const BusinessPlansPayment = (props) => {
  const dispatch = useDispatch();

  const [submitLoader, setSubmitLoader] = useState(false);

  const [plans, setPlans] = useState([]);
  console.log('plans', plans);

  const [selectedPlanUUID, setSelectedPlanUUID] = useState('');
  const [termChecked, setTermChecked] = useState(false);

  const [selectedPlanName, setSelectedPlanName] = useState('');

  const getAllPlans = async () => {
    await axiosInstance
      .get('vendor/subscription-plans-info')
      .then((res) => {
        setPlans(res?.data?.data);
      })
      .catch((e) => {
        console.log('Error', e);
      });
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const selectPlanTap = (planame, planUUID) => {
    setSelectedPlanUUID(planUUID);
    setSelectedPlanName(planame);
  };

  const validation = Yup.object().shape({});
  const formInitialValues = {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: validation,
    onSubmit: (values, actions) => {
      if (!termChecked) {
        props.showErrorToastMessage('Please agree the term & condition of acceptance.');
      } else {
        // submitData();
        props.onSave(selectedPlanUUID, selectedPlanName);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setFieldError } = formik;

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid lg={12} item mt={'5px'}>
              <Typography fontWeight={'500'} fontSize={'18px'}>
                Select Plan
              </Typography>

              <Typography fontWeight={'400'} fontSize={'14px'}>
                Select a plan to complete a registration
              </Typography>
            </Grid>

            <Grid container spacing={2} lg={12} item>
              {plans
                .filter((item) => item.subscription_type === 'vendor_recruitment_subscription')
                .map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={plans.length > 2 ? 3 : 6} key={index}>
                    <Box>
                      {selectedPlanUUID === item?.uuid && (
                        <>
                          <Box
                            sx={{
                              padding: '5px 20px',
                              backgroundColor: '#02742d',
                              color: '#fff',
                              position: 'absolute',
                              zIndex: 999,
                              borderTopLeftRadius: '15px',
                              borderTopRightRadius: '15px',
                              borderBottomRightRadius: '15px',
                              textAlign: 'center',
                              marginLeft: '0px',
                              marginTop: '-10px',
                              fontWeight: '600',
                            }}
                          >
                            SELECTED
                          </Box>
                        </>
                      )}

                      <Card
                        onClick={() => {
                          selectPlanTap(item?.plan_title, item?.uuid);
                        }}
                        style={{
                          padding: '20px',
                          marginTop: '0px',
                          borderRadius: 30,
                          border: '2px solid #035421',
                          cursor: 'pointer',
                          backgroundColor: `${selectedPlanUUID === item?.uuid ? '#e9f4ed' : '#F9F9F9'}`,
                        }}
                      >
                        <Grid
                          item
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            marginTop: '20px',
                          }}
                        >
                          <Typography variant="h5" sx={{ fontWeight: '700', fontSize: '26px', textAlign: 'center' }}>
                            {item.plan_title}
                          </Typography>

                          <Typography sx={{ fontWeight: '500', fontSize: '14px', textAlign: 'center' }}>
                            All the basics for businesses that are just getting started with per business per location.
                          </Typography>

                          <Typography variant="h5" sx={{ mr: 1, color: '#0B8238', textAlign: 'center' }}>
                            <b>₹ {`${item?.plan_price}`}</b> {` ${item?.plan_type === 'Yearly' ? '/ Year' : null}`}
                          </Typography>
                        </Grid>

                        <Divider />
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            // borderBottom: '1px solid #e8e4e4',
                            marginTop: `${selectedPlanUUID === item?.uuid ? '10px' : '10px'}`,
                          }}
                        >
                          <b> {item.no_of_locations === '1' ? '(SINGLE LOCATION)' : '(DOUBLE LOCATION)'}</b>
                        </Grid>

                        <Box sx={{ ml: 2 }}>
                          <Typography
                            style={{
                              fontWeight: '300',
                              fontSize: '15px',
                              display: 'flex',
                              marginTop: '5px',
                              marginRight: '5px',
                            }}
                          >
                            <CheckCircleIcon sx={{ color: 'green', fontSize: '20px', marginRight: '10px' }} />{' '}
                            Prioritizing user's needs and exceptional service free of charge
                          </Typography>

                          <Typography
                            style={{
                              fontWeight: '300',
                              fontSize: '15px',
                              display: 'flex',
                              marginTop: '5px',
                              marginRight: '5px',
                            }}
                          >
                            <CheckCircleIcon sx={{ color: 'green', fontSize: '20px', marginRight: '10px' }} /> No hidden
                            fees or subsriptions are required to access our comprehensive vendor database
                          </Typography>

                          <Typography
                            style={{
                              fontWeight: '300',
                              fontSize: '15px',
                              display: 'flex',
                              marginTop: '5px',
                              marginRight: '5px',
                            }}
                          >
                            <CheckCircleIcon sx={{ color: 'green', fontSize: '20px', marginRight: '10px' }} /> Users can
                            explore and connect with vendors without an obligations
                          </Typography>

                          <Typography
                            style={{
                              fontWeight: '300',
                              fontSize: '15px',
                              display: 'flex',
                              marginTop: '5px',
                              marginRight: '5px',
                            }}
                          >
                            <CheckCircleIcon sx={{ color: 'green', fontSize: '20px', marginRight: '10px' }} /> Trade
                            disputes will not be a part of this Application
                          </Typography>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                ))}
            </Grid>

            <Grid container lg={12} item>
              <Stack direction={'row'} alignItems={'center'}>
                <Checkbox
                  value={termChecked}
                  onChange={(e) => {
                    setTermChecked(e.target.checked);
                  }}
                />
                <Typography>
                  I Have Accept the{' '}
                  <a
                    href="https://sunn-grahak.com/vendor_payment_acceptance.html"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: 'green',
                      textDecoration: 'none',
                      fontWeight: '600',
                    }}
                  >
                    Terms & Conditions
                  </a>
                </Typography>
              </Stack>
            </Grid>

            <Grid lg={12} item mt={'5px'}>
              <Stack direction={'row'} justifyContent={'flex-end'} flex={1}>
                {props.isStepper ? (
                  <LoadingButton
                    variant="outlined"
                    size="medium"
                    color="error"
                    disableElevation
                    onClick={props.onBackPress}
                    sx={{
                      alignSelf: 'flex-end',
                      padding: '8px 40px',
                      marginRight: '10px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Back
                  </LoadingButton>
                ) : null}

                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={submitLoader}
                  disableElevation
                  disabled={selectedPlanUUID === ''}
                  sx={{
                    alignSelf: 'flex-end',
                    padding: '8px 40px',
                    textTransform: 'uppercase',
                    '&:hover': {
                      bgcolor: 'green',
                    },
                  }}
                >
                  {props.isStepper ? 'Save & Next' : 'Save'}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default BusinessPlansPayment;
