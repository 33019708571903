import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Container, Box, Card, Typography, FormControlLabel, Radio, FormGroup } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import PaymentContent from '../../../PaymentContent';
import { PROFILE_DATA, SUBSRIPTION_PLANS } from '../../../../actions/Types';
import { fetchProfile } from '../../../../api/CommanApicall';
import axiosInstance from '../../../../api/withJWTServer';
import Page from '../../../../components/Page';
import tick from '../../../../Assets/tick.png';

const MainBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  mt: 5,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

const PlansandPayments = (props) => {
  const [plans, setPlans] = useState([]);
  const [profileDetail, setProfileDetail] = useState('');
  const [termAccept, setTermAccept] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const { setFieldValue } = useFormikContext();
  const [locationArray, setLocationArray] = useState([]);
  const handleOpenCheckBox = () => setOpenCheckBox(true);
  const handleCloseCheckBox = () => setOpenCheckBox(false);
  const [openCheckBox, setOpenCheckBox] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const dispatch = useDispatch();
  const { profileData, subscriptionData, selectedPlans } = useSelector((state) => ({
    profileData: state?.profileData,
    subscriptionData: state?.subscriptionData,
    selectedPlans: state?.selectedPlans,
  }));
  useEffect(() => {
    props.checkTermsandCondition(checked);
  }, [checked]);

  useEffect(() => {
    if (termAccept) {
      setChecked(true);
    }
  }, [termAccept]);

  const allPlansUuid = subscriptionData?.map((val) => val?.uuid);
  const noofLocation = subscriptionData?.map((val) => val?.no_of_locations);
  const positions = allPlansUuid?.map((id) => id);

  const {
    formField: { plansBoxList },
  } = props;

  useEffect(() => {
    axiosInstance.get('vendor/subscription-plans-info').then((res) => {
      setPlans(res?.data?.data);
      dispatch({
        type: SUBSRIPTION_PLANS,
        payload: res?.data?.data,
      });
    });
  }, []);
  const handleRadioChange = (value, locationCount, val) => {
    setSelectedPlan(value); // Set the selected plan
    // const plan = value - 1;
    const selectedPlan = val;

    console.log('SelectedPlan:-', selectedPlan);

    setFieldValue('plansBoxList', selectedPlan); // Update the form field value

    const noLocationArray = [];
    for (let i = 1; i <= locationCount; i += 1) {
      noLocationArray?.push(1);
    }
    setLocationArray(noLocationArray);
  };
  useEffect(() => {
    async function profile() {
      try {
        const profileDetail = await fetchProfile();
        dispatch({
          type: PROFILE_DATA,
          payload: profileDetail?.data?.data?.[0] === undefined ? null : profileDetail?.data?.data[0],
        });
        setProfileDetail(profileDetail?.data?.data?.[0].status);
      } catch (error) {
        console.error(error);
      }
    }
    profile();
  }, []);
  const plansOptionsWithLocation = positions?.map((val, i) => ({
    value: i + 1,
    label: '',
    is_checked: 0,
  }));

  const plansOptions = plansOptionsWithLocation?.map((item, index) => ({
    ...item,
    location: noofLocation?.[index],
  }));

  useEffect(() => {
    props?.selectedPlans(selectedPlan);
  }, [selectedPlan]);
  const mergedArray = plans?.map((item, index) => ({ ...item, ...plansOptions?.[index] }));
  const handlecheckChange = (event) => {
    setTermAccept(!termAccept);
    setChecked(!checked);

    props?.termsConditionErrorCheck(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
    setChecked(false);
  };

  const [field] = useField('plansBoxList');
  const selectedAndPayedPlan =
    selectedPlans.length > 0 ? selectedPlans?.[selectedPlans.length - 1].subscriptionPlansModels?.[0] : null;
  const selectedSinglePlan = subscriptionData?.filter(
    (val) => val?.uuid === profileData?.vendorBusinessDetailsModels?.[0]?.selected_plan_uuid
  );
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ height: '550px' }}>
          <Typography id="modal-modal-description" sx={{ mt: 2, maxHeight: '400px', overflowY: 'auto' }}>
            <PaymentContent handleClose={handleClose} />
          </Typography>
        </Box>
      </Modal>
      <Page title="Dashboard" sx={{ width: '100%' }}>
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Plans & Payment
          </Typography>

          {profileData?.last_completed_step < 4 ? (
            <>
              <Grid container spacing={2} sx={{ my: 1 }}>
                {mergedArray?.map((val, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={5} key={index}>
                    <Box>
                      {selectedPlan === val?.uuid && (
                        <>
                          <Box
                            sx={{
                              padding: '5px 20px',
                              backgroundColor: '#02742d',
                              color: '#fff',
                              position: 'absolute',
                              zIndex: 999,
                              borderTopLeftRadius: '15px',
                              borderTopRightRadius: '15px',
                              borderBottomRightRadius: '15px',
                              textAlign: 'center',
                              marginLeft: '0px',
                              marginTop: '-10px',
                              fontWeight: '600',
                            }}
                          >
                            SELECTED
                          </Box>
                        </>
                      )}

                      <Card
                        onClick={() => {
                          handleRadioChange(val?.uuid, val?.no_of_locations, val);
                        }}
                        style={{
                          padding: '20px',
                          marginTop: '0px',
                          borderRadius: 30,
                          border: '2px solid #035421',
                          cursor: 'pointer',
                          backgroundColor: `${selectedPlan === val?.uuid ? '#e9f4ed' : '#F9F9F9'}`,
                        }}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            borderBottom: '1px solid #e8e4e4',
                            marginTop: '20px',
                          }}
                        >
                          <Typography variant="h5" sx={{ fontWeight: 700, fontSize: '26px' }}>
                            <b>{val.plan_title}</b>
                          </Typography>

                          <p style={{ textAlign: 'center' }}>
                            All the basics for businesses that are just getting started with per business per location.
                          </p>

                          <MainBox>
                            <Typography variant="h5" sx={{ mr: 1, color: '#0B8238' }}>
                              <b>₹</b>
                            </Typography>
                            <Typography
                              variant="h3"
                              style={{ marginRight: '5px', marginLeft: '5px', color: '#0B8238' }}
                            >
                              <b> {`${val?.plan_price}`}</b>
                            </Typography>
                            <Typography variant="h5" style={{ color: '#0B8238' }}>
                              {' '}
                              {` ${val?.plan_type === 'Yearly' ? '/ Year' : null}`}
                            </Typography>
                          </MainBox>
                        </Grid>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            // borderBottom: '1px solid #e8e4e4',
                            marginTop: `${selectedPlan === val?.uuid ? '10px' : '10px'}`,
                          }}
                        >
                          <MainBox>
                            <b> {val.no_of_locations === '1' ? '(SINGLE LOCATION)' : '(DOUBLE LOCATION)'}</b>
                          </MainBox>
                        </Grid>

                        <Box sx={{ ml: 2 }}>
                          <Typography
                            style={{
                              fontWeight: '300',
                              fontSize: '15px',
                              display: 'flex',
                              marginTop: '5px',
                              marginRight: '5px',
                            }}
                          >
                            <img
                              src={tick}
                              alt="plan"
                              height="20px"
                              width="20px"
                              style={{ marginRight: '5px', marginTop: '2px' }}
                            />
                            Prioritizing user's needs and exceptional service free of charge
                          </Typography>

                          <Typography
                            style={{
                              fontWeight: '300',
                              fontSize: '15px',
                              display: 'flex',
                              marginTop: '5px',
                              marginRight: '5px',
                            }}
                          >
                            <img
                              src={tick}
                              alt="plan"
                              height="20px"
                              width="20px"
                              style={{ marginRight: '5px', marginTop: '2px' }}
                            />
                            No hidden fees or subsriptions are required to access our comprehensive vendor database
                          </Typography>

                          <Typography
                            style={{
                              fontWeight: '300',
                              fontSize: '15px',
                              display: 'flex',
                              marginTop: '5px',
                              marginRight: '5px',
                            }}
                          >
                            <img
                              src={tick}
                              alt="plan"
                              height="20px"
                              width="20px"
                              style={{ marginRight: '5px', marginTop: '2px' }}
                            />
                            Users can explore and connect with vendors without an obligations
                          </Typography>

                          <Typography
                            style={{
                              fontWeight: '300',
                              fontSize: '15px',
                              display: 'flex',
                              marginTop: '5px',
                              marginRight: '5px',
                            }}
                          >
                            <img
                              src={tick}
                              alt="plan"
                              height="20px"
                              width="20px"
                              style={{ marginRight: '5px', marginTop: '2px' }}
                            />
                            Trade disputes will not be a part of this Application
                          </Typography>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <span style={{ color: 'red', fontSize: '12px' }}>
                {props?.planError ? 'Please select a Plan Type' : null}
              </span>
              <br />
              <Checkbox checked={checked} onChange={handlecheckChange} inputProps={{ 'aria-label': 'controlled' }} />I
              Accept
              <span
                role="button"
                tabIndex={0}
                // onClick={() => {
                //   setOpen(true);
                // }}
                // onKeyDown={(e) => {
                //   if (e.key === 'Enter' || e.key === ' ') {
                //     setOpen(true);
                //   }
                // }}
                style={{ marginLeft: '5px', cursor: 'pointer', color: 'green' }}
              >
                <a
                  href="https://sunn-grahak.com/vendor_payment_acceptance.html"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: 'green', textDecoration: 'none' }}
                >
                  Terms And Conditions
                </a>
              </span>
              <br />
              <span style={{ color: 'red', fontSize: '12px' }}>{props?.termsandConditionError}</span>
            </>
          ) : (
            <>
              <Grid container spacing={3} sx={{ my: 3 }}>
                <Grid item xs={12} sm={6} md={4} lg={3} />
                <Grid item xs={12} sm={6} md={4} lg={6}>
                  <Card
                    sx={{
                      borderRadius: '26px !important',
                      border: '2px solid #035421',
                      background: '#F9F9F9',
                      p: 3,
                    }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{ borderBottom: '1px solid #e8e4e4' }}
                    >
                      <MainBox>
                        <Typography variant="h5">
                          <b>
                            {selectedAndPayedPlan
                              ? selectedAndPayedPlan?.plan_title
                              : selectedSinglePlan?.[0]?.plan_title}
                          </b>
                        </Typography>
                      </MainBox>
                      <h4 style={{ fontWeight: 500, textAlign: 'center' }}>
                        All the basics for businesses that are just getting started with per business per location
                      </h4>
                      <MainBox>
                        <Typography variant="h5" sx={{ mr: 1 }}>
                          ₹
                        </Typography>
                        <Typography variant="h3" style={{ marginRight: '5px' }}>
                          {selectedAndPayedPlan
                            ? selectedAndPayedPlan?.plan_price
                            : selectedSinglePlan?.[0]?.plan_price}
                        </Typography>
                        <Typography variant="h5"> /{selectedSinglePlan?.[0]?.plan_type}</Typography>
                      </MainBox>
                    </Grid>
                    <br />
                    <MainBox>
                      {/* <Typography variant="h5" sx={{ mr: 1 }}>
                          Hi
                        </Typography>  */}
                    </MainBox>
                    <Box sx={{ ml: 2 }}>
                      <h3 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                        <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} />{' '}
                        Prioritizing user's needs and exceptional service free of charge
                      </h3>
                      <h3 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                        <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} /> No
                        hidden fees or subsriptions are required to access our comprehensive vendor database
                      </h3>
                      <h3 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                        <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} /> Users
                        can explore and connect with vendors without an obligations
                      </h3>
                      <h3 style={{ fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                        <img src={tick} alt="plan" height="25px" width="25px" style={{ marginRight: '5px' }} />
                        Trade disputes will not be a part of this Application
                      </h3>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} />
              </Grid>
            </>
          )}
        </Container>
      </Page>
    </>
  );
};

export default PlansandPayments;
