
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Stack, Grid, TextField, Typography, MenuItem, Checkbox, Box } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';
import axiosInstance from '../../api/withJWTServer';
import PaymentContent from '../PaymentContent';
import Iconify from '../../components/Iconify';
import IncorrectPostalCodeModel from './IncorrectPostalCodeModel';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  maxHeight: '600px', // Set the maximum height to enable scrolling
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};
const GOOGLE_GEOCODING_API_KEY = 'AIzaSyBk1nf5tRWZ1h6ccQGqstoAh-Di6VH3iXU';
const PlanAddressPaymentModel = (props) => {
  const dispatch = useDispatch();
  const { profileData, selectedPlans, upgradePlans } = useSelector((state) => ({
    profileData: state?.profileData,
    selectedPlans: state?.selectedPlans,
    updradePlans: state?.upgradePlan
  }));
 const [cities, setAllCities] = useState([]);
 const [citiesOne, setAllCitiesOne] = useState([]);
 const [openPostalModel, setOpenPostalModel] = useState(false);
  // const [currentCity, setCurrentCity] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  const [states, setState] = useState([]);
  const [wrongPostalCode, setWrongPostalCode] = useState(false);
  const [wrongPostalCodeTwo, setWrongPostalCodeTwo] = useState(false);
  const [postalCodecount, setPostalCodecount] = useState(0);
 const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleOpen();
    }
  };
  // const matchSelectedPlan = ()=>{
  //   let plan;
  //   if((profileData?.vendorBusinessDetailsModels?.[0]?.selected_plan_uuid === props?.selectedPlan?.uuid)){
  //     plan = true;
  //   }else{
  //     plan = false;
  //   }
  // }

  useEffect(() => {
    axiosInstance
      .get('vendor/get-states')
      .then((res) => {
        setState(res.data.data);
      })
      .catch((err) => {});
  }, []);
  const LoginSchema = Yup.object().shape({
    address: Yup.string().required(`Address is required`),
    country: Yup.string().required(`Country is required`),
    state: Yup.string().required(`State is required`),
    city: Yup.string().required(`City is required`),
    pin: Yup.number()
      .required(`Postal code is required`)
      .typeError('Postal code must be a number')
      .test('is-postal', 'Postal code should be 6-digit', (value) => {
        if (value) {
          const pinRegex = /^[0-9]{6}$/;
          return pinRegex.test(value.toString());
        }
        return false; // Return false if the field is empty
      }),
    agreedToTerms: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
  });
  const LoginSchema1 = Yup.object().shape({
    address: Yup.string().required(`Address is required`),
    country: Yup.string().required(`Country is required`),
    state: Yup.string().required(`State is required`),
    city: Yup.string().required(`City is required`),
    pin: Yup.number()
      .required(`Postal code is required`)
      .typeError('Postal code must be a number')
      .test('is-postal', 'Postal code should be 6-digit', (value) => {
        if (value) {
          const pinRegex = /^[0-9]{6}$/;
          return pinRegex.test(value.toString());
        }
        return false; // Return false if the field is empty
      }),
    address1: Yup.string().required(`Address is required`),
    country1: Yup.string().required(`Country is required`),
    state1: Yup.string().required(`State is required`),
    city1: Yup.string().required(`City is required`),
    pin1: Yup.number()
      .required(`Postal code is required`)
      .typeError('Postal code must be a number')
      .test('is-postal', 'Postal code should be 6-digit', (value) => {
        if (value) {
          const pinRegex = /^[0-9]{6}$/;
          return pinRegex.test(value.toString());
        }
        return false; // Return false if the field is empty
      }),
    agreedToTerms: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address:  '',
      landmark:  '',
      country:  'India',
      state:  '',
      city:  '',
      pin:  '',
      address1:  '',
      landmark1:  '',
      country1:  'India',
      state1:  '',
      city1:  '',
      pin1:  '',
      agreedToTerms: false, // Initialize checkbox to false
      remember: true,
    },
    validationSchema: props.noOfLocation === '2' ? LoginSchema1 : LoginSchema,
    onSubmit: (value, { resetForm }) => {
   
      const param = [
        {
          vendor_uuid: profileData?.uuid,
          plan_id: props?.selectedPlan?.uuid,
          isUpgrade: props?.planType === '1',
          address_line_1: value.address,
          city: value.city,
          state: value.state,
          country: value.country,
          address_line_2: value.landmark,
          pin: value.pin,
          
          // vendor_uuid: uuid,
          // plan_id: planuuid,
          // isUpgrade: false,
          // address_line_1: values.firstLocAddress,
          // address_line_2: values.firstLocLandmark,
          // country: values.firstLocCountry,
          // state: values.firstLocState,
          // city: values.firstLocCity,
          // pin: values.firstLocZipcode,
        },
      ];
      const param1 = [
        {
          vendor_uuid: profileData?.uuid,
          plan_id: props?.selectedPlan?.uuid,
          isUpgrade: props?.planType === '1',
          address_line_1: value.address,
          city: value.city,
          state: value.state,
          country: value.country,
          address_line_2: value.landmark,
          pin: value.pin,
        },
        {
          vendor_uuid: profileData?.uuid,
          plan_id: props?.selectedPlan?.uuid,
          isUpgrade: props?.planType === '1',
          address_line_1: value.address1,
          city: value.city1,
          state: value.state1,
          country: value.country1,
          address_line_2: value.landmark1,
          pin: value.pin1,
        },
      ];
      props.vendorAddressDetail(props.noOfLocation === '2' ? param1 : param);
      // props.checkTerms(true);
     
    },
  });
  const { errors, touched, getFieldProps } = formik;
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: 1,
      },
    },
    textarea: {
      resize: 'both',
    },
  }));
  const countries = [
    {
      id: '1',
      value: 'India',
      label: 'India',
    },
  ];

  useEffect(() => {
    axiosInstance
      .get(`vendor/get-districts?key=${profileData?.vendorOutletAddressModels?.[0]?.state}`)
      .then((res) => {
        setAllCities(res?.data?.data);
      })
      .catch((err) => {});
  }, []);

  const handleSelectedValueChange = (value, location) => {
    if(location === 1){
      formik.setFieldValue('pin', '');
    }else{
      formik.setFieldValue('pin1', '');
    }
    axiosInstance
      .get(`vendor/get-districts?key=${value}`)
      .then((res) => {
        if(location === 1){
          setAllCities(res?.data?.data);
        }else{
          setAllCitiesOne(res?.data?.data);
        }
      })
      .catch((err) => {});
  };
  
  const handleState = (e) => {
    setCurrentState(e.target.value);
  };
  const classes = useStyles();
  const handlePostalCode = async (postalCode, locationCount) => {
    if (/^\d{6}$/.test(postalCode)) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${GOOGLE_GEOCODING_API_KEY}`
        );
        if (response.data.results.length > 0) {
          const { address_components: AddressComponent } = response.data.results[0];
  
          let city = '';
          
          let state = '';
  
          AddressComponent.forEach((component) => {
                    if (component.types.includes('locality')) {
                      city = component.long_name;
                    } else if (component.types.includes('administrative_area_level_1')) {
                      state = component.long_name;
                    }
                  });
                
                setCurrentState(state);
                if(locationCount === 1){
                  formik.setFieldValue('state', state);
                }else{
                  formik.setFieldValue('state1', state);
                }
               if(locationCount === 1){
                axiosInstance
                .get(`vendor/get-districts?key=${state}`)
                .then((res) => {
                  setAllCities(res?.data?.data);
                     if(locationCount === 1){
                      if(res?.data?.data.some(item => item.value === city)){
                      formik.setFieldValue('city', city);
                    }
                    }
                })
                .catch((err) => {});
               }else{
                axiosInstance
                .get(`vendor/get-districts?key=${state}`)
                .then((res) => {
                  setAllCitiesOne(res?.data?.data);
                      if(res?.data?.data.some(item => item.value === city)){
                      formik.setFieldValue('city1', city);
                    }
                })
                .catch((err) => {});
               }
              
                setWrongPostalCode(false);
        } else {
          // if(locationCount === 1){
            // setWrongPostalCode(true);
            setOpenPostalModel(true)
          // }
          //   setWrongPostalCodeTwo(true);
          //   setOpenPostalModel(true)
          
          // throw new Error('No results found for the given postal code.');
        }
      } catch (error) {
        // if(locationCount === 1){
        //   setWrongPostalCode(true);
        //   setOpenPostalModel(true)
        // }
        //   setWrongPostalCodeTwo(true);
        //   setOpenPostalModel(true)
      
        // throw new Error('Error fetching location information. Please try again.');
      }
    }
  };
  const handleClosePostalCode = ()=>{
    setOpenPostalModel(false);
    if(postalCodecount === 1){
      formik.setFieldValue('pin', '');
      formik.setFieldValue('state', '');
      formik.setFieldValue('city', '');
    }else{
      formik.setFieldValue('pin1', '');
      formik.setFieldValue('state1', '');
      formik.setFieldValue('city1', '');
    }
  }
  return (
    <div>
      <>
      <IncorrectPostalCodeModel
      open={openPostalModel}
      handleClose={handleClosePostalCode}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ height: '550px' }}>
          <PaymentContent handleClose={handleClose}/>
        </Box>
      </Modal>
        <Box sx={{width:"100%" }}>
          <Box sx={{display:"flex",justifyContent:"space-between"}}>
        <h3>{props.selectedPlan.plan_title}</h3>
        <Iconify
            icon="eva:close-outline"
            sx={{
              float: 'right',
              height: '30px',
              width: '30px',
              color: 'black',
              cursor: 'pointer',
            }}
            onClick={props.onClose}
          /> 
        </Box>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate >
            <Stack spacing={3} sx={{ mt: 3, height : '75vh', maxHeight: '590px', overflowY: 'auto' }}>
              <Grid container spacing={2} sx={{width:"100%"}}>
                {props.noOfLocation === '2' ? (
                  <>
                    <>
                      <Grid item xs={6}>
                        <h4>Location One</h4>
                      </Grid>
                      <Grid item xs={6} />
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          autoComplete="address"
                          label="Address"
                          {...getFieldProps('address')}
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          autoComplete="landmark"
                          label="Landmark"
                          {...getFieldProps('landmark')}
                          inputProps={{ className: classes.textarea }}
                          multiline
                          rows={1}
                          error={Boolean(touched.landmark && errors.landmark)}
                          helperText={touched.landmark && errors.landmark}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          select
                          fullWidth
                          id="country"
                          name="country"
                          label="Country"
                          // defaultValue=""
                          // initialValues=''
                          {...getFieldProps('country')}
                          style={{ marginTop: '7px' }}
                          // value={selectedPlanType}
                          onChange={(e) => {
                            //   handlePlanType(e);
                            formik.handleChange(e);
                          }}
                          error={formik.touched.country && Boolean(formik.errors.country)}
                          helperText={formik.touched.country && formik.errors.country}
                        >
                          <MenuItem disabled initialValues value="">
                            <em>Country</em>
                          </MenuItem>
                          {countries?.map((option) => (
                            <MenuItem key={option.id} value={option.value} initialValues="">
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                        <TextField
                          select
                          fullWidth
                          id="state"
                          name="state"
                          label="State"
                          {...getFieldProps('state')}
                          style={{ marginTop: '15px' }}
                          // value={noOfTotalLocation}
                          onChange={(e) => {
                            handleState(e);
                            handleSelectedValueChange(e.target.value, 1);
                            formik.handleChange(e);
                          }}
                          error={formik.touched.state && Boolean(formik.errors.state)}
                          helperText={formik.touched.state && formik.errors.state || (wrongPostalCode && 'State is required')}
                        >
                          <MenuItem disabled initialValues value="">
                            <em>State</em>
                          </MenuItem>
                          {states?.map((option, index) => (
                            <MenuItem key={index} value={option.value} initialValues="">
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                        <TextField
                          select
                          fullWidth
                          id="city"
                          name="city"
                          label="City"
                          {...getFieldProps('city')}
                          style={{ marginTop: '7px' }}
                          // value={noOfTotalLocation}
                          onChange={(e) => {
                            //   handleNoOfLocation(e);
                            formik.handleChange(e);
                          }}
                          error={formik.touched.city && Boolean(formik.errors.city)}
                          helperText={formik.touched.city && formik.errors.city || (wrongPostalCode && 'City is required')}
                        >
                          <MenuItem disabled initialValues value="">
                            <em>City</em>
                          </MenuItem>
                          {cities?.map((option, index) => (
                            <MenuItem key={index} value={option.value} initialValues="">
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          autoComplete="pin"
                          label="Postal Code"
                          {...getFieldProps('pin')}
                          onChange={(e) => {
                            handlePostalCode(e.target.value,1);
                            setPostalCodecount(1);
                            formik.handleChange(e);
                          }}
                          value={formik.values.pin}
                          error={Boolean(touched.pin && errors.pin)}
                          helperText={touched.pin && errors.pin }
                        />
                      </Grid>
                    </>
                    <>
                      <Grid item xs={6}>
                        <h4>Location Two</h4>
                      </Grid>
                      <Grid item xs={6} />
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          autoComplete="address"
                          label="Address"
                          {...getFieldProps('address1')}
                          error={Boolean(touched.address1 && errors.address1)}
                          helperText={touched.address1 && errors.address1}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          autoComplete="landmark"
                          label="Landmark"
                          {...getFieldProps('landmark1')}
                          inputProps={{ className: classes.textarea }}
                          multiline
                          rows={1}
                          error={Boolean(touched.landmark1 && errors.landmark1)}
                          helperText={touched.landmark1 && errors.landmark1}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          select
                          fullWidth
                          id="country"
                          name="country"
                          label="Country"
                          // defaultValue=""
                          // initialValues=''
                          {...getFieldProps('country1')}
                          style={{ marginTop: '7px' }}
                          // value={selectedPlanType}
                          onChange={(e) => {
                            //   handlePlanType(e);
                            formik.handleChange(e);
                          }}
                          error={formik.touched.country1 && Boolean(formik.errors.country1)}
                          helperText={formik.touched.country1 && formik.errors.country1}
                        >
                          <MenuItem disabled initialValues value="">
                            <em>Country</em>
                          </MenuItem>
                          {countries?.map((option) => (
                            <MenuItem key={option.id} value={option.value} initialValues="">
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                        <TextField
                          select
                          fullWidth
                          id="state1"
                          name="state1"
                          label="State"
                          {...getFieldProps('state1')}
                          style={{ marginTop: '15px' }}
                          a
                          // value={noOfTotalLocation}
                          onChange={(e) => {
                            handleState(e);
                            handleSelectedValueChange(e.target.value, 2);
                            // setCurrentCity('');
                            formik.handleChange(e);
                          }}
                          error={formik.touched.state1 && Boolean(formik.errors.state1)}
                          helperText={formik.touched.state1 && formik.errors.state1}
                        >
                          <MenuItem disabled initialValues value="">
                            <em>State</em>
                          </MenuItem>
                          {states?.map((option, index) => (
                            <MenuItem key={index} value={option.value} initialValues="">
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                        <TextField
                          select
                          fullWidth
                          id="city1"
                          name="city1"
                          label="City"
                          {...getFieldProps('city1')}
                          style={{ marginTop: '7px' }}
                          // value={noOfTotalLocation}
                          onChange={(e) => {
                            //   handleNoOfLocation(e);
                            formik.handleChange(e);
                          }}
                          error={formik.touched.city1 && Boolean(formik.errors.city1)}
                          helperText={formik.touched.city1 && formik.errors.city1}
                        >
                          <MenuItem disabled initialValues value="">
                            <em>City</em>
                          </MenuItem>
                          {citiesOne?.map((option, index) => (
                            <MenuItem key={index} value={option.value} initialValues="">
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          autoComplete="pin1"
                          label="Postal Code"
                          {...getFieldProps('pin1')}
                          onChange={(e) => {
                            handlePostalCode(e.target.value,2);
                            setPostalCodecount(2);
                            formik.handleChange(e);
                          }}
                          error={Boolean(touched.pin1 && errors.pin1)}
                          helperText={touched.pin1 && errors.pin1 }
                        />
                      </Grid>
                    </>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoComplete="address"
                        label="Address"
                        {...getFieldProps('address')}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoComplete="landmark"
                        label="Landmark"
                        {...getFieldProps('landmark')}
                        inputProps={{ className: classes.textarea }}
                        multiline
                        rows={1}
                        error={Boolean(touched.landmark && errors.landmark)}
                        helperText={touched.landmark && errors.landmark}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        select
                        fullWidth
                        id="country"
                        name="country"
                        label="Country"
                        // defaultValue=""
                        // initialValues=''
                        {...getFieldProps('country')}
                        style={{ marginTop: '7px' }}
                        // value={selectedPlanType}
                        onChange={(e) => {
                          //   handlePlanType(e);
                          formik.handleChange(e);
                        }}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                        helperText={formik.touched.country && formik.errors.country}
                      >
                        <MenuItem disabled initialValues value="">
                          <em>Country</em>
                        </MenuItem>
                        {countries?.map((option) => (
                          <MenuItem key={option.id} value={option.value} initialValues="">
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                      <TextField
                        select
                        fullWidth
                        id="state"
                        name="state"
                        label="State"
                        {...getFieldProps('state')}
                        style={{ marginTop: '15px' }}
                        a
                        // value={noOfTotalLocation}
                        onChange={(e) => {
                          handleState(e);
                          handleSelectedValueChange(e.target.value, 1);
                          formik.handleChange(e);
                        }}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                      >
                        <MenuItem disabled initialValues value="">
                          <em>State</em>
                        </MenuItem>
                        {states?.map((option, index) => (
                          <MenuItem key={index} value={option.value} initialValues="">
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                      <TextField
                        select
                        fullWidth
                        id="city"
                        name="city"
                        label="City"
                        {...getFieldProps('city')}
                        style={{ marginTop: '7px' }}
                        a
                        // value={noOfTotalLocation}
                        onChange={(e) => {
                          //   handleNoOfLocation(e);
                          formik.handleChange(e);
                        }}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                      >
                        <MenuItem disabled initialValues value="">
                          <em>City</em>
                        </MenuItem>
                        {cities?.map((option, index) => (
                          <MenuItem key={index} value={option.value} initialValues="">
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoComplete="pin"
                        label="Postal Code"
                        {...getFieldProps('pin')}
                        onChange={(e) => {
                          handlePostalCode(e.target.value, 1);
                          setPostalCodecount(1);
                          formik.handleChange(e);
                        }}
                        error={Boolean(touched.pin && errors.pin)}
                        helperText={touched.pin && errors.pin}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <span style={{display: "flex"}}><Checkbox
                    {...getFieldProps('agreedToTerms')} // Bind the checkbox to formik
                    checked={formik.values.agreedToTerms} // Set the checkbox's value to formik's value
                  />
                  <h4 style={{marginTop: "10px"}}>I have read and accept the <span 
                  // onClick={handleOpen} 
                  onKeyDown={handleKeyDown}
                  role="button"
                  tabIndex={0}
                  style={{ color: "green", cursor: "pointer" }}><a href="https://sunn-grahak.com/vendor_payment_acceptance.html" target="_blank" rel="noreferrer" style={{textDecoration: "none", color: "green"}}>Terms and Conditions</a></span></h4></span>
                  {formik.touched.agreedToTerms && formik.errors.agreedToTerms && (
                    <Typography variant="body2" color="error">
                      {formik.errors.agreedToTerms}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ float: 'right' }}
                    // disabled={buttonClicked}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
                <Grid item xs={5} />
              </Grid>
            </Stack>
          </Form>
        </FormikProvider>
        </Box>
      </>
    </div>
  );
};

export default PlanAddressPaymentModel;
