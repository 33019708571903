import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Container } from '@material-ui/core';
import axios from 'axios';
import { Button } from '@mui/material';
import axiosInstance from '../../../../api/withJWTServer';
import InputPostalcode from '../FormFields/InputPostalcode';
import InputLocationField from '../FormFields/InputLocationField';
import SelectCity from '../FormFields/SelectCity';
import SelectState from '../FormFields/SelectState';
import SelectCountryField from '../FormFields/SelectCountryField';
import InputLandMark from '../FormFields/InputLandMark';
import InputMobileField from '../FormFields/InputMobileField';
import AlternateMobileField from '../FormFields/AlternateMobileField';
import { InputField } from '../FormFields';

export default function AddressForm(props) {
  const { status, initalState, initialCity, initialPostalCode } = useSelector((state) => ({
    status: state?.profileData,
    initalState: state?.profileData?.vendorBusinessDetailsModels?.[0]?.state,
    initialCity: state?.profileData?.vendorBusinessDetailsModels?.[0]?.city,
    initialPostalCode: state?.profileData?.vendorBusinessDetailsModels?.[0]?.pin,
  }));
  const [states, setState] = useState([]);
  const [cities, setAllCities] = useState([]);
  // Current state
  const [currentAddress, setCurrentAddress] = useState('');
  const [currentPostalCode, setCurrentPostalCode] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [currentLandMark, setCurrentLandMark] = useState(null);
  // Other state
  const [location, setLocation] = useState(null);
  const [selectedState, setSelectedState] = useState(initalState && initalState);
  const [wrongPostalCode, setWrongPostalCode] = useState(false);
  const [postalReset, setPostalReset] = useState(false);
  const GOOGLE_GEOCODING_API_KEY = 'AIzaSyBk1nf5tRWZ1h6ccQGqstoAh-Di6VH3iXU';
  const {
    formField: {
      address,
      Landmark,
      mobileNo,
      alertnateNo,
      city,
      state,
      zipcode,
      country,
      firstLocAddress,
      secondLocLandmark,
      firstLocCountry,
      firstLocState,
      firstLocCity,
      firstLocZipcode,
    },
  } = props;

  const countries = [
    {
      value: 'India',
      label: 'India',
    },
  ];

  useEffect(() => {
    axiosInstance
      .get('vendor/get-states')
      .then((res) => {
        setState(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const handleSelectedValueChange = (value) => {
    setPostalReset(!postalReset);
    setCurrentCity('');
    setSelectedState(value);
    setCurrentPostalCode(null);
    axiosInstance
      .get(`vendor/get-districts?key=${value}`)
      .then((res) => {
        setAllCities(res?.data?.data);
      })
      .catch((err) => {});
  };
  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });

          // Use Google Maps Geocoding API to get address
          const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_GEOCODING_API_KEY}`;

          axios
            .get(geocodingUrl)
            .then((response) => {
              const results = response?.data?.results;
              if (results && results.length > 0) {
                const addressComponents = results[0].address_components;
                // Define the types of address components you want to exclude
                const excludedTypes = [
                  'administrative_area_level_1', // State
                  'locality', // City
                  'postal_code', // Postal Code
                  'country', // Country
                ];

                // Filter out the excluded components
                const filteredComponents = addressComponents.filter(
                  (component) => !component.types.some((type) => excludedTypes.includes(type))
                );

                // Create an array of the long_name values of the remaining components
                const filteredComponentNames = filteredComponents?.map((component) => component.long_name);

                // Join the filtered component names to create the desired address format
                const formattedAddress = filteredComponentNames.join(', ');
                // Update state variables
                setCurrentAddress(formattedAddress);
                setCurrentLandMark(
                  results[0].address_components.find((component) => component.types.includes('sublocality_level_1'))
                    .long_name
                );
                setCurrentState(
                  results[0].address_components.find((component) =>
                    component.types.includes('administrative_area_level_1')
                  ).long_name
                );
                handleSelectedValueChange(
                  results[0].address_components.find((component) =>
                    component.types.includes('administrative_area_level_1')
                  ).long_name
                );
                setCurrentCity(
                  results[0].address_components.find((component) => component.types.includes('locality')).long_name
                );
                setCurrentPostalCode(
                  results[0].address_components.find((component) => component.types.includes('postal_code')).long_name
                );
              }
            })
            .catch((error) => {
              // console.error('Error fetching address:', error);
            });
        },
        (error) => {
          // console.error(error.message);
        }
      );
    } else {
      // console.error('Geolocation is not supported by this browser.');
    }
  };
  const splitAddress = (address) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${GOOGLE_GEOCODING_API_KEY}`;

    axios
      .get(geocodeUrl)
      .then((response) => {
        const { results } = response.data;
        if (results.length > 0) {
          const location = results[0].address_components;
          let city;
          let state;
          let pinCode;
          let landmark;

          location.forEach((component) => {
            if (component.types.includes('locality')) {
              city = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
            } else if (component.types.includes('postal_code')) {
              pinCode = component.long_name;
            } else if (component.types.includes('sublocality_level_1')) {
              landmark = component.long_name;
            }
          });
          if (landmark === undefined) {
            setCurrentLandMark('');
          } else {
            setCurrentLandMark(landmark);
          }
          setCurrentPostalCode(pinCode);
          setCurrentState(state);
          axiosInstance
            .get(`vendor/get-districts?key=${state}`)
            .then((res) => {
              setAllCities(res?.data?.data);
              if (res?.data?.data.some((item) => item.value === city)) {
                setCurrentCity(city);
              } else {
                setCurrentCity(null);
              }
            })
            .catch((err) => {});
        } else {
          // console.log('No results found for the address.');
        }
      })
      .catch((error) => {
        // console.error('Error fetching geocoding data:', error);
      });
  };
  useEffect(() => {
    if (currentPostalCode) {
      handlePostalCode(currentPostalCode);
    }
  }, [currentPostalCode]);
  const handlePostalCode = async (postalCode) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${GOOGLE_GEOCODING_API_KEY}`
      );

      if (response.data.results.length > 0) {
        const { address_components: AddressComponent } = response.data.results[0];

        let city = '';
        let state = '';

        AddressComponent.forEach((component) => {
          if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
          }
        });
        setCurrentState(state);
        axiosInstance
          .get(`vendor/get-districts?key=${state}`)
          .then((res) => {
            setAllCities(res?.data?.data);
            if (res?.data?.data.some((item) => item.value === city)) {
              setCurrentCity(city);
            } else {
              setCurrentCity(null);
            }
          })
          .catch((err) => {});
        setWrongPostalCode(false);
      } else {
        setWrongPostalCode(true);
        // throw new Error('No results found for the given postal code.');
      }
    } catch (error) {
      setWrongPostalCode(true);
      // throw new Error('Error fetching location information. Please try again.');
    }
  };

  const handleSelectedCity = () => {
    setPostalReset(!postalReset);
    setCurrentPostalCode(null);
  };

  const handleChangePostalCode = (e) => {
    if (e === null) {
      setWrongPostalCode(false);
      setCurrentState(null);
      setCurrentCity(null);
      axiosInstance
        .get('vendor/get-states')
        .then((res) => {
          setState(res.data.data);
        })
        .catch((err) => {});

      axiosInstance
        .get(`vendor/get-districts?key=${null}`)
        .then((res) => {
          setAllCities(res?.data?.data);
        })
        .catch((err) => {});
    }
    const sixDigitPostal = /^\d{6}$/.test(e);
    if (sixDigitPostal) {
      setCurrentPostalCode(e);
    }
  };
  return (
    <>
      <Container>
        <Typography variant="h6" gutterBottom>
          Address Details
          {/* {`for ${props?.locationNo} Location`} */}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
              {status?.status === 'Active' ? null : (
                <Button
                  onClick={() => handleCurrentLocation()}
                  style={{ color: 'green', float: 'right', marginBottom: '-30px', padding: '0px' }}
                >
                  Use Current Location
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={6}>
            <InputLocationField
              name={props?.planSlug === 0 ? address.name : firstLocAddress.name}
              label={props?.planSlug === 0 ? address.label : firstLocAddress.label}
              fullWidth
              currentAddress={currentAddress}
              splitAddress={splitAddress}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLandMark
              name={props?.planSlug === 0 ? Landmark.name : secondLocLandmark.name}
              label={props?.planSlug === 0 ? Landmark.label : secondLocLandmark.label}
              fulllWidth
              style={{ marginTop: '10px', width: '100%' }}
              currentLandMark={currentLandMark}
              disabled={status?.status === 'Active' ? 1 : 0}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectCountryField
              name={props?.planSlug === 0 ? country.name : firstLocCountry.name}
              label={props?.planSlug === 0 ? country.label : firstLocCountry.label}
              data={countries}
              fullWidth
              onValueChange={() => {}}
              disabled={status?.status === 'Active' ? 1 : 0}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectState
              name={props?.planSlug === 0 ? state.name : firstLocState.name}
              label={props?.planSlug === 0 ? state.label : firstLocState.label}
              data={states}
              fullWidth
              currentState={currentState}
              onValueChange={handleSelectedValueChange}
              initalState={initalState}
              disabled={status?.status === 'Active' ? 1 : 0}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectCity
              name={props?.planSlug === 0 ? city.name : firstLocCity.name}
              label={props?.planSlug === 0 ? city.label : firstLocCity.label}
              data={cities}
              fullWidth
              currentCity={currentCity}
              onValueChangeCity={handleSelectedCity}
              initialCity={initialCity}
              disabled={status?.status === 'Active' ? 1 : 0}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputPostalcode
              name={props?.planSlug === 0 ? zipcode.name : firstLocZipcode.name}
              label={props?.planSlug === 0 ? zipcode.label : firstLocZipcode.label}
              fullWidth
              currentPostalCode={currentPostalCode}
              onChangePostal={(e) => handleChangePostalCode(e)}
              wrongPostalCode={wrongPostalCode}
              postalReset={postalReset}
              initialPostalCode={initialPostalCode}
              currentState={currentState}
              currentCity={currentCity}
              disabled={status?.status === 'Active' ? 1 : 0}
            />
          </Grid>
          {
            props?.planSlug === 0 && (
              <>
                <Grid item xs={12} sm={6}>
                  <InputMobileField name={mobileNo.name} label={mobileNo.label} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AlternateMobileField name={alertnateNo.name} label={alertnateNo.label} fullWidth />
                </Grid>
              </>
            )
            //  <ContactDetail formField={props}/>
          }
        </Grid>
      </Container>
    </>
  );
}
