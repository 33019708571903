import {  VENDOR_DETAIL } from "../actions/Types";

const INIT_STATE = 1;

export default function VendorDocsReducer(state = INIT_STATE, {type, payload} = {}) {
// export default function ProfilePicReducer(state = INIT_STATE, {type, payload} = {}) {
    switch(type) {
        case VENDOR_DETAIL:
            return payload;

        default: 
            return state;
    }
}

