import { SET_ALERT, REMOVE_ALERT, REMOVE_SPECIFIC_ALERT, RESET_STATE } from "../actions/Types";

const INIT_STATE = [];

export default function AlertReducer(state = INIT_STATE, {type, payload} = {}) {
    switch(type) {
        case SET_ALERT:
            return [...state, payload];

        case REMOVE_SPECIFIC_ALERT: 
            return state.filter((val)=> val.id !== payload);
        
        case REMOVE_ALERT: 
            return state.filter((val)=> val.id !== payload);

        case RESET_STATE:
            return [...INIT_STATE];

        default: 
            return state;
    }
}

