import { SELECTED_PLANS } from "../actions/Types";

const INIT_STATE = [];

export default function SelectedPlansReducer(state = INIT_STATE, {type, payload} = {}) {
   switch(type) {
        case SELECTED_PLANS:
            return payload;

        default: 
            return state;
    }
}

