import React from 'react';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Tooltip } from '@mui/material';
import { red, teal, blue } from '@mui/material/colors';

const ActionColumn = ({
  showView = true,
  showEdit = true,
  showDelete = true,
  handleView,
  handleDelete,
  handleEdit,
  Candidates // New prop indicating whether it's a candidate page or not
}) => {
  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      {showEdit && (
        <Tooltip title="Edit">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px',
              borderWidth: '1px',
              borderColor: teal[600],
              borderStyle: 'solid',
              borderRadius: '6px',
              background: teal[50],
              cursor: 'pointer',
            }}
          >
            <ModeEditOutlineOutlinedIcon
              sx={{
                color: teal[600],
                fontSize: 17,
              }}
              onClick={handleEdit}
            />
          </Box>
        </Tooltip>
      )}
         {showView && Candidates && ( // Condition to show the "View" icon only if it's a candidate page
        <Tooltip title="View">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px',
              borderWidth: '1px',
              borderColor: blue[600],
              borderStyle: 'solid',
              borderRadius: '6px',
              background: blue[50],
              cursor: 'pointer',
            }}
          >
            <VisibilityOutlinedIcon
              sx={{
                color: blue[600],
                fontSize: 17,
              }}
              onClick={handleView}
            />
          </Box>
        </Tooltip>
      )}

      {showDelete && (
        <Tooltip title="Delete">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px',
              borderWidth: '1px',
              borderColor: red[600],
              borderStyle: 'solid',
              borderRadius: '6px',
              background: red[50],
              cursor: 'pointer',
            }}
          >
            <DeleteOutlinedIcon
              sx={{
                color: red[600],
                fontSize: 17,
              }}
              onClick={handleDelete}
            />
          </Box>
        </Tooltip>
      )}

    </Box>
  );
};

export default ActionColumn;
