import axiosInstance from './withJWTServer';

const fetchProfile = async () => {
  try {
    const response = await axiosInstance.get('vendor/profile');
    return response;
  } catch (error) {
    console.log('fetch error');
  }
};

const noOfLocationWithAddress = (values, uuid, noOfLocation, planuuid) => {
  const locOne = [
    {
      vendor_uuid: uuid,
      plan_id: planuuid,
      isUpgrade: false,
      address_line_1: values.firstLocAddress,
      address_line_2: values.firstLocLandmark,
      country: values.firstLocCountry,
      state: values.firstLocState,
      city: values.firstLocCity,
      pin: values.firstLocZipcode,
    },
  ];
  const loc = [
    {
      vendor_uuid: uuid,
      plan_id: planuuid,
      isUpgrade: false,
      address_line_1: values.firstLocAddress,
      address_line_2: values.firstLocLandmark,
      country: values.firstLocCountry,
      state: values.firstLocState,
      city: values.firstLocCity,
      pin: values.firstLocZipcode,
    },
  ];
  if (values.secondLocAddress) {
    const locsecond = {
      vendor_uuid: uuid,
      plan_id: planuuid,
      isUpgrade: false,
      address_line_1: values.secondLocAddress,
      address_line_2: values.secondLocLandmark,
      country: values.secondLocCountry,
      state: values.secondLocState,
      city: values.secondLocCity,
      pin: values.secondLocZipcode,
    };
    loc.push(locsecond);
  }

  return noOfLocation === '1' ? locOne : loc;
};

export { fetchProfile, noOfLocationWithAddress };
