import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Button,
  Typography,
  Stack,
  Box,
  Divider,
  Grid,
  TextField,
  Autocomplete,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { Form, Formik, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chip from '@mui/material/Chip';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import nobusinessLogo from '../../Assets/nobusinessLogo.png';
import { fetchProfile } from '../../api/CommanApicall';
import axiosInstance from '../../api/withJWTServer';

const BusinessDetails = (props) => {
  const [category, setCategoryOption] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [allTags, setAlltags] = useState([]);

  const lastStep = useRef(0);

  const [allDayChecked, setAllDayChecked] = useState(false);

  const { uuid } = useSelector((state) => ({
    uuid: state.auth.loggedUser.uuid,
  }));

  // const [sun, setSun] = useState(false);
  // const [mon, setMon] = useState(false);
  // const [tue, setTue] = useState(false);

  const [daysChecked, setDaysChecked] = useState({
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  });

  const [submitLoader, setSubmitLoader] = useState(false);

  const categoryId = useRef('');

  const handleCategoryChange = (event, newValue) => {
    console.log('newValue', newValue);

    if (newValue) {
      setSelectedCategory(newValue);
      formik.setFieldValue('business_category_id', newValue.value);
      formik.setFieldValue('business_category', newValue.label);
    }
  };
  const getCategories = async () => {
    const response = await axiosInstance.get('vendor/categories').then((res) => {
      const data = res?.data?.data;

      console.log('cat key', categoryId.current);
      data.forEach((item) => {
        console.log(item.value, categoryId.current);
        if (item.value === categoryId.current) {
          formik.setFieldValue('business_category', item.label);
          console.log('cat key here ', categoryId.current);
        }
      });

      setCategoryOption(data);
    });
  };

  const getCompanyLogoOnly = async () => {
    const response = await fetchProfile();

    const profileInfo = response.data.data[0];

    let companyLogo = '';
    let companyLogoID = '';
    const docKeys = Object.keys(profileInfo.vendorBusinessDocs);
    console.log('Keys', docKeys);
    if (profileInfo.vendorBusinessDocs) {
      if (docKeys.includes('company_logo')) {
        const companyLogoObject = profileInfo.vendorBusinessDocs.company_logo;
        companyLogo = companyLogoObject.doc_path;
        companyLogoID = companyLogoObject.id;
        console.log('LOGO', companyLogoObject);
      }
    }

    formik.setFieldValue('business_logo', companyLogo ?? '');
    formik.setFieldValue('business_logo_id', companyLogoID ?? '');
  };

  const getVendorProfile = async () => {
    try {
      const response = await fetchProfile();
      if (response.data?.data) {
        // setProfileData(response.data.data);
        const profileInfo = response.data.data[0];
        console.log('profileInfo', profileInfo);

        /// get business logo

        let companyLogo = '';
        let companyLogoID = '';
        const docKeys = Object.keys(profileInfo.vendorBusinessDocs);
        console.log('Keys', docKeys);
        if (profileInfo.vendorBusinessDocs) {
          if (docKeys.includes('company_logo')) {
            const companyLogoObject = profileInfo.vendorBusinessDocs.company_logo;
            companyLogo = companyLogoObject.doc_path;
            companyLogoID = companyLogoObject.id;
            console.log('LOGO', companyLogoObject);
          }
        }

        lastStep.current = profileInfo.last_completed_step ?? 0;

        categoryId.current = profileInfo?.vendorBusinessDetailsModels[0]?.category_id ?? 0;
        if (profileInfo?.vendorBusinessDetailsModels[0]?.business_tags !== '') {
          const tags = profileInfo?.vendorBusinessDetailsModels[0]?.business_tags?.split(', ') ?? [];
          setAlltags(tags);
        }

        formik.setValues({
          business_name: profileInfo?.vendorBusinessDetailsModels[0]?.business_name ?? '',
          business_description: profileInfo?.vendorBusinessDetailsModels[0]?.business_details ?? '',
          business_experience: profileInfo?.vendorBusinessDetailsModels[0]?.business_expertise ?? '',
          opening_time: profileInfo?.buinessTimingsModels[0]?.start_timing ?? '',
          closing_time: profileInfo?.buinessTimingsModels[0]?.end_timing ?? '',
          review_link: profileInfo?.vendorBusinessDetailsModels[0]?.business_review_link ?? '',
          business_logo: companyLogo ?? '',
          business_logo_id: companyLogoID ?? '',
          business_category_id: profileInfo?.vendorBusinessDetailsModels[0]?.category_id ?? '',
          vendor_status: profileInfo?.status ?? '',
          business_category: '',
        });

        /// Setting values of business timing

        daysChecked.sun = profileInfo?.buinessTimingsModels[0].sunday_open;
        daysChecked.mon = profileInfo?.buinessTimingsModels[0].monday_open;
        daysChecked.tue = profileInfo?.buinessTimingsModels[0].tuesday_open;
        daysChecked.wed = profileInfo?.buinessTimingsModels[0].wednesday_open;
        daysChecked.thu = profileInfo?.buinessTimingsModels[0].thursday_open;
        daysChecked.fri = profileInfo?.buinessTimingsModels[0].friday_open;
        daysChecked.sat = profileInfo?.buinessTimingsModels[0].saturday_open;

        if (
          daysChecked.sun &&
          daysChecked.mon &&
          daysChecked.tue &&
          daysChecked.wed &&
          daysChecked.thu &&
          daysChecked.fri &&
          daysChecked.sat
        ) {
          setAllDayChecked(true);
        } else {
          setAllDayChecked(false);
        }

        await getCategories();
      }
    } catch (error) {
      // Handle error if fetchProfile() fails
      console.error('Error fetching vendor profile:', error);
    }
  };

  const uploadCompanyLogo = async (businessFile) => {
    setSubmitLoader(true);
    const docs = new FormData();
    docs.append('business-doc', businessFile);
    docs.append('file_category', 'company_logo');
    await axiosInstance
      .post('vendor/upload-business-docs', docs)
      .then((response) => {
        if (response.data.status === 200) {
          props.showToastMessage(response.data.message);
        }
        setSubmitLoader(false);

        if (props.isStepper) {
          getCompanyLogoOnly();
        } else {
          getVendorProfile();
        }
      })
      .catch((error) => {
        // setCompanyLogo('Business logo is required');
      });
  };

  const setAllDaysCheckedTap = (val) => {
    setAllDayChecked(val);

    setDaysChecked({
      sun: val,
      mon: val,
      tue: val,
      wed: val,
      thu: val,
      fri: val,
      sat: val,
    });
  };

  const addTags = () => {
    if (allTags.length >= 5) {
      props.showErrorToastMessage('You can add only 5 tags. ');
    } else {
      const tag = formik.values.business_single_tag;
      if (!tag) {
        formik.setFieldError('business_single_tag', 'Please add tag first!');
      } else if (allTags.includes(tag)) {
        props.showErrorToastMessage('Tags already added.');
      } else {
        // formik.values.business_tags.push(tag);
        formik.setFieldValue('business_single_tag', '');
        setAlltags([...allTags, tag.trim().toString()]);
      }
    }
  };

  const removeTags = (tag) => {
    const newArray = allTags.filter((item) => item !== tag);
    setAlltags(newArray);
    console.log('tags', tag);
  };

  useEffect(() => {
    if (
      daysChecked.sun &&
      daysChecked.mon &&
      daysChecked.tue &&
      daysChecked.wed &&
      daysChecked.thu &&
      daysChecked.fri &&
      daysChecked.sat
    ) {
      setAllDayChecked(true);
    } else {
      setAllDayChecked(false);
    }
  }, [daysChecked]);

  // const openDoumentDialog = () => {
  //   const fileDialog = document.createElement('input');
  //   fileDialog.type = 'file';
  //   fileDialog.accept = '.png,.jpeg,.jpg';
  //   fileDialog.addEventListener('change', (e) => {
  //     console.log('file', e.target.files);
  //     // setSelectedFile(e.target.files[0]);
  //     if (e.target.files.length > 0) {
  //       const file = e.target.files[0];
  //       uploadCompanyLogo(file);
  //     }
  //   });
  //   fileDialog.click();
  // };

  const openDoumentDialog = () => {
    const fileDialog = document.createElement('input');
    fileDialog.type = 'file';
    fileDialog.accept = '.png,.jpeg,.jpg';
    fileDialog.addEventListener('change', (e) => {
      console.log('file', e.target.files);
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        if (file.type === 'image/gif') {
          // Display an error message or handle the disallowed file type as needed
          setErrorMessage('GIF files are not allowed.');
          return; // Exit the function without further processing
        }
        uploadCompanyLogo(file);
      }
    });
    fileDialog.click();
  };

  const deleteDocsApi = async () => {
    setSubmitLoader(true);
    await axiosInstance
      .delete('vendor/delete-doc', {
        params: {
          id: String(formik.values.business_logo_id),
        },
      })
      .then(async (res) => {
        if (res?.data?.status === 200) {
          props.showToastMessage(res.data.message ?? '');
        }

        setSubmitLoader(false);
        if (props.isStepper) {
          getCompanyLogoOnly();
        } else {
          await getVendorProfile();
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  useEffect(() => {
    // getCategories();
    getVendorProfile().then(async () => {
      setTimeout(async () => {
        await getCategories();
      }, [500]);
    });
  }, []);

  useCallback(() => {
    getVendorProfile().then(async () => {
      setTimeout(async () => {
        await getCategories();
      }, [500]);
    });
  }, []);

  const BusinessDetailsValidationSchema = Yup.object().shape({
    business_name: Yup.string()
      .required('Business name is required')
      .max(100, 'Business name should not exceed 100 characters')
      .nullable(),
    business_category: Yup.string().required('Business Category is required').nullable(),
    // business_description: Yup.string().required('Business Description is required'),
    // opening_time: Yup.date().required('Opening Time is required'),
    // closing_time: Yup.date().required('Closing Time is required'),
    business_experience: Yup.string().matches(
      /^(?:\d{1,2}(?:\.\d{1,2})?)?$/,
      'Input should be numeric with maximum two digits'
    ),
    review_link: Yup.string().url('Please enetr valid url').nullable(),
  });

  const formInitialValues = {
    business_name: '',
    business_category: '',
    business_category_id: '',
    business_description: '',
    business_logo: '',
    business_logo_id: '',
    opening_time: '',
    closing_time: '',
    business_experience: '',
    review_link: '',
    business_single_tag: '',
    business_tags: [],
    vendor_status: '',
  };
  console.log('formInitialValues', formInitialValues);
  const updateBusinessDetails = async () => {
    if (
      daysChecked.sun ||
      daysChecked.mon ||
      daysChecked.tue ||
      daysChecked.wed ||
      daysChecked.thu ||
      daysChecked.fri ||
      daysChecked.sat
    ) {
      setSubmitLoader(true);
      let tags = '';
      if (allTags.length > 0) {
        tags = allTags.join(', ');
      }

      console.log('tags', tags);

      const params = {
        vendor_uuid: uuid,
        business_name: formik.values.business_name,
        category_label: formik.values.business_category,
        category_id: formik.values.business_category_id,
        business_expertise: formik.values.business_experience.toString(),
        business_tags: tags,
        business_review_link: formik.values.review_link,
        business_details: formik.values.business_description,
      };
      console.log('params', params);

      if (lastStep.current >= 1) {
        /// Update Business details

        await axiosInstance
          .patch('vendor/update-business-details', params)
          .then((res) => {
            updateBusinessTiming();
          })
          .catch((error) => {
            console.log('Error', error);
            setSubmitLoader(false);
          });
      } else {
        /// Register business details

        await axiosInstance
          .post('vendor/register-business-details', params)
          .then((res) => {
            updateBusinessTiming();
          })
          .catch((error) => {
            console.log('Error', error);
            setSubmitLoader(false);
          });
      }
    } else {
      props.showErrorToastMessage('Please select business days');
    }
  };

  const timeFormat = (param) => {
    const selectedDate = new Date(Date.parse(param));
    return selectedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  const handleCloseTimeChange = (newTime) => {
    console.log('closing time', timeFormat(newTime));
    formik.setFieldValue('closing_time', timeFormat(newTime));
  };
  const handleOpenTimeChange = (newTime) => {
    console.log('opening time', timeFormat(newTime));
    formik.setFieldValue('opening_time', timeFormat(newTime));
  };

  const updateBusinessTiming = async () => {
    const params = {
      vendor_uuid: uuid,
      monday_open: daysChecked.mon ? 1 : 0,
      tuesday_open: daysChecked.tue ? 1 : 0,
      wednesday_open: daysChecked.wed ? 1 : 0,
      thursday_open: daysChecked.thu ? 1 : 0,
      friday_open: daysChecked.fri ? 1 : 0,
      saturday_open: daysChecked.sat ? 1 : 0,
      sunday_open: daysChecked.sun ? 1 : 0,
      start_timing: formik.values.opening_time !== '' ? formik.values.opening_time : '10:00 AM',
      end_timing: formik.values.closing_time !== '' ? formik.values.closing_time : '8:00 PM',
    };

    console.log('params', params);

    await axiosInstance
      .post('vendor/business-timing', params)
      .then((res) => {
        setSubmitLoader(false);
        if (res.data.status === 200) {
          props.showToastMessage('Business information in updated.');
        }

        props.onSave();
      })
      .catch((error) => {
        console.log('Error', error);
        setSubmitLoader(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: BusinessDetailsValidationSchema,
    onSubmit: (values, actions) => {
      console.log('values..', values);
      updateBusinessDetails();
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setFieldError } = formik;
  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid lg={6} item>
              <TextField
                id="outlined-name-input"
                fullWidth
                type="text"
                name="business_name"
                size="medium"
                variant="outlined"
                label="Business Name*"
                disabled={formik.values.vendor_status === 'Active'}
                inputProps={{
                  style: {
                    fontSize: '16px',
                  },
                }}
                error={Boolean(touched.business_name && errors.business_name)}
                helperText={touched.business_name && errors.business_name}
                {...getFieldProps('business_name')}
              />
            </Grid>
            <Grid lg={6} item>
              <Autocomplete
                disablePortal
                fullWidt
                options={category}
                value={formik.values.business_category || ''}
                disabled={formik.values.vendor_status === 'Active'}
                onChange={(event, newValue) => handleCategoryChange(event, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="business_category"
                    label="Business Category*"
                    type="text"
                    size="medium"
                    variant="outlined"
                    {...getFieldProps('business_category')}
                    error={formik.touched.business_category && Boolean(formik.errors.business_category)}
                    helperText={formik.touched.business_category && formik.errors.business_category}
                  />
                )}
              />

              <Typography fontSize={'12px'} ml={'4px'} mt={'4px'}>
                If you didn't find category please contact admin.{' '}
                <a
                  href="mailto:info@sunngrahak.com"
                  style={{ color: 'green', textDecoration: 'none', fontWeight: '600' }}
                >
                  Send Email
                </a>
              </Typography>
            </Grid>
            <Grid lg={12} item>
              <TextField
                fullWidth
                size="medium"
                id="outlined-name-input"
                type="text"
                name="business_description"
                label="Business Description"
                multiline
                disabled={formik.values.vendor_status === 'Active'}
                maxRows={3}
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: '16px ',
                  },
                }}
                // error={Boolean(touched.business_description && errors.business_description)}
                // helperText={touched.business_description && errors.business_description}
                {...getFieldProps('business_description')}
              />
            </Grid>

            <Grid item lg={12}>
              <Divider sx={{ my: '10px' }} />
            </Grid>
            <Grid lg={12} item mt={'5px'}>
              <Typography fontWeight={'500'} fontSize={'18px'}>
                Business Logo
              </Typography>

              <Typography fontWeight={'400'} fontSize={'14px'}>
                Upload / Update your business logo
              </Typography>

              <img
                src={formik.values.business_logo ? formik.values.business_logo : nobusinessLogo}
                style={{ marginTop: '5px', borderRadius: '10px' }}
                alt="businesslogo"
                width={'140px'}
                height={'120px'}
              />
              {errorMessage && (
                <Typography color="error" variant="caption" sx={{ mt: '5px' }}>
                  {errorMessage}
                </Typography>
              )}

              {submitLoader === false ? (
                <Box>
                  <Button
                    variant="outlined"
                    sx={{ mt: '10px', padding: '5px 15px' }}
                    size="small"
                    onClick={openDoumentDialog}
                  >
                    Upload Logo
                  </Button>
                  {formik.values.business_logo && (
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ mt: '10px', ml: '10px', padding: '5px 15px' }}
                      size="small"
                      onClick={deleteDocsApi}
                    >
                      Remove Logo
                    </Button>
                  )}
                </Box>
              ) : (
                <CircularProgress sx={{ mt: '5px' }} />
              )}
            </Grid>

            <Grid item lg={12}>
              <Divider sx={{ my: '10px' }} />
            </Grid>

            <Grid lg={12} item mt={'5px'}>
              <Typography fontWeight={'500'} fontSize={'18px'}>
                Business Timing *
              </Typography>

              <Typography fontWeight={'400'} fontSize={'14px'}>
                Update your business timing
              </Typography>
            </Grid>

            <Grid lg={6} item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['TimePicker']}>
                  <TimePicker
                    name="opening_time"
                    value={dayjs(
                      formik.values.opening_time !== '' ? formik.values.opening_time : '10:00 AM',
                      'hh:mm a'
                    )}
                    onChange={(newValue) => {
                      handleOpenTimeChange(newValue);
                    }}
                    label="Opening Time"
                    fullwidth
                    sx={{ width: '100%' }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid lg={6} item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['TimePicker']}>
                  <TimePicker
                    name="closing_time"
                    value={dayjs(
                      formik.values.closing_time !== '' ? formik.values.closing_time : '20:00 PM',
                      'hh:mm a'
                    )}
                    onChange={(newValue) => {
                      handleCloseTimeChange(newValue);
                    }}
                    label="Closing Time"
                    fullwidth
                    sx={{ width: '100%' }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            <Grid item lg={12}>
              <Divider sx={{ my: '10px' }} />
            </Grid>

            <Grid lg={12} item mt={'5px'}>
              <Typography fontWeight={'500'} fontSize={'18px'}>
                Business Days *
              </Typography>

              <Typography fontWeight={'400'} fontSize={'14px'}>
                Update your business working days
              </Typography>
            </Grid>

            <Grid lg={12} item>
              <Stack direction={'column'}>
                <FormControlLabel
                  control={<Switch />}
                  label="All Days"
                  checked={allDayChecked}
                  value={allDayChecked}
                  sx={{ ml: '2px', width: '200px ' }}
                  onChange={(e) => {
                    setAllDaysCheckedTap(e.target.checked);
                  }}
                />
                <Stack direction={'row'} spacing={3}>
                  <FormControlLabel
                    control={<Switch />}
                    label="Sun"
                    checked={daysChecked.sun}
                    value={daysChecked.sun}
                    onChange={(e) => setDaysChecked((prevState) => ({ ...prevState, sun: e.target.checked }))}
                  />
                  <FormControlLabel
                    control={<Switch />}
                    label="Mon"
                    checked={daysChecked.mon}
                    value={daysChecked.mon}
                    onChange={(e) => setDaysChecked((prevState) => ({ ...prevState, mon: e.target.checked }))}
                  />
                  <FormControlLabel
                    control={<Switch />}
                    label="Tue"
                    checked={daysChecked.tue}
                    value={daysChecked.tue}
                    onChange={(e) => setDaysChecked((prevState) => ({ ...prevState, tue: e.target.checked }))}
                  />
                  <FormControlLabel
                    control={<Switch />}
                    label="Wed"
                    checked={daysChecked.wed}
                    value={daysChecked.wed}
                    onChange={(e) => setDaysChecked((prevState) => ({ ...prevState, wed: e.target.checked }))}
                  />
                  <FormControlLabel
                    control={<Switch />}
                    label="Thu"
                    checked={daysChecked.thu}
                    value={daysChecked.thu}
                    onChange={(e) => setDaysChecked((prevState) => ({ ...prevState, thu: e.target.checked }))}
                  />
                  <FormControlLabel
                    control={<Switch />}
                    label="Fri"
                    checked={daysChecked.fri}
                    value={daysChecked.fri}
                    onChange={(e) => setDaysChecked((prevState) => ({ ...prevState, fri: e.target.checked }))}
                  />
                  <FormControlLabel
                    control={<Switch />}
                    label="Sat"
                    checked={daysChecked.sat}
                    value={daysChecked.sat}
                    onChange={(e) => setDaysChecked((prevState) => ({ ...prevState, sat: e.target.checked }))}
                  />
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={12}>
              <Divider sx={{ my: '10px' }} />
            </Grid>

            <Grid lg={6} item mt={'5px'}>
              <Typography fontWeight={'500'} fontSize={'18px'}>
                Business Experience
              </Typography>

              <Typography mb={'20px'} fontWeight={'400'} fontSize={'14px'}>
                Update your business experience (in year)
              </Typography>

              <TextField
                fullWidth
                size="medium"
                variant="outlined"
                name="business_experience"
                label="Business Experience"
                type="number"
                InputProps={{
                  style: {
                    fontSize: '16px ',
                  },
                  maxLength: 2,
                }}
                error={Boolean(touched.business_experience && errors.business_experience)}
                helperText={touched.business_experience && errors.business_experience}
                {...getFieldProps('business_experience')}
              />
            </Grid>

            <Grid lg={6} item mt={'5px'}>
              <Typography fontWeight={'500'} fontSize={'18px'}>
                Google Review Link
              </Typography>

              <Typography mb={'20px'} fontWeight={'400'} fontSize={'14px'}>
                Update Google Review Link of your Business
              </Typography>

              <TextField
                fullWidth
                size="medium"
                variant="outlined"
                name="review_link"
                label="Google Review Link of Business"
                InputProps={{
                  style: {
                    fontSize: '16px ',
                  },
                }}
                error={Boolean(touched.review_link && errors.review_link)}
                helperText={touched.review_link && errors.review_link}
                {...getFieldProps('review_link')}
              />
            </Grid>

            <Grid item lg={12}>
              <Divider sx={{ my: '10px' }} />
            </Grid>

            <Grid lg={12} item mt={'5px'}>
              <Stack>
                <Typography fontWeight={'500'} fontSize={'18px'}>
                  Business Keywords
                </Typography>

                <Typography mb={'20px'} fontWeight={'400'} fontSize={'14px'}>
                  Add relevant keywords of your business
                </Typography>

                <TextField
                  fullWidth
                  size="medium"
                  variant="outlined"
                  label="Add Keyword and press + button to add into the list"
                  name="business_single_tag"
                  error={Boolean(touched.business_single_tag && errors.business_single_tag)}
                  helperText={touched.business_single_tag && errors.business_single_tag}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          addTags();
                        }}
                      >
                        <AddCircleIcon sx={{ color: 'green' }} />
                      </IconButton>
                    ),
                    style: {
                      fontSize: '16px ',
                    },
                  }}
                  {...getFieldProps('business_single_tag')}
                />

                <Stack direction="row" spacing={1} mt={'15px'}>
                  {allTags.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      onDelete={() => removeTags(item)}
                      color="error"
                      variant="outlined"
                      onClick={() => {
                        console.log(item);
                      }}
                      sx={{
                        color: 'green',
                        border: '1.4px solid green ',
                        padding: '2px 10px',
                        height: '40px',
                        borderRadius: '100px',
                        fontWeight: '600',
                        '&:hover': {
                          bgcolor: 'white',
                        },
                      }}
                      deleteIcon={
                        <DoDisturbOnIcon
                          color="error"
                          sx={{
                            '& .MuiChip-deleteIcon': {
                              color: 'red',
                            },
                          }}
                        />
                      }
                    />
                  ))}
                </Stack>

                <Divider sx={{ my: '20px' }} />

                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={submitLoader}
                  disableElevation
                  sx={{
                    alignSelf: 'flex-end',
                    padding: '8px 40px',
                    textTransform: 'uppercase',
                    '&:hover': {
                      bgcolor: 'green',
                    },
                  }}
                >
                  {props.isStepper ? 'Save & Next ' : 'Save'}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default BusinessDetails;
