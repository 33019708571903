import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stepper, Step, StepLabel, Card, Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import { Formik, Form } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { HandleExceptionWithSecureCatch } from '../../../actions/CombineCatch';
import axiosInstance from '../../../api/withJWTServer';
import AddressForm from './Address/AddressForm';
import UploadDocuments from './Docs/UploadDocuments';
import FormSuccess from './FormSuccess/FormSuccess';
import BusinessDetail from './BusinessDetail';
import validationSchema from './FormModel/validationSchema';
import stepperFormModel from './FormModel/stepperFormModel';
import { VENDOR_DETAIL, PROFILE_DATA, SUBSRIPTION_PLANS } from '../../../actions/Types';
import useStyles from './styles';
import PlansandPayments from './PlansPayments/PlansandPayments';
import useResponsive from '../../../hooks/useResponsive';

const steps = ['Business Details', 'Address Details', 'Upload Documents', 'Plans and Payments'];
const { formId, formField } = stepperFormModel;

export default function StepperForm() {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [formInitialValue, setFormInitialValue] = useState(null);
  const currentValidationSchema = validationSchema[activeStep];
  const firstStep = activeStep === 0;
  const midStep = activeStep === steps.length - 3;
  const isLastStep = activeStep === steps.length - 2;
  const ispaymentStep = activeStep === steps.length - 1;
  const mdUp = useResponsive('up', 'md');
  console.log('activeStep', activeStep);
  const { uuid, profileData, subscriptionData } = useSelector((state) => ({
    uuid: state.auth.loggedUser.uuid,
    profileData: state?.profileData,
    subscriptionData: state?.subscriptionData,
  }));

  const [businessLogoFile, setBusinessLogoFile] = useState(null);
  const [companyLogo, setCompanyLogo] = useState('');
  const [docsUploadMsg, setDocsUploadMsg] = useState('');
  const [gumastaUploadMsg, setGumastaUploadMsg] = useState('');
  const [gstDocs, setGstDocs] = useState(null);
  const [gumastaDocs, setGumastaDocs] = useState(null);
  const [aadharFront, setAadharfrontDocs] = useState(null);
  const [aadharBack, setAadharBackDocs] = useState(null);
  const [panDocs, setPandocs] = useState(null);
  const [gstFileError, setGstFileError] = useState('');
  const [gumastaFileError, setGumastaFileError] = useState('');
  const [aadharFrontError, setAadharFrontError] = useState('');
  const [aadharBackError, setAadharBackError] = useState('');
  const [panError, setPanError] = useState('');
  const [gstDocsgumastaError, setGstDocsGumastaError] = useState('');
  const [aadharPanError, setAadharPanError] = useState('');
  const [atlistOneDoc, setAlistOneDoc] = useState('');
  const [inputTagsField, setInputTagsField] = useState('');
  const [noPlanSelectError, setNoSelectPlanError] = useState('');
  const [checkTermCondition, setCheckTermCondition] = useState(false);
  const [termsandConditionError, setTermsandconditionError] = useState('');

  const selectedPlans = (plan) => {
    if (plan) {
      setNoSelectPlanError('');
    } else if (profileData?.last_completed_step !== 4) {
      setNoSelectPlanError('Please select a Plan Type');
    }
  };

  const checkTermsandCondition = (e) => {
    setCheckTermCondition(e);
  };
  const termsConditionErrorCheck = (e) => {
    if (e) {
      setTermsandconditionError('');
    }
  };

  const {
    formField: {
      bussinessName,
      otherCategory,
      bussinessExpertise,
      businessCategory,
      googlereviewlink,
      city,
      state,
      zipcode,
      country,
      address,
      Landmark,
      mobileNo,
      alertnateNo,
      businessFromTime,
      businessToTime,
      uploadGstFile,
      uploadGumastaFile,
      uploadAadharCard,
      uploadAadharCardback,
      uploadPanCard,
      uploadBusinessDoc,
      uploadGumastaLicence,
      checkboxList,
      businessDoc,
      gumastaLicenceNo,
      plansBoxList,
      bussinessDescription,
    },
  } = stepperFormModel;

  function _renderStepContent(step, error) {
    switch (step) {
      case 0:
        return (
          <BusinessDetail
            formField={formField}
            onFileUpload={fileUpload}
            errorMsg={companyLogo}
            timingError={error.checkboxList}
            handleInputTags={handleInputTags}
          />
        );
      case 1:
        return <AddressForm formField={formField} planSlug={0} />;
      case 2:
        return (
          <UploadDocuments
            formField={formField}
            onDocsUpload={docsUpload}
            gstFileError={gstFileError}
            gumastaFileError={gumastaFileError}
            aadharFrontError={aadharFrontError}
            aadharBackError={aadharBackError}
            panError={panError}
            gstDocsgumastaError={gstDocsgumastaError}
            aadharPanError={aadharPanError}
            handleDocsFile={handleDocsFile}
            errFileDocs={docsUploadMsg}
            errFileGumasta={gumastaUploadMsg}
            atlistOneDoc={atlistOneDoc}
          />
        );
      case 3:
        return (
          <PlansandPayments
            formField={formField}
            planError={noPlanSelectError}
            selectedPlans={selectedPlans}
            checkTermsandCondition={checkTermsandCondition}
            termsandConditionError={termsandConditionError}
            termsConditionErrorCheck={termsConditionErrorCheck}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }

  function uploadDocument(docs, fileCategory) {
    if (docs) {
      console.log('Doccc', docs);
      const formData = new FormData();
      formData.append('business-doc', docs);
      formData.append('file_category', fileCategory);

      axiosInstance
        .post('vendor/upload-business-docs', formData)
        .then((res) => {
          profileCall();
          axiosInstance.get('vendor/subscription-plans-info').then((res) => {
            dispatch({
              type: SUBSRIPTION_PLANS,
              payload: res?.data?.data,
            });
          });
        })
        .catch((err) => {
          // Handle the error
        });
    }
  }

  const getFileExtension = (filename) => `.${filename.split('.').pop().toLowerCase()}`;

  const timeFormat = (param) => {
    const selectedDate = new Date(Date.parse(param));
    return selectedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  const allowedFileExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
  const handleFileChange = (event, type) => {
    const file = event;
    if (file) {
      const fileExtension = getFileExtension(file.name);
      const fileSize = file.size; // File size in bytes
      const maxSizeInBytes = 10 * 1024 * 1024; // 5MB
      const maxSizeInMB = 10;

      if (allowedFileExtensions.includes(fileExtension)) {
        if (fileSize <= maxSizeInBytes) {
          // File is valid and within the size limit
          if (type === 'gst_registration_doc') {
            setGstFileError('');
            setGstDocsGumastaError('');
          } else if (type === 'gumasta_doc') {
            setGumastaFileError('');
            setGstDocsGumastaError('');
          } else if (type === 'aadhar_card_front') {
            setAadharFrontError('');
            setAadharPanError('');
          } else if (type === 'aadhar_card_back') {
            setAadharBackError('');
          } else if (type === 'pan_card') {
            setPanError('');
            setAadharPanError('');
          }
        } else {
          const errorMessage = `File size exceeds ${maxSizeInMB}MB.`;

          if (type === 'gst_registration_doc') {
            setGstFileError(errorMessage);
          } else if (type === 'gumasta_doc') {
            setGumastaFileError(errorMessage);
          } else if (type === 'aadhar_card_front') {
            setAadharFrontError(errorMessage);
          } else if (type === 'aadhar_card_back') {
            setAadharBackError(errorMessage);
          } else if (type === 'pan_card') {
            setPanError(errorMessage);
          }
        }
      } else {
        const errMsg = 'Invalid file format. Please select a JPG, JPEG, PNG, or PDF file.';
        if (type === 'gst_registration_doc') {
          setGstFileError(errMsg);
        } else if (type === 'gumasta_doc') {
          setGumastaFileError(errMsg);
        } else if (type === 'aadhar_card_front') {
          setAadharFrontError(errMsg);
        } else if (type === 'aadhar_card_back') {
          setAadharBackError(errMsg);
        } else if (type === 'pan_card') {
          setPanError(errMsg);
        }
      }
    }
  };
  const handleDocsFile = (...params) => {
    setAlistOneDoc('');
    console.log('Setting', params);
    if (params?.[1] === 'gst_registration_doc') {
      setGstDocs(params?.[0]);
      handleFileChange(params?.[0], 'gst_registration_doc');
    } else if (params?.[1] === 'gumasta_doc') {
      setGumastaDocs(params?.[0]);
      handleFileChange(params?.[0], 'gumasta_doc');
    } else if (params?.[1] === 'aadhar_card_front') {
      setAadharfrontDocs(params?.[0]);
      handleFileChange(params?.[0], 'aadhar_card_front');
    } else if (params?.[1] === 'aadhar_card_back') {
      setAadharBackDocs(params?.[0]);
      handleFileChange(params?.[0], 'aadhar_card_back');
    } else if (params?.[1] === 'pan_card') {
      setPandocs(params?.[0]);
      handleFileChange(params?.[0], 'pan_card');
    }
  };
  function fileUpload(file) {
    setBusinessLogoFile(file);
    setCompanyLogo('');
  }

  function docsUpload(file, type) {
    // setDocsFileUpload(file);
    setDocsUploadMsg('');
  }
  useEffect(() => {
    axiosInstance.get('vendor/profile').then((res) => {
      setFormInitialValue(res?.data?.data[0]);
      dispatch({
        type: PROFILE_DATA,
        payload: res?.data?.data[0] === undefined ? null : res?.data?.data[0],
      });
      if (location.pathname === '/dashboard/uploaddocs') {
        setActiveStep(0);
      } else {
        setActiveStep(res?.data?.data[0].last_completed_step === null ? 0 : res?.data?.data[0].last_completed_step);
      }
    });
  }, []);

  const filterDays = (array, day) => array.filter((val) => val === day);
  function dateFormat(param) {
    const originalDateStr = String(param);

    // Convert the original date string to a Date object
    const originalDate = new Date(originalDateStr);

    // Create options for formatting the time in 12-hour format (AM/PM)
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour format (AM/PM)
    };

    // Format the time with the options
    return originalDate.toLocaleString('en-US', options);
  }
  const profileCall = (e) => {
    axiosInstance.get('vendor/profile').then((res) => {
      dispatch({
        type: PROFILE_DATA,
        payload: res?.data?.data[0] === undefined ? null : res?.data?.data[0],
      });
    });
  };
  function _handleSubmit(values, actions) {
    function getOpenValue(values, day) {
      if (values.days) {
        return 1;
      }
      const filteredDays = filterDays(values.checkboxList, day);
      if (filteredDays[0] === day) {
        return 1;
      }

      return 0;
    }
    axiosInstance.get('vendor/categories').then((res) => {
      const data = res?.data?.data;
      const dataLabel = data.filter((val) => val.value === values.businessCategory);
      if (firstStep) {
        const params = {
          vendor_uuid: uuid,
          business_name: values.bussinessName,
          category_label: values.businessCategory === 0 ? values.otherCategory : dataLabel?.[0]?.label,
          category_id: values.businessCategory,
          business_expertise: values.bussinessExpertise,
          business_tags: inputTagsField ? String(inputTagsField.replace(/'/g, '')) : '',
          business_review_link: values.googlereviewlink,
          business_details: values.bussinessDescription,
        };

        if (profileData?.last_completed_step >= 1) {
          axiosInstance
            .patch('vendor/update-business-details', params)
            .then((res) => {
              profileCall();
              if (values.checkboxList || (values.businessFromTime && values.businessToTime)) {
                const para = {
                  vendor_uuid: uuid,
                  monday_open: getOpenValue(values, 'Mon'),
                  tuesday_open: getOpenValue(values, 'Tue'),
                  wednesday_open: getOpenValue(values, 'Wed'),
                  thursday_open: getOpenValue(values, 'Thu'),
                  friday_open: getOpenValue(values, 'Fri'),
                  saturday_open: getOpenValue(values, 'Sat'),
                  sunday_open: getOpenValue(values, 'Sun'),
                  start_timing: timeFormat(values.businessFromTime),
                  end_timing: timeFormat(values.businessToTime),
                };
                axiosInstance
                  .post('vendor/business-timing', para)
                  .then((res) => {
                    // dispatch(HandleExceptionWithSecureCatch(res));
                  })
                  .catch(
                    (err) => {}
                    // {dispatch(HandleExceptionWithSecureCatch(err))}
                  );
              }
              if (businessLogoFile) {
                const docs = new FormData();
                docs.append('business-doc', businessLogoFile);
                docs.append('file_category', 'company_logo');
                axiosInstance
                  .post('vendor/upload-business-docs', docs)
                  .then((response) => {
                    profileCall('hello company');
                    setCompanyLogo('');
                  })
                  .catch((error) => {
                    // setCompanyLogo('Business logo is required');
                  });
              }

              setActiveStep(activeStep + 1);
              actions.setTouched({});
              actions.setSubmitting(false);
              try {
                axiosInstance.get('vendor/profile').then((res) => {
                  dispatch({
                    type: PROFILE_DATA,
                    payload: res?.data?.data[0] === undefined ? null : res?.data?.data[0],
                  });
                });
              } catch (error) {
                console.error(error);
              }
            })
            .catch((error) => {});
        } else {
          axiosInstance
            .post('vendor/register-business-details', params)
            .then((res) => {
              profileCall();
              if (values.checkboxList || (values.businessFromTime && values.businessToTime)) {
                const para = {
                  vendor_uuid: uuid,
                  monday_open: getOpenValue(values, 'Mon'),
                  tuesday_open: getOpenValue(values, 'Tue'),
                  wednesday_open: getOpenValue(values, 'Wed'),
                  thursday_open: getOpenValue(values, 'Thu'),
                  friday_open: getOpenValue(values, 'Fri'),
                  saturday_open: getOpenValue(values, 'Sat'),
                  sunday_open: getOpenValue(values, 'Sun'),
                  start_timing: dateFormat(values.businessFromTime),
                  end_timing: dateFormat(values.businessToTime),
                  business_details: values.bussinessDescription,
                };
                axiosInstance
                  .post('vendor/business-timing', para)
                  .then((res) => {
                    // dispatch(HandleExceptionWithSecureCatch(res));
                  })
                  .catch(
                    (err) => {}
                    // {dispatch(HandleExceptionWithSecureCatch(err))}
                  );
              }
              const docs = new FormData();
              docs.append('business-doc', businessLogoFile);
              docs.append('file_category', 'company_logo');
              axiosInstance
                .post('vendor/upload-business-docs', docs)
                .then((response) => {
                  profileCall('hello company');
                  setCompanyLogo('');
                })
                .catch((error) => {
                  // setCompanyLogo('Business logo is required');
                });
              setActiveStep(activeStep + 1);
              actions.setTouched({});
              actions.setSubmitting(false);
              try {
                axiosInstance.get('vendor/profile').then((res) => {
                  dispatch({
                    type: PROFILE_DATA,
                    payload: res?.data?.data[0] === undefined ? null : res.data?.data[0],
                  });
                });
              } catch (error) {
                console.error(error);
              }
            })
            .catch((error) => {});
        }
      } else if (midStep) {
        const params = {
          address_line_1: values.address,
          address_line_2: values.Landmark,
          city: values.city,
          state: values.state,
          pin: values.zipcode,
          country: values.country,
          mobile_number: values.mobileNo,
          alternate_mobile_number: values.alertnateNo,
          business_details: values.bussinessDescription,
        };
        axiosInstance
          .patch('vendor/update-business-details', params)
          .then((res) => {
            profileCall();
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);

            try {
              axiosInstance.get('vendor/profile').then((res) => {
                dispatch({
                  type: PROFILE_DATA,
                  payload: res?.data?.data[0] === undefined ? null : res.data?.data[0],
                });
              });
            } catch (error) {
              console.error(error);
            }
          })
          .catch((error) => {
            actions.setSubmitting(false);
          });
      } else if (isLastStep) {
        if (
          (profileData?.vendorBusinessDocs?.gst_registration_doc?.doc_path ||
            profileData?.vendorBusinessDocs?.gumasta_doc?.doc_path) &&
          (profileData?.vendorBusinessDocs?.aadhar_card_front?.doc_path ||
            profileData?.vendorBusinessDocs?.pan_card?.doc_path)
        ) {
          if (gstDocs || gumastaDocs || aadharFront || aadharBack || panDocs) {
            if (activeStep < 3) {
              setActiveStep(activeStep + 1);
              actions.setTouched({});
              actions.setSubmitting(false);
            } else {
              uploadDocument(gstDocs, 'gst_registration_doc');
              uploadDocument(gumastaDocs, 'gumasta_doc');
              uploadDocument(aadharFront, 'aadhar_card_front');
              uploadDocument(aadharBack, 'aadhar_card_back');
              uploadDocument(panDocs, 'pan_card');
              setActiveStep(activeStep + 1);
              actions.setTouched({});
              actions.setSubmitting(false);
              dispatch({
                type: VENDOR_DETAIL,
                payload: 0,
              });
              try {
                axiosInstance.get('vendor/profile').then((res) => {
                  dispatch({
                    type: PROFILE_DATA,
                    payload: res?.data?.data[0] === undefined ? null : res.data?.data[0],
                  });
                });
              } catch (error) {
                console.error(error);
              }
            }
          } else {
            console.log('console11221011');
            dispatch({
              type: VENDOR_DETAIL,
              payload: 0,
            });
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
            try {
              axiosInstance.get('vendor/profile').then((res) => {
                dispatch({
                  type: PROFILE_DATA,
                  payload: res?.data?.data[0] === undefined ? null : res.data?.data[0],
                });
              });
            } catch (error) {
              console.error(error);
            }
          }
        } else {
          console.log('');
          console.log('console112233');

          if (gstDocs === null && gumastaDocs === null && aadharFront === null && panDocs === null) {
            setAadharPanError('Please select any document ');
          } else if (aadharFront !== null && aadharBack === null) {
            setAadharPanError('Please select aadhar card back side . ');
          } else {
            console.log('Upload document and move next');
            if (gstDocs !== null) {
              uploadDocument(gstDocs, 'gst_registration_doc');
            }
            if (gumastaDocs !== null) {
              uploadDocument(gumastaDocs, 'gumasta_doc');
            }
            if (panDocs !== null) {
              uploadDocument(panDocs, 'pan_card');
            }
            if (aadharFront !== null && aadharBack !== null) {
              uploadDocument(aadharFront, 'aadhar_card_front');
              uploadDocument(aadharBack, 'aadhar_card_back');
            }

            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
            try {
              axiosInstance.get('vendor/profile').then((res) => {
                dispatch({
                  type: PROFILE_DATA,
                  payload: res?.data?.data[0] === undefined ? null : res.data?.data[0],
                });
              });
            } catch (error) {
              console.error(error);
            }
          }

          // if (
          //   (gstDocs ||
          //     profileData?.vendorBusinessDocs?.gst_registration_doc?.doc_path ||
          //     gumastaDocs ||
          //     profileData?.vendorBusinessDocs?.gumasta_doc?.doc_path) &&
          //   (aadharFront ||
          //     profileData?.vendorBusinessDocs?.aadhar_card_front?.doc_path ||
          //     panDocs ||
          //     profileData?.vendorBusinessDocs?.pan_card?.doc_path)
          // ) {
          //   if (
          //     !profileData?.vendorBusinessDocs?.gst_registration_doc?.doc_path &&
          //     !profileData?.vendorBusinessDocs?.gumasta_doc?.doc_path
          //   ) {
          //     if (gstDocs || gumastaDocs) {
          //       uploadDocument(gstDocs, 'gst_registration_doc');
          //       uploadDocument(gumastaDocs, 'gumasta_doc');
          //       setActiveStep(activeStep + 1);
          //       actions.setTouched({});
          //       actions.setSubmitting(false);
          //     } else {
          //       setGstDocsGumastaError('Please Select GST or Gumasta ');
          //     }
          //   } else {
          //     setGstDocsGumastaError('Please Select GST or Gumasta ');
          //   }

          //   if (
          //     !profileData?.vendorBusinessDocs?.aadhar_card_front?.doc_path ||
          //     !profileData?.vendorBusinessDocs?.pan_card?.doc_path
          //   ) {
          //     if (aadharFront || panDocs) {
          //       uploadDocument(aadharFront, 'aadhar_card_front');
          //       uploadDocument(aadharBack, 'aadhar_card_back');
          //       uploadDocument(panDocs, 'pan_card');
          //       setActiveStep(activeStep + 1);
          //       actions.setTouched({});
          //       actions.setSubmitting(false);
          //     } else {
          //       setGstDocsGumastaError('Please Select GST or Gumasta ');
          //     }
          //   } else {
          //     setAadharPanError('Please select Aadhar Card Front or PAN Card');
          //   }
          //   setActiveStep(activeStep + 1);
          //   actions.setTouched({});
          //   actions.setSubmitting(false);
          //   try {
          //     axiosInstance.get('vendor/profile').then((res) => {
          //       dispatch({
          //         type: PROFILE_DATA,
          //         payload: res?.data?.data[0] === undefined ? null : res.data?.data[0],
          //       });
          //     });
          //   } catch (error) {
          //     console.error(error);
          //   }
          // } else {
          //   if (!gstDocs && !gumastaDocs) {
          //     if (
          //       !profileData?.vendorBusinessDocs?.gst_registration_doc?.doc_path &&
          //       !profileData?.vendorBusinessDocs?.gumasta_doc?.doc_path
          //     ) {
          //       setGstDocsGumastaError('Please Select GST or Gumasta');
          //     } else {
          //       setGstDocsGumastaError('');
          //     }
          //   } else {
          //     setGstDocsGumastaError('');
          //   }
          //   if (!aadharFront && !panDocs) {
          //     if (
          //       !profileData?.vendorBusinessDocs?.aadhar_card_front?.doc_path &&
          //       !profileData?.vendorBusinessDocs?.pan_card?.doc_path
          //     ) {
          //       setAadharPanError('Please select Aadhar Card Front or PAN Card');
          //     } else {
          //       setAadharPanError('');
          //     }
          //   } else {
          //     setAadharPanError('');
          //   }
          // }
        }
      } else if (ispaymentStep) {
        console.log('console112233');
        if (values?.plansBoxList && checkTermCondition) {
          if (checkTermCondition) {
            const selectedPlan = values.plansBoxList;

            const params = {
              selected_plan_uuid: selectedPlan?.uuid,
              selected_plan_name: selectedPlan?.plan_title,
            };

            console.log('Params Plans:-', params);

            try {
              axiosInstance.patch('vendor/update-business-details', params).then((res) => {
                profileCall();
                dispatch({
                  type: VENDOR_DETAIL,
                  payload: 0,
                });
                navigate('/dashboard/app', { replace: true });
                const param = {
                  data: {
                    message: `${
                      profileData?.last_completed_step !== 4
                        ? 'Vendor registration is successfully completed'
                        : 'Vendor information successfully updated'
                    }`,
                    status: 200,
                  },
                };
                dispatch(HandleExceptionWithSecureCatch(param));
              });
            } catch (error) {
              console.error(error);
            }
          } else {
            setTermsandconditionError('Please Select Terms and Condition');
          }
        } else {
          console.log('');
          if (profileData?.last_completed_step === 4) {
            if (values?.plansBoxList && !checkTermCondition) {
              setTermsandconditionError('Please Select Terms & Condition');
            } else {
              console.log('');
              if (checkTermCondition && !values?.plansBoxList?.[0]) {
                setNoSelectPlanError('Please select a Plan Type');
              } else {
                const selectedPlan = values.plansBoxList;

                const params = {
                  selected_plan_uuid: selectedPlan?.uuid,
                  selected_plan_name: selectedPlan?.plan_title,
                };

                try {
                  axiosInstance.patch('vendor/update-business-details', params).then((res) => {
                    profileCall();
                    dispatch({
                      type: VENDOR_DETAIL,
                      payload: 0,
                    });
                    navigate('/dashboard/app', { replace: true });
                    const param = {
                      data: {
                        message: `${
                          profileData?.last_completed_step !== 4
                            ? 'Stepper successfully Completed'
                            : 'Stepper successfully Updated'
                        }`,
                        status: 200,
                      },
                    };
                    dispatch(HandleExceptionWithSecureCatch(param));
                  });
                } catch (error) {
                  console.error(error);
                }
              }
            }
          } else if (checkTermCondition && values?.plansBoxList?.[0]) {
            const selectedPlan = subscriptionData?.filter((val) => val?.uuid === values?.plansBoxList?.[0]);

            const params = {
              selected_plan_uuid: selectedPlan?.[0]?.uuid,
              selected_plan_name: selectedPlan?.[0]?.plan_title,
            };
            try {
              axiosInstance.patch('vendor/update-business-details', params).then((res) => {
                profileCall();
                dispatch({
                  type: VENDOR_DETAIL,
                  payload: 0,
                });
                navigate('/dashboard/app', { replace: true });
                const param = {
                  data: {
                    message: `${
                      profileData?.last_completed_step !== 4
                        ? 'Stepper successfully Completed'
                        : 'Stepper successfully Updated'
                    }`,
                    status: 200,
                  },
                };
                dispatch(HandleExceptionWithSecureCatch(param));
              });
            } catch (error) {
              console.error(error);
            }
          } else if (checkTermCondition && !values?.plansBoxList?.[0]) {
            setTermsandconditionError('');
          } else {
            setTermsandconditionError('Please Select Terms & Condition');
          }
        }
      } else {
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    });
  }

  const handleInputTags = (array) => {
    console.log('HANDLE CHECKLIST');
    setInputTagsField(array?.map((item) => `'${item}'`).join(', '));
  };

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }
  const checkbox = [
    profileData?.buinessTimingsModels?.[0]?.sunday_open === 1 ? 'Sun' : null,
    profileData?.buinessTimingsModels?.[0]?.monday_open === 1 ? 'Mon' : null,
    profileData?.buinessTimingsModels?.[0]?.tuesday_open === 1 ? 'Tue' : null,
    profileData?.buinessTimingsModels?.[0]?.wednesday_open === 1 ? 'Wed' : null,
    profileData?.buinessTimingsModels?.[0]?.thursday_open === 1 ? 'Thu' : null,
    profileData?.buinessTimingsModels?.[0]?.friday_open === 1 ? 'Fri' : null,
    profileData?.buinessTimingsModels?.[0]?.saturday_open === 1 ? 'Sat' : null,
  ];
  const dateWithTime = new Date();
  const formInitialValues = {
    [bussinessName.name]: profileData?.vendorBusinessDetailsModels?.[0]?.business_name || '',
    [bussinessExpertise.name]: profileData?.vendorBusinessDetailsModels?.[0]?.business_expertise || '',
    [businessCategory.name]: profileData?.vendorBusinessDetailsModels?.[0]?.category_id || '',
    [bussinessDescription.name]: profileData?.vendorBusinessDetailsModels?.[0]?.business_details || '',
    [otherCategory.name]: profileData?.vendorBusinessDetailsModels?.[0]?.category_label || '',
    [googlereviewlink.name]: profileData?.vendorBusinessDetailsModels?.[0]?.business_review_link || '',
    [city.name]: profileData?.vendorBusinessDetailsModels?.[0]?.city || '',
    [city.name]: profileData?.vendorBusinessDetailsModels?.[0]?.city || '',
    [state.name]: profileData?.vendorBusinessDetailsModels?.[0]?.state || '',
    [zipcode.name]: profileData?.vendorBusinessDetailsModels?.[0]?.pin || '',
    [country.name]: profileData?.vendorBusinessDetailsModels?.[0]?.country || '',
    [address.name]: profileData?.vendorBusinessDetailsModels?.[0]?.address_line_1 || '',
    [Landmark.name]: profileData?.vendorBusinessDetailsModels?.[0]?.address_line_2 || '',
    [mobileNo.name]: profileData?.vendorBusinessDetailsModels?.[0]?.mobile_number || '',
    [alertnateNo.name]: profileData?.vendorBusinessDetailsModels?.[0]?.alternate_mobile_number || '',
    [businessFromTime.name]: profileData?.buinessTimingsModels?.[0]?.start_timing || '',
    [businessToTime.name]: profileData?.buinessTimingsModels?.[0]?.end_timing || '',
    [uploadBusinessDoc.name]: '',
    [uploadGumastaLicence.name]: '',
    [checkboxList.name]: checkbox?.filter((val) => val != null) || '',
    [businessDoc.name]:
      profileData?.vendorBusinessDetailsModels?.[0]?.business_doc_id || `${dateWithTime.setHours(10, 0, 0, 0)}`,
    [gumastaLicenceNo.name]:
      profileData?.vendorBusinessDetailsModels?.[0]?.gumasta_id || `${dateWithTime.setHours(19, 0, 0, 0)}`,
    [uploadGstFile.name]: '',
    [uploadGumastaFile.name]: '',
    [uploadAadharCard.name]: '',
    [uploadAadharCardback.name]: '',
    [uploadPanCard.name]: '',
    [plansBoxList.name]: [0],
  };
  const lastStepCheck = (ispaymentStep, status) => {
    console.log('save  next called');
    if (ispaymentStep) {
      if (profileData?.last_completed_step === 4) {
        return `${status === 'With-Hold' ? 'Resubmit for Approval' : 'Save'}`;
      }
      return 'Submit for Approval';
    }
    return 'Save & Next';
  };

  return (
    <>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps?.map((label) => (
          <Step key={label}>
            <StepLabel>
              <Typography className={classes.stepText}>{label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <>
        <Card style={{ width: mdUp ? '70%' : '90%', margin: 'auto', padding: 20, marginTop: '10px' }}>
          {activeStep === steps.length ? (
            <FormSuccess />
          ) : (
            <Formik
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              onSubmit={(values, actions) => {
                _handleSubmit(values, actions);
              }}
            >
              {({ errors, isSubmitting }) => (
                <Form id={formId}>
                  {_renderStepContent(activeStep, errors)}

                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={() => _handleBack()} className={classes.button}>
                        Back
                      </Button>
                    )}

                    <div className={classes.wrapper}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        sx={{
                          borderRadius: '10px',
                          px: '20px',
                          '&:hover': {
                            bgcolor: 'green',
                          },
                        }}
                      >
                        {lastStepCheck(ispaymentStep, formInitialValue?.status ? formInitialValue?.status : '')}
                      </LoadingButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Card>
      </>
    </>
  );
}
