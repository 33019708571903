import React, { useState, useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import { Box, FormControlLabel, FormGroup } from '@mui/material';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function CheckboxList({
  options,
  option,
  title,
  nameType,
  showSelectAll = false,
  row = false,
  selectAllCheckBox,
  ...props
}) {
  const [field] = useField(nameType);
  const [checkedAll, setCheckedAll] = useState(options.filter((val) => val.is_checked === 1).length === 7 && true);
  const { value } = field;
  const [check, setCheck] = useState(options);
  const { setFieldValue } = useFormikContext();

  const handleCheckboxChange = (param) => {
    setCheckedAll(!checkedAll);
    if (!checkedAll) {
      setFieldValue('checkboxList', ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
    } else {
      setFieldValue('checkboxList', []);
    }
  };

  const isInitialRender = useRef(true);
  useEffect(() => {
    // Check if it's not the initial render
    if (!isInitialRender.current) {
      const newArray = check.map((item) => ({
        ...item,
        is_checked: checkedAll ? 1 : 0,
      }));
      setCheck(newArray);
    } else {
      // It's the initial render, set the flag to false
      isInitialRender.current = false;
    }
  }, [checkedAll]);

  const handleSingleCheckBox = (index) => {
    if (!checkedAll) {
      const updatedCheck = [...check]; // Create a copy of the check array
      updatedCheck[index] = {
        ...updatedCheck[index],
        is_checked: updatedCheck[index].is_checked === 1 ? 0 : 1, // Toggle the is_checked property
      };

      // Check if all days are checked or not
      const allChecked = updatedCheck.every((item) => item.is_checked === 1);
      setCheckedAll(allChecked);

      // Update the form field value
      const selectedDays = updatedCheck.filter((item) => item.is_checked === 1).map((item) => item.value);
      setFieldValue('checkboxList', selectedDays);

      // Update the check array with the new state
      setCheck(updatedCheck);
    }
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          label="All Days"
          {...field}
          value={1}
          checked={checkedAll}
          control={
            <Checkbox
              onChange={handleCheckboxChange} // Call handleCheckboxChange on checkbox change
            />
          }
        />
      </FormGroup>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {check?.map((opt, index) => (
          <FormGroup key={opt.value}>
            <FormControlLabel
              {...field}
              {...field}
              {...props}
              label={opt.label}
              value={opt.is_checked}
              control={
                <Checkbox
                  checked={opt.is_checked === 1}
                  onChange={() => handleSingleCheckBox(index)}
                  value={opt.value}
                />
              }
            />
          </FormGroup>
        ))}
      </Box>
    </>
  );
}
