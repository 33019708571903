import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ButtonCard from '../../components/comman/ButtonCard';
import Iconify from '../../components/Iconify';
import './index.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 700,
  width: 800,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

export default function CommanModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const pdfUrlSrc = /\.pdf$/i.test(props.image?.props?.src);
  const pdfUrlImg = /\.pdf$/i.test(props.image);

  const imageUrl = () => {
    let content;

    if (pdfUrlSrc || pdfUrlImg) {
      content = (
        <iframe
          title="PDF Viewer"
          src={`${props.image?.props?.src || props.image}#toolbar=0`}
          width="100%"
          height="90%"
          frameBorder="0"
          scrolling="auto"
          allow="fullscreen"
          style={{ clip: 'rect(100px, auto, auto, auto)' }}
        />
      );
    } else if (props.image?.props?.src) {
      content = (
        <span style={{ alignItems: 'center' }}>
          <img src={props.image.props.src} alt="login" height="600" />
        </span>
      );
    } else {
      content = <img src={props.image} alt="img" height="600" />;
    }

    return content;
  };

  const content = () => {
    if (props.slug !== 'logo' && !pdfUrlSrc && !pdfUrlImg) {
      return (
        <div
          role="button"
          tabIndex={0}
          onClick={handleOpen}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleOpen();
            }
          }}
        >
          <ButtonCard text={'View Document'} />
        </div>
      );
    }

    if (pdfUrlSrc || pdfUrlImg) {
      return (
        <span
          style={{
            padding: '7px 15px',
            cursor: 'pointer',
            color: '#fff',
            borderRadius: '20px',
            border: '1.5px solid #0B8238',
            background: '#F9F9F9',
          }}
        >
          <a href={`${props?.pdf}`} rel="noreferrer" style={{ textDecoration: 'none', color: '#02742d' }}>
            Download Docs.
          </a>
        </span>
      );
    }

    return null;
  };

  return (
    <div>
      <div>{content()}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Iconify
            icon="eva:close-outline"
            sx={{
              float: 'right',
              height: '30px',
              width: '30px',
              color: '#000',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ zIndex: 9999, background: '#fff' }}>
            {props.name}
          </Typography>
          <br />
          {imageUrl()}
        </Box>
      </Modal>
    </div>
  );
}
