import { Navigate } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard/index';
import UploadLayout from './layouts/dashboard/Upload';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Support from './pages/Support';
import TermsCondition from './pages/TermsandCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DashboardApp from './pages/DashboardApp';
import Jobs from './pages/VendorJobs/Jobs';
import CreateVendorJob from './pages/VendorJobs/CreateVendorJob';
import Candidates from './pages/Candidates/Candidates';
import UploadDocs from './pages/UploadDocs';
import ResetPassword from './sections/auth/ResetPassword';
import Otp from './sections/auth/login/Otp';
import Register from './sections/auth/login/Register';
import Payment from './pages/Payment';
import Profile from './pages/Profile';
import Gumasta from './pages/WizardComponent/Steps/Gumasta';
// import SuceessPayment from './pages/SucessPayment';
import FailPayment from './pages/FailPayment';
import ValidatingPayment from './pages/ValidatingPayment';
import Notifications from './pages/Notifications';
import BusinessProfile from './pages/BusinessProfile';
import StepperRegistration from './pages/StepperRegistration';
import PersonalDetails from './pages/TabComp/PersonalDetails';
import ViewCandidate from './pages/Candidates/ViewCandidate';
import VendorJobDetails from './pages/VendorJobs/VendorJobDetails';
import ValidatingMatrimonyPayment from './pages/ValidatingMatrimonyPayment';
import SucessPayment from './pages/SucessPayment';

const routes = (isLogged, vendorDetail) => [
  {
    path: '/dashboard',
    element: (() => {
      if (isLogged) {
        if (vendorDetail === 0) {
          return <DashboardLayout />;
        }
        if (vendorDetail === 1) {
          return <UploadLayout />;
        }
      }
      return <Navigate to="/" />;
    })(),
    children: [
      { path: 'app', element: <DashboardApp /> },
      { path: 'uploaddocs', element: <UploadDocs /> },
      { path: 'upload-images', element: <Gumasta /> },
      { path: 'payment/:plan', element: <Payment /> },
      { path: 'jobs', element: <Jobs /> },
      { path: 'viewJob', element: <VendorJobDetails /> },
      { path: 'createVendorJob', element: <CreateVendorJob /> },
      { path: 'candidates', element: <Candidates /> },
      { path: 'viewCandidate', element: <ViewCandidate /> },
      { path: 'profile', element: <PersonalDetails /> },
      { path: 'termCondition', element: <TermsCondition /> },
      { path: 'privacyPolicy', element: <PrivacyPolicy /> },
      { path: 'support', element: <Support /> },
      { path: 'notifications', element: <Notifications /> },
      { path: 'business-profile', element: <BusinessProfile /> },
      { path: 'stepper-registration', element: <StepperRegistration /> },
    ],
  },
  {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { path: '/', element: <Navigate to="/login" /> },
      { path: 'resetpassword', element: <ResetPassword /> },
      { path: 'login', element: <Login /> },
      { path: 'otp', element: <Otp /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'term&condition', element: <TermsCondition /> },
    ],
  },
  { path: '*', element: <Navigate to="/404" replace /> },

  // -----------Payemnt routes---------
  { path: 'sucessPayment', element: <SucessPayment /> },
  { path: 'failPayment', element: <FailPayment /> },
  { path: 'validating_payment', element: <ValidatingPayment /> },
  { path: 'validating_matrimony_payment', element: <ValidatingMatrimonyPayment /> },
];

export default routes;
