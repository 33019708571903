
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormHelperText,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';

function SelectCountryField(props) {
  // const { profileData } = useSelector((state) => ({
  //   profileData: state?.profileData,
  // }));
  const { label, data, onValueChange, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const isInitialRender = useRef(true);

 
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }
  
  useEffect(()=>{
    helpers.setValue("India");
  },[])
 
  return (
  <>
   <FormControl {...rest} error={isError} variant="standard" sx={{ mt: "-4px !important" }}>
      <InputLabel>{label}</InputLabel>
     <Select {...field} value={selectedValue} label={label} >
     {data?.map((item, index) => (
       <MenuItem key={index} value={item.value}>
         {item.label}
       </MenuItem>
     ))}
   </Select>
      {_renderHelperText()}
    </FormControl>
   
  </>
  );
}

SelectCountryField.defaultProps = {
  data: [],
};

SelectCountryField.propTypes = {
  data: PropTypes.array,
};

export default SelectCountryField;
