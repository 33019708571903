import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { MuiChipsInput } from 'mui-chips-input';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axiosInstance from '../../../../api/withJWTServer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

export default function InputTagField(props) {
  const { profileData } = useSelector((state) => ({
    profileData: state?.profileData,
  }));
  const [inputValue, setInputValue] = React.useState('');
  console.log('inputValue', inputValue);
  const [debouncedValue, setDebouncedValue] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [chips, setChips] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newOption, setNewOption] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    if (profileData?.vendorBusinessDetailsModels?.[0]?.business_tags) {
      const tag = profileData?.vendorBusinessDetailsModels?.[0]?.business_tags
        .split(', ')
        .map((item) => item.replace(/'/g, ''));
      setNewOption(tag);
    }
  }, []);

  useEffect(() => {
    if (profileData?.vendorBusinessDetailsModels?.[0]?.category_id) {
      setCategoryId(profileData?.vendorBusinessDetailsModels?.[0]?.category_id);
    }
  }, []);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = true;
      return;
    }
    setCategoryId(props.selectCategory);
  }, [props.selectCategory]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [inputValue]);

  useEffect(() => {
    if (debouncedValue) {
      axiosInstance
        .get(
          `vendor/business-tags?category_id=${
            props.selectCategory ? props.selectCategory : categoryId
          }&key=${debouncedValue}`
        )
        .then((res) => {
          setOptions(res?.data?.data);
        })
        .catch((err) => {
          setOptions([]);
        });
    } else {
      axiosInstance
        .get(`vendor/business-tags?category_id=${props.selectCategory ? props.selectCategory : categoryId}&key=${null}`)
        .then((res) => {
          setOptions(res?.data?.data);
        })
        .catch((err) => {
          setOptions([]);
        });
    }
  }, [debouncedValue, categoryId]);

  useEffect(() => {
    setSelectedTags(selectedTags.concat(newOption));
    props.selectedTagProp(newOption);
  }, [newOption]);

  const handleChange = (newChips, params) => {
    if (newChips && params === 'delete') {
      setSelectedTags(selectedTags?.filter((value) => value !== newChips));
    } else if (filteredArray.length < 5) {
      handleOpen();
      setChips(newChips);
    }
  };

  const handleOptionChange = (e) => {
    setNewOption(e.target.outerText);
    handleClose();
  };

  useEffect(() => {
    const handleEnterKey = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (inputValue && options?.findIndex((o) => o === inputValue) === -1) {
          setSelectedTags([...selectedTags, inputValue]);
          setOptions((o) => o.concat(inputValue));
          handleClose();
        }
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleEnterKey);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, [inputValue, options, selectedTags]);

  const filteredArray = [...new Set(selectedTags?.filter((value) => value !== undefined))];
  useEffect(() => {
    props.handleTags(filteredArray);
  }, [filteredArray]);

  useEffect(() => {
    setOptions([]);
  }, [open]);
  return (
    <>
      <MuiChipsInput
        value={filteredArray}
        onDeleteChip={(e) => handleChange(e, 'delete')}
        onClick={(e) => handleChange(e)}
        aria-readonly
        placeholder={`${filteredArray?.length > 0 ? '' : 'Add Tags'}`}
        inputValue=""
        fullWidth
        style={{ border: '2px solid green', borderRadius: '10px', backgroundColor: '#eaf7ef' }}
        InputProps={{
          endAdornment: <ArrowDropDownIcon style={{ cursor: 'pointer', color: 'green', marginTop: '5px' }} />,
        }}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Relevant Tags <br />
            <span style={{ color: 'gray', fontSize: '10px' }}>Max 20 characters</span>
          </Typography>
          <br />
          <Autocomplete
            maxLength={20}
            options={options}
            noOptionsText="Enter to create a new option"
            getOptionLabel={(option) => option}
            onChange={handleOptionChange}
            onInputChange={(e, newValue) => {
              // Enforce a maximum length of 2 characters
              setInputValue(newValue.slice(0, 20));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select"
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && options?.findIndex((o) => o === inputValue) === -1) {
                    setSelectedTags([...selectedTags, inputValue]);
                    setOptions((o) => {
                      o.concat(inputValue);
                    });
                    handleClose();
                  }
                }}
              />
            )}
          />
        </Box>
      </Modal>
    </>
  );
}
