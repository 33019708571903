import React, { useEffect, useMemo } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@mui/material';

export default function InputLandMark(props) {
  const { errorText, ...rest } = useMemo(() => props, [props]);
  const [field, meta, helpers] = useField(props);
  const { value } = field;

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (error) {
      return error;
    }
  }
  useEffect(() => {
    if (props.currentLandMark) {
      helpers.setValue(String(props.currentLandMark));
    }
  }, [props.currentLandMark]);

  return (
    <TextField
      type="text"
      variant="standard"
      error={meta.error && true}
      helperText={_renderHelperText()}
      value={value}
      {...field}
      {...rest}
    />
  );
}
