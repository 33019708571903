import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Container, Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Fullloader from '../../Loader/Fullloader';
import CommanModal from '../../Modal/CommanModal';
import Iconify from '../../../components/Iconify';
import axiosInstance from '../../../api/withJWTServer';
import { HandleExceptionWithSecureCatch } from '../../../actions/CombineCatch';
import Page from '../../../components/Page';
import { VENDOR_DETAIL } from '../../../actions/Types';
import noImage from '../../../Assets/noImage.png';
import ShowMessage from '../../Modal/ShowMessage';
import BusinesImages from '../../../components/Component/BusinesImages';

const Gumasta = () => {
  const { loggedUseruuid } = useSelector((state) => ({
    loggedUseruuid: state?.auth?.loggedUser?.uuid,
  }));
  const [open, setOpen] = React.useState(false);
  const [uploadedImages, setuploadedImages] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [images, setImages] = useState([]);
  const [imageError, setImageError] = useState(null);
  const dispatch = useDispatch();

  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const onDrop = (acceptedFiles) => {
    const updatedImages = acceptedFiles.filter((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileSize = file.size; // File size in bytes
      const maxSizeInBytes = 10 * 1024 * 1024; // 5MB
      if (fileSize <= maxSizeInBytes) {
        setImageError();
      } else {
        setImageError('File size exceeds. Each file should be max 5mb');
      }

      return ['png', 'jpg', 'jpeg'].includes(fileExtension) && fileSize <= maxSizeInBytes;
    });

    setImages((prevImages) => [...prevImages, ...updatedImages]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  useEffect(() => {
    if (uploadedImages?.length === 5) {
      setImageError('Remove old images to upload new one, you can upload maximum 5 files');
    }
    if (images?.length === 0) {
      setImageError('');
    }
    if (uploadedImages?.length + images?.length > 5) {
      setImageError('You can upload maximum 5 files, either remove old images or upload required images');
    } else {
      setImageError('');
    }
  }, [images, uploadedImages]);

  const handleSubmit = async () => {
    setSubmitLoader(true);
    setOpen(true);
    try {
      const formData = new FormData();
      images.forEach((image, index) => {
        formData.append(`business-images`, image);
      });

      await axiosInstance.post('vendor/upload-business-images', formData).then((res) => {
        setSubmitLoader(false);
        setIsShow(true);
        setError(false);
        setErrorMessage(res.data.message);

        dispatch({
          type: VENDOR_DETAIL,
          payload: 0,
        });
        setImages([]);
        fetchImages();
        setOpen(false);
      });
    } catch (error) {
      setSubmitLoader(false);
      setOpen(false);
      setIsShow(true);
      setError(true);
      setErrorMessage(error.message);
    }
  };

  const fetchImages = () => {
    const params = { uuid: loggedUseruuid };
    try {
      axiosInstance.get('vendor/business-images', { params }).then((res) => {
        setuploadedImages(res?.data?.data);
      });
    } catch (error) {
      // console.error('API Error:', error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleRemoveImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };
  const handleSingleRemove = (id) => {
    axiosInstance
      .delete('vendor/delete-image', {
        params: {
          id: String(id),
        },
      })
      .then((res) => {
        setIsShow(true);
        setError(false);
        setErrorMessage(res.data.message);
        fetchImages();
      })
      .catch((error) => {
        setIsShow(true);
        setError(true);
        setErrorMessage(error.message);
      });
  };

  const [openDialog, setOpenDialog] = useState(false);

  const isSubmitAllowed =
    images?.length > 0 && uploadedImages?.length < 5 && uploadedImages?.length + images?.length <= 5;
  return (
    <>
      <Page title="User">
        <Container>
          <BusinesImages docs={uploadedImages} isOpen={openDialog} handleClose={() => setOpenDialog(false)} />

          <Stack>
            <h3>Business Images</h3>
            <h5 style={{ fontWeight: 300 }}>Here we have list of your business images</h5>
            <p style={{ color: 'grey', fontSize: '15px' }}>Supported Format*: png, jpg, jpeg</p>
            <p style={{ color: 'grey', fontSize: '15px', marginTop: '-2px' }}>Size*: 10mb size</p>
            <div className="image-uploader">
              <div
                {...getRootProps()}
                className={`dropzone ${isDragActive ? 'active' : ''}`}
                style={{ background: '#d9d9d9' }}
              >
                <input {...getInputProps()} accept="image/*" multiple />
                {isDragActive ? (
                  <p className="drop-text">Drop the images here</p>
                ) : (
                  <p className="drop-text">Drag and drop images here or click to select files</p>
                )}
              </div>
              <span style={{ color: 'red' }}> {imageError}</span>
              <div className="image-preview">
                <h4>{images?.length > 0 ? <>Selected Images </> : <></>}</h4>
                <h4>
                  {uploadedImages?.length > 0 ? (
                    <>{images?.length > 0 && uploadedImages?.length > 0 ? <></> : <>Uploaded Images</>}</>
                  ) : (
                    <></>
                  )}
                </h4>
                <br />
                <div className="image-list">
                  {images?.length > 0 ? (
                    <>
                      {images?.map((file, index) => (
                        <div key={index} className="image-item" style={{ borderRadius: '10px', height: '152px' }}>
                          <CommanModal
                            name={`${
                              images?.length > 0 && uploadedImages?.length > 0 ? 'Selected Image' : 'Uploaded Images'
                            }`}
                            image={URL.createObjectURL(file)}
                            slug={'logo'}
                          />

                          <img
                            src={URL.createObjectURL(file)}
                            alt="img"
                            style={{ borderRadius: '10px', objectFit: 'cover', height: '150px', width: '160px' }}
                          />
                          <Box height={'10px'} />
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleRemoveImage(index)}
                            fullWidth
                            sx={{ borderColor: 'red' }}
                          >
                            Delete Image
                          </Button>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {uploadedImages?.map((file, index) => (
                        <>
                          <div key={index} className="image-item" style={{ borderRadius: '10px', height: '152px' }}>
                            <Button
                              onClick={() => {
                                setOpenDialog(true);
                              }}
                            >
                              <img
                                src={`${file?.image_path}`}
                                alt="img"
                                style={{
                                  borderRadius: '10px',
                                  objectFit: 'cover',
                                  width: '200px',
                                  height: '150px',
                                  cursor: 'pointer',
                                }}
                              />
                            </Button>

                            <Box height={'10px'} />
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleSingleRemove(file.id)}
                              fullWidth
                              sx={{ borderColor: 'red' }}
                            >
                              Delete Image
                            </Button>
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>

              {uploadedImages?.length > 0 || images?.length > 0 ? (
                <> </>
              ) : (
                <>
                  <img src={noImage} alt="noimage" height="80px" width="80px" />{' '}
                  <span style={{ paddingLeft: '10px', fontSize: '15px' }} className="drop-text">
                    No Business Images
                  </span>
                </>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'right', mt: 5, pb: 2 }}>
                {isSubmitAllowed && (
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained"
                    loading={submitLoader && true}
                    sx={{
                      '&:hover': {
                        bgcolor: 'green',
                      },
                    }}
                  >
                    Upload Image
                  </LoadingButton>
                )}
              </Box>
            </div>

            <ShowMessage
              onClose={() => setIsShow(false)}
              title={errorMessage}
              type={error ? 'error' : 'success'}
              open={isShow}
            />
          </Stack>
        </Container>
      </Page>

      <Fullloader open={open} />
    </>
  );
};

export default Gumasta;
