import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Typography, Stack, Box, Divider, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import moment from 'moment';
import axiosInstance from '../api/withJWTServer';

const Notifications = () => {
  const [isloading, setIsloading] = useState(false);
  const [notifications, setNotification] = useState([]);

  const columns = [
    {
      field: 'id',
      headerName: 'Sr no',
      width: 100,
      flex: 0.3,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: 'notification_heading',
      headerName: 'Title',
      width: 250,
      type: 'string',
      flex: 0.6,
    },
    {
      field: 'notification_content',
      headerName: 'Description',
      width: 250,
      type: 'string',
      flex: 1.4,

      renderCell: (params) => (
        <Typography fontWeight={'400'} fontSize={'14px'} sx={{ padding: '10px' }}>
          {params.row.notification_content}
        </Typography>
      ),
    },
    {
      field: `createdAt`,
      headerName: 'Created On',
      width: 150,
      type: 'string',
      flex: 0.5,

      renderCell: (params) => moment(params?.row?.createdAt).format('DD/MM/YYYY hh:MM A'),
    },
  ];
  const rows = [];

  const getNotifications = async () => {
    setIsloading(true);
    axiosInstance
      .get('vendor/notifications')
      .then((res) => {
        setIsloading(false);

        const reverseNotes = res.data.data.reverse();

        setNotification(reverseNotes);
      })
      .catch((err) => {
        setIsloading(false);
        setNotification([]);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <Box px={'10px'}>
        <Stack>
          <Typography fontWeight={'600'} fontSize={'18px'}>
            Notifications
          </Typography>
          <Typography fontSize={'14px'}>It is showing list of notifitions</Typography>
        </Stack>
        <Card sx={{ marginTop: '25px' }}>
          <Grid container>
            <Grid item lg={12} xs={12} sm={12}>
              <DataGrid
                disableColumnMenu
                style={{
                  padding: '10px',
                  height: 500,
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '14px',
                }}
                getRowHeight={() => 'auto'}
                loading={isloading}
                rows={notifications ?? rows}
                columns={columns}
                localeText={{ noRowsLabel: 'No Notifications' }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        bgcolor: '#ffffff',
                        // borderRadius: '8px',
                        // border: '1px solid rgba(0,0,0,0.1)',
                        pb: '0px',
                        '& fieldset': { border: 'none' },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default Notifications;
