import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useField } from 'formik';
import { at } from 'lodash';
import { MobileTimePicker } from '@mui/lab';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import './styles.css';

export default function InputTime(props) {
  const { profileData } = useSelector((state) => ({
    profileData: state?.profileData,
  }));
  const [error, setError] = useState(null);
  const { errorText, ...rest } = useMemo(() => props, [props]);
  const [field, meta, helpers] = useField(props);
  const { value } = field;
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (error) {
      return error;
    }
  }

  const today = new Date();

  // Set the time to 10:00 AM
  today.setHours(10, 0, 0, 0);

  // Update the original date with today's date and the new time
  today.setFullYear(today.getFullYear());
  today.setMonth(today.getMonth());
  today.setDate(today.getDate());
  today.setHours(today.getHours());
  today.setMinutes(today.getMinutes());
  today.setSeconds(today.getSeconds());
  function convertTimeToDateString(timeString) {
    const todayNew = new Date();
    const [time, ampm] = timeString.split(' ');
  
    // Parse the hour and minute
    const [hourString, minuteString] = time.split(':');
    let hour = parseInt(hourString, 10);
    const minute = parseInt(minuteString, 10);
  
    // Convert to 24-hour format if needed
    if (ampm === 'PM' && hour !== 12) {
      hour += 12;
    } else if (ampm === 'AM' && hour === 12) {
      hour = 0;
    }
  
    // Set the time on the current date
    todayNew.setHours(hour, minute, 0, 0);
  
    // Format the date string using template literals
    const formattedDateString = `${todayNew.toDateString()} ${todayNew.toTimeString()}`;
  
    return formattedDateString;
  }
  
  // Example usage:
  const timeString = profileData?.buinessTimingsModels?.[0]?.start_timing;
  const formattedDateString = timeString?convertTimeToDateString(timeString):today;
  useEffect(() => {
    helpers.setValue(formattedDateString || today);
  }, []);

  const handleTimeChange = (newTime) => {
    helpers.setValue(newTime);
  };
  
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DemoItem label="">
          <MobileTimePicker
            label="Select Time"
            value={value || today}
            onChange={handleTimeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{ readOnly: true, style: { padding: '7px' } }} // Set readOnly to true to disable text input
              />
            )}
            views={['hours', 'minutes']}
            style={{
              '@media (min-width: 600px)': {
                '.MuiMobileTimePickerToolbar-icon': {
                  visibility: 'hidden',
                },
              },
            }}
            ampm={1}
          />
        </DemoItem>
      </LocalizationProvider>
      <br />
      <span style={{ color: 'red', fontSize: '12px' }}>{error}</span>
    </>
  );
}
