import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import { Box, Drawer } from '@mui/material';
// mock
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
import loginIcon from '../../Assets/sidebarLogo.png';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

const ImageStyle = styled('div')(({ theme }) => ({
  width: '80%',
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  margin: '10px auto',
  alignItems: 'center',
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ paddingBottom: 3, paddingTop: 3, display: 'inline-flex' }}>
        <ImageStyle>
          <img src={loginIcon} alt="login" style={{ marginLeft: '-100px' }} width={'180px'} />
        </ImageStyle>
      </Box>
      <NavSection navConfig={navConfig} />
    </Scrollbar>
  );
  const useStyles = makeStyles((theme) => ({
    sideBar: {
      '@media print': {
        /* Add your print-specific styles here */
        display: 'none',
      },
    },
  }));
  const classes = useStyles();
  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, background: '#0B8238' },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          className={`${classes.sideBar}`}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              backgroundColor: '#0B8238 !important',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
