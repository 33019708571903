import React, { useMemo } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';

export default function InputMobileField(props) {
  const { errorText, ...rest } = useMemo(() => props, [props]);
  const [field, meta, helpers] = useField(props);
  const { value } = field;

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }
  
  return (
    <TextField
      type="text"
      error={ meta.error && meta.touched}
      helperText={_renderHelperText()}
      value={value}
      inputProps={{ maxLength: 10 }} 
      {...field}
      {...rest}
    />
  );
}
