import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Grid, Container, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { fetchProfile } from '../../../../api/CommanApicall';
import { HandleExceptionWithSecureCatch } from '../../../../actions/CombineCatch';
import Iconify from '../../../../components/Iconify';
import axiosInstance from '../../../../api/withJWTServer';
import { PROFILE_DATA } from '../../../../actions/Types';
import noImage from '../../../../Assets/no_image.png';
import noPdf from '../../../../Assets/noPdf.png';

export default function UploadDocuments(props) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [gstImage, setGstImage] = useState(null);
  const [aadharFrontImage, setAadharFrontImage] = useState(null);
  const [aadharBackImage, setAadharBackImage] = useState(null);
  const [panImage, setPanImage] = useState(null);
  const [openWarning, SetOpenWarning] = useState(false);

  const handleWarningCancel = () => {
    SetOpenWarning(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const styles = {
    container: {
      display: 'flex',
      padding: 20,
    },
    line: {
      width: '50%',
      margin: 'auto',
      border: '1px solid #E4E4E4',
    },
    orText: {
      color: '#7E7D7D',
      fontSize: '16px',
      fontWeight: 600,
      padding: '0 10px',
    },
  };

  const { profileData } = useSelector((state) => ({
    profileData: state?.profileData,
  }));
  useEffect(() => {
    console.log('isPdfUrlfunction(gstImage)', isPdfUrlfunction(gstImage), gstImage);
  }, [gstImage]);
  const fileInputRef = React.createRef();
  const fileInputgstRef = React.createRef();
  const fileInputaadharFrontRef = React.createRef();
  const fileInputaadharBackRef = React.createRef();
  const fileInputPanRef = React.createRef();
  const dispatch = useDispatch();

  const {
    formField: { uploadGstFile, uploadGumastaFile },
  } = props;

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file size exceeds 10 MB
      if (file.size > 10 * 1024 * 1024) {
        // Handle error (file size exceeds the limit)
        console.error('File size exceeds 10 MB limit');
        return;
      }

      // Read the selected file as a data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the Selected Docs. in the state
        if (type === 'gst_registration_doc') {
          setGstImage(reader.result);
        } else if (type === 'gumasta_doc') {
          setSelectedImage(reader.result);
        } else if (type === 'aadhar_card_front') {
          setAadharFrontImage(reader.result);
        } else if (type === 'aadhar_card_back') {
          setAadharBackImage(reader.result);
        } else if (type === 'pan_card') {
          console.log('Pan File ');
          setPanImage(reader.result);
        }
      };

      reader.readAsDataURL(file);
    }

    // Pass the file to the parent component for further processing
    props.handleDocsFile(file, type);
  };

  const handleClearImage = (type) => {
    if (type === 'gst_registration_doc') {
      setGstImage(null);
    } else if (type === 'gumasta_doc') {
      setSelectedImage(null);
    } else if (type === 'aadhar_card_front') {
      setAadharFrontImage(null);
    } else if (type === 'aadhar_card_back') {
      setAadharBackImage(null);
    } else if (type === 'pan_card') {
      setPanImage(null);
    }
    props.handleDocsFile(null, type);
  };

  const isPdfUrlfunction = (param) => param?.includes(':application/pdf');
  const gstDocsPath = profileData?.vendorBusinessDocs?.gst_registration_doc?.doc_path;
  const isPdfUrl = /\.pdf$/i.test(gstDocsPath);
  const gumastaDocsPath = profileData?.vendorBusinessDocs?.gumasta_doc?.doc_path;
  const gumastaPdf = /\.pdf$/i.test(gumastaDocsPath);

  const aadharFrontpath = profileData?.vendorBusinessDocs?.aadhar_card_front?.doc_path;
  const aadharFrontpdf = /\.pdf$/i.test(aadharFrontpath);

  const aadharBackpath = profileData?.vendorBusinessDocs?.aadhar_card_back?.doc_path;
  const aadharBackpdf = /\.pdf$/i.test(aadharBackpath);

  const panPath = profileData?.vendorBusinessDocs?.pan_card?.doc_path;
  const panPdf = /\.pdf$/i.test(panPath);

  const deleteDocs = (e) => {
    if (e) {
      axiosInstance
        .delete('vendor/delete-doc', {
          params: {
            id: String(e),
          },
        })
        .then((res) => {
          async function profile() {
            try {
              const profileDetail = await fetchProfile();
              dispatch({
                type: PROFILE_DATA,
                payload: profileDetail?.data?.data[0] === undefined ? null : profileDetail?.data?.data[0],
              });
            } catch (error) {
              console.error(error);
            }
          }
          profile();
          dispatch(HandleExceptionWithSecureCatch(res));
        })
        .catch((err) => {
          dispatch(HandleExceptionWithSecureCatch(err));
        });
    }
  };
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleGstImageClick = () => {
    if (fileInputgstRef.current) {
      fileInputgstRef.current.click();
    }
  };
  const handleImageaadharFrontClick = () => {
    if (fileInputaadharFrontRef.current) {
      fileInputaadharFrontRef.current.click();
    }
  };
  const handleImageaadharBackClick = () => {
    if (aadharFrontImage === null) {
      // alert("Please select aadhar front ")
      SetOpenWarning(true);
    } else if (fileInputaadharBackRef.current) {
      fileInputaadharBackRef.current.click();
    }
  };
  const handlePanImageClick = () => {
    if (fileInputPanRef.current) {
      fileInputPanRef.current.click();
    }
  };
  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Container>
            <Typography variant="h6" gutterBottom>
              Upload Documents
            </Typography>
            <span style={{ color: '#afb4b8' }}>
              <p>Supported Format*: png, jpg, jpeg, pdf</p>
              <p>Size*: Maximum 10mb</p>
              <p>Note* : Only one document is mandatory</p>
            </span>
            {/* <span style={{ color: 'red', fontSize: '15px' }}>{props?.atlistOneDoc}</span> */}
            <Grid container spacing={3} sx={{ mt: '10px' }}>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <h5>GST File</h5>
                {!gstImage && (
                  <div
                    role="button"
                    tabIndex="0"
                    style={{
                      display: 'flex',
                      color: '#333',
                      cursor: 'pointer',
                    }}
                    onClick={handleGstImageClick}
                    onKeyPress={(e) => e.key === 'Enter' && handleGstImageClick()} // Trigger on Enter key press
                  >
                    <img src={noImage} alt="Selected" style={{ maxWidth: '25%', maxheight: '10%' }} />
                    <p style={{ paddingTop: '40px' }}>
                      {!gstDocsPath ? 'Please upload your GST Docs.' : null} Tap to Select
                    </p>
                  </div>
                )}

                <input
                  name={uploadGstFile.name}
                  type="file"
                  style={{ display: 'none' }}
                  accept=".pdf,image/*"
                  onChange={(e) => handleFileChange(e, 'gst_registration_doc')}
                  disabled={profileData?.status === 'Active' ? 1 : 0}
                  ref={fileInputgstRef}
                />
                {gstImage && (
                  <div>
                    <p>Selected Docs.:</p>
                    <div>
                      <div style={{ position: 'relative' }}>
                        <Iconify
                          icon="eva:minus-outline"
                          sx={{
                            position: 'absolute',
                            height: '25px',
                            width: '25px',
                            color: '#fff',
                            background: 'red',
                            borderRadius: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => handleClearImage('gst_registration_doc')}
                        />
                        <img
                          src={gstImage.includes('/pdf') ? noPdf : gstImage}
                          alt="Selected"
                          style={{ maxWidth: '25%', height: '25%' }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <br />
                <span style={{ color: 'red', fontSize: '15px' }}>{props?.gstFileError}</span>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ float: 'right' }}>
                  {gstDocsPath && <p>Uploaded Docs:</p>}
                  {gstDocsPath && (
                    <>
                      {isPdfUrl ? (
                        <>
                          {profileData?.status !== 'Active' && (
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                height: '25px',
                                width: '25px',
                                color: '#fff',
                                background: 'red',
                                borderRadius: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.gst_registration_doc?.id)}
                            />
                          )}
                          <img src={noPdf} alt="Selected" style={{ maxWidth: '25%', maxheight: '25%' }} />
                          <Box sx={{ marginTop: '20px' }}>
                            <span
                              style={{
                                padding: '7px 15px',
                                background: '#f9f9f9',
                                border: '1px solid #02742d',
                                borderRadius: '15px',
                              }}
                            >
                              <a
                                href={`${gstDocsPath}`}
                                rel="noreferrer"
                                style={{ textDecoration: 'none', color: '#02742d', marginTop: '10px' }}
                              >
                                Download Document
                              </a>
                            </span>
                          </Box>
                        </>
                      ) : (
                        <>
                          {profileData?.status !== 'Active' && (
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                height: '25px',
                                width: '25px',
                                color: '#fff',
                                background: 'red',
                                borderRadius: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.gst_registration_doc?.id)}
                            />
                          )}

                          <img src={`${gstDocsPath}`} alt="company logo" style={{ height: '100px', width: '100px' }} />
                        </>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
            <div style={styles.container}>
              <hr style={styles.line} /><span style={styles.orText}>OR</span><hr style={styles.line} />
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <h5>Gumasta File</h5>
                {!selectedImage && (
                  <div
                    role="button"
                    tabIndex="0"
                    style={{
                      display: 'flex',
                      color: '#333',
                      cursor: 'pointer',
                    }}
                    onClick={handleImageClick}
                    onKeyPress={(e) => e.key === 'Enter' && handleImageClick()} // Trigger on Enter key press
                  >
                    <img src={noImage} alt="Selected" style={{ maxWidth: '25%', maxheight: '10%' }} />
                    <p style={{ paddingTop: '40px' }}>
                      {!gumastaDocsPath ? 'Please upload your Gumasta Docs.' : null} Tap to Select
                    </p>
                  </div>
                )}
                <input
                  name={uploadGumastaFile.name}
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, 'gumasta_doc')}
                  disabled={profileData?.status === 'Active' ? 1 : 0}
                  ref={fileInputRef}
                />
                {selectedImage && (
                  <div>
                    <p>Selected Docs.:</p>
                    <div>
                      <div style={{ position: 'relative' }}>
                        <Iconify
                          icon="eva:minus-outline"
                          sx={{
                            position: 'absolute',
                            height: '25px',
                            width: '25px',
                            color: '#fff',
                            background: 'red',
                            borderRadius: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => handleClearImage('gumasta_doc')}
                        />
                        <img
                          src={selectedImage.includes('/pdf') ? noPdf : selectedImage}
                          alt="Selected"
                          style={{ maxWidth: '25%', height: '25%' }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <br />
                <span style={{ color: 'red', fontSize: '15px' }}>{props.gumastaFileError}</span>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ float: 'right' }}>
                  {gumastaDocsPath && <p>Uploaded Docs:</p>}
                  {gumastaDocsPath && (
                    <>
                      {gumastaPdf ? (
                        <>
                          {profileData?.status !== 'Active' && (
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                height: '25px',
                                width: '25px',
                                color: '#fff',
                                background: 'red',
                                borderRadius: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.gumasta_doc?.id)}
                            />
                          )}

                          <img src={noPdf} alt="Selected" style={{ maxWidth: '25%', maxheight: '25%' }} />
                          <Box sx={{ marginTop: '20px' }}>
                            <span
                              style={{
                                padding: '7px 15px',
                                background: '#f9f9f9',
                                border: '1px solid #02742d',
                                borderRadius: '15px',
                                marginTop: '10px',
                              }}
                            >
                              <a
                                href={`${gumastaDocsPath}`}
                                rel="noreferrer"
                                style={{ textDecoration: 'none', color: '#02742d' }}
                              >
                                Download Document
                              </a>
                            </span>
                          </Box>
                        </>
                      ) : (
                        <>
                          {profileData?.status !== 'Active' && (
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                height: '25px',
                                width: '25px',
                                color: '#fff',
                                background: 'red',
                                borderRadius: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.gumasta_doc?.id)}
                            />
                          )}

                          <img
                            src={`${gumastaDocsPath}`}
                            alt="company logo"
                            style={{ height: '100px', width: '100px' }}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <>
                  <span style={{ color: 'red', fontSize: '15px' }}>{props.gstDocsgumastaError}</span>
                </>
              </Grid>
            </Grid>
            <div style={styles.container}>
              <hr style={styles.line} /><span style={styles.orText}>OR</span><hr style={styles.line} />
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <h5>Aadhar Card Front</h5>
                {!aadharFrontImage && (
                  <div
                    role="button"
                    tabIndex="0"
                    style={{
                      display: 'flex',
                      color: '#333',
                      cursor: 'pointer',
                    }}
                    onClick={handleImageaadharFrontClick}
                    onKeyPress={(e) => e.key === 'Enter' && handleImageaadharFrontClick()} // Trigger on Enter key press
                  >
                    <img src={noImage} alt="Selected" style={{ maxWidth: '25%', maxheight: '10%' }} />
                    <p style={{ paddingTop: '40px' }}>
                      {!aadharFrontpath ? 'Please upload your Aadhar Front.' : null} Tap to Select
                    </p>
                  </div>
                )}
                <input
                  name={uploadGumastaFile.name}
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, 'aadhar_card_front')}
                  disabled={profileData?.status === 'Active' ? 1 : 0}
                  ref={fileInputaadharFrontRef}
                />
                {aadharFrontImage && (
                  <div>
                    <p>Selected Docs.:</p>
                    <div>
                      <div style={{ position: 'relative' }}>
                        <Iconify
                          icon="eva:minus-outline"
                          sx={{
                            position: 'absolute',
                            height: '25px',
                            width: '25px',
                            color: '#fff',
                            background: 'red',
                            borderRadius: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => handleClearImage('aadhar_card_front')}
                        />
                        <img
                          src={aadharFrontImage.includes('/pdf') ? noPdf : aadharFrontImage}
                          alt="Selected"
                          style={{ maxWidth: '25%', height: '25%' }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <br />
                <span style={{ color: 'red', fontSize: '15px' }}>{props?.aadharFrontError}</span>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ float: 'right' }}>
                  {aadharFrontpath && <p>Uploaded Docs:</p>}
                  {aadharFrontpath && (
                    <>
                      {aadharFrontpdf ? (
                        <>
                          {profileData?.status !== 'Active' && (
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                height: '25px',
                                width: '25px',
                                color: '#fff',
                                background: 'red',
                                borderRadius: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.aadhar_card_front?.id)}
                            />
                          )}

                          <img src={noPdf} alt="Selected" style={{ maxWidth: '25%', maxheight: '25%' }} />
                          <Box sx={{ marginTop: '20px' }}>
                            <span
                              style={{
                                padding: '7px 15px',
                                background: '#f9f9f9',
                                border: '1px solid #02742d',
                                borderRadius: '15px',
                                marginTop: '10px',
                              }}
                            >
                              <a
                                href={`${aadharFrontpath}`}
                                rel="noreferrer"
                                style={{ textDecoration: 'none', color: '#02742d' }}
                              >
                                Download Document
                              </a>
                            </span>
                          </Box>
                        </>
                      ) : (
                        <>
                          {profileData?.status !== 'Active' && (
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                height: '25px',
                                width: '25px',
                                color: '#fff',
                                background: 'red',
                                borderRadius: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.aadhar_card_front?.id)}
                            />
                          )}
                          <img
                            src={`${aadharFrontpath}`}
                            alt="company logo"
                            style={{ height: '100px', width: '100px' }}
                          />{' '}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h5>Aadhar Card Back</h5>
                {!aadharBackImage && (
                  <div
                    role="button"
                    tabIndex="0"
                    style={{
                      display: 'flex',
                      color: '#333',
                      cursor: 'pointer',
                    }}
                    onClick={handleImageaadharBackClick}
                    onKeyPress={(e) => e.key === 'Enter' && handleImageaadharBackClick()} // Trigger on Enter key press
                  >
                    <img src={noImage} alt="Selected" style={{ maxWidth: '25%', maxheight: '10%' }} />
                    <p style={{ paddingTop: '40px' }}>
                      {!aadharBackpath ? 'Please upload your Aadhar Back.' : null} Tap to Select
                    </p>
                  </div>
                )}
                <input
                  name={uploadGumastaFile.name}
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, 'aadhar_card_back')}
                  disabled={profileData?.status === 'Active' ? 1 : 0}
                  ref={fileInputaadharBackRef}
                />
                {aadharBackImage && (
                  <div>
                    <p>Selected Docs.:</p>
                    <div>
                      <div style={{ position: 'relative' }}>
                        <Iconify
                          icon="eva:minus-outline"
                          sx={{
                            position: 'absolute',
                            height: '25px',
                            width: '25px',
                            color: '#fff',
                            background: 'red',
                            borderRadius: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => handleClearImage('aadhar_card_back')}
                        />
                        <img
                          src={aadharBackImage.includes('/pdf') ? noPdf : aadharBackImage}
                          alt="Selected"
                          style={{ maxWidth: '25%', height: '25%' }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <br />
                <span style={{ color: 'red', fontSize: '15px' }}>{props?.aadharBackError}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ float: 'right' }}>
                  {aadharBackpath && <p>Uploaded Docs</p>}
                  {aadharBackpath && (
                    <>
                      {aadharBackpdf ? (
                        <>
                          {profileData?.status !== 'Active' && (
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                height: '25px',
                                width: '25px',
                                color: '#fff',
                                background: 'red',
                                borderRadius: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.aadhar_card_back?.id)}
                            />
                          )}

                          <img src={noPdf} alt="Selected" style={{ maxWidth: '25%', maxheight: '25%' }} />
                          <Box sx={{ marginTop: '20px' }}>
                            <span
                              style={{
                                padding: '7px 15px',
                                background: '#f9f9f9',
                                border: '1px solid #02742d',
                                borderRadius: '15px',
                                marginTop: '10px',
                              }}
                            >
                              <a
                                href={`${aadharBackpath}`}
                                rel="noreferrer"
                                style={{ textDecoration: 'none', color: '#02742d' }}
                              >
                                Download Document
                              </a>
                            </span>
                          </Box>
                        </>
                      ) : (
                        <>
                          {profileData?.status !== 'Active' && (
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                height: '25px',
                                width: '25px',
                                color: '#fff',
                                background: 'red',
                                borderRadius: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.aadhar_card_back?.id)}
                            />
                          )}

                          <img
                            src={`${aadharBackpath}`}
                            alt="company logo"
                            style={{ height: '100px', width: '100px' }}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
            <div style={styles.container}>
              <hr style={styles.line} /><span style={styles.orText}>OR</span><hr style={styles.line} />
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <h5>PAN Card</h5>
                {!panImage && (
                  <div
                    role="button"
                    tabIndex="0"
                    style={{
                      display: 'flex',
                      color: '#333',
                      cursor: 'pointer',
                    }}
                    onClick={handlePanImageClick}
                    onKeyPress={(e) => e.key === 'Enter' && handlePanImageClick()} // Trigger on Enter key press
                  >
                    <img src={noImage} alt="Selected" style={{ maxWidth: '25%', maxheight: '10%' }} />
                    <p style={{ paddingTop: '40px' }}>
                      {!panPath ? 'Please upload your PAN Docs.' : null} Tap to Select
                    </p>
                  </div>
                )}
                <input
                  name={uploadGstFile.name}
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, 'pan_card')}
                  disabled={profileData?.status === 'Active' ? 1 : 0}
                  ref={fileInputPanRef}
                />
                {panImage && (
                  <div>
                    <p>Selected Docs.:</p>
                    <div>
                      <div style={{ position: 'relative' }}>
                        <Iconify
                          icon="eva:minus-outline"
                          sx={{
                            position: 'absolute',
                            left:80,
                            height: '25px',
                            width: '25px',
                            color: '#fff',
                            background: 'red',
                            borderRadius: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => handleClearImage('pan_card')}
                        />
                        <img
                          src={panImage.includes('/pdf') ? noPdf : panImage}
                          alt="Selected"
                          style={{ maxWidth: '25%', height: '25%' }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <br />
                <span style={{ color: 'red', fontSize: '15px' }}>{props?.panError}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
              <div style={{ float: 'right'}}>
                  {panPath && <p>Uploaded Docs:</p>}
                  {panPath && (
                    <>
                      {panPdf ? (
                        <>
                          {profileData?.status !== 'Active' && (
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                height: '25px',
                                width: '25px',
                                color: '#fff',
                                background: 'red',
                                borderRadius: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.pan_card?.id)}
                            />
                          )}

                          <img src={noPdf} alt="Selected" style={{ maxWidth: '25%', maxheight: '25%' }} />
                          <Box sx={{ marginTop: '20px' }}>
                            <span
                              style={{
                                padding: '7px 15px',
                                background: '#f9f9f9',
                                border: '1px solid #02742d',
                                borderRadius: '15px',
                                marginTop: '10px',
                              }}
                            >
                              <a
                                href={`${panPath}`}
                                rel="noreferrer"
                                style={{ textDecoration: 'none', color: '#02742d' }}
                              >
                                Download Document
                              </a>
                            </span>
                          </Box>
                        </>
                      ) : (
                        <div>
                          {profileData?.status !== 'Active' && (
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                height: '25px',
                                width: '25px',
                                color: '#fff',
                                background: 'red',
                                borderRadius: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.pan_card?.id)}
                            />
                          )}

                          <img src={`${panPath}`} alt="company logo" style={{ height: '100px', width: '100px' }} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <>
                  <span style={{ color: 'red', fontSize: '15px' }}>{props?.aadharPanError}</span>
                </>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Modal
          open={openWarning}
          onClose={handleWarningCancel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography variant="h6" style={{ color: 'black', fontSize: '18px' }}>
              Please Select Aadhar Card Front Side.
            </Typography>

            <br />

            <Button
              size="small"
              type="submit"
              onClick={handleWarningCancel}
              variant="contained"
              style={{ float: 'right', margin: 10 }}
            >
              Ok
            </Button>
          </Box>
        </Modal>
      </Container>
    </>
  );
}
