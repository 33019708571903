import React, { useEffect, useState } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import MyLocationIcon from '@material-ui/icons/MyLocation'; // Import the auto-detect icon
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';

export default function InputLocationField(props) {
  const { status } = useSelector((state) => ({
    status: state?.profileData,
  }));
  const { errorText, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  const [open, setOpen] = useState(false);
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (error && touched) {
      return error;
    }
  }

  const handleOpen = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.currentAddress) {
      helpers.setValue(props.currentAddress);
    }
  }, [props.currentAddress]);

  const autoselectValue = (selectedLocation) => {
    const cityStateCountryPattern = /,\s([^,]+?),\s([^,]+?),\s([^,]+)$/;

    const streetAddress2 = selectedLocation ? selectedLocation.replace(cityStateCountryPattern, '').trim() : '';
    props.splitAddress(selectedLocation);

    if (selectedLocation) {
      if (streetAddress2) {
        helpers.setValue(streetAddress2);
      }
    }
  };

  return (
    <>
      <TextField
        type="text"
        error={meta.error && meta.touched}
        variant="standard"
        helperText={_renderHelperText()}
        {...field}
        {...rest}
        disabled={status?.status === 'Active' ? 1 : 0}
      />
      {/* <AutoCompleteLocation open={open} handleOpen={handleOpen} autoselectValue={autoselectValue} /> */}
    </>
  );
}
