import { SetNewAlert } from './AlertActions';

const HandleExceptionWithSecureCatch = (exception, slug) => (dispatch) => {
    const message = exception?.data?.message;
    const statusCode = exception?.data?.status;

    if (statusCode === 401 && window.location.pathname === '/login') {
      dispatch(
        SetNewAlert({
          msg: 'Session Expired',
          alertType: 'danger',
        })
      );
    } else {
      let alertMsg;
      let alertType;

      if (slug === 'deleted') {
        alertType = 'danger';
        alertMsg = message;
      } else {
        if (slug) {
          alertMsg = 'Stepper successfully completed';
        } else {
          alertMsg = message;
        }

        if (slug) {
          alertType = 'success';
        } else {
          alertType = exception?.data?.status === 200 ? 'success' : 'danger';
        }
      }

      dispatch(
        SetNewAlert({
          msg: alertMsg,
          alertType,
        })
        // SetNewAlert({
        //     msg: exception === undefined? 'No internet connection': slug? 'Stepper sucessfully completed': message,
        //     alertType: slug? 'success':`${exception?.data?.status === 200 ? 'success': 'danger'}`
        // })
      );
    }
  };

export { HandleExceptionWithSecureCatch };
