import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Typography,
  Stack,
  Box,
  Divider,
  Grid,
  TextField,
  Autocomplete,
  IconButton,
  CircularProgress,
  Card,
} from '@mui/material';
import { Form, Formik, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import VerifiedIcon from '@mui/icons-material/Verified';
import { LoadingButton } from '@mui/lab';
import { PROFILE_DATA } from '../../actions/Types';
import nobusinessLogo from '../../Assets/nobusinessLogo.png';
import { fetchProfile } from '../../api/CommanApicall';
import axiosInstance from '../../api/withJWTServer';
import ShowMessage from '../Modal/ShowMessage';

const PersonalDetails = (props) => {
  const { profile, vendoreProfileId } = useSelector((state) => ({
    profile: state?.profileData?.vendorBusinessDocs?.vendor_profile?.doc_path,
    vendoreProfileId: state?.profileData?.vendorBusinessDocs?.vendor_profile?.id,
  }));
  const dispatch = useDispatch();
  const [profileDetail, setProfileDetail] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);

  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const showToastMessage = (message) => {
    setIsShow(true);
    setError(false);
    setErrorMessage(message);
    setTimeout(() => {
      setIsShow(false);
    }, 1500);
  };

  const fetchData = () => {
    try {
      axiosInstance.get('vendor/profile').then((res) => {
        setProfileDetail(res?.data?.data[0]);
        const profileInfo = res.data.data[0];
        console.log('profileInfo', profileInfo);

        /// get business logo

        let companyLogo = '';

        let companyLogoID = '';
        const docKeys = Object.keys(profileInfo.vendorBusinessDocs);
        console.log('Keys', docKeys);
        if (profileInfo.vendorBusinessDocs) {
          if (docKeys.includes('vendor_profile')) {
            const companyLogoObject = profileInfo.vendorBusinessDocs.vendor_profile;
            companyLogo = companyLogoObject.doc_path;
            companyLogoID = companyLogoObject.id;
            console.log('LOGO', companyLogoObject);
          }
        }

        formik.setValues({
          firstname: profileInfo ? profileInfo.firstname : '',
          email: profileInfo ? profileInfo.email : '',
          mobile_number: profileInfo ? profileInfo.mobile_number : '',
          image_path_id: companyLogoID,
          image_path: companyLogo,
        });

        dispatch({
          type: PROFILE_DATA,
          payload: res?.data?.data[0] === undefined ? null : res.data?.data[0],
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('Full name is required'),
    email: Yup.string().email('Invalid email address'),
    mobile_number: Yup.string()
      .required('Phone Number is required')
      .test(
        'is-10-digits',
        'Phone number must be valid 10 digits',
        (value) => /^[1-9]{1}\d{9}$/.test(value) && !/^[0]{2,}/.test(value)
      ),
  });
  const formik = useFormik({
    initialValues: {
      firstname: profileDetail.firstname || '',
      email: profileDetail.email || '',
      mobile_number: profileDetail.mobile_number || '',
      image_path_id: '',
      image_path: '',
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setSubmitLoader(true);
      const params = {
        firstname: values.firstname,
        email: values.email,
        mobile_number: values.mobile_number,
      };
      try {
        axiosInstance.patch('vendor/register', params).then((response) => {
          fetchData();
          async function profile() {
            try {
              const profileDetail = await fetchProfile();
              dispatch({
                type: PROFILE_DATA,
                payload: profileDetail?.data?.data[0] === undefined ? null : profileDetail?.data?.data[0],
              });
            } catch (error) {
              console.error(error);
            }
          }
          profile();
          setSubmitLoader(false);
          if (response?.data?.status === 200) {
            showToastMessage(response.data.message ?? '');
          }
        });
      } catch (error) {
        setSubmitLoader(false);
      }
    },
  });
  const deleteDocsApi = async () => {
    setSubmitLoader(true);
    await axiosInstance
      .delete('vendor/delete-doc', {
        params: {
          id: String(formik.values.image_path_id),
        },
      })
      .then(async (res) => {
        if (res?.data?.status === 200) {
          showToastMessage(res?.data?.message ?? '');
        }
        setSubmitLoader(false);
        await fetchData();
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const uploadCompanyLogo = async (Profile) => {
    setSubmitLoader(true);
    const docs = new FormData();
    docs.append('business-doc', Profile);
    docs.append('file_category', 'vendor_profile');
    await axiosInstance
      .post('vendor/upload-business-docs', docs)
      .then((response) => {
        if (response.data.status === 200) {
          showToastMessage(response?.data?.message ?? '');
        }
        setSubmitLoader(false);
        fetchData();
      })
      .catch((error) => {
        // setCompanyLogo('Business logo is required');
      });
  };
  const openDoumentDialog = () => {
    const fileDialog = document.createElement('input');
    fileDialog.type = 'file';
    fileDialog.accept = '.png,.jpeg,.jpg';
    fileDialog.addEventListener('change', (e) => {
      console.log('file', e.target.files);
      // setSelectedFile(e.target.files[0]);
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        uploadCompanyLogo(file);
      }
    });
    fileDialog.click();
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setFieldError } = formik;
  return (
    <div>
      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      <Card sx={{ mt: '10px', paddingX: '30px', paddingY: '20px ' }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid lg={12} item mt={'5px'}>
                <Typography fontWeight={'500'} fontSize={'18px'}>
                  Profile Picture
                </Typography>

                <Typography fontWeight={'400'} fontSize={'14px'}>
                  Upload/ Update your profile picture
                </Typography>

                <img
                  src={formik.values.image_path ? formik.values.image_path : nobusinessLogo}
                  style={{ marginTop: '5px', borderRadius: '5px' }}
                  alt="businesslogo"
                  width={'140px'}
                  height={'120px'}
                />

                {submitLoader === false ? (
                  <Box>
                    <Button
                      variant="outlined"
                      sx={{ mt: '10px', padding: '5px 15px' }}
                      size="small"
                      onClick={openDoumentDialog}
                    >
                      Upload Profile Picture
                    </Button>
                    {formik.values.image_path && (
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ mt: '10px', ml: '10px', padding: '5px 15px' }}
                        size="small"
                        onClick={deleteDocsApi}
                      >
                        Remove Logo
                      </Button>
                    )}
                  </Box>
                ) : (
                  <CircularProgress sx={{ mt: '5px' }} />
                )}
              </Grid>

              <Grid item lg={12}>
                <Divider sx={{ my: '10px' }} />
              </Grid>

              <Grid lg={6} item>
                <TextField
                  id="firstname"
                  fullWidth
                  type="text"
                  name="firstname"
                  size="medium"
                  variant="outlined"
                  label="First Name*"
                  inputProps={{
                    style: {
                      fontSize: '16px',
                    },
                  }}
                  error={Boolean(touched.firstname && errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                  {...getFieldProps('firstname')}
                />
              </Grid>
              <Grid lg={6} item>
                <TextField
                  id="outlined-name-input"
                  fullWidth
                  type="text"
                  name="email"
                  size="medium"
                  variant="outlined"
                  label="Email*"
                  inputProps={{
                    style: {
                      fontSize: '16px',
                    },
                  }}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  {...getFieldProps('email')}
                />
              </Grid>
              <Grid lg={6} item>
                <TextField
                  id="mobile_number"
                  fullWidth
                  type="text"
                  name="mobile_number"
                  size="medium"
                  variant="outlined"
                  label="Contact Number*"
                  InputProps={{
                    style: {
                      fontSize: '16px',
                      backgroundColor: 'rgba(0,0,0,0.03)',
                    },

                    readOnly: true,
                    endAdornment: <VerifiedIcon sx={{ color: 'green' }} />,
                  }}
                  error={Boolean(touched.mobile_number && errors.mobile_number)}
                  helperText={touched.mobile_number && errors.mobile_number}
                  {...getFieldProps('mobile_number')}
                />
              </Grid>

              <Grid lg={12} item mt={'5px'}>
                <Stack>
                  <Divider sx={{ my: '20px' }} />
                  <LoadingButton
                    variant="contained"
                    size="medium"
                    type="submit"
                    loading={submitLoader}
                    disableElevation
                    sx={{
                      alignSelf: 'flex-end',
                      padding: '8px 40px',
                      textTransform: 'uppercase',
                      '&:hover': {
                        bgcolor: 'green',
                      },
                    }}
                  >
                    {props.isStepper ? 'Save & Next ' : 'Save'}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Card>
    </div>
  );
};

export default PersonalDetails;
