import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Stack,
  Grid,
  TextField,
  Container,
  Typography,
  InputAdornment,
  Divider,
  Chip,
  Button,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import DownloadingIcon from '@mui/icons-material/Downloading';
import Page from '../../components/Page';

import photo1 from '../../Assets/photo1.png';
import profile from '../../Assets/profile.png';
import { GetCandidateById } from '../../actions/CandidateAction';
import { fDate, fDateTime } from '../../utils/formatTime';
import { SetNewAlert } from '../../actions/AlertActions';

const ViewCandidate = () => {
  const formik = useFormik({});
  const dispatch = useDispatch();
  const { errors, touched, getFieldProps } = formik;

  const candidateFormData = useLocation();
  const candidateData = candidateFormData?.state?.candidateData;
  console.log('CANDIDATE DATA', candidateData);
  const { singleCandidateData } = useSelector((state) => ({
    singleCandidateData: state.candidates?.candidateDetails,
  }));

  console.log('SINGLE CANDIDATE DATA', singleCandidateData);

  useEffect(() => {
    dispatch(GetCandidateById(candidateData?.uuid));
  }, []);

  const columns = [
    { field: 'mobile_number', headerName: 'Phone' },
    { field: 'email', headerName: 'Email' },
    { field: 'date_of_birth', headerName: 'DOB' },
    { field: 'gender', headerName: 'Gender' },
  ];
  const rows = [
    {
      id: 1,
      mobile_number: singleCandidateData?.data?.mobile_number,
      email: singleCandidateData?.data?.email_address,
      gender: singleCandidateData?.data?.gender,
      date_of_birth: singleCandidateData?.data?.date_of_birth ? fDate(singleCandidateData?.data?.date_of_birth) : 'NA',
    },
  ];
  const columns1 = [{ field: 'address', headerName: 'Address' }];
  const rows1 = [
    {
      id: 1,
      address: singleCandidateData?.data?.address,
    },
  ];
  const columns2 = [
    { field: 'Work_Experience', headerName: 'Work Experience' },
    { field: 'State', headerName: 'State' },
    { field: 'Country', headerName: 'Country' },
    { field: 'Created_On', headerName: 'Created On' },
  ];
  const rows2 = [
    {
      id: 1,
      Work_Experience: singleCandidateData?.data?.work_experience,
      State: singleCandidateData?.data?.state,
      Country: 'India',
      Created_On: singleCandidateData?.data?.createdAt ? fDateTime(singleCandidateData?.data?.createdAt) : 'NA',
    },
  ];
  const styles = {
    languageContainer: {
      display: 'flex',
      gap: '20px',
      marginTop: 10,
    },
    language: {
      padding: '5px 10px',
      borderRadius: '15px',
      backgroundColor: '#e8e4e4',
    },
  };
  const styles1 = {
    card: {
      width: 656,
      height: 196,
      margin: '10px',
      marginTop: '20px',
      padding: '20px',
    },
  };

  const handleDownloadResume = () => {
    if (candidateData.resume_link !== '') {
      const link = document.createElement('a');
      link.href = candidateData.resume_link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      dispatch(
        SetNewAlert({
          msg: 'Resume not found',
          alertType: 'danger',
        })
      );
    }

    // dispatch(downloadResume('388b71e1-f029-4aa3-98b3-7f79052e8bf8'));
  };

  const handleOpenLinkedin = () => {
    if (candidateData.linked_in_profile_link !== '') {
      const link = document.createElement('a');
      link.href = candidateData.linked_in_profile_link;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      dispatch(
        SetNewAlert({
          msg: 'Link not found',
          alertType: 'danger',
        })
      );
    }
  };

  return (
    <div>
      <Grid container spacing={2} sx={{ padding: '10px' }}>
        <Grid item lg={12} mg={12} xs={12} sm={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Candidate Details
            <Typography fontSize={'14px'} sx={{ fontWeight: '300' }}>
              View the information of candidate
            </Typography>
          </Typography>
        </Grid>

        <Grid item lg={12} mg={12} xs={12} sm={12}>
          <Card sx={{ padding: '20px' }}>
            <FormikProvider value={formik}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  letterSpacing: '0.6500000953674316px',
                  textAlign: 'left',
                }}
              >
                Basic Details
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'left',
                }}
              >
                It is showing your candidate details
              </Typography>
              <Divider sx={{ my: '10px' }} />

              <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'column'} flex={1}>
                  <Typography
                    sx={{
                      fontSize: '30px',
                      fontWeight: '700',
                      lineHeight: '24px',
                      textAlign: 'left',
                      marginTop: '20px',
                    }}
                  >
                    {singleCandidateData?.data?.first_name} {singleCandidateData?.data?.last_name}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '700',
                      lineHeight: '24px',
                      textAlign: 'left',
                      marginTop: '10px',
                    }}
                  >
                    About Me
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      fontWeight: '400',
                      textAlign: 'left',
                    }}
                  >
                    {singleCandidateData?.data?.profile_description}
                  </Typography>
                </Stack>

                <img
                  src={
                    singleCandidateData?.data?.profile_photo_link
                      ? singleCandidateData?.data?.profile_photo_link
                      : profile
                  }
                  alt="img"
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '200px',
                  }}
                />
              </Stack>

              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '24px',
                  textAlign: 'left',
                  marginTop: '20px',
                }}
              >
                Job Category
              </Typography>

              <Grid item>
                <Stack direction={'row'}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '600',
                      lineHeight: '24px',
                      textAlign: 'center',
                      backgroundColor: '#d1e7d9',
                      color: '#035421',
                      borderRadius: '6px',
                      border: '1.5px solid #035421',
                      padding: '2px 10px',
                    }}
                  >
                    {singleCandidateData?.data?.recruitmentJobCategoryModel?.name}
                  </Typography>
                </Stack>
              </Grid>

              <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                {rows.map((row) => (
                  <Grid item key={row.id} xs={12}>
                    <Grid container spacing={2}>
                      {columns.map((column) => (
                        <Grid item xs key={column.field}>
                          <Typography fontWeight={'700'} fontSize={'15px'}>
                            {column.headerName}:
                          </Typography>
                          <Typography>{row[column.field]}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                {rows1.map((row) => (
                  <Grid item key={row.id} xs={12}>
                    <Grid container spacing={2}>
                      {columns1.map((column) => (
                        <Grid item xs key={column.field}>
                          <Typography fontWeight={'700'} fontSize={'15px'}>
                            {column.headerName}:
                          </Typography>
                          <Typography component="div">{row[column.field]}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                {rows2.map((row) => (
                  <Grid item key={row.id} xs={12}>
                    <Grid container spacing={2}>
                      {columns2.map((column) => (
                        <Grid item xs key={column.field}>
                          <Typography fontWeight={'700'} fontSize={'15px'}>
                            {column.headerName}:
                          </Typography>
                          <Typography variant="body1" component="div">
                            {row[column.field]}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </FormikProvider>
          </Card>
        </Grid>

        <Grid lg={6} xs={12} sm={12} md={6} item>
          <Card sx={{ padding: '15px', height: '200px', scrollBehavior: 'auto' }}>
            <Typography fontWeight={'700'} fontSize={'16px'}>
              Languages Known
            </Typography>
            <Typography fontWeight={'400'} fontSize={'14px'}>
              The list of languages known to the candidate
            </Typography>

            <Divider sx={{ my: '10px' }} />

            <Grid spacing={2} item>
              {singleCandidateData?.data?.recruitmentCandidateLanguageModels?.map((value, index) => {
                return <Chip label={value?.recruitmentLanguagesModel?.name} sx={{ mr: '5px', mb: '5px' }} />;
              })}
            </Grid>
          </Card>
        </Grid>

        <Grid lg={6} xs={12} sm={12} md={6} item>
          <Card sx={{ padding: '15px', height: '200px', scrollBehavior: 'auto' }}>
            <Typography fontWeight={'700'} fontSize={'16px'}>
              Skills
            </Typography>
            <Typography fontWeight={'400'} fontSize={'14px'}>
              The list of skills of the candidate
            </Typography>

            <Divider sx={{ my: '10px' }} />

            <Grid spacing={2} item>
              {singleCandidateData?.data?.recruitmentCandidateSkillsModels?.map((value, index) => {
                return <Chip label={value?.recruitmentJobSkillsModel?.name} sx={{ mr: '5px', mb: '5px' }} />;
              })}
            </Grid>
          </Card>
        </Grid>
        <Grid lg={6} xs={12} sm={12} md={6} item>
          <Card sx={{ padding: '15px', height: '200px', scrollBehavior: 'auto' }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography fontWeight={'700'} fontSize={'16px'}>
                LinkedIn Profile
              </Typography>
              <LinkedInIcon sx={{ color: '#197AC5', fontSize: '30px' }} />
            </Stack>

            <Divider sx={{ my: '10px' }} />

            <Typography fontWeight={'400'} fontSize={'15px'}>
              You can visit candidate linked in profile from here tab to below button to open linked in profile of
              candidate
            </Typography>

            <Button
              disableElevation
              sx={{
                mt: '10px',
                color: 'white',
                padding: '8px 20px',
                bgcolor: 'green',
                cursor: 'pointer',
                '&:hover': { bgcolor: 'green' },
              }}
              onClick={() => handleOpenLinkedin()}
            >
              Open LinkedIn Profile
            </Button>
          </Card>
        </Grid>
        <Grid lg={6} xs={12} sm={12} md={6} item>
          <Card sx={{ padding: '15px', height: '200px', scrollBehavior: 'auto' }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography fontWeight={'700'} fontSize={'16px'}>
                Download Resume
              </Typography>
              <DownloadingIcon sx={{ color: 'green', fontSize: '30px' }} />
            </Stack>

            <Divider sx={{ my: '10px' }} />

            <Typography fontWeight={'400'} fontSize={'15px'}>
              You can download candidate resume just tap on below download button it will download.
            </Typography>

            <Button
              sx={{
                mt: '10px',
                color: 'white',
                padding: '8px 20px',
                bgcolor: 'green',
                '&:hover': { bgcolor: 'green' },
              }}
              onClick={() => handleDownloadResume()}
            >
              Download Resume
            </Button>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewCandidate;
