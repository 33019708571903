import React from 'react'
import Iconify from '../components/Iconify'

const PaymentContent = (props) => (
    <>
     <Iconify
            icon="eva:close-outline"
            sx={{
              position: 'absolute',
              top: '7px',
              right: "25px",
              height: '30px',
              width: '30px',
              color: 'black',
              cursor: 'pointer',
            }}
            onClick={props?.handleClose}
          /> 
             <div><h2>Privacy Policy and Acceptance</h2> <br />
    1. This Privacy Policy sets out the legally binding terms for your use of our website and membership. This
    policy may be modified by Sunn-grahak.com time to time, effective upon notice to you as a visitor, user, and
    member.
    <br /> 2. If you wish to become a member and communicate with other members and make use of Sunn-grahak.com
    service read these terms of use and follow the instruction for the registration process. If you visit or
    sign up with Sunn-grahak.com it is presumed and understood that you fully accept and bound by all these
    terms and conditions.
    <br /> <h3>APPLICABLE LAW</h3> <br />
    This site is created and controlled by Sunn-grahak.com in Nagpur, India, as such the law of India shall
    apply, courts in Sunn-grahak.com India shall alone have exclusive jurisdiction (subject to Arbitration at
    Nagpur) in respect of all terms, conditions and disclaimer Sunn-grahak.com alone reserves the right to make
    changes to the site and the terms, conditions and disclaimers thereof. The membership is deemed to have been
    entered into at Nagpur, India and the laws of India will govern the membership. All disputes arising out of
    or in any way affecting the membership as also including interpretations of any of the terms are to be
    referred to Arbitration in ) Nagpur India under Arbitration and Conciliation Act(Indian Act),1996 before a
    Sole Arbitrator
    <br />
    <h3>ELIGIBILITY</h3>
    You present and warrant that you are competent and eligible to enter into a legally binding agreement and
    have the requisite authority to bind other party to this privacy policy. You shall not use these services if
    you are not competent to contract under applicable Indian laws, rules, and regulations. By registering on
    Sunn-grahak.com you explicitly understand and agree that:
    <br /> a) the minimum age for registering is 18 years for women and 21 years for men <br /> b) you are not
    disable by any law from entering a contract <br /> c) you have gone through the Terms and Conditions and
    agree to be bound by them
    <br />
    <h3>TERM</h3> We have at any stage at our option and discretion right to refuse or reject or terminate or
    cancel the membership without assigning any reasons. Sunn-grahak.com may terminate your access to the site
    and/or your membership and/or for any reason effective upon sending you a notice on email address you have
    provided in your application membership or any such email address as you may later provide to
    Sunn-grahak.com However, Sunn-grahak.com reserves the rights, in it sole discretion, to terminate your
    access to the site and the related services or any portion thereof at any time, without notice. The
    provisions of Sections 13,14,15,16, and 18 of Indian Contract Act, 1872 shall service any such termination
    <br />
    <h3> MODIFICATION OF TERMS AND CONDITION OF SERVICE </h3>Sunn-grahak.com may at any time modify the terms
    and conditions (“Terms”) of the service without any prior notice of intimation to you. You can access the
    latest version of the terms at any given time. You should regularly review the terms and you are accepted to
    do so at regular intervals. In the event, the modified terms are not acceptable to you, you should
    discontinue using the services. However, if you continue to avail the services provided by the website, you
    irrevocably and unconditionally agree to accept and abide by the modified terms. <br />
    We may engage trusted third party service providers to assist us in delivering our products, services and
    communications. These providers are obligated to protect your personal information and may only use it for
    the purposes specified by us.
    <h3>MEMBERSHIP</h3> Membership is not automatic. The right of admission vests with Sunn-grahak.com at its
    sole discretion. You become a member upon due acceptance of the Profile/Payment by Sunn-grahak.com
    Membership and rights of admission is reserved solely for <br /> a) Indian National & Citizens <br /> b)
    Persons of Indian Origin (PIO)
    <br /> c) Non-Residents Indians (NRI) <br /> d) Persons of Indian Descent or Indian Heritage Our membership
    is only for personal use. It is not to be directly and/or indirectly assigned, transferred, or licensed so
    as to be used by any other person/entity
    <h3>REGISTRATION DATA</h3> To avail of and use the services you will have to register with Sunn-grahak.com.
    As a part of registration process, you agree to provide to us complete and accurate registration information
    (the “Registration Data”) and time to time update the same. <br />
    If you provide any information that is untrue, inaccurate, not current, or incomplete or Sunn-grahak.com has
    reasonable grounds to suspect that such information is untrue inaccurate, not current or incomplete,
    Sunn-grahak.com has the right to indefinitely suspend or terminate your membership without giving any notice
    or without assigning any reasons and shall refuse to provide you with access to Sunn-grahak.com.
    <br /> We may collect personal information from you, such as your name, contact details, and other
    identifiable information, when you voluntarily provide it to us through our website, forms, or other
    communication channels. <br /> 2.2. Cookies and Tracking Technologies, we use cookies and similar tracking
    technologies to enhance your experience, analyse website usage, and provide personalized content. These
    technologies may collect information about your browsing activities on our website.
    <br />
    <h3>CONFIDENTIALITY</h3>Member is advised to make appropriate enquiries before acting upon any contact. Our
    sight does not vouch for or subscribe to claims and representations made by any one including, members,
    users, subscribers, or visitors to Sunn-grahak.com. Sunn-grahak.com will maintain confidentiality about
    personal information (other than that meant for posting or transmission) furnish by Members. However,
    Sunn-grahak.com may divulge such information if required by law. By agreeing to register at Sunn-grahak.com
    and its consortium of portals, a user allows a Sunn-grahak.com to get in touch with him or her from time to
    time for events or offers. This could include offers, information, as well as promotions; Sunn-grahak.com
    can use your email address and/or telephone/mobile number provided by you for this purpose.
    <br /> Third-Party Service Providers, we may engage trusted third-party service providers to assist us in
    delivering our products, services, and communications. These providers are obligated to protect your
    personal information and may only use it for the purposes specified by us.
    <br />
    <h3>LICENSE AND SITE ACCESS</h3> Sunn-grahak.com grants you a limited license to access and make personal
    use of this site and not to download (other than page caching) or modify it, or any portion of it, except
    with express written consent of Sunn-grahak.com. This license does not include any resale or commercial use
    of this site or this contents, any collection and use of any product listing, descriptions or prices any
    derivative use of this site or its contents, any downloading or copying of account information for the
    benefit of another merchant, or any use of data mining, robots, or similar data gathering and extraction
    tools. This site or any portion of this site may not be reproduced, duplicated, copied, resold, sold,
    visited or otherwise exploited any commercial purposes. You shall not frame or utilise framing techniques to
    enclose any trademark, logo or other proprietary information (including images text, page layout, or form)
    of Sunn-grahak.com and our affiliates without prior express written consent of Sunn-grahak.com. You shall
    not be use any Meta tags or any ‘hidden text’ utilising Sunn-grahak.com’s name or trademarks without the
    prior express written consent of Sunn-grahak.com Any unauthorised use terminates the permission or license
    granted by Sunn-grahak.com for any purpose to you. You shall not directly and/or indirectly use any
    Sunn-grahak.com logo other proprietary graphic or trademark as part of the link without express written
    permission from Sunn-grahak.com. <br />
    Sunn-grahak.com can use Personal Information as below:
    <br /> To provide and improve/enhance our products, services, and customer support.
    <br /> Process and fulfil your requests and transactions.
    <br /> Communicate with you, including responding to your inquiries and providing relevant updates.
    <br />
    Personalize your experience and tailor our offerings to your preferences.
    <br /> Send you promotional materials and information about our products and services, where permitted by
    law.
    <br />
    <h3> PLATFORM FOR COMMUNICATION</h3>The site is only a venue where user may meet and interact with one
    another for their transaction. We do not and cannot be a party to or control in any manner any transaction
    between two users of the site. The use of the site you shall entirely at your risk and consequences thereof.
    Consequently-
    <br /> 1) We are not responsible for any non- performance or breach of any contract between users. We cannot
    and do not guarantee that the concerned users will perform any transaction concluded on the site. We shall
    not and are not required to mediate or resolve any dispute or disagreement between users.
    <br /> 2) We do make any representation or warranty as to the attributes (such as quality, worth,
    marketability) of the service proposed and/or suggested on the site. We do not implicitly or explicitly
    support or endorse any transactions/services on the site. We accept no liability for any errors or
    omissions, whether on behalf of website or third party.
    <br /> 3) We do not make any representation or warranty as to the attributes (such as legal title,
    creditworthiness, identity etc) of any of our users. You are advised and required to independently verify
    the bonafides of any particular user that you choose to deal with on the site and use your best judgment in
    the behalf. We will not be responsible for anything in that regard or for the outcome and/or consequences of
    your judgment. <br /> 4)This site is a channel of the communication whereby the users can reach the larger
    base to avail services but at their own risk as to cost and consequence thereof. We are only providing a
    platform for communication and it is agreed that the contract for any transactions/services shall be
    strictly bipartite contract between the users/members without involving us. At no time neither shall any
    right, title or interest over the terms of the contract enter into by the two parties (users/members) vest
    in us nor shall we have any obligations or liabilities in respect of such contracts. We are not responsible
    for unsatisfactory or delayed performance of services or non-performance of services.
    <br /> 5) You release and indemnify us and /or all or any of our officers and representatives from any
    claims as to cost, damage, liability or other consequence of any of the actions of the users of the site and
    specifically waive any claims that you may have in this behalf under any applicable law by which this
    privacy policy is governed. Not with standing our reasonable efforts in that behalf, we cannot control the
    information provided by other users which is made available on the site. You may find other User’s
    information to be offensive, harmful, inaccurate, false or deceptive. Please use caution, common sense and
    safe practice while using the site. Please note that there are also risks of dealing with unwanted and wrong
    people as also with people of unsocial elements. You may report any content which you may find offensive by
    writing to us at support and we shall verify and take necessary action as we may deem fit in our sole
    discretion.
    <br />
    <h3>CONTENT POSTED ON THE SITE </h3> You understand and agree that Sunn-grahak.com may delete any content,
    messages, photos or profiles (collectively, “content”) which are in the sole judgment of Sunn-grahak.com, in
    violation of terms of use or which might be offensive, false ,illegal ,against public interest,
    discriminative, or that might violate the rights,harm,or threaten the safety of Sunn-grahak.com and/or its
    members and/or general public at large
    <br />
    <h3>PROPRIETARY RIGHTS IN CONTENT ON SUNN-GRAHAK.COM </h3>Sunn-grahak.com owns and retains other proprietary
    rights in the Sunn-grahak.com site and the Sunn-grahak.com service. The site contains copyrighted material
    trademarks, and other proprietary information of Sunn-grahak.com and its licensors. Have been given
    permission, you shall not directly/or indirectly copy, modify publish, transmit distribute, perform,
    display, or sell any proprietary information
    <br />
    <h3> LIMITATION OF LIABILITY</h3> i) Sunn-grahak.com has made available this service as a matter of
    convenience. We expressly disclaim any claim or liability arising out of uploading of any obscene, vulgar,
    or pornographic images, photograph or a picture or alerting or distorting the images available under these
    services in an obscene, vulgar, pornographic manner. User irrevocably agrees and acknowledges that the user
    shall be solely responsible for his/her conduct and agrees to indemnify and hereby indemnifies the website
    from any legal actions in relation to an acts. Sunn-grahak.com reserves the right to terminate your rights
    to use the service immediately in case of you uploading of any obscene, vulgar or pornographic images,
    photographs or pictures or alerting or distorting the images available under this service in an obscene,
    vulgar, pornographic manner, notwithstanding penal provisions under the Indian cyber laws or any other
    allied laws enacted by the Government of India or any other statutory or legislative or any regulatory
    authority authorised in this regard from time to time.
    <br /> ii) In no event shall Sunn-grahak.com be liable for any direct, indirect ,punitive, incidental or
    special consequential damages or for any damages whatsoever including without limitation, damages for loss
    of use, data or profits, arising out of or in any way connected with the use of performance of the
    sites/services for interrupted communications, delay, lost data or lost profits arising out of or in
    connection with this privacy policy, even if Sunn-grahak.com has been advised of possibility of damages or
    inability to use the sites/services or related services, the provision of or failure to provide services, or
    for any information, software, products, services and related graphics obtained through the other
    sites/services, or otherwise arising out of the use of the other sites/services, whether based on contract,
    tort, negligence, strict liability or otherwise, even if Sunn-grahak.com or any of its suppliers have been
    advised of the possibility of damages. Sunn-grahak.com does not endorse in any way any advertisers/contents
    of advertisers on its web pages.
    <br /> ii) if you use this site, you are responsible for maintaining the confidentiality of your account and
    password and for restricting access to your computer and you agreed to accept the responsibility for all
    activities that occur under your account and password. Sunn-grahak.com and its affiliates reserve the right
    to refuse service, terminate accounts, remove, or edit content or cancel subscription in their sole
    discretion. <h3>INDEMNITY</h3> User agrees to defend, hold harmless indemnify and here by indemnifies
    Sunn-grahak.com it employees, directors ,officers, agents, and their successor and assigns from and against
    any and all claims, liabilities damages, losses, costs and expenses, including Advocates and Attorney’s fees
    caused by or arising out of claims based upon the use of users actions or inaction including but not limited
    to any warranties, representations or undertaking or in relation the non-fulfilment of any of its obligation
    under this privacy policy or arising out of the user’s infringement of any applicable laws, regulations,
    including but not limited to intellectual property rights, payment of statutory dues and taxes claim of
    libel, defamation, violation of rights of privacy or publicity, loss of service by other subscriber and
    infringement of intellectual property or other rights. This clause shall survive the expiry or termination
    of this agreement. <h3>SUGGESTIONS, COMPLAINTS AND DISPUTES</h3> Suggestions and complaints are to be first
    addressed to Sunn-grahak.com Customer Support Department at Sunn-grahak.com
    <br /> <h3>ELECTRONIC COMMUNICATION</h3> When you visit Sunn-grahak.com or send e mails to us you are
    communicating with us electronically. You Consent to receive communications from us electronically. We will
    communicate with you by email or by posting notice on the site. You agree that all agreements, notices,
    disclosures, and other communications that we provide to you electronically satisfy any legal requirement
    that such communication be in writing.</div>
    </>
 
  )

export default PaymentContent