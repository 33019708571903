import React from 'react';
import { useSelector } from 'react-redux';
import { green, red } from '@mui/material/colors';
import { Grid, Typography, Box, Card, Divider, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import tick from '../../Assets/tick.png';

const MainBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  mt: 5,
}));

const PaymentModel = (props) => {
  const { profileData, selectedPlans, subscriptionData } = useSelector((state) => ({
    profileData: state?.profileData,
    selectedPlans: state?.selectedPlans,
    subscriptionData: state?.subscriptionData,
  }));

  console.log('Props:-', props.daysLeft);

  return (
    <div>
      <Grid container spacing={3} sx={{ my: 3, mt: -2 }}>
        {/* {plans?.map((val, index) => ( */}
        <Grid item xs={12} sm={6} md={4} lg={12}>
          <Box
            //   onClick={profileDetail === "Active"? ()=>{}:handleOpen}
            sx={{
              border: 'none',
              background: '#fff',
            }}
          >
            {/* </span> */}
            <MainBox style={{ marginBottom: '10px' }}>
              {/* <img src={star} alt="login" style={{ marginRight: '5px' }} /> */}
              {/* <Typography variant="h4">{selectedPlans?.[0]?.subscriptionPlansModels?.[0]?.plan_title? selectedPlans?.[0]?.subscriptionPlansModels?.[0]?.plan_title: profileData.vendorBusinessDetailsModels[0].selected_plan_name}</Typography> */}
              <Stack>
                <Typography variant="h4" color={'green'}>
                  {props.currentPlan.planName}
                </Typography>
                <Typography variant="p">{props.currentPlan.description}</Typography>
              </Stack>
            </MainBox>

            <MainBox>
              <Typography variant="h5" sx={{ mr: 1 }}>
                ₹
              </Typography>

              <Typography variant="h3" style={{ marginRight: '5px' }}>
                {props.currentPlan.amount}
              </Typography>
              <Typography variant="h5">/{props.currentPlan.planType}</Typography>
            </MainBox>

            <Divider />

            <Typography sx={{ mt: '10px' }}>
              <b>{props.noOfLocation === '1' ? '(SINGLE LOCATION)' : '(DOUBLE LOCATION)'}</b>
            </Typography>
            {props?.startDate && (
              <>
                <Grid container>
                  <Grid item lg={6}>
                    <Typography>Start Date :</Typography>
                    <Typography fontWeight={'700'} color={'green'}>
                      {props?.startDate}
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography>End Date :</Typography>
                    <Typography fontWeight={'700'} color={'green'}>
                      {props?.endDate}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

            <Divider sx={{ my: '10px' }} />

            {props?.daysLeft && (
              <Box>
                <Stack alignContent={'center'} alignItems={'center'} direction={'row'}>
                  <Button
                    sx={{
                      borderRadius: '10px',
                      color: props.daysLeft > 10 ? green[900] : red[900],
                      fontSize: '20px',
                      border: props.daysLeft > 10 ? '1.5px solid green' : '1.5px solid red',
                      fontWeight: '700',
                      paddingX: '15px',
                      paddingY: '3px',
                      bgcolor: props.daysLeft > 10 ? green[50] : red[50],
                    }}
                  >
                    {props.daysLeft}{' '}
                  </Button>
                  <Typography sx={{ marginLeft: '5px', fontSize: '18px', fontWeight: '600' }}>Days Left</Typography>
                </Stack>
                <Divider sx={{ my: '10px' }} />
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <h3 style={{ fontSize: '17px', fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                <img
                  src={tick}
                  alt="plan"
                  height="20px"
                  width="20px"
                  style={{ marginRight: '5px', marginTop: '5px' }}
                />
                Prioritizing user's needs and exceptional service free of charge
              </h3>
              <h3 style={{ fontSize: '17px', fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                <img
                  src={tick}
                  alt="plan"
                  height="20px"
                  width="20px"
                  style={{ marginRight: '5px', marginTop: '5px' }}
                />{' '}
                No hidden fees or subsriptions are required to access our comprehensive vendor database
              </h3>
              <h3 style={{ fontSize: '17px', fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                <img
                  src={tick}
                  alt="plan"
                  height="20px"
                  width="20px"
                  style={{ marginRight: '5px', marginTop: '5px' }}
                />{' '}
                Users can explore and connect with vendors without an obligations
              </h3>
              <h3 style={{ fontSize: '17px', fontWeight: 300, display: 'flex', marginTop: '5px', marginRight: '5px' }}>
                <img
                  src={tick}
                  alt="plan"
                  height="20px"
                  width="20px"
                  style={{ marginRight: '5px', marginTop: '5px' }}
                />{' '}
                Trade disputes will not be a part of this Application
              </h3>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentModel;
