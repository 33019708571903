import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {
  Grid,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Tooltip,
  Divider,
  Stack,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import EventBusyIcon from '@mui/icons-material/EventBusy';
// components
import Chip from '@mui/material/Chip';
import { green, orange, red } from '@mui/material/colors';
import moment from 'moment';

import CardRapped from '../components/comman/CardRapped';
import { fetchProfile } from '../api/CommanApicall';
import Page from '../components/Page';
import axiosInstance from '../api/withJWTServer';
import Nodocs from '../Assets/nobusinessLogo.png';
import { PROFILE_DATA, SELECTED_PLANS, UPGRADE_PLANS, SUBSRIPTION_PLANS } from '../actions/Types';

import Iconify from '../components/Iconify';
import PaymentModel from './Modal/PaymentModel';
import EditAddressModel from './Modal/EditAddressModel';
import logoNew from '../Assets/grayLogoNew.png';
import footerLogo from '../Assets/bottomImg.png';
import pdf from '../Assets/pdf.png';
import PlanAddressPaymentModel from './Modal/PlanAddressPaymentModel';
import rocketGreen from '../Assets/img_rocket_2.png';
import rocketYellow from '../Assets/img_rocket_1.png';

// ---------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
const MainBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '20px',
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  opacity: 1,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 4,
};
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#8b8b8b',
  paddingLeft: '10px',
  fontSize: '15px',
};

export default function DashboardApp() {
  const { profileData, selectedPlans, upgradePlans } = useSelector((state) => ({
    profileData: state?.profileData,
    selectedPlans: state?.selectedPlans,
    updradePlans: state?.upgradePlan,
  }));

  const businessDocsObject = profileData?.vendorBusinessDocs ? Object.keys(profileData?.vendorBusinessDocs) : [];
  const dispatch = useDispatch();
  const [dashboardDetail, setDashboardDetail] = useState([]);
  const [subscriptionDetail, setsingleSubscriptionDetail] = useState();

  const [currentPlan, setCurrentPlan] = useState({
    planName: '',
    noOfLocation: '',
    description: '',
    uuid: '',
    amount: '',
    planType: '',
  });

  const [imgDocument, setImgDocument] = useState({
    doc_path: '',
    doc_name: '',
  });
  const [outletAddressDetail, setOutletAddressDetail] = useState({});
  const [openOutlet, setOpenOutlet] = useState(false);
  const [outletId, setOutletId] = useState();
  const handleOpenOutlet = () => setOpenOutlet(true);
  const handleCloseOutlet = (e) => {
    setOpenOutlet(e);
  };
  const [open, setOpen] = useState(false);
  const [docOpen, setDocOpen] = useState(false);
  const [newAddressOpen, setNewAddressOpen] = useState(false);

  const currentPlanUUID = useRef('');

  const handleOpen = () => {
    setOpen(true);
    const selectedPlanId = selectedPlans?.[selectedPlans?.length - 1]?.subscriptionPlansModels?.[0];
    setsingleSubscriptionDetail(selectedPlanId);
  };
  const handleClose = () => setOpen(false);

  const handleDocOpen = (imgObject) => {
    setImgDocument({
      doc_path: imgObject.doc_path,
      doc_name: imgObject.doc_category_name,
    });
    setDocOpen(true);
  };

  const handleDocClose = () => setDocOpen(false);
  const navigate = useNavigate();

  const theme = useTheme();
  useEffect(() => {
    axiosInstance
      .get('vendor/dashboard-stats')
      .then((response) => {
        setDashboardDetail(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });

    axiosInstance
      .get('vendor/subscription-details')
      .then((response) => {
        dispatch({
          type: SELECTED_PLANS,
          payload: response?.data?.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const isOpen = [
    { isOpen: profileData?.buinessTimingsModels?.[0]?.sunday_open, day: 'Sun', TodayDay: 'Sunday' },
    { isOpen: profileData?.buinessTimingsModels?.[0]?.monday_open, day: 'Mon', TodayDay: 'Monday' },
    { isOpen: profileData?.buinessTimingsModels?.[0]?.tuesday_open, day: 'Tue', TodayDay: 'Tuesday' },
    { isOpen: profileData?.buinessTimingsModels?.[0]?.wednesday_open, day: 'Wed', TodayDay: 'Wednesday' },
    { isOpen: profileData?.buinessTimingsModels?.[0]?.thursday_open, day: 'Thu', TodayDay: 'Thursday' },
    { isOpen: profileData?.buinessTimingsModels?.[0]?.friday_open, day: 'Fri', TodayDay: 'Friday' },
    { isOpen: profileData?.buinessTimingsModels?.[0]?.saturday_open, day: 'Sat', TodayDay: 'Saturday' },
  ];
  const openDay = isOpen.filter((val) => val.TodayDay === moment().format('dddd') && val.isOpen === 1);
  const useStyles = makeStyles((theme) => ({
    alignedClass: {
      width: '50%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    color: {
      color: '#07703C',
    },
    heading: {
      height: '62px',
    },
    subHeader: {
      height: '40px',
    },
  }));
  const CompanyDocPath = profileData?.vendorBusinessDocs?.company_logo?.doc_path;
  const companyPdfurl = /\.pdf$/i.test(CompanyDocPath);

  const gstDocPath = profileData?.vendorBusinessDocs?.gst_registration_doc?.doc_path;
  const gstPdfurl = /\.pdf$/i.test(gstDocPath);

  const GumastaDocPath = profileData?.vendorBusinessDocs?.gumasta_doc?.doc_path;
  const gumastaPdfurl = /\.pdf$/i.test(GumastaDocPath);

  const AadharFrontDocPath = profileData?.vendorBusinessDocs?.aadhar_card_front?.doc_path;
  const AadharfrontPdfurl = /\.pdf$/i.test(AadharFrontDocPath);

  const AadharBackDocPath = profileData?.vendorBusinessDocs?.aadhar_card_back?.doc_path;
  const AadharbackPdfurl = /\.pdf$/i.test(AadharBackDocPath);

  const panDocPath = profileData?.vendorBusinessDocs?.pan_card?.doc_path;
  const panPdfurl = /\.pdf$/i.test(panDocPath);

  const getAllPlansInfo = async () => {
    await axiosInstance.get('vendor/subscription-plans-info').then((res) => {
      if (currentPlanUUID.current !== '') {
        const getCurrentPlan = res?.data?.data.filter((item) => item.uuid === currentPlanUUID.current);

        console.log('cP', getCurrentPlan[0]);
        console.log('cPID', currentPlanUUID.current);

        setCurrentPlan({
          planName: getCurrentPlan[0].plan_title,
          noOfLocation: getCurrentPlan[0].no_of_locations,
          description: getCurrentPlan[0].plan_description,
          uuid: getCurrentPlan[0].uuid,
          amount: getCurrentPlan[0].plan_price,
          planType: getCurrentPlan[0].plan_type,
        });

        dispatch({
          type: SUBSRIPTION_PLANS,
          payload: res?.data?.data,
        });
      }
    });
  };

  useEffect(() => {
    async function profile() {
      try {
        const profileDetail = await fetchProfile();
        dispatch({
          type: PROFILE_DATA,
          payload: profileDetail?.data?.data[0] === undefined ? null : profileDetail?.data?.data[0],
        });

        if (profileDetail?.data?.data[0]) {
          currentPlanUUID.current = profileDetail.data.data[0].vendorBusinessDetailsModels[0].selected_plan_uuid ?? '';
        }

        // Do something with profileDetail
      } catch (error) {
        console.error(error);
      }
    }
    profile().then(() => {
      setTimeout(async () => {
        await getAllPlansInfo();
      }, [1000]);
    });
  }, []);

  function renderContent(pdf, path) {
    if (pdf) {
      return <iframe title="PDF Viewer" src={`${path}`} width="100%" height="100%" frameBorder="0" scrolling="auto" />;
    }

    return (
      <img
        src={`${path}`}
        alt="company logo"
        style={{ height: '180px', width: '200px', borderRadius: '10px', objectFit: 'cover' }}
      />
    );
  }
  const classes = useStyles();

  console.log('Docs', JSON.stringify(businessDocsObject));

  const tagArray = profileData?.vendorBusinessDetailsModels?.[0]?.business_tags
    ?.split(', ')
    .map((item) => item.replace(/'/g, ''));

  const handleUpgradePlan = () => {
    const param = {
      vendor_uuid: profileData?.uuid,
    };
    axiosInstance
      .post('vendor/calculate-plan-amount', param)
      .then((response) => {
        navigate('/dashboard/payment/1', { replace: true });
        dispatch({
          type: UPGRADE_PLANS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {}, []);
  const dateFormat = (date) => {
    const inputDate = moment(date, 'DD-MM-YYYY');

    // Format the moment object as "16 Nov 2023"
    return inputDate.format('DD MMM YYYY');
  };
  // const backgroundImageUrl = require('../Assets/rocket.png');

  function cropString(inputString, maxLength) {
    if (inputString.length <= maxLength) {
      return inputString;
    }
    return `${inputString.slice(0, maxLength)}...`;
  }

  const vendorAddressDetail = (selectedAddressDetail) => {
    axiosInstance
      .post('vendor/outlet-address', selectedAddressDetail)
      .then((res) => {
        axiosInstance.get('vendor/profile').then((res) => {
          dispatch({
            type: PROFILE_DATA,
            payload: res?.data?.data[0] === undefined ? null : res?.data?.data[0],
          });
          handleNewAddressModal();
        });
      })
      .catch((err) => {
        // handleChange(false);
      });
  };

  const handleNewAddressModal = () => {
    setNewAddressOpen(!newAddressOpen);
  };

  return (
    <RootStyle>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction={'row'}>
            <Stack direction={'column'} flex={1}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Current Plan
              </Typography>
              <Typography id="modal-modal-title">It is showing current plan information</Typography>
            </Stack>
            <Box>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CancelIcon sx={{ color: 'red' }} />
              </IconButton>
            </Box>
          </Stack>

          <PaymentModel
            noOfLocation={currentPlan.noOfLocation}
            currentPlan={currentPlan}
            startDate={selectedPlans?.[0]?.start_date && selectedPlans?.[0]?.start_date}
            endDate={selectedPlans?.[0]?.end_date && selectedPlans?.[0]?.end_date}
            daysLeft={selectedPlans?.[0]?.remainingDays && selectedPlans?.[0]?.remainingDays}
          />
        </Box>
      </Modal>

      {/* It is model showing when open document image  */}
      <Modal
        open={docOpen}
        onClose={handleDocClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction={'column'}>
            <Stack direction={'row'}>
              <Stack direction={'column'} flex={1}>
                <Typography fontWeight={'700'} fontSize={'20px'} textTransform={'uppercase'}>
                  {imgDocument.doc_name.replaceAll('_', ' ')}
                </Typography>
                <Typography>It is showing {imgDocument.doc_name.replaceAll('_', ' ')} </Typography>
              </Stack>

              <Box>
                <IconButton onClick={handleDocClose}>
                  <CancelIcon sx={{ color: 'red' }} />
                </IconButton>
              </Box>
            </Stack>

            <img
              src={imgDocument.doc_path}
              alt="document"
              style={{ borderRadius: '10px', width: '500px', height: '400px', marginTop: '10px' }}
            />
          </Stack>
        </Box>
      </Modal>
      <EditAddressModel
        openOutlet={openOutlet}
        handleCloseOutlet={handleCloseOutlet}
        outletAddressDetail={outletAddressDetail}
        outletId={outletId}
      />
      <Modal
        open={newAddressOpen}
        onClose={handleNewAddressModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{}}>
          <Typography id="modal-modal-description" sx={{ mt: 2, height: 'max-content' }}>
            <PlanAddressPaymentModel
              vendorAddressDetail={vendorAddressDetail}
              noOfLocation={selectedPlans?.[0]?.subscriptionPlansModels?.[0]?.no_of_locations}
              selectedPlan={{ uuid: selectedPlans?.[0]?.subscription_plan_uuid }}
              planType={0}
              onClose={handleNewAddressModal}
            />
          </Typography>
        </Box>
      </Modal>
      <Page title="Dashboard" sx={{ width: '100%' }}>
        <Container maxWidth="xl">
          <Typography variant="h5" sx={{ mb: 1 }}>
            Dashboard
            <Typography fontSize={'14px'} sx={{ fontWeight: '300' }}>
              Here we have a summary of your business
            </Typography>
          </Typography>

          {/* <Grid container columnSpacing={2} rowSpacing={2} mt={'0px'}>
            <Grid md={4} item>
              <Box sx={{ background: 'white', borderRadius: '6px', boxShadow: 2, padding: '10px' }}>
                <Iconify icon={'mdi:briefcase-variant'} width={24} height={24} color={'teal'} />

                <Typography fontWeight={'700'} fontSize={20}>
                  89
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Total Jobs
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is showing statistics of your jobs
                </Typography>
              </Box>
            </Grid>
            <Grid md={4} item>
              <Box sx={{ background: 'white', borderRadius: '6px', boxShadow: 2, padding: '10px' }}>
                <Iconify icon={'mdi:sticker-check'} width={22} height={22} color={'green'} />

                <Typography fontWeight={'700'} fontSize={20}>
                  89
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Open Jobs
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is statistics of your open jobs
                </Typography>
              </Box>
            </Grid>
            <Grid md={4} item>
              <Box sx={{ background: 'white', borderRadius: '6px', boxShadow: 2, padding: '10px' }}>
                <Iconify icon={'mdi:sticker-minus'} width={22} height={22} color={'red'} />

                <Typography fontWeight={'700'} fontSize={20}>
                  89
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Closed Jobs
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is statistics of your closed jobs
                </Typography>
              </Box>
            </Grid>
          </Grid> */}

          <Box height={'20px'} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card sx={{ padding: '20px ' }}>
                <Typography fontWeight={'700'} fontSize={'20px'}>
                  Business Details
                </Typography>
                <Typography fontWeight={'300'} fontSize={'14px'}>
                  It is showing your business detail
                </Typography>

                <Divider sx={{ height: '10px', mb: '20px' }} />

                <CardRapped
                  text={profileData?.vendorBusinessDetailsModels?.[0]?.category_label.toUpperCase()}
                  slug=""
                />

                <Stack direction={'row'}>
                  <Stack direction={'column'} flex={1}>
                    <Typography variant="h3" sx={{ mb: 1, mt: 1 }}>
                      {profileData?.vendorBusinessDetailsModels?.[0]?.business_name}
                    </Typography>
                    <Typography fontWeight={'600'} fontSize={'17px'}>
                      Business description :
                    </Typography>
                    <Typography fontWeight={'400'} fontSize={'15px'}>
                      {profileData?.vendorBusinessDetailsModels?.[0]?.business_details}
                    </Typography>
                  </Stack>

                  {CompanyDocPath ? (
                    <div style={{ padding: '0px 0px 0px 0px', marginTop: '0px' }}>
                      <img
                        src={CompanyDocPath}
                        alt="company logo"
                        style={{ height: '130px', width: '130px', marginTop: '10px', borderRadius: '10px' }}
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        src={Nodocs}
                        alt="company logo"
                        style={{ height: '130px', width: '140px', marginTop: '10px' }}
                      />
                    </div>
                  )}
                </Stack>

                <Divider sx={{ height: '10px', mb: '20px' }} />

                <Grid container>
                  <Grid lg={4}>
                    <Typography fontWeight={'600'} fontSize={'15px'}>
                      Mobile No :{' '}
                    </Typography>
                    <Typography>
                      {profileData?.vendorBusinessDetailsModels?.[0]?.mobile_number}
                      {profileData?.vendorBusinessDetailsModels?.[0]?.alternate_mobile_number ? (
                        <>/ {profileData?.vendorBusinessDetailsModels?.[0]?.alternate_mobile_number}</>
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </Grid>
                  <Grid lg={4}>
                    <Typography fontWeight={'600'} fontSize={'15px'}>
                      State :{' '}
                    </Typography>
                    <Typography>{profileData?.vendorBusinessDetailsModels?.[0]?.state}</Typography>
                  </Grid>
                  <Grid lg={4}>
                    <Typography fontWeight={'600'} fontSize={'15px'}>
                      Country :{' '}
                    </Typography>
                    <Typography>{profileData?.vendorBusinessDetailsModels?.[0]?.country}</Typography>
                  </Grid>
                </Grid>

                <Typography fontWeight={'600'} fontSize={'17px'} mt={'10px'}>
                  Address :
                </Typography>
                <Typography>
                  {profileData?.vendorBusinessDetailsModels?.[0]?.address_line_1},{' '}
                  {profileData?.vendorBusinessDetailsModels?.[0]?.city},{' '}
                  {profileData?.vendorBusinessDetailsModels?.[0]?.pin}{' '}
                </Typography>

                <Typography fontWeight={'600'} fontSize={'17px'} mt={'10px'}>
                  Landmark :
                </Typography>
                <Typography>
                  {profileData?.vendorBusinessDetailsModels?.[0]?.address_line_2
                    ? profileData?.vendorBusinessDetailsModels?.[0]?.address_line_2
                    : '....'}
                </Typography>

                <Divider sx={{ height: '20px' }} />

                {selectedPlans?.length !== 0 && profileData?.vendorOutletAddressModels?.length === 0 ? (
                  <>
                    <Box sx={{ marginTop: '10px' }}>
                      <Stack direction={'row'}>
                        <Typography
                          sx={{
                            bgcolor: orange[800],
                            padding: '5px 8px',
                            fontSize: '12px',
                            marginTop: '10px',
                            borderRadius: '8px',
                            color: 'white',
                            fontWeight: '600',
                          }}
                        >
                          Note : Last step to go. Your payment is completed now you can add your outlet address.
                        </Typography>
                      </Stack>
                      <Typography fontWeight={'600'} fontSize={'17px'} mt={'10px'}>
                        Outlet Address :
                      </Typography>

                      <Button
                        variant="contained"
                        sx={{
                          '&:hover': {
                            bgcolor: 'green',
                          },
                          mt: '10px',
                        }}
                        onClick={() => {
                          handleNewAddressModal();
                        }}
                      >
                        Add Address
                      </Button>
                    </Box>
                  </>
                ) : (
                  profileData?.vendorOutletAddressModels?.map((val, index) => (
                    <div key={index}>
                      <Box sx={{ marginTop: '10px' }}>
                        <Typography fontWeight={'600'} fontSize={'17px'} mt={'10px'} mb={'0px'}>
                          Outlet Address {index + 1} :
                        </Typography>
                        {val.address_line_1} {val.city && `, ${val.city}`}
                        {val.state && `, ${val.state}`}
                        {val.pin && `, ${val.pin}`}
                        <b className={classes.color} />
                      </Box>

                      <Grid container sx={{ mt: '5px' }}>
                        <Grid item lg={4}>
                          <Typography fontWeight={'600'} fontSize={'17px'} mt={'10px'}>
                            Landmark :
                          </Typography>
                          <Typography>{val.address_line_2 ? val.address_line_2 : '....'}</Typography>
                        </Grid>

                        <Grid item lg={4}>
                          <Typography fontWeight={'600'} fontSize={'17px'} mt={'10px'}>
                            State :
                          </Typography>
                          <Typography>{val.state}</Typography>
                        </Grid>
                        <Grid item lg={4}>
                          <Typography fontWeight={'600'} fontSize={'17px'} mt={'10px'}>
                            Country :
                          </Typography>
                          <Typography>{val.country}</Typography>
                        </Grid>
                      </Grid>

                      <Button
                        variant="outlined"
                        sx={{ marginTop: '15px' }}
                        onClick={() => {
                          setOutletAddressDetail(val);
                          setOutletId(index + 1);
                          handleOpenOutlet();
                        }}
                      >
                        EDIT OUTLET ADDRESS {index + 1}
                      </Button>

                      <Divider sx={{ height: '10px' }} />
                    </div>
                  ))
                )}
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={'5px'}>
            <Grid item lg={4}>
              <Card sx={{ padding: '15px', height: '200px' }}>
                <Typography fontSize={'30px'} fontWeight={'700'} color={'green'}>
                  {profileData?.vendorBusinessImages?.length > 0 && profileData?.vendorBusinessImages?.length}{' '}
                </Typography>
                <Typography fontSize={'18px'} fontWeight={'600'}>
                  Business Images
                </Typography>
                <Typography fontSize={'16px'} fontWeight={'400'}>
                  It is showing the info of your business images
                </Typography>

                <Link to="/dashboard/upload-images" style={{ textDecoration: 'none', color: '#000' }}>
                  <Button variant="contained" sx={{ mt: '10px ', '&:hover': { bgcolor: 'green' } }}>{`${
                    profileData?.vendorBusinessImages?.length < 1 ? 'Upload Images' : 'View Images'
                  }`}</Button>
                </Link>
              </Card>
            </Grid>
            <Grid item lg={4}>
              <Card sx={{ padding: '15px', height: '200px' }}>
                <Typography fontSize={'30px'} fontWeight={'700'} color={'green'}>
                  {+profileData?.vendorBusinessDetailsModels?.[0]?.business_expertise > 0
                    ? profileData?.vendorBusinessDetailsModels?.[0]?.business_expertise
                    : 0}
                  {+profileData?.vendorBusinessDetailsModels?.[0]?.business_expertise > 1 ? ' years' : ' year'}
                </Typography>
                <Typography fontSize={'18px'} fontWeight={'600'}>
                  Business Experience
                </Typography>
                <Typography fontSize={'16px'} fontWeight={'400'}>
                  It is showing the info of your business experience
                </Typography>
              </Card>
            </Grid>
            <Grid item lg={4}>
              <Card sx={{ padding: '15px', height: '200px' }}>
                <Typography fontSize={'30px'} fontWeight={'700'} color={'green'}>
                  {profileData?.vendorBusinessImages?.length > 0 && profileData?.vendorBusinessImages?.length}{' '}
                </Typography>
                <Typography fontSize={'18px'} fontWeight={'600'}>
                  Business Review Link
                </Typography>
                <Typography fontSize={'16px'} fontWeight={'400'}>
                  It is showing the info of your business review link
                </Typography>

                <Typography fontWeight={'500'}>
                  {profileData?.vendorBusinessDetailsModels?.[0]?.business_review_link ? (
                    <a
                      href={`${profileData?.vendorBusinessDetailsModels?.[0]?.business_review_link}`}
                      target="_blank"
                      className={classes.color}
                      rel="noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      {profileData?.vendorBusinessDetailsModels?.[0]?.business_review_link ? (
                        <>{cropString(profileData?.vendorBusinessDetailsModels?.[0]?.business_review_link, 50)}</>
                      ) : (
                        '....'
                      )}
                    </a>
                  ) : (
                    '....'
                  )}
                </Typography>
              </Card>
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: '15px' }}>
            <Grid item lg={12}>
              <Card sx={{ padding: '15px ' }}>
                <Typography fontWeight={'600'} fontSize={'18px'}>
                  Plan Details
                </Typography>
                <Typography fontWeight={'300'} fontSize={'14px'}>
                  It is showing your plan details
                </Typography>
                <Divider sx={{ height: '10px', mb: '10px' }} />

                {currentPlan.noOfLocation !== '' && currentPlan.noOfLocation === '1' && (
                  <img
                    src={rocketYellow}
                    alt="rocket"
                    style={{ borderRadius: '10px', width: '50px', height: '50px' }}
                  />
                )}

                {currentPlan.noOfLocation !== '' && currentPlan.noOfLocation === '2' && (
                  <img src={rocketGreen} alt="rocket" style={{ borderRadius: '10px', width: '50px', height: '50px' }} />
                )}

                {currentPlan.planName && (
                  <Stack direction={'row'} alignItems={'center'}>
                    <Typography fontWeight={'700'} fontSize={'23px'} color={green[900]}>
                      {currentPlan.planName ?? ''}
                    </Typography>
                    <Typography fontWeight={'600'} fontSize={'16px'} ml={'5px'}>
                      {currentPlan.noOfLocation === '1' ? ' (SINGLE LOCATION)' : ' (DOUBLE LOCATION)'}
                    </Typography>
                  </Stack>
                )}

                <Typography>{currentPlan.description}</Typography>

                <Divider sx={{ my: '10px' }} />

                {selectedPlans?.[selectedPlans?.length - 1]?.remainingDays &&
                  (selectedPlans?.[selectedPlans?.length - 1]?.remainingDays <= 0 ? (
                    <Stack direction={'row'} my={'10px'}>
                      <Box
                        sx={{
                          bgcolor: red[600],
                          borderRadius: '8px',
                          padding: '8px 15px ',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <EventBusyIcon fontSize="12px" />
                        <Typography fontSize={'12px'} fontWeight={'600'} ml={'5px'}>
                          Your current plan is expired now. You have to renew your subscription plan.
                        </Typography>
                      </Box>
                    </Stack>
                  ) : (
                    <Stack>
                      <Stack alignContent={'center'} alignItems={'center'} direction={'row'}>
                        <Button
                          sx={{
                            borderRadius: '10px',
                            color:
                              selectedPlans?.[selectedPlans?.length - 1]?.remainingDays > 10 ? green[900] : red[900],
                            fontSize: '20px',
                            border:
                              selectedPlans?.[selectedPlans?.length - 1]?.remainingDays > 10
                                ? '1.5px solid green'
                                : '1.5px solid red',
                            fontWeight: '700',
                            paddingX: '15px',
                            paddingY: '3px',
                            bgcolor:
                              selectedPlans?.[selectedPlans?.length - 1]?.remainingDays > 10 ? green[50] : red[50],
                          }}
                        >
                          {selectedPlans?.[selectedPlans?.length - 1]?.remainingDays}{' '}
                        </Button>
                        <Typography sx={{ marginLeft: '5px', fontSize: '18px', fontWeight: '600' }}>
                          Days Left
                        </Typography>
                      </Stack>
                      <Typography>Days left to expire your current plan</Typography>
                      <Divider sx={{ my: '10px' }} />
                    </Stack>
                  ))}

                <Grid container>
                  <Grid item lg={6}>
                    <Typography fontWeight={'600'} fontSize={'17px'} mt={'10px'}>
                      Start Date :
                    </Typography>
                    <Typography>{selectedPlans?.[0]?.start_date ?? '------'}</Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography fontWeight={'600'} fontSize={'17px'} mt={'10px'}>
                      End Date :
                    </Typography>
                    <Typography>{selectedPlans?.[0]?.end_date ?? '------'}</Typography>
                  </Grid>
                </Grid>

                <Divider sx={{ my: '10px' }} />

                <Stack direction={'row'} mt={'15px'}>
                  <Button
                    onClick={handleOpen}
                    sx={{
                      background: green[50],
                      color: '#07703c',
                      border: '1.5px solid #07703c',
                      borderRadius: '8px',
                      fontWeight: '500',
                      paddingX: '15px',
                    }}
                  >
                    View Plan Details
                  </Button>
                  {selectedPlans?.length < 2 ? (
                    <>
                      {selectedPlans[0]?.subscriptionPlansModels[0]?.no_of_locations === '1' && (
                        <Button
                          onClick={handleUpgradePlan}
                          sx={{
                            marginLeft: '10px',
                            background: '#07703c',
                            color: '#fff',
                            borderRadius: '8px',
                            paddingX: '20px',
                            fontWeight: '500',
                            '&:hover': {
                              bgcolor: 'green',
                            },
                          }}
                        >
                          Upgrade Plan
                        </Button>
                      )}
                    </>
                  ) : null}
                </Stack>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={'5px'}>
            <Grid item lg={6}>
              <Card sx={{ padding: '15px' }}>
                <Grid container>
                  <Grid item lg={6}>
                    <Typography fontWeight={'600'} fontSize={'18px'}>
                      Business Days
                    </Typography>
                    <Typography fontWeight={'300'} fontSize={'14px'}>
                      It is showing your business timings
                    </Typography>
                  </Grid>
                  <Grid item lg={6} alignSelf={'center'}>
                    <Stack direction={'row'} justifyContent="end" alignItems={'center'}>
                      <Typography
                        style={{
                          color: `${openDay?.[0]?.isOpen === 1 ? '#07703C' : '#c90202'}`,
                          fontSize: '16px',
                          fontWeight: '600',
                          textAlign: 'right',
                        }}
                      >
                        {moment().format('dddd')}{' '}
                      </Typography>
                      <Typography sx={{ ml: '5px' }}>
                        {openDay?.[0]?.isOpen === 1 ? (
                          <Chip
                            label={`OPEN`}
                            sx={{
                              bgcolor: green[50],
                              border: '1.5px solid green',
                              color: green[800],
                              fontSize: '12px',
                              fontWeight: '600',
                              padding: '5px',
                            }}
                          />
                        ) : (
                          <Chip
                            label={`CLOSED`}
                            sx={{
                              bgcolor: red[50],
                              border: '1.5px solid red',
                              color: red[800],
                              fontSize: '12px',
                              fontWeight: '600',
                            }}
                          />
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>

                <Divider sx={{ height: '10px', mb: '10px' }} />

                <Stack direction={'row'}>
                  {isOpen?.map((val) => (
                    <>
                      {val?.isOpen === 1 ? (
                        <>
                          <Chip
                            label={val.day}
                            sx={{
                              bgcolor: green[50],
                              border: '1.5px solid green',
                              color: green[800],
                              fontWeight: '600',
                              marginRight: '5px',
                              paddingX: '3px',
                              textTransform: 'uppercase',
                            }}
                          />
                        </>
                      ) : (
                        <Chip
                          label={val.day}
                          sx={{
                            fontWeight: '600',
                            marginRight: '5px',
                            bgcolor: red[50],
                            border: '1.5px solid red',
                            color: red[800],
                            paddingX: '3px',
                            textTransform: 'uppercase',
                          }}
                        />
                      )}
                    </>
                  ))}
                </Stack>

                <Divider sx={{ height: '10px', mb: '10px' }} />

                <Grid container>
                  <Grid lg={6} item>
                    <Typography fontWeight={'500'} fontSize={'15px'}>
                      Opening Time:
                    </Typography>
                    <Typography fontWeight={'700'} color={green[700]}>
                      {profileData?.buinessTimingsModels?.[0]?.start_timing}
                    </Typography>
                  </Grid>

                  <Grid lg={6} item>
                    <Typography fontWeight={'500'} fontSize={'15px'}>
                      Closing Time:
                    </Typography>
                    <Typography fontWeight={'700'} color={red[700]}>
                      {profileData?.buinessTimingsModels?.[0]?.end_timing}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item lg={6}>
              <Card sx={{ padding: '15px', height: '200px' }}>
                <Typography fontWeight={'600'} fontSize={'18px'}>
                  Business Keywords
                </Typography>
                <Typography fontWeight={'300'} fontSize={'14px'}>
                  It is showing your business keywords
                </Typography>

                <Divider sx={{ height: '10px', mb: '20px' }} />
                {tagArray?.[0] ? (
                  <>
                    {tagArray.map((val, index) => (
                      <>
                        <Chip
                          label={`${val}`}
                          sx={{
                            bgcolor: 'rgba(0,0,0,0.04)',
                            border: '1.5px solid green',
                            color: green[800],
                            fontWeight: '600',
                            marginRight: '10px',
                            paddingX: '10px',
                            marginBottom: '10px',
                          }}
                        />
                      </>
                    ))}
                  </>
                ) : (
                  <Box textAlign={'center'}>
                    <BookmarksIcon sx={{ color: 'gray', opacity: 0.5 }} />
                    <Typography style={{ fontSize: '15px' }}> No Tags Available</Typography>
                  </Box>
                )}
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={'5px'}>
            <Grid item lg={12}>
              <Card sx={{ padding: '20px' }}>
                <Typography fontWeight={'600'} fontSize={'18px'}>
                  Business Documents
                </Typography>
                <Typography fontWeight={'300'} fontSize={'14px'}>
                  It is showing your business documents
                </Typography>

                <Divider sx={{ height: '10px', mb: '10px' }} />

                <Grid container lg={12}>
                  {businessDocsObject &&
                    businessDocsObject.map((item) => {
                      const docItem = profileData?.vendorBusinessDocs[item];

                      const name = item.replaceAll('_', ' ');

                      return (
                        item !== 'company_logo' &&
                        item !== 'vendor_profile' && (
                          <Grid item lg={4}>
                            <Typography
                              sx={{ fontSize: '16px', fontWeight: '600', color: 'green', textTransform: 'uppercase' }}
                            >
                              {name}
                            </Typography>
                            {docItem.doc_path.includes('.pdf') ? (
                              <img
                                src={pdf}
                                alt="Document"
                                style={{ borderRadius: '10px', width: '80px', height: '70px', marginTop: '10px' }}
                              />
                            ) : (
                              <img
                                src={docItem.doc_path}
                                alt="Document"
                                style={{ borderRadius: '10px', width: '150px', height: '100px', marginTop: '10px' }}
                              />
                            )}

                            <Typography mt={'10px'}> Tap to view {name}</Typography>
                            <Button
                              onClick={() => {
                                if (docItem.doc_path.includes('.pdf')) {
                                  window.open(docItem.doc_path, '_blank');
                                } else {
                                  handleDocOpen(docItem);
                                }
                              }}
                              sx={{
                                bgcolor: 'green',
                                color: 'white',
                                paddingX: '15px',
                                fontWeight: 500,
                                marginTop: '5px',
                                '&:hover': {
                                  bgcolor: 'green',
                                },
                              }}
                            >
                              {docItem.doc_path.includes('.pdf') ? 'Download Document' : 'View Document'}
                            </Button>
                          </Grid>
                        )
                      );
                    })}
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={'20px'}>
            <Grid container>
              <Grid item xs={12}>
                <CardContent sx={{ mb: -12 }}>
                  <img src={logoNew} alt="SunnGrahk" style={{ marginTop: '50px' }} width="250px" />

                  <div style={{ display: 'flex', marginTop: '10px' }}>
                    <img src={footerLogo} alt="logo" style={{ width: '50px', height: '50px', marginLeft: '10px' }} />
                    <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                      <Typography style={{ fontSize: '10px', fontWeight: '300', color: '#B7B7B7' }}>
                        In association with
                      </Typography>
                      <Typography style={{ fontSize: '12px', fontWeight: '600', color: '#B7B7B7' }}>
                        International Sindhi Samaj Sangathan
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </RootStyle>
  );
}
