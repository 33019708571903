import React from 'react';
import { makeStyles } from '@material-ui/styles';

const CardRapped = (props) => {
  const useStyles = makeStyles((theme) => ({
    approve: {
      border: '2px solid #035421',
      borderRadius: '5px',
      padding: '10px',
      color: '#035421',
      backgroundColor: '#d1e7d9',
      fontSize: '20px',
      fontWeight: 700,
    },
    approvesmall: {
      border: '1px solid #035421',
      borderRadius: '5px',
      padding: '5px 10px',
      color: '#035421',
      backgroundColor: '#d1e7d9',
      fontSize: '15px',
      fontWeight: 600,
    },
    warning: {
      border: '2px solid #A36203',
      borderRadius: '5px',
      padding: '10px',
      color: '#A36203',
      backgroundColor: '#ecdeca',
      fontSize: '15px',
      fontWeight: 600,
    },
    danger: {
      border: '2px solid #D0250E',
      borderRadius: '5px',
      padding: '10px',
      color: '#D0250E',
      backgroundColor: '#f5d1cc',
      fontSize: '15px',
      fontWeight: 600,
    },
  }));
  const classes = useStyles();
  let className;
  if (props.text < 5) {
    className = classes.danger;
  } else if (props.text < 10) {
    className = classes.warning;
  } else {
    className = classes.approve;
  }
  return (
    <>
      {props.slug === 'days' ? (
        <>
          <span className={className}>{props.text}</span>
        </>
      ) : (
        <span className={classes.approvesmall}>{props.text}</span>
      )}
    </>
  );
};

export default CardRapped;
