import { Container, Card } from '@mui/material';
import React from 'react';

const TermsCondition = () => (
    <>
      <Card>
      
        <Container>
          <div>
            <br />
            <h2>Terms and Conditions : Dial 4 Vyapar and Pay Services</h2>
            <br />
            Effective date: 15 August 2023
            <br />
            <br />
            <h3>Introduction</h3>
            This TERMS OF USE FOR D4V PAY SERVICES governs the terms of your use of D4V PAY Services offered by
            Dial4Vyapar Limited. (herein after referred to as Dial4Vyapar) BY REGISTERING FOR OR USING D4V PAY SERVICES,
            YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS, PLEASE DO NOT USE THE D4V PAY
            SERVICES. As the D4V PAY Services owned by Dial4Vyapar, hence all the rights, benefits, liabilities &amp;
            obligations under the following terms &amp; conditions shall accrue to the benefit of Dial4Vyapar. When you
            use D4V PAY Services provided by Dial4Vyapar, You will be subject to the rules, guidelines, policies, terms,
            and conditions applicable to usage of D4V PAY Services. You are solely responsible for understanding and
            complying with any and all laws, rules and regulations of your specific jurisdiction that may be applicable
            to you in connection with your use of the D4V PAY Services. We may amend these Terms at any time by posting
            a revised version on dial4vyapar.com or mobile site, any other website we maintain for purposes of providing
            the D4V PAY Services. Amended Terms are effective at the time we post them and your continued use of the D4V
            PAY Services constitutes your acceptance of any amended Terms. We may notify you regarding upcoming Terms
            changes by us.
            <h3>Defination</h3>
            “Account" refers to the account created for a User as soon as he successfully completes the process of
            registering at www.dial4vyapar.com, which contains information regarding his D4V Pay Services, which permit
            payment and domestic money remittance as per RBI direction, as amended by RBI from time to time. “Customer”
            “or” “you” mean a person who has registered with Dial4Vyapar for availing the D4V PAY Services and who has
            accepted these Terms and Conditions and, owns/operates/has access to an internet compatible device that
            supports the D4V PAY Services. “Charge(s)” or “Service Charge” shall mean the charges which Dial4Vyapar may
            levy upon you in consideration for subscribing to the D4V PAY Services. "Eligible" means satisfying the
            conditions of eligibility prescribed under these T&amp;Cs. “KYC” stands for Know your Customer and refers to
            the various norms, rules, laws and statutes issued by RBI from time to time under which Dial4Vyapar is
            required to procure personal identification details from you before any services can be delivered. Know your
            Customer (KYC) documents may be required by Dial4Vyapar from the Customer at the time of Registration and/
            or on a later date, for availing and / or continuation of the D4V PAY Services. “D4V PAY Services” allows
            its registered users to: a) to make a payment via payment gateway internet enabled links which shall be sent
            on their email/sms/links; or b) scan QR Codes placed on posters or other media displayed at Merchant’s
            establishment to enable you to quickly and easily make payment via method internet enabled links which shall
            be sent on their email/sms/links (i) register with and/or log in to the Merchant's website; and/or (ii) make
            payments to the Merchant, or (c) transfer funds to a savings or current bank account, or to make any valid
            transaction, upon completing the necessary formalities mentioned herein, or otherwise mention time to time.
            "Merchant" means any of the establishments listed on the webpage available at www.dial4vyapar.com or at its
            portals which/who accepts payment through D4V Pay Services for sale of their goods and services and with
            whom we have executed physical contracts. The list of Merchants available at www.dial4vyapar.com h) may be
            revised by us from time to time without intimation to you. may be revised by us from time to time without
            intimation to you. Merchant Establishment” shall mean and include physical Merchant’s shops, and any other
            outlet that has been authorized by Dial4Vyapar to accept payment using D4V PAY Services. “D4V Registered
            Users” mean any User who completes the necessary formalities as required for registration “Password” means
            the secret password used to secure D4V PAY Services applications, without knowledge of which D4V PAY
            Services will not be operable. “RBI” means the Reserve Bank of India. “Registration Form” shall mean the D4V
            PAY Services Registration Form, as is required by Dial4Vyapar from the Customer at the time of Registration
            for availing and / or continuation of the D4V PAY Services. “Transaction” means make a payment for purchase
            of goods or services at Merchant establishments or fund transfer to the savings or current bank account, or
            any other legitimate transactions. "Terms and Conditions of Service" or "T&amp;Cs" refers to these terms and
            conditions of use of Dial4Vyapar's Services, and any future revisions of the same, which you are informed of
            via automated e-mail sent to Your Registered E-mail Address.
            <h3>ELIGIBILITY TO USE THE D4V PAY SERVICES</h3>
            While using D4V Pay Services, you represent and confirm that: You must be 18 (eighteen) years old; You are
            Indian citizen, a legal resident of India or a business entity, authorized to conduct business in India; You
            must be an authorized representative with the authority to bind that company or entity to these Terms; You
            are not 'incompetent to contract' within the meaning of the Indian Contract Act, 1972; You are entering into
            and performing this Agreement, as per applicable law and are not a person debarred from using the D4V Pay
            Services under the laws of India or other applicable laws. D4V Pay Services can only be used in India. You
            acknowledge that Dial4Vyapar Services may be subject to export restrictions imposed by the laws, rules,
            regulations, and guidelines in force in India. We reserve the right to immediately terminate your Account if
            we have reason to believe that your Account is being used by any person who is not eligible, or for any
            other reason. We rely completely on your representation that you are eligible and will bear no liability if
            you or anyone who uses your Account or D4V Pay Services is found to be not eligible.
            <h3> REGISTRATION OF D4V PAY- USER ACCOUNT</h3>
            Legal Requirements
            <br />
            To open an Account or to Use the Services, you must provide us with the following "Registration Data": a
            valid and functional e-mail address ("Registered E-mail ID"); a valid and functional phone number registered
            in your name ("Registered Phone Number"); a password; Valid Bank / Card details (for example, Bank Account
            No, IFSC Code, Bank Account No, Card No, Card Expiry details etc.) and any additional information that RBI
            Regulations may prescribe to be necessary to satisfy Know Your Customer norms ("KYC Norms"), or as we deem
            fit. The Registration Data and any other information provided by you to us must be accurate, current and
            complete information about yourself or your company as prompted by our registration form (including your
            email address) and maintain and update your information (including your email address) to keep it accurate,
            current and complete. Any liability for false, fictitious, inaccurate, not current or incomplete
            Registration Data provided by you will be borne solely by you and not us. You must keep password
            confidential and not share it with any other person. You are responsible for all activity on your account,
            whether or not you authorized it. Please do not share your Registration Data with any third parties as you
            will be solely responsible for maintaining the confidentiality of your Registration Data and will be liable
            for all activities and transactions that occur through your Account and D4V Pay, whether initiated by you or
            any third party. In order to protect the security of your Account and D4V Pay, we require you to (a)
            immediately inform us at our Hotline No. 9552361110 &amp; email us at D4V support@dial4vyapar.com , of any
            unauthorized use of your Account and (b) to ensure that you log out of Your Account at the end of every
            session. In accordance with RBI Regulations to prevent money laundering and the funding of terrorism in
            India, We may suspend or terminate your Account with immediate effect if we have reason to believe that the
            Registration Data or any other data provided by you is incorrect, or that the security of your Account has
            been compromised in any way. Please note that in order to comply with RBI Regulations, we do not allow you
            to open more than one Account in association with the phone number registered in your name. In the event
            that you have forgotten the password to your Account, you may click on Forgot Password to request a new
            password. Based on our sole discretion and if we are satisfied that the identity of the applicant for a new
            password matches your identity, we will send an e-mail to your Registered E-mail ID, with instructions to
            reset your password ("Password Instructions"). You will be solely responsible for any transactions which
            occur through your Account once we send the Password Instructions to your Registered E-mail ID. vii. In the
            event that you are unable to access to your Account for any reason other than forgetting your password,
            please inform us at our Hotline No. 9552361110 &amp; email us at D4V support@dial4vyapar.com and make a
            request for blocking your Account. We will not be liable for any unauthorized transactions made through your
            Account prior to you making a request for blocking. You may be required to provide additional information to
            allow us to verify your identity and/or your account information. We may also verify your information
            against third party databases or other sources and you authorize Dial4Vyapar to make such inquiries. You are
            responsible for keeping your Account information and password secure. If you share your Account credentials
            with another person, you are responsible for all activity they conduct using D4V Pay Services, regardless of
            whether or not you authorized their activity. We will never ask you for your Account credentials.
            <h3>Documentations</h3>
            The collection, verification, audit and maintenance of correct and updated Customer information is a
            continuous process and Dial4Vyapar reserves the right, at any time, to take steps necessary to ensure
            compliance with all relevant and applicable KYC requirements. Dial4Vyapar reserves the right to discontinue
            services/ reject applications for D4V PAY Services at any time if there are discrepancies in information
            and/or documentation provided by you. Any information provided to Dial4Vyapar with the intention of securing
            D4V PAY Services shall vest with Dial4Vyapar, and may be used by Dial4Vyapar, for any purpose consistent
            with any applicable law or regulation, at its discretion. Once you have successfully created an Account
            after completing the registration process in accordance with these T&amp;Cs, you can start availing of our
            D4V PAY Services.
            <h3>Genearal Conditions</h3>
            <h3> 1. Use of D4V Pay Services</h3>
            In order to access D4V Pay Services, YOU/ Merchant is required to download Dial4Vyapar application , you may
            be required to provide information about yourself (such as identification, contact or payment details) as
            part of the registration process for the Service, or as part of your continued use of the Services. You
            agree that any registration information you give to us will always be true, accurate, correct, complete and
            up to date. Any phone number used to register with our service needs to be registered in your name and you
            might be asked to provide supporting documents to prove the same. D4V Pay Services allows you to make a
            payment to Merchant via payment gateway internet enabled links and/or QR Codes for the products and services
            purchased by you or transfer funds to the savings or current bank account of the person, after completing
            the necessary formalities mentioned herein or otherwise mention by Dial4Vyapar time to time. D4V Pay
            Services also allows you to store your bank or credit account information with Dial4Vyapar for making the
            instant payment without re-entering that information or sharing it with third parties. D4V Pay Services is
            encrypted and secure and your information is safe with us. D4V Pay Services cannot be used to hold or store
            money and Dial4Vyapar does not hold or store any of your funds. Dial4Vyapar does not have control of, or
            liability for, any goods or services that you pay for using the D4V Pay Services. Dial4Vyapar does not
            guarantee the identity of any Vendors, sellers, and does not guarantee that a transaction will be completed.
            You may also choose to make the payment to Merchant through D4V Pay Services, for the products and services
            purchased by you or Fund Transfer by using your valid credit / debit and/ or any other cards including
            e-wallets, online banking facility to make payment. You shall be bound to use your own credit / debit and/
            or any other payment cards, online banking accounts to make payment via D4V Pay Services. Dial4Vyapar shall
            not be liable and responsible for any credit/debit or any other card frauds, misuse of your card or any
            unauthorized payment transactions which has been processed by Bank / third party payment processor. When you
            choose to make the payment or transfer funds to the savings or current bank account of the other person
            through D4V Pay Services, you have to submit the complete bank details of the recipient of money such as
            Bank Account No, IFSC Code, and/or any additional information that is necessary to complete the transaction
            or RBI may prescribe time to time. If you would not submit the bank details of the recipient of money, we
            will not process your transactions or funds will not transfer to the bank account of the recipient. When we
            receive payment instructions from you to pay a Merchant or to any Users or person, you authorize and order
            us to commit your payment to that Merchant or to another Users or Person. This authorization will remain in
            effect as long as you maintain an Account with us. In the event that you are having any issues in goods or
            services which is including but not limited to cancellation, refund, pricing, warranty, guarantee or you are
            not satisfied with the goods or services provided to you by a Merchant for any reason, we recommend that you
            report the matter to the Merchant in question. Please note that Dial4Vyapar is an are intermediary and are
            not responsible for the goods or services provided by Merchants and cannot be involved in any disputes
            between a Merchant and User regarding the same. You accept and acknowledge that payment processing is hosted
            by a third party payment processor and usage of such payment processing shall be subject to such additional
            terms and conditions which such third party payment processor may prescribe from time to time. You agree and
            confirm to comply with the terms and conditions of the Merchant from whom you are taking the
            services/products and thereafter making the payment to them via D4V Pay Services. Dial4Vyapar facilitates
            payment solutions to the Users to make a payment to Merchant or Transfer Fund. In the case of commercial
            transactions Dial4Vyapar is acting as an intermediary, which enabling users to make a payment to Merchant
            through D4V Pay Services. All commercial terms are offered by and agreed to between you and Sellers alone.
            Dial4Vyapar does not have any control or does not determine or advise or in any way involve itself in the
            offering or acceptance of any commercial terms between the User and Merchant. In order to use the D4V Pay
            Services, you must link you bank account/card details, to D4V Pay Services. When you make a payment to
            Merchant or Transfer Fund using the D4V Pay Services, you are authorizing us to initiate an electronic
            transfer from your linked bank account in the amount you specify. You are solely responsible for complying
            with any terms set by your bank with respect to your bank account, including any fee terms, such as
            non-sufficient fund or overdraft fee terms. If you receive a payment from Merchant or Fund Transfer via D4V
            Pay Services or are entitled to a reversal, refund, or other adjustment associated with a payment or Fund
            Transfer made by you using the D4V Pay Services, you also authorize a credit to your linked bank account to
            complete that transaction. If you make payment for a purchase using the D4V Pay Services or using for Fund
            Transfer, fund will be transferred to Merchant/ Users instantly. Dial4Vyapar does not provide any
            endorsements or guarantees for any individual or entity using the D4V Pay, nor any third party offers, goods
            or services featured on our websites. Information regarding third party offers, goods, and services is
            provided on our websites for informational purposes only, and may not be true, accurate, or reliable.
            Information provided by Dial4Vyapar or otherwise obtained from the Website will not be used for any
            unauthorized and unlawful purpose; You agree not to access (or attempt to access) any of the Services by any
            means other than through the interface that is provided by Dial4Vyapar, unless you have been specifically
            allowed to do so in a separate agreement with us; You agree that you will not engage in any activity that
            interferes with or disrupts the Services (or the servers and networks which are connected to the Services);
            You agree that you are solely responsible for (and that Dial4Vyapar has no responsibility to you or to any
            third party for) any breach of your obligations under the Terms and for the consequences (including any loss
            or damage which Dial4Vyapar may suffer) of any such breach. D4V PAY Services is not transferable.
            <h3>2. Use of D4V Pay Services</h3>
            By availing the complete 'One Tap' feature, you will be able to process your payment requests made using
            your debit or credit card stored with us by simply selecting the faster checkout saved card and clicking the
            “Make Payment” button. The One Tap feature may be divided in two parts: Faster Checkout option: When you
            enter your debit card/debit card details for the first time while making a payment, we will provide you an
            option to save such card details for faster checkout. If you select the ‘One Tap Checkout’ option (currently
            name as One Tap Checkout), you understand and agree that we will save your card details in a manner
            compliant with our PCI DSS certification. For the next time when you make a payment using the Services, you
            will not be required to enter your card details. You can simply click on the saved card, while making a
            payment request and your payment instructions will be sent to the Payment System Providers for
            authentication, authorization and processing. The second part ‘auto-read OTP’ option is currently only
            available on our android mobile application. Auto-Read of One Time Password (“OTP”): OTP is a one-time
            password, which is provided by your issuing bank in order to carry out the second factor authentication of
            your debit/credit card. If you register for our ‘One Tap’ feature, you understand that we will be able to
            retrieve your OTP from the message received on your mobile and populate and submit the OTP on the issuer’s
            page for second factor authentication of your debit/credit card. In order to avail the entire ‘One Tap’
            feature, you may go to the payment page and save the card for One Tap Checkout. You understand that you have
            agreed to select the ‘One Tap’ feature in whole or in part at your own risk and for your convenience. You
            agree that we are only acting as your technology intermediary and eliminating the need for you to manually
            enter your card details or OTP. All information and instructions received from your Card will be deemed to
            have been authorized by you and we shall not be liable for any unauthorized use of your Cards or any
            unauthorized transactions made using the whole or any part of the ‘One Tap feature’. It is to be clarified
            that the ‘One Tap’ feature doesn’t avoid the two-factor authentication system, it only provides a technology
            platform which eliminates the need of manual typing of card details and OTP by you. We emphasize that we are
            not involved in the authentication, authorization and processing of you payment request and only facilitate
            you in sending such payment instruction to the Payment System Provider. In the event that your device
            (mobile, tablet, laptop, etc.) is stolen/lost or your suspect any unauthorized use of your debit/credit
            Card, please ensure you go to the payment page of Dial4vyapar App and de-activate the ‘One Tap’ feature
            immediately. You should also inform us of the same after de-activation at D4Vsupport@dial4vyapar.com. In the
            event that you have de-activated the ‘One Tap’ feature and informed Dial4Vyapar of the same and thereafter
            an unauthorized transaction is made, Dial4Vyapar’s service provider will take responsibility for the same
            provided that you provide us with adequate information that the transaction was not made by you or any
            person that you know. Dial4Vyapar’s service provider shall determine that legitimacy of the claim at our
            sole discretion.
            <h3>Charges</h3>
            Currently, we do not charge you to use the D4V Pay Services. However, your mobile network operator may
            charge you to access the D4V Pay and you are responsible for these charges. Please note that in future D4V
            Pay Services may be chargeable at the discretion of Dial4Vyapar, in the form and manner prescribed for such
            payment. Dial4Vyapar may at its discretion, change, amend, increase, or reduce the Service Charges without
            prior intimation to the Customer. Dial4Vyapar reserves the right to introduce a policy as per the applicable
            RBI guidelines for D4V PAY Service expiration and balance forfeiture in future. The terms and conditions
            related to any such policy that we may introduce in the future will come into effect from the date that will
            be clearly indicated under the “Terms of Use” for D4V PAY Services.
            <h3> Your Information</h3>
            When you use the D4V Pay Services, we will send your information (including, where applicable, your
            encrypted payment card details) from the D4V Pay server to the third party payment services provider. By
            using D4V Pay Services you agree to receive promotional SMS/Emails from Dial4Vyapar or its associates, agent
            or partners. Your privacy is very important to Dial4Vyapar. When you using D4V Pay Services, you confirm
            that you have read and accepted our Privacy Policy. The Privacy Policy, which is accessible through the D4V
            Pay Services and forms an integral part of this Agreement, describes how we collect and use your
            Information. The Dial4Vyapar reserves the right to use your Information in order to contact you in the
            future in relation to marketing any other products or services offered by the Dial4Vyapar, any of its
            related group companies or participating merchants. You shall be responsible for maintaining the
            confidentiality of your personal and sensitive data or information and you shall be responsible for all
            activities that occur under your use of D4V Pay. You agree that if you provide any information that is
            untrue, inaccurate, not current or incomplete or we have reasonable grounds to suspect that such information
            is untrue, inaccurate, not current or incomplete, or not in accordance with the this Terms of Use, we shall
            have the right to indefinitely suspend or block your access to D4V Pay Services. You shall be responsible
            for maintaining the confidentiality of your personal and sensitive data or information and you shall be
            responsible for all activities that occur under your use of D4V Pay. You agree that if you provide any
            information that is untrue, inaccurate, not current or incomplete or we have reasonable grounds to suspect
            that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the this
            Terms of Use, we shall have the right to indefinitely suspend or block your access to D4V Pay Services.
            <h3>Your Obligations</h3>
            D4V PAY Services availability is subject to the maintenance of an active mobile phone, internet connection
            with an associated telecom provider and Bank / Card Account. D4V PAY Services availability is subject to the
            maintenance of a mobile phone handset and other application on which Services run and the Customer is solely
            responsible for all liability arising from the unavailability of Services due to a mobile handset or
            internet service provider not supporting any D4V PAY Services channel or application. You must ensure the
            availability of sufficient funds before executing any Transaction from your D4V PAY Services. You shall be
            solely responsible for the confidentiality, safety and security of the Password. You shall be the sole owner
            of the Password and shall be responsible for the consequences arising out of disclosure of the Password
            and/or the unauthorized use of D4V PAY Services. In case the Password is lost or misplaced, you shall
            promptly inform Dial4Vyapar by calling at the customer care numbers where after the Password will be barred
            and a new Password will be issued to you after necessary validation. In case the mobile phone/ SIM card
            associated with your D4V PAY Services is lost/stolen/misplaced/ no longer in your control; you shall
            promptly inform Dial4Vyapar. Dial4Vyapar will upon receipt of such information block the relevant account.
            You shall intimate Dial4Vyapar about change in your address, if any, in writing along with such proof of
            address as per the KYC documents. You shall not use D4V PAY Services for any purpose that might be construed
            as contrary or repugnant to any applicable law, regulation, guideline, judicial dicta, Dial4Vyapar policy or
            public policy or for any purpose that might negatively prejudice the goodwill of Dial4Vyapar. You
            acknowledge and understand that D4V PAY Services are linked to your mobile phone number and you shall be
            solely responsible for any liability arising out of the loss/ theft / misuse of the mobile phone number or
            deactivation of mobile connection by the concerned telecom service provider, in respect of the Services.
            Information submitted by you for availing D4V PAY Services and/or information submitted while using D4V PAY
            Services may be shared with third parties by Dial4Vyapar , inter alia, to facilitate the provision of D4V
            PAY Services. You shall ensure that the Services are not used for Transactions in foreign currency. D4V PAY
            Services is issued &amp; shall be valid only in India and shall be used at Merchant Establishments only in
            India. Without limiting the foregoing, you agree that you will not use the Dial4Vyapar Site to undertake any
            of the following actions or to display, upload, modify, publish, distribute, disseminate, transmit, update
            or share any information that is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic,
            paedophilic, libellous, invasive of another’s privacy, hateful, or racially, ethnically objectionable,
            disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner
            whatever; Infringes any patent, trademark, copyright or other proprietary rights; contains viruses,
            corrupted files, or any other similar software or programs that is designed to interrupt, destroy or that
            may limit the functionality of any computer source or that may damage or adversely affect the operation of
            another person’s computer, its web-sites, any software or hardware, or telecommunications equipment;
            Advertises or offers to sell any goods or services for any commercial purpose; is in the nature of
            promotional services, products, surveys, contests, pyramid schemes, spam, unsolicited advertising or
            promotional materials, or chain letters; Falsify or delete any author attributions, legal or other proper
            notices or proprietary designations or labels of the origin or the source of software or other material;
            Violates any law for the time being in force; Belongs to another person to which you do not have any right
            to; Interferes with or disrupts Dial4Vyapar ’s websites, servers, or networks; Impersonate any other person;
            Harm minors in any way; Forge headers or manipulate identifiers or other data in order to disguise the
            origin of any content transmitted through its websites or to manipulate your presence on its websites;
            Engage in any illegal activities; Threatens the unity, integrity, defence, security or sovereignty of India,
            friendly relations with foreign states, or public order, or causes incitement to the commission of any
            cognizable offence, or prevents the investigation of any offence or insults any other nation. You shall not
            misuse, rent, lease, assign, or otherwise transfer the D4V Pay services to any other Person. You may not
            alter, reproduce, adapt, distribute, display, publish, reverse engineer, translate, disassemble, decompile
            or otherwise attempt to create any source code that is derived from or included in the D4V Pay
            services/System. All rights, title and interest to the D4V Pay services are owned exclusively by
            Dial4Vyapar. You will only use the D4V Pay services for your own purposes and not on behalf of any other
            person or entity; You and all payments initiated by you will comply with all laws, rules, and regulations;
            and you will not use the D4V Pay, directly or indirectly, for any fraudulent undertaking or in any manner so
            as to interfere with the functionality of the D4V Pay services. You will not breach these Terms or any other
            applicable terms or policies; You will not engage in illegal or fraudulent activities. You will not engage
            in transactions involving illegal goods, including but not limited to counterfeit goods, stolen goods,
            illegal or controlled substances, and substances that pose a risk to consumer safety, counterfeit services,
            illegal gambling, and money laundering, purchase, sale, or exchange of Virtual Currency, or provide a
            Virtual Currency marketplace or exchange; You will not attempt to receive or actually receive duplicate
            compensation for a disputed payment from the Merchant, Dial4Vyapar, and/or your bank; or You will not engage
            in activity that indicates, in Dial4Vyapar’s sole discretion, that there may be a high level of risk
            associated with you, or your Account activity. The use of any D4V Pay that has connectivity to the Internet
            or any external network poses an increased risk, and you assumes all liability for such increased risks. You
            will fully cooperate with Dial4Vyapar in complying with the laws. You shall liable for data security breach
            and credit card information if you fail to protect the data and information. You shall protect your data.
            You must vigilantly comply with all applicable law, including, without limitation to, Information Technology
            Act, 2000 as amended by the Information Technology (Amendment) Act, 2008, and the Information Technology
            (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 and
            rules and regulations made thereunder and any other law for the time being in force, and shall not do, or
            omit to do, any act that will cause Dial4Vyapar to be in breach of any such applicable law. If you breach
            the terms of this agreement, you shall indemnify us against any costs claims and liabilities arising as a
            result of the breach. You shall not, fraudulently or dishonestly, make use of the electronic signature,
            password or any other unique identification feature of any other person and shall not misuse communication
            device or computer resource of Dial4Vyapar, without its permissions, and further use the said devices for
            cheats by personation.
            <h3>Refund Policy</h3>
            Dial4Vyapar shall process a refund, when a valid authorization and presentment was previously processed and
            the Users subsequently either cancelled the transaction or returns the goods, or wishes to refund the amount
            in cases of services not rendered, or to rectify any transaction processing error, or services/goods is not
            delivered within prescribed period of your completion of the transaction, then you may inform us by sending
            an email to our customer services email address mentioned on the Contact Us page, containing the complete
            details of the said disputed transactions such as date, time, order/transaction numbers, account number
            etc.. For processing a refund, the users shall present a refund request to Dial4Vyapar along with all the
            required detail to identify the previously authorized and settled transaction and Dial4Vyapar shall process
            the refund within 5 (Five) working days from the date the refund request is received. The refund request
            must describe the failed / returned / rejected / cancelled transactions or merchandise were returned,
            services cancelled or adjustment made. The funds have to be credited back to the same source from where
            these were received, and not by cash or any other mode. In case of any users’ complaint regarding non-
            refund for failed transactions and/or non-credit for successful transactions shall be dealt by the Bank. Any
            complaint about credit not being given to Users should be dealt with conclusively and bilaterally by the
            remitting and beneficiary banks as per its Policy. Note: Dial4Vyapar is not responsible for any wrong
            purchase or recharge for an incorrect mobile number or DTH account number or fund transfers in an incorrect
            bank account or any unauthorised payment by users. You are sole responsible for the accuracy, authenticity
            of information pertaining to the goods/services or the payment credentials while using the D4V Pay Services.
            <h3>SECURITY AND UNAUTHORISED PAYMENTS</h3>
            We give you the opportunity to choose a password to gain access to the D4V Pay Services itself or to various
            features within the D4V Pay Services. As long as the correct Password is entered (or if you elect not to use
            a Password), we will assume that you are the person giving instructions and making payments or fund transfer
            and you will be liable for them. You must therefore choose a secure Password that is not easily guessed by
            another person, keep the Password is secret and make sure that it is not stored in a way that enables others
            to access it or impersonate you. In addition, for the avoidance of doubt, if you disclose the Password to
            any person whom you authorise to access the D4V Pay Services, you are also responsible and liable for any
            access, use, misuse or disclosure of your Password by such person. If you become aware of a payment being
            made via the D4V Pay Services that was not authorised by you or if you lose the device on which the D4V Pay
            is installed, disclose your Password to a person unauthorised by you, or believe that someone else can use
            the D4V Pay by impersonating you, you should inform us immediately and we will disable the payments feature
            of the D4V Pay until you re-enter your payment card details in the D4V Pay. Unless and until you provide
            such notice: you will be responsible for any instruction which we receive and act on, even if it was not
            given by you; and we will not be responsible for any unauthorised access to confidential information about
            you in the D4V Pay. We can refuse to act on any instruction including where we believe an instruction: (i)
            was unclear; (ii) was not given by you; or (iii) might cause us to breach a legal or other duty; (iv) if we
            believe the D4V Pay is being used for an illegal purpose; or (v) may harm our reputation. You agree to fully
            cooperate with us, affiliates, payment gateway service providers, Banks, merchants, regulatory authorities
            and the police where you or we suspect there have been Unauthorised Payments in respect of the D4V Pay
            Services.
            <h3> Our Obligations</h3>
            When you use the D4V Pay Services, we are acting as an intermediary to assist you in concluding the purchase
            of goods or services from participating merchants or to make the payment successful to the recipient . When
            you purchase goods or services from participating merchants, the contract for the sale of those goods or
            services will be between you and the merchant. You understand and agree that the Dial4Vyapar shall not be
            responsible for the outcome of your transactions with external merchants via the D4V Pay Services. We do not
            have control, nor shall we be liable for, the legality of, or the use of, the goods and services that are
            paid for using the D4V Pay Services. Dial4Vyapar shall not be responsible for any fraudulent transaction by
            any person in any manner whatsoever. Dial4Vyapar shall not be responsible for any chargeback, refunds or
            cancellation of products or services. Dial4Vyapar will take adequate encryption and security measures to
            maintain the data secured generated and it shall maintain high standards in relation to providing secure
            services to you. Dial4Vyapar is not responsible for any non-performance or breach of any contract entered
            into between you and Merchants. Dial4Vyapar cannot and does not guarantee that the concerned Merchants
            and/or You will perform any transaction concluded via D4V Pay, Dial4Vyapar shall not and is not required to
            mediate or resolve any dispute or disagreement between Merchants and You. Dial4Vyapar is only providing a
            platform for the purposes of transaction and it is agreed that the contract for sale of any of the products
            or services shall be a strictly bipartite contract between you and Merchants. At no time shall Dial4Vyapar
            hold any right, title or interest over the products nor shall Dial4Vyapar have any obligations or
            liabilities in respect of any transaction between you and Merchants. Dial4Vyapar is not responsible for
            unsatisfactory or delayed performance of services or damages or delays as a result of products which are out
            of stock, unavailable etc. It is the responsibility of you and the relevant Merchants, not us, to resolve
            any dispute or claim raised by you relating to any payments or fund transfer made via the D4V Pay Services.
            We may, however, initiate a refund if (a) a payment made via the D4V Pay Services is reversed by a court,
            regulatory authority or other third party acting in accordance with applicable law, (b) we, in our sole and
            absolute discretion, accept or have reason to believe that a payment was not authorized by you, or (c) a
            payment is allegedly unlawful, suspicious, or in breach of this Agreement. Where you have a dispute with
            Merchants, we will provide information we hold, which may assist resolution and otherwise attempt to assist
            you as far as we consider practicable. We may charge you an administration fee to cover our reasonable costs
            of providing any such assistance. Dial4Vyapar is as an independent entity under the terms and conditions of
            this Agreement. Dial4Vyapar has no relationship with Merchant. You alone shall be responsible and neither
            Dial4Vyapar nor the Bank or anybody connected to Dial4Vyapar shall have any responsibility or liability.
            Dial4Vyapar is neither concerned nor required to monitor in any manner the use of the payment or fund
            transfer modes by you. You should be required to use the payment modes at their sole option and risks.
            <h3> Our Obligations</h3>
            By registering to use of D4V Pay Services, you consent to conduct transactions and receive communications,
            notices and information from us electronically or otherwise, whether sent by e-mail or other means.
            Communications shall be deemed to have been received by you when we send the communication to the email
            address/mobile number that as per our records, or when we post the communication on Dial4Vyapar website. You
            can withdraw your consent at any time by contacting us, but we reserve the right to terminate your account
            upon such withdrawal. Withdrawal of your consent will not affect the legal validity and enforceability of
            any notice, statement or disclosure previously received. You agree to notify us promptly if your email
            address or other contact information changes by updating your account information or contacting us.
            <h3>YOUR USE OF OUR INFORMATION</h3>
            Except for Posted Information that you submit, all of the information available on or through the Services
            and/or the Website, including without limitation, text, photographs, graphics and video and audio content,
            is owned by us and our licensors and is protected by copyright, trademark, patent and trade secret laws,
            other proprietary rights and international treaties. You acknowledge that the Services and any underlying
            technology or software used in connection with the Services contain Dial4Vyapar's proprietary information.
            We give you permission to use the aforementioned content for personal, non-commercial purposes only and do
            not transfer any intellectual property rights to you by virtue of permitting your use of the Services. You
            may not copy, distribute, republish (except as permitted in this paragraph), sell, or exploit any of the
            content, or exploit the Website in whole or in part, for any commercial gain or purpose whatsoever. Except
            as is expressly and unambiguously provided herein, we do not grant you any express or implied rights, and
            all rights in the Website and the Services not expressly granted by us to you are retained by Dial4Vyapar.
            <h3>TERMINATION/SUSPENSION OF SERVICE</h3>
            We may suspend or terminate your access to the D4V Pay Services without notice where it is reasonable for us
            to do so and specifically if: (i) for any reason we decide to discontinue to provide the D4V Pay, (ii) we
            believe that you have breached any of the terms of this Agreement, (iii) your use of the D4V Pay has been in
            any way improper or breaches the spirit of this Agreement, (iv) we reasonably believe use of the D4V Pay may
            be at risk of fraud or misuse; (v) our information technology infrastructure has failed, is experiencing
            outages or requires maintenance; or (vi) ordered or recommended to do so by the security services, court or
            a relevant regulatory authority. Dial4Vyapar reserves the right to suspend/discontinue D4V PAY Services to
            you at any time, for any cause, including, but not limited, to the following- For any suspected violation of
            the rules, regulations, orders, directions, notifications issued by RBI from time to time or for any
            violation of the terms and conditions mentioned in this document For any suspected discrepancy in the
            particular(s), documentation or registration Form provided by you; To combat potential fraud, sabotage,
            willful destruction, threat to national security or for any other force majeure reasons etc; If the same is
            due to technical failure, modification, upgradation, variation, relocation, repair, and/or maintenance due
            to any emergency or for any technical reasons. If the same is due to any transmission deficiencies caused by
            topographical and geographical constraints/limitations; If the mobile connection with which your D4V PAY
            Services is related ceases to be operational or in your possession or control. If Dial4Vyapar believes, in
            its reasonable opinion, that cessation/ suspension is necessary.
            <h3>THIRD PARTY INFORMATION ON THE WEBSITE</h3>
            As some information appearing on the Website is provided to Dial4Vyapar by third parties, Dial4Vyapar will
            have no liability in respect of any loss or damage arising from third party information which appears on the
            Website, including the manner in which the information is displayed or information which may be: Out of
            date; Inaccurate; Duplication; Impersonation by a person to be another person. All dealings and
            communication arising from Dial4Vyapar's facilitation of interaction between Merchant and users will be
            between the Merchant and users only and Dial4Vyapar is not responsible in any manner for any communication
            between the users and the Merchant, and has no liability in respect of such dealings whatsoever. Dial4Vyapar
            is not an agent for any of the Merchants listed on this Website and Dial4Vyapar has no responsibility for
            and no liability whatsoever in respect of the conduct of a Merchant or quality of service provided by a
            Merchant.
            <h3> Additional T&amp;C</h3>
            When you acquire goods, software or any other services from a Merchant Establishment through any of
            Dial4Vyapar’s Services, you understand and agree that, Dial4Vyapar is not a party to the contract between
            you and the Merchant Establishment. Furthermore, Dial4Vyapar is under no obligation to monitor the Merchant
            Establishment’s service used by you; the Merchant Establishment alone will be responsible for all
            obligations under the contract including (without limitation) warranties or guarantees. Any dispute with or
            complaint against any Merchant Establishment must be directly resolved by the Customer with the Merchant
            Establishment. It is clarified that Dial4Vyapar shall not be responsible or liable for any deficiency in
            goods and/or services purchased using D4V PAY Services. This exclusion of liability shall apply even for
            goods and/or services made available by Dial4Vyapar under promotional schemes. You are instructed to satisfy
            yourself regarding the quality, quantity and fitness of any good and/or service before purchasing the same.
            Any amount transferred erroneously by the Customer to any Merchant Establishment shall not be refunded to
            the Customer by Dial4Vyapar in any circumstances. In the event of any dispute, Dial4Vyapar records shall be
            binding as the conclusive evidence of the Transactions carried out through use of D4V PAY Services.
            Dial4Vyapar shall send all customer communications by SMS and/or email/link and the SMS/link/email shall be
            deemed to have been received by you after they have been submitted for delivery to the mobile phone
            operator. You agree to receive all commercial message including transactional messages from Dial4Vyapar.
            <h3> DISCLAIMER</h3>
            PLATFORM/NETWORK AND SERVICES OFFERED. WE EXPRESSLY DISCLAIM THE IMPLIED WARRANTIES OF NON-INFRINGEMENT,
            MERCHANTABILITY, AND FITNESS FOR ANY PARTICULAR PURPOSE. THE D4V PAY SERVICES IS PROVIDED "AS IS" “WHERE
            IS”, “WITH ALL FAULTS” BASIS. WE MAKE NO WARRANTY OR REPRESENTATION, WHETHER EXPRESS OR IMPLIED, IN RELATION
            TO THE SATISFACTORY QUALITY, FITNESS FOR PURPOSE, COMPLETENESS OR ACCURACY OF THE SERVICE OFFERED THROUGH
            THE D4V PAY. WE MAKE NO WARRANTY THAT THE OPERATION OF THE D4V PAY WILL BE UNINTERRUPTED, TIMELY, SECURE OR
            ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SOFTWARE OR THE SERVER THAT MAKES IT AVAILABLE ARE
            FREE OF VIRUSES OR BUGS ,OR REPRESENT THE FULL FUNCTIONALITY, ACCURACY, RELIABILITY OF THE MATERIALS
            THEREIN.
            <h3> LIMITATION OF LIABILITY</h3>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, NEITHER DIAL4VYAPAR, NOR ITS AFFILIATED
            COMPANIES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES OR ANY RELATED PARTY SHALL HAVE ANY LIABILITY TO
            USERS OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF
            REVENUE OR PROFITS ARISING UNDER OR RELATING TO THESE TERMS, THE SITE OR THE OFFERINGS, EVEN IF ANY OF SAID
            PARTIES HAD BEEN ADVISED OF, KNEW OF, OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. TO THE
            MAXIMUM EXTENT PERMITTED BY LAW, OUR MAXIMUM AGGREGATE LIABILITY TO YOU FOR ANY CAUSES WHATSOEVER, AND
            REGARDLESS OF THE FORM OF ACTION (WHETHER LIABILITY ARISES DUE TO NEGLIGENCE OR OTHER TORT, BREACH OF
            CONTRACT, VIOLATION OF STATUTE, MISREPRESENTATION OR FOR ANY OTHER REASON), WILL AT ALL TIMES BE LIMITED TO
            INR ONE HUNDRED (INR 100).
            <h3>INDEMNITY</h3>
            You agree to indemnify, defend and hold Dial4Vyapar and/or related parties harmless from any and all claims,
            losses, damages, and liabilities, penalty, costs and expenses, including and without limitation legal fees
            and expenses, arising out of or related to your use or misuse of the D4V PAY Services, any breach of these
            Terms and Conditions, or any breach of the representations, warranties, and covenants made by you.
            <h3>COMPLIANCE WITH LAW</h3>
            Dial4Vyapar shall comply with the laws as applicable for the time being in force.
            <h3>CONFIDENTIALITY</h3>
            You specifically agree that in order to facilitate the provision of D4V PAY Services, Dial4Vyapar may be
            required to disclose any information or particulars pertaining to you to any authority, statutory or
            otherwise.
            <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
            The Dial4Vyapar hereby grants you the non-exclusive, non-transferable right to use our D4V Pay services in
            accordance with this Agreement. We, or our licensors, own all right, title and interest, including, but not
            limited to all copyright, trademark, patent, trade secret or other proprietary rights (“IP Rights”), in and
            to the website, the Usage Data or D4V Pay services. You shall not reproduce, distribute, transmit, modify,
            create derivative works, display, perform or otherwise use the Site, the Platform or any of the IP Rights,
            or attempt to reverse engineer, decompile, disassemble, or derive the source code for the D4V Pay to create
            a competing product. You hereby acknowledge that by using the D4V Pay services, you obtain no rights in the
            software, trademarks or content related to the D4V Pay and you may only use the same in accordance with this
            Agreement. Unauthorized use is strictly prohibited. All rights are expressly reserved to Dial4Vyapar.
            <h3>SYSTEM COMPLAINTS AND DISPUTE RESOLUTION</h3>
            For Redressal of disputes/complaints you can email to us at D4Vsupport@dial4vyapar.com, with brief details
            of your complaint or call on our hotline No. 9552361110. Our customer service staff will acknowledge and
            redress your complaint on best effort basis.
            <h3>ARBITRATION</h3>
            Any dispute or difference which may arise at any time between the parties, as to the construction, meaning
            or effect of, or, as to any clause, matter or things contained herein, or as to the rights or liabilities of
            the parties under this Agreement, shall be referred to arbitration under the Arbitration and Conciliation
            Act, 1996 (Indian) to be adjudicated by a sole arbitrator to be appointed, at the sole discretion, by
            Dial4Vyapar. Arbitration shall be held at Mumbai, India. The proceeding of arbitration shall be in the
            English language. The arbitrator’s award shall be final and binding on the Parties. The parties irrevocably
            agree that the Agreement shall be governed by and construed in accordance with the laws of India and the
            courts of Mumbai, India, shall have exclusive jurisdiction to settle any dispute or claim that arises out of
            or in connection with this agreement and waive any objection to such personal jurisdiction based on forums
            non-convenience or any other basis.
            <h3>FORCE MAJEURE</h3>
            Dial4Vyapar shall not be responsible for any delay in the performance of any terms and conditions hereunder
            to the extent that such delay is caused by technical issue, server malfunction, war, revolution, riot, act
            of public enemy, terrorism, sabotage, epidemic, quarantine restrictions, accident, fire flood, tempest,
            earthquake, civil commotion, governmental action, Acts of God (force majeure ) including, but not restricted
            to, strike, lock-out, fire, break-down, war, destruction of network, web space or website by way of hacking,
            virus prone, defacement, stoppage of display or transmission of the website/app of Dial4Vyapar, Act, or
            Regulation, or restriction of Government, inability to secure Government authorization, or approval, or any
            other cause beyond their reasonable control, including the breakdown of systems and any other causes beyond
            its reasonable control.
            <h3> Genearal</h3>
            We reserve the right to modify or terminate the D4V Pay Services at any time, without notice, and we will
            not be liable to you as a result of any such action. Dial4Vyapar shall have the right to assign/transfer
            these presents to any third party including its holding company, subsidiaries, affiliates, associates group
            companies. This Agreement constitute the entire Agreement between Merchant and the Dial4Vyapar pertaining to
            the subject matter hereof and supersedes in their entirety all written or oral agreements between the
            parties. The Parties to this Agreement are independent contractors and nothing in this Agreement shall make
            them joint ventures, partners, employees, agents, associates, affiliates or other representatives of the
            other party hereto. To the maximum extent permitted by law, you waive, release, discharge and hold harmless
            Dial4Vyapar, its affiliated and subsidiary companies, and each of their directors, officers, employees, and
            agents, from any and all claims, losses, damages, liabilities, expenses and causes of action arising out of
            your use of the Platform/ D4V PAY Services. If any provision of this Agreement is determined to be
            unenforceable for any reason, then the remaining provisions hereof shall remain unaffected and in full force
            and effect. No variation or amendment to this Agreement shall bind either party unless made in writing and
            signed by the duly authorized persons/officers of both the parties. In this Agreement and unless otherwise
            stated, "we", "us" or "our" refers collectively to the Dial4Vyapar and its subsidiaries, affiliates,
            directors, officers, employees, agents and contractors.
          </div>
          <br />
          <br />
        </Container>
      </Card>
    </>
  );

export default TermsCondition;
