import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';

const persistStateToLocalStorage = function(prevState) {
    try{
        const serializedState = JSON.stringify(prevState);
        localStorage.setItem("dial4vyapar_vendor_state", serializedState);
    } catch(e){
        // console.log(e);
    }
} 

const fetchPersistedStateFormLocalStorage = function(){
    try{
        const serializedState = localStorage.getItem("dial4vyapar_vendor_state");
        if(serializedState === null){
            return undefined;
        }
        return JSON.parse(serializedState);
    }catch(e){
        // console.log(e);
    }
}

const  persistedState= fetchPersistedStateFormLocalStorage();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const dataStore = createStore(
    reducers, 
    persistedState,
    composeEnhancers(
        applyMiddleware(ReduxThunk)
    )
)

dataStore.subscribe(() => persistStateToLocalStorage(dataStore.getState()));

export default dataStore;