import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React from 'react';

const LogoutModelBox = (props) => (
    <Dialog
      open={props.isOpen}
      keepMounted
      onClose={props.handleSuspendClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: { borderRadius: '10px', padding: '30px' },
      }}
    >
      <DialogTitle sx={{ fontWeight: '500', fontSize: '16px', p: 0, mb: 1 }}>Do you want to logout?</DialogTitle>
      <DialogContent sx={{ p: 0, mb: 3 }}>
        <DialogContentText id="alert-dialog-slide-description" sx={{ fontWeight: '500', fontSize: '14px', p: 0, m: 0 }}>
          After logout your session will expire but you can login again.
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{}}>
        <Button
          //   loading={submitLoader && true}
          size="small"
          type="submit"
          onClick={props.handleLogout}
          variant="contained"
          style={{ float: 'center', margin: 10, background: 'red', width: '86px', height: '34px', borderRadius: '6px' }}
          sx={{ '&:hover': { bgcolor: 'red' } }}
        >
          Logout
        </Button>
        <Button
          size="small"
          type="submit"
          onClick={props.handleLogoutCancel}
          variant="contained"
          sx={{ '&:hover': { bgcolor: 'teal' } }}
          style={{
            float: 'center',
            background: 'teal',
            margin: 10,
            width: '86px',
            height: '34px',
            borderRadius: '6px',
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

export default LogoutModelBox;
