import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Card,
  Button,
  Typography,
  Stack,
  Box,
  Divider,
  Grid,
  TextField,
  Autocomplete,
  IconButton,
} from '@mui/material';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { fetchProfile } from '../api/CommanApicall';

import ShowMessage from './Modal/ShowMessage';
import BusinessDetails from './TabComp/BusinessDetails';
import BusinessAddress from './TabComp/BusinessAddress';
import BusinessDocument from './TabComp/BusinessDocument';
import BusinessPlansPayment from './TabComp/BusinessPlansPayment';
import ShopAddressDetails from './TabComp/ShopAddressDetails';

const StepperRegistration = () => {
  const steps = [
    'Business Details',
    'Personal Address Details',
    'Upload Documents',
    'Plans and Payments',
    'Shop Address Details',
  ];

  const [activeStep, setActiveStep] = useState(0);

  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const selectedPlanUUID = useRef('');
  const selectedPlanName = useRef('');

  const showToastMessage = (message) => {
    setIsShow(true);
    setError(false);
    setErrorMessage(message);
    setTimeout(() => {
      setIsShow(false);
    }, 1500);
  };

  const showErrorToastMessage = (message) => {
    setIsShow(true);
    setError(true);
    setErrorMessage(message);
    setTimeout(() => {
      setIsShow(false);
    }, 1500);
  };

  const getVendorProfile = async () => {
    try {
      const response = await fetchProfile();
      if (response.data?.data) {
        const profileInfo = response.data.data[0];
        console.log('profileInfo', profileInfo);

        setActiveStep(profileInfo.last_completed_step ?? 0);
      }
    } catch (error) {
      // Handle error if fetchProfile() fails
      console.error('Error fetching vendor profile:', error);
    }
  };

  useEffect(() => {
    getVendorProfile();
  }, []);

  return (
    <>
      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      <Box px={'10px'}>
        <Stepper activeStep={activeStep}>
          {steps?.map((label) => (
            <Step key={label}>
              <StepLabel>
                <Typography fontWeight={'500'} fontSize={'16px'}>
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Card sx={{ mt: '30px', ml: '100px', mr: '100px', padding: '50px' }}>
          {activeStep === 0 && (
            <BusinessDetails
              isStepper
              showToastMessage={(message) => {
                showToastMessage(message);
              }}
              showErrorToastMessage={(message) => {
                showErrorToastMessage(message);
              }}
              onSave={() => {
                setActiveStep(1);
              }}
            />
          )}

          {activeStep === 1 && (
            <BusinessAddress
              isStepper
              showToastMessage={(message) => {
                showToastMessage(message);
              }}
              onSave={() => {
                setActiveStep(2);
              }}
              onBackPress={() => setActiveStep(0)}
            />
          )}

          {activeStep === 2 && (
            <BusinessDocument
              isStepper
              showToastMessage={(message) => {
                showToastMessage(message);
              }}
              showErrorToastMessage={(message) => {
                showErrorToastMessage(message);
              }}
              onSave={() => {
                setActiveStep(3);
              }}
              onBackPress={() => setActiveStep(1)}
            />
          )}

          {activeStep === 3 && (
            <BusinessPlansPayment
              isStepper
              showToastMessage={(message) => {
                showToastMessage(message);
              }}
              showErrorToastMessage={(message) => {
                showErrorToastMessage(message);
              }}
              onBackPress={() => setActiveStep(2)}
              onSave={(planId, planName) => {
                selectedPlanUUID.current = planId;
                selectedPlanName.current = planName;
                setActiveStep(4);
              }}
            />
          )}

          {activeStep === 4 && (
            <ShopAddressDetails
              isStepper
              showToastMessage={(message) => {
                showToastMessage(message);
              }}
              showErrorToastMessage={(message) => {
                showErrorToastMessage(message);
              }}
              selectedPlanUUID={selectedPlanUUID.current}
              selectedPlanName={selectedPlanName.current}
              onBackPress={() => setActiveStep(3)}
            />
          )}
        </Card>
      </Box>
    </>
  );
};

export default StepperRegistration;
