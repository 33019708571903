import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Card, Grid, Container, Box, Typography, Button, TextField, MenuItem, Divider } from '@mui/material';
import { green } from '@mui/material/colors';
import Page from '../../components/Page';
import photo from '../../Assets/photo.png';
import profile from '../../Assets/profile.png';

import { GetCandidates, GetCity, GetEducation, GetJobCategory, GetState } from '../../actions/CandidateAction';
import Fullloader from '../Loader/Fullloader';
import ShowMessage from '../Modal/ShowMessage';

const Candidates = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [candidateList, setCandidateList] = useState([]);
  console.log('candidateList', candidateList);
  const [jobCategoryId, setJobCategoryId] = useState('');
  console.log('jobCategoryId', jobCategoryId);
  const [educationId, setEducationId] = useState();
  const [stateValue, setStateValue] = useState('');
  console.log('stateValue', stateValue);
  const [cityValue, setCityValue] = useState('');
  const [isReset, setIsReset] = useState(false);
  const loaderRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const pageSize = 10;

  const { candidates, jobCategory, education, states, city } = useSelector((state) => ({
    candidates: state.candidates.candidates,
    jobCategory: state.candidates.jobCategory,
    education: state.candidates.education,
    states: state.candidates.state,
    city: state.candidates.city,
  }));

  console.log('JOB CATEGORY', jobCategory);

  useEffect(() => {
    const reqObj = {
      page,
      limit: pageSize,
      jobCategoryId,
      educationId,
      stateValue,
      cityValue,
    };
    dispatch(GetCandidates(reqObj));
    setPage(page + 1);
    dispatch(GetJobCategory(reqObj));
    dispatch(GetEducation(reqObj));

    dispatch(GetState(reqObj));
    setIsLoading(true);
  }, []);

  const apiRef = useRef({
    jobCatIdRef: jobCategoryId,
    stateRef: stateValue,
    cityRef: cityValue,
  });

  const fetchCandidates = () => {
    const reqObj1 = {
      page,
      limit: pageSize,
      jobCategoryId: apiRef.current.jobCatIdRef,
      stateValue: apiRef.current.stateRef,
      cityValue: apiRef.current.cityRef,
    };

    console.log('reqibj1', reqObj1);
    setLoading(true);
    dispatch(GetCandidates(reqObj1));
    setPage(page + 1);
  };

  useEffect(() => {
    // Function to fetch data for a given page

    const handleScroll = () => {
      if (loaderRef.current && isBottom(loaderRef.current) && !loading) {
        fetchCandidates();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [page, loading]);

  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  };

  useEffect(() => {
    setIsLoading(false);
    if (isReset) {
      console.log('ISRESET', candidates.data);
      setCandidateList(candidates?.data);
      setIsReset(false);
    } else {
      setCandidateList((prevArray) => [...prevArray, ...candidates?.data]);
    }
    setLoading(false);
  }, [candidates]);

  const handleJobCategoryChange = (e) => {
    setJobCategoryId(e.target.value);
    apiRef.current.jobCatIdRef = e.target.value;
  };

  const handleEducationChange = (e) => {
    setEducationId(e.target.value);
  };

  const handleStateChange = (e) => {
    setStateValue(e.target.value);
    setCityValue('');
    dispatch(GetCity(e.target.value));
    apiRef.current.state = e.target.value;
  };

  const handleCityChange = (e) => {
    setCityValue(e.target.value);
    apiRef.current.city = e.target.value;
  };
  const showErrorToastMessage = (message) => {
    setIsShow(true);
    setError(true);
    setErrorMessage(message);
    setTimeout(() => {
      setIsShow(false);
    }, 1500);
  };

  const handleSearchCandidate = () => {
    const reqObj = {
      page: 1,

      limit: pageSize,
      jobCategoryId,
      stateValue,
      cityValue,
    };

    if (!reqObj.jobCategoryId && (!reqObj.stateValue || !reqObj.cityValue)) {
      console.log('Please select either Job category or State and City');
      showErrorToastMessage('Please select either Job category or State and City');
    }
    if (reqObj.stateValue && !reqObj.cityValue) {
      console.log('Please select a City for the selected State');
      showErrorToastMessage('Please select a City for the selected State');
    } else {
      setIsReset(true);
      setIsLoading(true);
      // setPage(2);
      dispatch(GetCandidates(reqObj))
        .then((response) => {
          setCandidateList(response.data);
        })
        .catch((error) => {
          console.error('Error fetching candidates:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleReset = () => {
    apiRef.current.jobCatIdRef = '';
    apiRef.current.stateRef = '';
    apiRef.current.cityRef = '';
    setJobCategoryId('');
    setStateValue('');
    setCityValue('');
    setIsReset(true);
    setIsLoading(true);
    setPage(1);

    const reqObj = {
      page: 1,
      limit: pageSize,
      jobCategoryId: apiRef.current.jobCatIdRef,
      // educationId: '',
      stateValue: apiRef.current.stateRef,
      cityValue: apiRef.current.cityRef,
    };

    dispatch(GetCandidates(reqObj))
      .then((response) => {
        setCandidateList(response.data);
      })
      .catch((error) => {
        console.error('Error fetching candidates:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Page title="Dashboard" sx={{ width: '100%' }}>
      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type={'error'} open={isShow} />
      <Container maxWidth="xl">
        <Typography variant="h5" sx={{ mb: 1 }}>
          Candidates
          <Typography fontSize={'14px'} sx={{ fontWeight: '300' }}>
            List of all Candidates in your app.
          </Typography>
        </Typography>
        <Card sx={{ marginTop: '25px', p: 5 }}>
          <Grid container spacing={2} alignItems="center" mb={2}>
            <Fullloader open={isLoading} />
            <Grid item xs={6} sm={3} md={3}>
              <TextField
                select
                label="Select Job Category"
                variant="outlined"
                fullWidth
                onChange={handleJobCategoryChange}
                value={jobCategoryId && jobCategoryId}
              >
                {jobCategory?.data
                  ?.slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  ?.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value.uuid}>
                        {value.name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
              <TextField
                select
                label="Select State"
                variant="outlined"
                fullWidth
                onChange={handleStateChange}
                value={stateValue}
              >
                {states?.data?.map((value, index) => {
                  console.log('valuestste', value);
                  return (
                    <MenuItem key={index} value={value.value}>
                      {value.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <TextField select label="Select City" variant="outlined" fullWidth onChange={handleCityChange}>
                {stateValue ? (
                  city?.data?.map((value, index) => (
                    <MenuItem key={index} value={value.value}>
                      {value.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No state selected</MenuItem>
                )}
              </TextField>
            </Grid>

            <Grid item xs={6} sm={3} md={3} sx={{ display: 'flex', gap: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                // fullWidth
                onClick={handleSearchCandidate}
                // sx={{ paddingY: '15px', fontSize: '15px' }}
              >
                Search Candidte
              </Button>
              <Button
                variant="contained"
                color="primary"
                // fullWidth
                onClick={handleReset}
                // sx={{ paddingY: '15px', fontSize: '15px' }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {candidateList?.length === 0
              ? !isLoading && (
                  <Grid container spacing={2} alignItems="center" mt={2}>
                    <Typography
                      fontSize={'14px'}
                      sx={{
                        fontWeight: '500',
                        justifyContent: 'center',
                        display: 'flex',
                        margin: 'auto',
                        padding: '20px',
                      }}
                    >
                      No Record Found
                    </Typography>
                  </Grid>
                )
              : candidateList?.map((employee) => (
                  <Grid item key={employee.id} xs={12} sm={6} md={4}>
                    <EmployeeCard candidate={employee} />
                  </Grid>
                ))}
          </Grid>
          <Box ref={loaderRef} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {loading && <CircularProgress color="inherit" />}
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

const EmployeeCard = ({ candidate }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate('/dashboard/viewCandidate', {
      state: {
        candidateData: candidate,
      },
    });
  };

  const useStyles = makeStyles(() => ({
    ellipsis: {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      textOverflow: 'ellipsis',
      height: '58px',
      maxHeight: '58px',
    },
  }));

  const classes = useStyles();

  return (
    <Card sx={{ p: 2, textAlign: 'center', boxShadow: '0px 0px 30.2px 0px #00000040', marginTop: 5 }}>
      <Box textAlign="center" mb={2}>
        <img
          src={candidate?.profile_photo_link ? candidate?.profile_photo_link : profile}
          alt={candidate?.first_name}
          style={{ borderRadius: '100px', height: '80px', width: '80px', margin: 'auto' }}
        />
      </Box>
      <Typography
        fontWeight={'700'}
        fontSize={'18px'}
        sx={{ textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {candidate.first_name} {candidate.last_name}
      </Typography>
      <Typography fontWeight={'400'} fontSize={'14px'}>
        {candidate?.recruitmentJobCategoryModel ? candidate?.recruitmentJobCategoryModel?.name : 'NA'}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 1,
          fontSize: '11px',
          background: '#0B82383B',
          border: '1px solid #035421',
          display: 'inline-block',
          borderRadius: '6px',
          padding: '4px 10px',
          fontWeight: '500',
        }}
      >
        {candidate.email_address ? candidate.email_address : 'NA'}
      </Typography>
      <Typography fontWeight={'400'} fontSize={'12px'} className={classes.ellipsis}>
        {candidate.profile_description ? candidate.profile_description : 'NA'}
      </Typography>
      <Box display="flex" justifyContent="space-between" style={{ fontWeight: '600', marginTop: '10px' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography fontWeight={'700'} fontSize={'14px'}>
            {candidate.work_experience ? candidate.work_experience : 'NA'}
          </Typography>
          <Typography style={{ fontSize: '13px' }}>Experience</Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography fontWeight={'700'} fontSize={'14px'}>
            {candidate?.recruitmentEducation ? candidate?.recruitmentEducation?.name : 'NA'}
          </Typography>
          <Typography variant="body2" style={{ fontSize: 12 }}>
            Education
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography fontWeight={'700'} fontSize={'14px'}>
            {candidate.gender ? candidate.gender : 'NA'}
          </Typography>
          <Typography variant="body2" style={{ fontSize: 12 }}>
            Gender
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: '10px' }} />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleViewDetails()}
        sx={{
          marginTop: '5px',
          width: '100%',
          borderRadius: '51px',
          '&:hover': {
            bgcolor: 'green',
          },
        }}
      >
        View Details
      </Button>
    </Card>
  );
};

export default Candidates;
