import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
// routes
import routes from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import './App.css';

// ----------------------------------------------------------------------

export default function App() {
  const { isLogged, vendorDetail } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    vendorDetail: state.vendorDetailDocs,
  }));

  const routing = useRoutes(routes(isLogged, vendorDetail));

  return (
    <ThemeProvider>
      <ScrollToTop />
      {routing}
    </ThemeProvider>
  );
}
