import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Container, Card, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { GetVendorJobs, DeleteVendorJob } from '../../actions/VendorJobsAction';
import Page from '../../components/Page';
import JobCategoryButton from '../../components/Component/TableComponent/JobCategoryButton';
import ActionColumn from '../../components/Component/TableComponent/ActionColumn';
import StatusColumn2 from '../../components/Component/DataGridComponents/StatusColumn2';
import PagesTopBar from '../../components/Component/PagesTopBar';
import CommonDeleteBox from '../../components/Component/ModelBox/CommonDeleteBox';
import ShowMessage from '../Modal/ShowMessage';

export default function Jobs() {
  // ==================Const decleration====================
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { vendorJobs, isloading, profileData } = useSelector((state) => ({
    vendorJobs: state?.vendorJobs?.vendorJobs,
    isloading: state.vendorJobs.isloading,
    profileData: state.profileData?.uuid,
  }));
  // ==================Use staate====================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [filterData, setFilterData] = useState(null);
  const [vendorJobDelete, setVendorJobDelete] = useState();
  const [open, setOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [serialNumber, setSerialNumber] = useState(0);

  // ==================Use Effects====================
  useEffect(() => {
    dispatch(GetVendorJobs(paramsData, profileData));
  }, []);

  useEffect(() => {
    dispatch(GetVendorJobs(paramsData, profileData));
  }, [filterData, paginationModel]);

  // ====================constnts for datagrid==========================
  const columns = [
    {
      field: 'vendor_id',
      headerName: 'Sr no',
      width: 100,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    { field: `job_title`, headerName: 'Job Title', width: 150, type: 'string' },
    { field: 'job_description', headerName: ' Description', width: 200, type: 'string' },
    {
      field: 'job_min_experience',
      headerName: 'Minimum Experience',
      width: 150,
      renderCell: (params) => {
        console.log('params in edu', params);
        return (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            {params.row.job_min_experience} years
          </Typography>
        );
      },
    },

    {
      field: 'job_max_experience',
      headerName: ' Maximun Experience',
      width: 150,
      renderCell: (params) => {
        console.log('params in edu', params);
        return (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            {params.row.job_max_experience} years
          </Typography>
        );
      },
    },
    {
      field: 'recruitment_education_uuid',
      headerName: 'Recruitment Education ID',
      width: 150,
      renderCell: (params) => {
        console.log('params in edu', params);
        return params.row.recruitmentEducation ? params.row.recruitmentEducation.name : '';
      },
    },
    {
      field: 'no_of_openings',
      headerName: 'Jobs Open ',
      width: 150,
      renderCell: (params) => {
        console.log('params in edu', params);
        return (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            {params.row.no_of_openings ? params.row.no_of_openings : ''}
          </Typography>
        );
      },
    },
    { field: 'state', headerName: 'State', width: 150, type: 'string' },
    { field: 'city', headerName: 'City', width: 150, type: 'string' },
    {
      field: 'job_min_salary',
      headerName: ' Min Salary ',
      width: 150,
      renderCell: (params) => (
        <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
          &#8377; {params?.row?.job_min_salary}
        </Typography>
      ),
    },
    {
      field: 'job_max_salary',
      headerName: ' Max Salary ',
      width: 150,
      renderCell: (params) => (
        <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
          &#8377; {params?.row?.job_max_salary}
        </Typography>
      ),
    },
    {
      field: 'job_Status',
      headerName: 'Job Status',
      width: 150,
      renderCell: (params) => <StatusColumn2 params={params.row} />,
    },
    {
      field: 'job_type',
      headerName: 'Job Type',
      width: 200,
    },

    {
      field: `recruitmentJobCategoryModel`,
      headerName: 'Job Category',
      width: 150,
      renderCell: (params) => {
        return (
          <JobCategoryButton
            data={params.row.recruitmentJobCategoryModel ? params.row.recruitmentJobCategoryModel.name || '' : ''}
          />
        );
      },
    },
    {
      field: `createdAt`,
      headerName: 'Created On',
      width: 250,
      type: 'string',

      renderCell: (params) => {
        return moment(params?.row?.createdAt).format('DD/MM/YYYY hh:MM A');
      },
    },
    {
      field: 'col6',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <ActionColumn
          Candidates
          handleView={() => handleView(params.row)}
          handleEdit={() => handleEdit(params.row)}
          handleDelete={() => handleDelete(params.row)}
        />
      ),
    },
  ];
  const rows = [];

  const paramsData = {
    filterData,
    page,
    rowsPerPage,
  };

  const getPeginationModelValue = (e) => {
    console.log('getPeginationModelValue', e);
    setSerialNumber(e.page * e.pageSize);
    setPaginationModel(e);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };

  const handleEdit = (rowData) => {
    navigate('/dashboard/createVendorJob', {
      state: {
        jobData: rowData,
      },
    });
  };
  const handleDelete = (e) => {
    setVendorJobDelete(e.uuid);
    setOpen(!open);
  };
  const handleSubmit = () => {
    dispatch(DeleteVendorJob(vendorJobDelete))
      .then((response) => {
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          setErrorMessage(response.data.message);
          dispatch(GetVendorJobs(paramsData, profileData));
          setTimeout(() => {
            setIsShow(false);
            // navigate(-1);
          }, 1500);
        }
      })
      .catch((error) => {
        console.error('Error deleting reviewer:', error);
      });
  };
  const handleView = (rowData) => {
    navigate('/dashboard/viewJob', {
      state: {
        jobData: rowData,
      },
    });
  };
  const handleSearch = (value) => {
    setFilterData(value);
    setSearchValue(value);
  };
  const handleDeleteCancle = () => {
    // setReviewerDelete(e.uuid);
    setOpen(!open);
  };
  const downloadXlsx = () => {
    // dispatch(downloadUserExcel());
  };

  return (
    <Page title="User" sx={{ width: '100%' }}>
      {/* -----------------Models---------------- */}
      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Vendor Job"
          description="Are you sure you want to delete vendor job ?"
        />
      ) : null}
      {/* --------------container------------------ */}
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <PagesTopBar
          candidate
          //  downloadXlsx={downloadXlsx}
          excel
          Add
          title="Vendor Jobs"
          subTitle="List of all Vendor Jobs in your app."
          btnText="Add Vendor job"
          link="/dashboard/createVendorJob"
          handleSearch={handleSearch}
          clearInput={() => {
            handleSearch('');
          }}
        />

        <Card sx={{ marginTop: '25px' }}>
          <Grid container>
            <Grid item lg={12} xs={12} sm={12}>
              <DataGrid
                style={{
                  padding: '10px',
                  height: 420,
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '14px',
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={getPeginationModelValue}
                pageSizeOptions={[5, 10, 25]}
                paginationMode="server"
                loading={isloading}
                rows={vendorJobs?.data ? vendorJobs?.data : rows}
                columns={columns}
                getRowId={(row) => row.id}
                rowCount={vendorJobs?.data_count}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        bgcolor: '#ffffff',
                        pb: '0px',
                        '& fieldset': { border: 'none' },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}
