import { LOGIN, LOG_OUT, RESET_STATE } from '../actions/Types';

const INIT_STATE = {
  isLogged: false,
  loggedUser: null,
  isLoggedOut: false
};

export default function AuthReducer(state = INIT_STATE, { type, payload } = {}) {
  switch (type) {
    case LOGIN: {
      let isLoggedValue;
  
      if (state.isLogged === true) {
        isLoggedValue = 1;
      } else if (state.isLogged === 1) {
        isLoggedValue = true;
      } else {
        isLoggedValue = !state.isLogged;
      }
  
      return {
        ...state,
        loggedUser: payload,
        isLogged: isLoggedValue,
      };
    }
      
    case LOG_OUT:
      return {
        ...state,
        isLoggedOut: !state.isLoggedOut,
      };
    case RESET_STATE:
      return { ...INIT_STATE };
    default:
      return state;
  }
}
