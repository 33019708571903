import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Container } from '@material-ui/core';
import { InputField, SelectField } from './FormFields';
import axiosInstance from '../../../api/withJWTServer';
import CheckboxList from '../Steps/CheckaboxList';
import InputTagField from './FormFields/InputTagField';
import InputTime from './FormFields/InputTime';
import noImage from '../../../Assets/no_image.png';

import { HandleExceptionWithSecureCatch } from '../../../actions/CombineCatch';
import Iconify from '../../../components/Iconify';
import { PROFILE_DATA } from '../../../actions/Types';
import InputEndTime from './FormFields/InputEndTime';
import { fetchProfile } from '../../../api/CommanApicall';

export default function BusinessDetail(props) {
  const [businessLogoError, setBusinessLogoError] = useState('');
  const [selectAllCheckBox, setSelectAllCheckbox] = useState(false);
  const [openOtherField, setOpenOtherField] = useState(false);
  const [selectCategory, setSelectCategory] = useState('');
  const { profileData } = useSelector((state) => ({
    profileData: state?.profileData,
  }));
  const [categoryOption, setCategoryOption] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();

  const fileInputRef = React.createRef();
  const {
    formField: {
      bussinessName,
      otherCategory,
      bussinessExpertise,
      tag,
      googlereviewlink,
      checkboxList,
      businessCategory,
      businessFromTime,
      businessToTime,
      bussinessDescription,
    },
  } = props;
  useEffect(() => {
    axiosInstance.get('vendor/categories').then((res) => {
      const data = res?.data?.data;
      setCategoryOption(data);
    });
  }, []);
  useEffect(() => {
    if (profileData?.vendorBusinessDetailsModels?.[0]?.category_id === 0) {
      setOpenOtherField(true);
    }
  }, []);

  // const allowedFileExtensions = ['.jpg', '.jpeg', '.png'];
  const options = [
    { value: 'Sun', label: 'Sun', is_checked: profileData?.buinessTimingsModels?.[0]?.sunday_open },
    { value: 'Mon', label: 'Mon', is_checked: profileData?.buinessTimingsModels?.[0]?.monday_open },
    { value: 'Tue', label: 'Tue', is_checked: profileData?.buinessTimingsModels?.[0]?.tuesday_open },
    { value: 'Wed', label: 'Wed', is_checked: profileData?.buinessTimingsModels?.[0]?.wednesday_open },
    { value: 'Thu', label: 'Thu', is_checked: profileData?.buinessTimingsModels?.[0]?.thursday_open },
    { value: 'Fri', label: 'Fri', is_checked: profileData?.buinessTimingsModels?.[0]?.friday_open },
    { value: 'Sat', label: 'Sat', is_checked: profileData?.buinessTimingsModels?.[0]?.saturday_open },
  ];

  const option = [
    { value: 'Sun', label: 'Sun', is_checked: 1 },
    { value: 'Mon', label: 'Mon', is_checked: 1 },
    { value: 'Tue', label: 'Tue', is_checked: 1 },
    { value: 'Wed', label: 'Wed', is_checked: 1 },
    { value: 'Thu', label: 'Thu', is_checked: 1 },
    { value: 'Fri', label: 'Fri', is_checked: 1 },
    { value: 'Sat', label: 'Sat', is_checked: 1 },
  ];

  // useEffect(()=>{

  // })

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Read the selected file as a data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileExtension = getFileExtension(file.name);
        if (fileExtension.toLowerCase() === 'pdf' || fileExtension.toLowerCase() === 'gif') {
          // Set the selected image in the state
          setSelectedImage(null);
        } else {
          // Set the selected image in the state
          setSelectedImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
    if (file) {
      const fileExtension = getFileExtension(file.name);
      const fileSize = file.size; // File size in bytes
      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      const maxSizeInMB = 5;

      if (fileExtension.toLowerCase() === 'pdf' || fileExtension.toLowerCase() === 'gif') {
        // Disallow PDF files, display an error message
        setBusinessLogoError('Please select a JPG, JPEG, or PNG file.');
      } else if (fileSize > maxSizeInBytes) {
        // File size exceeds the limit, display an error message
        setBusinessLogoError(`File size exceeds ${maxSizeInMB}MB. Please select a smaller file.`);
      } else {
        // File is valid and within the size limit
        setBusinessLogoError('');
        props.onFileUpload(event.target.files[0]);
      }
    }
  };

  const getFileExtension = (fileName) => fileName.split('.').pop().toLowerCase();

  const onSelectAll = (value) => {
    props.selectedAllCheckBox(value);
    setSelectAllCheckbox(!selectAllCheckBox);
  };

  const companyLogoDocPath = profileData?.vendorBusinessDocs?.company_logo?.doc_path;
  const isPdfUrl = /\.pdf$/i.test(companyLogoDocPath);

  const otherCategoryField = (e) => {
    setOpenOtherField(e);
  };

  const selectedCategory = (e) => {
    setSelectCategory(e);
  };

  const selectedTagProp = (tag) => {
    // console.log('selectedTagProp', tag);
  };

  const handleTags = (tag) => {
    props.handleInputTags(tag);
  };

  const deleteDocs = (e) => {
    // const params = { id: String(e) };
    if (e) {
      axiosInstance
        .delete('vendor/delete-doc', {
          params: {
            id: String(e),
          },
        })
        .then((res) => {
          async function profile() {
            try {
              const profileDetail = await fetchProfile();
              dispatch({
                type: PROFILE_DATA,
                payload: profileDetail?.data?.data[0] === undefined ? null : profileDetail?.data?.data[0],
              });
            } catch (error) {
              console.error(error);
            }
          }
          profile();
          dispatch(HandleExceptionWithSecureCatch(res));
        })
        .catch((err) => {
          dispatch(HandleExceptionWithSecureCatch(err));
        });
    }
  };

  const handleClearImage = () => {
    setSelectedImage(null);
    fileInputRef.current.value = '';
    props.onFileUpload(null);
  };
  const handleImageClick = () => {
    // Trigger the file input when the placeholder is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <>
      <Container>
        <Typography variant="h6" gutterBottom>
          Business Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputField
              name={bussinessName.name}
              disabled={profileData?.status === 'Active' ? 1 : 0}
              label={bussinessName.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              name={businessCategory.name}
              disabled={profileData?.status === 'Active' ? 1 : 0}
              label={businessCategory.label}
              data={categoryOption}
              fullWidth
              otherCategory={otherCategory}
              otherCategoryField={otherCategoryField}
              selectedCategory={selectedCategory}
            />
            {/* {openOtherField && (
              <InputFieldOther
                name={otherCategory.name}
                categoryData={categoryOption}
                label={otherCategory.label}
                openOtherField={openOtherField}
                fullWidth
              />
            )} */}
            <p style={{ color: 'grey', fontSize: '13px', marginTop: '2px' }}>
              If you didn't find category please contact admin.
              <a
                style={{ color: 'green', textDecoration: 'none', fontWeight: '600', marginLeft: '3px' }}
                href="mailto:info@sunngrahak.com"
              >
                Send Email
              </a>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={bussinessDescription.name} label={bussinessDescription.label} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <Typography variant="h6" gutterBottom>
              Business Logo
            </Typography>
            <div>
              {!selectedImage && (
                <div
                  role="button"
                  tabIndex="0"
                  style={{
                    display: 'flex',
                    color: '#333',
                    cursor: 'pointer',
                  }}
                  onClick={handleImageClick}
                  onKeyPress={(e) => e.key === 'Enter' && handleImageClick()} // Trigger on Enter key press
                >
                  <img src={noImage} alt="Selected" style={{ maxWidth: '25%', maxheight: '10%' }} />
                  <p style={{ paddingTop: '40px' }}>
                    {!companyLogoDocPath && 'Please upload your business logo.'} Tap to select logo
                  </p>
                </div>
              )}

              <input
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
              />

              {selectedImage && (
                <div>
                  <p>Selected Image:</p>
                  <div>
                    <div style={{ position: 'relative' }}>
                      <Iconify
                        icon="eva:minus-outline"
                        sx={{
                          // float: 'right',
                          position: 'absolute',
                          height: '25px',
                          width: '25px',
                          color: '#fff',
                          background: 'red',
                          borderRadius: '15px',
                          cursor: 'pointer',
                        }}
                        onClick={handleClearImage}
                      />
                      <img src={selectedImage} alt="Selected" style={{ maxWidth: '130px', height: '130px' }} />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <br />
            <span style={{ color: 'red', fontSize: '12px' }}>{props.errorMsg}</span>
            <span style={{ color: 'red', fontSize: '12px' }}>{businessLogoError}</span>
          </Grid>{' '}
          {companyLogoDocPath && (
            <Grid item xs={12} sm={6} lg={6} md={6} style={{ marginTop: '35px' }}>
              {' '}
              <p style={{ float: 'right' }}>Uploaded Image:</p>
              <br />
              {companyLogoDocPath && (
                <>
                  {isPdfUrl ? (
                    <iframe
                      title="PDF Viewer"
                      src={`${companyLogoDocPath}`}
                      width="170px"
                      height="120px"
                      frameBorder="0"
                      scrolling="auto"
                    />
                  ) : (
                    <img
                      src={`${companyLogoDocPath}`}
                      alt="company logo"
                      style={{ height: '130px', width: '130px', float: 'right' }}
                    />
                  )}
                </>
              )}
              <Iconify
                icon="eva:minus-outline"
                sx={{
                  float: 'right',
                  marginRight: '-140px',
                  height: '25px',
                  width: '25px',
                  color: '#fff',
                  background: 'red',
                  borderRadius: '20px',
                }}
                onClick={() => deleteDocs(profileData?.vendorBusinessDocs?.company_logo?.id)}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" gutterBottom>
              Business timing
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: '-20px' }}>
            Opening Time:
            <br />
            <br />
            <InputTime name={businessFromTime.name} type="time" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: '-20px' }}>
            Closing Time:
            <br />
            <br />
            <InputEndTime name={businessToTime.name} type="time" fullWidth />
          </Grid>
          <hr />
          <Grid item xs={12} sm={12}>
            <CheckboxList
              row
              name={checkboxList.name}
              title=""
              nameType="days"
              options={options}
              option={option}
              selectAllCheckBox={selectAllCheckBox}
            />
            <span style={{ color: 'red', fontSize: '12px' }}>
              {props.timingError ? 'Please select a business day' : null}
            </span>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Business experience
            </Typography>
            <InputField
              name={bussinessExpertise.name}
              disabled={profileData?.status === 'Active' ? 1 : 0}
              label={bussinessExpertise.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Add Google Review Link of your Business
            </Typography>
            <InputField name={googlereviewlink.name} label={googlereviewlink.label} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            {' '}
            <Typography variant="h6" gutterBottom>
              Add Relevant Keywords For Your Business
            </Typography>
            <h5 style={{ marginTop: '-10px', marginBottom: '15px', color: 'rgba(0, 0, 0, 0.38)' }}>
              Only five tags can be added
            </h5>
            <InputTagField
              name={tag.name}
              label={tag.label}
              fullWidth
              selectCategory={selectCategory}
              selectedTagProp={selectedTagProp}
              handleTags={handleTags}
              maxlength={20}
            />
            <br />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
