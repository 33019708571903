import {  UPGRADE_PLANS } from "../actions/Types";

const INIT_STATE = [];

export default function upgradeplanReducer(state = INIT_STATE, {type, payload} = {}) {
   switch(type) {
        case UPGRADE_PLANS:
            return payload;

        default: 
            return state;
    }
}

