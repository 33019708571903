import React, { useState, useEffect } from 'react';

import {
  Button,
  Typography,
  Stack,
  Box,
  Divider,
  Grid,
  TextField,
  Autocomplete,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@mui/material';

import { Form, Formik, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { CheckCircle } from '@material-ui/icons';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import axios from 'axios';
import axiosInstance from '../../api/withJWTServer';
import { PROFILE_DATA } from '../../actions/Types';
import pdfImg from '../../Assets/pdf.png';
import { fetchProfile } from '../../api/CommanApicall';
import DocUploadComponent from './DocUploadComponent';

// import axiosInstance from '../../api/withJWTServer';

const BusinessDocument = (props) => {
  const dispatch = useDispatch();

  const [submitLoader, setSubmitLoader] = useState(false);
  const [submitFileType, setSubmitFileType] = useState('');

  const [docType, setDocType] = useState({
    gstDocType: 'gst_registration_doc',
    gumastaDocType: 'gumasta_doc',
    panDocType: 'pan_card',
    aadharFrontDocType: 'aadhar_card_front',
    aadharBackDocType: 'aadhar_card_back',
  });

  const [gstDoc, setGstDoc] = useState('');
  const [gumastaDoc, setGumastaDoc] = useState('');
  const [panDoc, setPanDoc] = useState('');
  const [aadharFrontDoc, setAadharFrontDoc] = useState('');
  const [aadharBackDoc, setAadharBackDoc] = useState('');

  const getVendorProfile = async () => {
    try {
      const response = await fetchProfile();
      if (response.data?.data) {
        // setProfileData(response.data.data);
        const profileInfo = response.data.data[0];
        console.log('profileInfo', profileInfo);

        let gumastaDoc = '';
        let gstDoc = '';
        let panDoc = '';
        let aadharFrontDoc = '';
        let aadharBackDoc = '';

        let gumastaDocID = '';
        let gstDocID = '';
        let panDocID = '';
        let aadharFrontDocID = '';
        let aadharBackDocID = '';
        const docKeys = Object.keys(profileInfo.vendorBusinessDocs);
        console.log('Keys', docKeys);
        if (profileInfo.vendorBusinessDocs) {
          if (docKeys.includes('gumasta_doc')) {
            gumastaDoc = profileInfo.vendorBusinessDocs.gumasta_doc.doc_path ?? '';
            gumastaDocID = profileInfo.vendorBusinessDocs.gumasta_doc.id ?? '';
          }

          if (docKeys.includes('gst_registration_doc')) {
            gstDoc = profileInfo.vendorBusinessDocs.gst_registration_doc.doc_path ?? '';
            gstDocID = profileInfo.vendorBusinessDocs.gst_registration_doc.id ?? '';
          }

          if (docKeys.includes('pan_card')) {
            panDoc = profileInfo.vendorBusinessDocs.pan_card.doc_path ?? '';
            panDocID = profileInfo.vendorBusinessDocs.pan_card.id ?? '';
          }

          if (docKeys.includes('aadhar_card_front')) {
            aadharFrontDoc = profileInfo.vendorBusinessDocs.aadhar_card_front.doc_path ?? '';
            aadharFrontDocID = profileInfo.vendorBusinessDocs.aadhar_card_front.id ?? '';
          }

          if (docKeys.includes('aadhar_card_back')) {
            aadharBackDoc = profileInfo.vendorBusinessDocs.aadhar_card_back.doc_path ?? '';
            aadharBackDocID = profileInfo.vendorBusinessDocs.aadhar_card_back.id ?? '';
          }
        }

        formik.setValues({
          gumasta_doc_path: gumastaDoc,
          gumasta_doc_path_id: gumastaDocID,
          gst_doc_path: gstDoc,
          gst_doc_path_id: gstDocID,
          pan_doc_path: panDoc,
          pan_doc_path_id: panDocID,
          aadhar_front_doc_path: aadharFrontDoc,
          aadhar_front_doc_path_id: aadharFrontDocID,
          aadhar_back_doc_path: aadharBackDoc,
          aadhar_back_doc_path_id: aadharBackDocID,
          vendor_status: profileInfo?.status ?? '',
        });
      }
    } catch (error) {
      // Handle error if fetchProfile() fails
      console.error('Error fetching vendor profile:', error);
    }
  };

  useEffect(() => {
    getVendorProfile();
  }, []);

  const formInitialValues = {
    gumasta_doc_path: '',
    gumasta_doc_path_id: '',
    gst_doc_path: '',
    gst_doc_path_id: '',
    pan_doc_path: '',
    pan_doc_path_id: '',
    aadhar_front_doc_path: '',
    aadhar_front_doc_path_id: '',
    aadhar_back_doc_path: '',
    aadhar_back_doc_path_id: '',
    vendor_status: '',
  };

  const submitDocuments = async () => {
    if (
      !formik.values.gst_doc_path &&
      !formik.values.gumasta_doc_path &&
      !formik.values.pan_doc_path &&
      !formik.values.aadhar_front_doc_path
    ) {
      props.showErrorToastMessage('Please select any one document for KYC.');
    } else if (formik.values.aadhar_front_doc_path && !formik.values.aadhar_back_doc_path) {
      props.showErrorToastMessage('Please select aadhar back side document.');
    } else if (!formik.values.aadhar_front_doc_path && formik.values.aadhar_back_doc_path) {
      props.showErrorToastMessage('Please select aadhar front side document.');
    } else {
      if (gstDoc) {
        await uploadDocuments(gstDoc, docType.gstDocType);
        setGstDoc('');
      }

      if (gumastaDoc) {
        await uploadDocuments(gumastaDoc, docType.gumastaDocType);
        setGumastaDoc('');
      }

      if (panDoc) {
        await uploadDocuments(panDoc, docType.panDocType);
        setPanDoc('');
      }

      if (aadharFrontDoc && aadharBackDoc) {
        await uploadDocuments(aadharFrontDoc, docType.aadharFrontDocType);
        await uploadDocuments(aadharBackDoc, docType.aadharBackDocType);
        setAadharFrontDoc('');
        setAadharBackDoc('');
      }
      //  if (aadharFrontDoc && aadharBackDoc === '') {
      //   props.showErrorToastMessage('Please select aadhar back side document.');
      // } else if (aadharFrontDoc === '' && aadharBackDoc) {
      //   props.showErrorToastMessage('Please select aadhar front side document.');
      // }

      getVendorProfile();
      if (props.isStepper) {
        props.onSave();
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    onSubmit: (values, actions) => {
      console.log('values in documents', values);

      submitDocuments();
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setFieldError } = formik;

  const deleteDocsApi = async (docId) => {
    setSubmitLoader(true);
    await axiosInstance
      .delete('vendor/delete-doc', {
        params: {
          id: String(docId),
        },
      })
      .then(async (res) => {
        if (res?.data?.status === 200) {
          props.showToastMessage(res.data.message ?? '');
        }

        setSubmitLoader(false);
        await getVendorProfile();
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const deleteDocument = async (docId) => {
    deleteDocsApi(docId);
  };

  const deleteLocalDocument = async (type) => {
    if (type === docType.gstDocType) {
      setGstDoc('');
      formik.values.gst_doc_path = '';
    } else if (type === docType.gumastaDocType) {
      setGumastaDoc('');
      formik.values.gumasta_doc_path = '';
    } else if (type === docType.panDocType) {
      setPanDoc('');
      formik.values.pan_doc_path = '';
    } else if (type === docType.aadharFrontDocType) {
      setAadharFrontDoc('');
      formik.values.aadhar_front_doc_path = '';
    } else if (type === docType.aadharBackDocType) {
      setAadharBackDoc('');
      formik.values.aadhar_back_doc_path = '';
    }
  };

  const openDoumentDialog = (fileType) => {
    if (fileType === docType.aadharBackDocType && aadharFrontDoc === '') {
      props.showErrorToastMessage('Please select aadhar card front side.');
      return;
    }

    if (!submitLoader) {
      const fileDialog = document.createElement('input');
      fileDialog.type = 'file';
      fileDialog.accept = '.pdf,.png,.jpeg,.jpg';
      fileDialog.addEventListener('change', (e) => {
        console.log('file', e.target.files);
        // setSelectedFile(e.target.files[0]);
        if (e.target.files.length > 0) {
          const file = e.target.files[0];
          if (fileType === docType.gstDocType) {
            setGstDoc(file);

            console.log('file name', file.name);

            formik.values.gst_doc_path = file.name;
          } else if (fileType === docType.gumastaDocType) {
            setGumastaDoc(file);
            formik.values.gumasta_doc_path = file.name;
          } else if (fileType === docType.panDocType) {
            setPanDoc(file);
            formik.values.pan_doc_path = file.name;
          } else if (fileType === docType.aadharFrontDocType) {
            setAadharFrontDoc(file);
            formik.values.aadhar_front_doc_path = file.name;
          } else if (fileType === docType.aadharBackDocType) {
            setAadharBackDoc(file);
            formik.values.aadhar_back_doc_path = file.name;
          }
          console.log('file path', formik.values.gst_doc_path);
        }
      });
      fileDialog.click();
    }
  };

  const uploadDocuments = async (businessFile, fileType) => {
    setSubmitLoader(true);
    const docs = new FormData();
    docs.append('business-doc', businessFile);
    docs.append('file_category', fileType);
    setSubmitFileType(fileType);
    await axiosInstance
      .post('vendor/upload-business-docs', docs)
      .then((response) => {
        if (response.data.status === 200) {
          props.showToastMessage('Document Uploaded Successfully !');
        }
        setSubmitLoader(false);
        setSubmitFileType('');
      })
      .catch((error) => {
        setSubmitFileType('');
        setSubmitLoader(false);
      });
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} lg={12}>
            <Grid lg={12} item mt={'5px'}>
              <Typography fontWeight={'500'} fontSize={'18px'}>
                Update Documents
              </Typography>

              <Typography fontWeight={'400'} fontSize={'14px'}>
                Upload / Update your business documents
              </Typography>
            </Grid>

            <Grid lg={4} item sx={{ borderRight: '1px solid rgba(0,0,0,0.1)' }}>
              <DocUploadComponent
                title={'GST Document'}
                subTitle={'Tap to upload gst document '}
                path={formik.values.gst_doc_path}
                submitFileType={submitFileType}
                docType={docType.gstDocType}
                submitLoader={submitLoader}
                selectedFile={gstDoc}
                openFileDialog={() => openDoumentDialog(docType.gstDocType)}
                deleteFile={() => deleteDocument(formik.values.gst_doc_path_id)}
                deleteLocalFile={() => deleteLocalDocument(docType.gstDocType)}
                status={formik.values.vendor_status}
              />
            </Grid>

            <Grid lg={4} item sx={{ borderRight: '1px solid rgba(0,0,0,0.1)' }}>
              <DocUploadComponent
                title={'Gumasta Document'}
                subTitle={'Tap to upload gumasta document'}
                path={formik.values.gumasta_doc_path}
                submitFileType={submitFileType}
                docType={docType.gumastaDocType}
                submitLoader={submitLoader}
                selectedFile={gumastaDoc}
                openFileDialog={() => openDoumentDialog(docType.gumastaDocType)}
                deleteFile={() => deleteDocument(formik.values.gumasta_doc_path_id)}
                deleteLocalFile={() => deleteLocalDocument(docType.gumastaDocType)}
                status={formik.values.vendor_status}
              />
            </Grid>

            <Grid lg={4} item>
              <DocUploadComponent
                title={'PAN Card Document'}
                subTitle={'Tap to upload pan card document'}
                path={formik.values.pan_doc_path}
                submitFileType={submitFileType}
                docType={docType.panDocType}
                submitLoader={submitLoader}
                selectedFile={panDoc}
                openFileDialog={() => openDoumentDialog(docType.panDocType)}
                deleteFile={() => deleteDocument(formik.values.pan_doc_path_id)}
                deleteLocalFile={() => deleteLocalDocument(docType.panDocType)}
                status={formik.values.vendor_status}
              />
            </Grid>

            <Grid item lg={12}>
              <Divider sx={{ my: '10px' }} />
            </Grid>

            <Grid lg={4} item>
              <DocUploadComponent
                title={'AADHAR Card Front Document'}
                subTitle={'Tap to upload aadhar card front document'}
                path={formik.values.aadhar_front_doc_path}
                submitFileType={submitFileType}
                docType={docType.panDocType}
                submitLoader={submitLoader}
                selectedFile={aadharFrontDoc}
                openFileDialog={() => openDoumentDialog(docType.aadharFrontDocType)}
                deleteFile={() => deleteDocument(formik.values.aadhar_front_doc_path_id)}
                deleteLocalFile={() => deleteLocalDocument(docType.aadharFrontDocType)}
                status={formik.values.vendor_status}
              />
            </Grid>
            <Grid lg={4} item>
              <DocUploadComponent
                title={'AADHAR Card Back Document'}
                subTitle={'Tap to upload aadhar card back document'}
                path={formik.values.aadhar_front_doc_path}
                submitFileType={submitFileType}
                docType={docType.aadharBackDocType}
                submitLoader={submitLoader}
                selectedFile={aadharBackDoc}
                openFileDialog={() => openDoumentDialog(docType.aadharBackDocType)}
                deleteFile={() => deleteDocument(formik.values.aadhar_back_doc_path_id)}
                deleteLocalFile={() => deleteLocalDocument(docType.aadharBackDocType)}
                status={formik.values.vendor_status}
              />
            </Grid>

            <Grid item lg={12}>
              <Divider sx={{ my: '20px' }} />

              <Stack direction={'row'} flex={1} justifyContent={'flex-end'}>
                {props.isStepper ? (
                  <LoadingButton
                    variant="outlined"
                    size="medium"
                    color="error"
                    disableElevation
                    onClick={props.onBackPress}
                    sx={{
                      alignSelf: 'flex-end',
                      padding: '8px 40px',
                      marginRight: '10px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Back
                  </LoadingButton>
                ) : null}
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={submitLoader}
                  disableElevation
                  sx={{
                    alignSelf: 'flex-end',
                    padding: '8px 40px',
                    textTransform: 'uppercase',
                    '&:hover': {
                      bgcolor: 'green',
                    },
                  }}
                >
                  {props.isStepper ? 'Save & Next ' : 'Save'}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default BusinessDocument;
