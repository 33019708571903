import React, { useEffect, useMemo } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';

export default function AlternateMobileField(props) {
  const { errorText, ...rest } = useMemo(() => props, [props]);
  const [field, meta, helpers] = useField(props);
  const { value } = field;

  useEffect(() => {
    const isValidMobileNumber = /^[1-9]\d{0,9}$/.test(value);

    if (value && !isValidMobileNumber) {
      helpers.setError('Mobile number should be a 10-digit number and should not start with leading zero');
    } else {
      helpers.setError(undefined);
    }
  }, [value, helpers]);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    return touched && error ? error : '';
  }

  return (
    <TextField
      type="text"
      error={meta.touched && Boolean(meta.error)}
      helperText={_renderHelperText()}
      value={value}
      inputProps={{ maxLength: 10 }} 
      {...field}
      {...rest}
    />
  );
}
